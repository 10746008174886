<div>

  <div class="config-tab-control">
    <div class="tab-links">
      <div class="link"
        (click)="onToggleGroup('settings')"
        [class.active]="activeGroup === 'settings'">
        Settings
      </div>
      <div class="link 2"
        (click)="onToggleGroup('stack_rules')"
        [class.active]="activeGroup === 'stack_rules'">
        Stacks
      </div>
      <div class="link 2"
        (click)="onToggleGroup('team_rules')"
        [class.active]="activeGroup === 'team_rules'">
        Teams
      </div>
      <div class="link 2"
        (click)="onToggleGroup('player_rules')"
        [class.active]="activeGroup === 'player_rules'">
        Players
      </div>
      <div class="link 2"
        (click)="onToggleGroup('matchup_rules')"
        [class.active]="activeGroup === 'matchup_rules'"
        *ngIf="jobData.jobSubType === 'classic' ">
        Matchups
      </div>
    </div>
  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'settings'">
    <div class="centered-form">
      <section *ngIf="jobData.jobSubType">
        <presets-manager [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="onClickSetPresetDefault($event.id, $event.index, $event.data)"
          (onClickRemovePresetDefault)="onClickRemovePresetDefault($event.id, $event.index, $event.data)"
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"></presets-manager>
      </section>

      <hr style="margin-bottom: 20px; margin-top: 24px">

      <div class="inline-inputs">
        <input-group label="Number of Lineups"
          tooltip="Number of lineups you want to generate"
          [(ngModel)]="jobData.options.numLineups"
          [min]="0"
          [max]="5000"
          [errorMessage]="'Value between 0 and 5,000'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Max Distance Between Batters"
          tooltip="Maximum Number of Batting Order Slots Allowed Between Hitters in Lineup"
          [(ngModel)]="jobData.options.maxDistanceBetweenBatters"
          [min]="0"
          [max]="3"
          [errorMessage]="'Value between 0 and 3'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Max Batters Vs Pitcher"
          tooltip="Maximum Number of Batters Allowed in Lineup vs Pitcher"
          [(ngModel)]="jobData.options.maxBattersVsPitcher"
          [min]="0"
          [max]="4"
          [errorMessage]="'Value between 0 and 4'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Min Unique Players"
          tooltip="Determines the minimum number of players that will be different between each lineup"
          [(ngModel)]="jobData.options.numUniques"
          [min]="1"
          [max]="5"
          [errorMessage]="'Value between 1 and 5'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Global Team Limit"
          tooltip="Team limit for the opto job"
          [(ngModel)]="jobData.options.globalTeamLimit"
          [min]="0"
          [max]="20"
          [errorMessage]="'Enter Valid Number Based on Roster Size'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [max]="10000000"
          [errorMessage]="'This field is required'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          (valueChange)="onValueChange({ userChange: true })"></input-group>

        <input-group label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          (valueChange)="onValueChange({ userChange: true })"></input-group>
      </div>

    </div>
  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'stack_rules'">

    <!-- PRIMARY STACK -->
    <div class="rules-list p16"
      *ngIf="jobData.options.secondaryStackConfig">

      <div class="flex space-between">
        <div class="job-options-header"
          style="margin-bottom: 8px;">
          Primary Stack
        </div>
        <tool-tip
          explanation="Select the minimum number of players and maximum number of players you wish to have in your primary stack. The list of teams below are the ones being considered for your primary stack. Add or remove teams as you wish" />
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="flex space-between">
        <div class="ss-form-group mr16">
          <div class="flex space-between">
            <label for="projectionMinimum">Min Players</label>
          </div>
          <input appSelectAllText
            type="number"
            class="ss-input expanded"
            id="primaryStackMin"
            [(ngModel)]="jobData.options.primaryStackConfig.min"
            (ngModelChange)="onValueChange({ userChange: true })"
            [ngClass]="{ 'error' : jobData.options.primaryStackConfig.min < 1 || jobData.options.primaryStackConfig.min == null || jobData.options.primaryStackConfig.min > stackConfigValLimit}">
          <div class="field-error"
            *ngIf="jobData.options.primaryStackConfig.min < 1 || jobData.options.primaryStackConfig.min == null || jobData.options.primaryStackConfig.min > stackConfigValLimit">
            Enter Valid Value
          </div>
        </div>
        <div class="ss-form-group">
          <div class="flex space-between">
            <label for="projectionMinimum">Max Players</label>
          </div>
          <input appSelectAllText
            type="number"
            class="ss-input expanded"
            id="primaryStackMax"
            [(ngModel)]="jobData.options.primaryStackConfig.max"
            (ngModelChange)="onValueChange({ userChange: true })"
            [ngClass]="{ 'error' : jobData.options.primaryStackConfig.max < 1 || jobData.options.primaryStackConfig.max == null || jobData.options.primaryStackConfig.max > stackConfigValLimit}">
          <div class="field-error"
            *ngIf=" jobData.options.primaryStackConfig.max < 1 || jobData.options.primaryStackConfig.max == null || jobData.options.primaryStackConfig.max > stackConfigValLimit">
            Enter Valid Value
          </div>
        </div>
      </div>

      <div class="flex"
        *ngIf="jobData.options.primaryStackConfig.teams.length < availableTeams.length">
        <typeahead [items]="availableTeams"
          (itemSelected)="onSelectStackTeam($event, 'primary')"
          label="Add Team"
          placeholder="Search Teams..."
          isClearAfterSelect="true"
          style="flex-grow: 1;"></typeahead>

        <div class="stack-option-btn"
          (click)="onClickClearStackTeams('primary')">Clear All</div>
        <div class="stack-option-btn"
          (click)="onClickRestoreStackTeams('primary')">Restore All</div>
      </div>

      <div class="stack-empty-error"
        *ngIf="jobData.options.primaryStackConfig.teams.length === 0">
        Stack Rule Cannot be Empty
      </div>

      <label class="solo-label">Teams</label>
      <div class="staged-chips"
        *ngIf="jobData.options.primaryStackConfig.teams.length > 0">
        <div class="item-chip"
          *ngFor="let team of jobData.options.primaryStackConfig.teams; let i = index">
          <div class="text">
            {{team.name}}
          </div>
          <div class="icon"
            (click)="onClickRemoveStackTeam(i, 'primary')">
            <svg-icon class="icon"
              [svgStyle]="{ 'height.px':18 }"
              src="/assets/icons/close-square.svg"></svg-icon>
          </div>
        </div>
      </div>

    </div>

    <!-- SECONDARY STACK -->
    <div class="rules-list p16"
      *ngIf="jobData.options.secondaryStackConfig">

      <div class="flex space-between">
        <div class="job-options-header"
          style="margin-bottom: 8px;">
          Secondary Stack
        </div>
        <tool-tip
          explanation="Select the minimum number of players and maximum number of players you wish to have in your secondary stack. The list of teams below are the ones being considered for your secondary stack. Add or remove teams as you wish" />
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="flex space-between">
        <div class="ss-form-group mr16">
          <div class="flex space-between">
            <label for="projectionMinimum">Min Players</label>
          </div>
          <input appSelectAllText
            type="number"
            class="ss-input expanded"
            id="secondaryStackMin"
            [(ngModel)]="jobData.options.secondaryStackConfig.min"
            (ngModelChange)="onValueChange({ userChange: true })"
            [ngClass]="{ 'error' : jobData.options.secondaryStackConfig.min < 1 || jobData.options.secondaryStackConfig.min == null  || jobData.options.secondaryStackConfig.min > stackConfigValLimit}">
          <div class="field-error"
            *ngIf=" jobData.options.secondaryStackConfig.min < 1 || jobData.options.secondaryStackConfig.min == null || jobData.options.secondaryStackConfig.min > stackConfigValLimit">
            Enter Value
          </div>
        </div>
        <div class="ss-form-group">
          <div class="flex space-between">
            <label for="projectionMinimum">Max Players</label>
          </div>
          <input appSelectAllText
            type="number"
            class="ss-input expanded"
            id="secondaryStackMax"
            [(ngModel)]="jobData.options.secondaryStackConfig.max"
            (ngModelChange)="onValueChange({ userChange: true })"
            [ngClass]="{ 'error' : jobData.options.secondaryStackConfig.max < 1 || jobData.options.secondaryStackConfig.max == null || jobData.options.secondaryStackConfig.max > stackConfigValLimit}">
          <div class="field-error"
            *ngIf=" jobData.options.secondaryStackConfig.max < 1 || jobData.options.secondaryStackConfig.max == null || jobData.options.secondaryStackConfig.max > stackConfigValLimit">
            Enter Value
          </div>
        </div>
      </div>

      <div class="flex"
        *ngIf="jobData.options.secondaryStackConfig.teams.length < availableTeams.length">
        <typeahead [items]="availableTeams"
          (itemSelected)="onSelectStackTeam($event, 'secondary')"
          label="Add Team"
          placeholder="Search Teams..."
          isClearAfterSelect="true"
          style="flex-grow: 1;"></typeahead>

        <div class="stack-option-btn"
          (click)="onClickClearStackTeams('secondary')">Clear All</div>
        <div class="stack-option-btn"
          (click)="onClickRestoreStackTeams('secondary')">Restore All</div>
      </div>

      <div class="stack-empty-error"
        *ngIf="jobData.options.secondaryStackConfig.teams.length === 0">
        Stack Rule Cannot be Empty
      </div>

      <label class="solo-label">Teams</label>
      <div class="staged-chips"
        *ngIf="jobData.options.secondaryStackConfig.teams.length > 0">
        <div class="item-chip"
          *ngFor="let team of jobData.options.secondaryStackConfig.teams; let i = index">
          <div class="text">
            {{team.name}}
          </div>
          <div class="icon"
            (click)="onClickRemoveStackTeam(i, 'secondary')">
            <svg-icon class="icon"
              [svgStyle]="{ 'height.px':18 }"
              src="/assets/icons/close-square.svg"></svg-icon>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="settings-wrapper"
  *ngIf="activeGroup === 'player_rules'">
  <div class="rules-list p16">

    <div class="flex space-between">
      <div class="job-options-header"
        style="margin-bottom: 8px;">
        Player Rules
      </div>
      <div class="import-setting-link"
        (click)="onClickImportPlayerRules()">Import Recent</div>
    </div>

    <hr style="margin-bottom: 20px;">

    <div class="rule-wrapper"
      *ngFor="let rule of jobData.rules; let i = index">
      <div class="info">
        <div class="title">
          <span>{{rule.type === 'at-least' ? 'At Least' : 'At Most' }} - {{rule.value}}</span>
        </div>
        <div class="players-wrapper">
          <div class="player"
            *ngFor="let player of rule.players">{{player.name}}</div>
        </div>
      </div>
      <div class="icon"
        (click)="onClickRemoveRule(i)">
        <svg-icon class="icon"
          [svgStyle]="{ 'height.px':20 }"
          src="/assets/icons/close-square.svg"></svg-icon>
      </div>
    </div>

    <button class="ss-utility-btn secondary fluid"
      (click)="onClickAddRule()"
      *ngIf="!showRuleForm">Add a
      Rule</button>

    <div *ngIf="showRuleForm">

      <div class="input-row">

        <div class="ss-form-group">
          <label for="contestSlate">Rule Type:</label>
          <select class="ss-input expanded"
            id="type"
            [(ngModel)]="ruleType">
            <option value="at-least">At Least</option>
            <option value="at-most">At Most</option>
          </select>
        </div>

        <div class="ss-form-group">
          <label for="contestType">Number:</label>
          <input appSelectAllText
            class="ss-input expanded"
            id="value"
            placeholder="Number"
            type="number"
            maxlength="100"
            [(ngModel)]="ruleValue" />
        </div>

      </div>

      <div class="staged-players"
        *ngIf="rulePlayers.length > 0">
        <div class="player-chip"
          *ngFor="let player of rulePlayers; let i = index">
          <div class="text">
            {{player.name}}
          </div>
          <div class="icon"
            (click)="onClickRemoveStagedPlayer(i)">
            <svg-icon class="icon"
              [svgStyle]="{ 'height.px':18 }"
              src="/assets/icons/close-square.svg"></svg-icon>
          </div>
        </div>
      </div>

      <player-type-ahead [items]="searchablePlayers"
        (itemSelected)="onSelectPlayer($event)"
        label="Select Player:"
        placeholder="Search Players..."></player-type-ahead>

      <div class="base-error-text mb16">
        {{ruleError}}
      </div>

      <div class="save-row">
        <div class="actions">
          <button class="ss-utility-btn secondary"
            (click)="onClickCancelRule()">Cancel</button>
          <button class="ss-utility-btn"
            (click)="onClickSaveRule()"
            [disabled]="rulePlayers.length === 0 || !ruleValue">Add Rule</button>
        </div>
      </div>

    </div>

  </div>
  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'team_rules'">

    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header"
          style="margin-bottom: 8px;">
          Team Rules
        </div>
        <div class="import-setting-link"
          (click)="onClickImportTeamRules()">Import Recent</div>
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="rule-wrapper"
        *ngFor="let rule of jobData.teamRules; let i = index">
        <div class="info">
          <div class="title"
            style="margin-bottom: 0;">
            <span>{{rule.team}} - {{rule.type === 'team-limit' ? 'Limit' : 'At Least' }}: {{rule.value}}</span>
          </div>
        </div>
        <div class="icon"
          (click)="onClickRemoveTeamRule(i)">
          <svg-icon class="icon"
            [svgStyle]="{ 'height.px':20 }"
            src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <button class="ss-utility-btn secondary fluid"
        (click)="onClickAddTeamRule()"
        *ngIf="!showTeamRuleForm">Add a
        Rule</button>

      <div *ngIf="showTeamRuleForm">

        <div class="input-row">

          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded"
              id="type"
              [(ngModel)]="teamRuleType">
              <option value="team-limit">Team Limit</option>
              <option value="team-at-least">Team at Least</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input appSelectAllText
              class="ss-input expanded"
              id="value"
              placeholder="Number"
              type="number"
              maxlength="100"
              [(ngModel)]="teamRuleValue" />
          </div>

        </div>

        <typeahead [items]="availableTeams"
          (itemSelected)="onSelectTeam($event)"
          label="Select Team:"
          placeholder="Search Teams..."></typeahead>

        <div class="base-error-text mb16">
          {{teamRuleError}}
        </div>

        <div class="save-row">
          <div class="actions">
            <button class="ss-utility-btn secondary"
              (click)="onClickCancelTeamRule()">Cancel</button>
            <button class="ss-utility-btn"
              (click)="onClickSaveTeamRule()"
              [disabled]="!teamRuleValue || !teamRuleTeam">Add Rule</button>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'matchup_rules'">

    <div class="rules-list p16"
      *ngIf="jobData.jobSubType === 'classic' ">

      <div class="flex space-between">
        <div class="job-options-header"
          style="margin-bottom: 8px;">
          Matchup Rules
        </div>
        <div class="import-setting-link"
          (click)="onClickImportMatchupRules()">Import Recent</div>
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="rule-wrapper"
        *ngFor="let rule of jobData.matchupRules; let i = index">
        <div class="info">
          <div class="title"
            style="margin-bottom: 0;">
            <span>{{rule.matchup}} - {{rule.type === 'matchup-limit' ? 'Limit' : 'At Least' }}: {{rule.value}}</span>
          </div>
        </div>
        <div class="icon"
          (click)="onClickRemoveMatchupRule(i)">
          <svg-icon class="icon"
            [svgStyle]="{ 'height.px':20 }"
            src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <button class="ss-utility-btn secondary fluid"
        (click)="onClickAddMatchupRule()"
        *ngIf="!showMatchupRuleForm">Add a
        Rule</button>

      <div *ngIf="showMatchupRuleForm">

        <div class="input-row">

          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded"
              id="type"
              [(ngModel)]="matchupRuleType">
              <option value="matchup-limit">Matchup Limit</option>
              <option value="matchup-at-least">Matchup at Least</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input appSelectAllText
              class="ss-input expanded"
              id="value"
              placeholder="Number"
              type="number"
              maxlength="100"
              [(ngModel)]="matchupRuleValue" />
          </div>

        </div>

        <typeahead [items]="availableMatches"
          (itemSelected)="onSelectMatchup($event)"
          label="Select Matchup:"
          placeholder="Search Matchups..."></typeahead>

        <div class="base-error-text mb16">
          {{matchupRuleError}}
        </div>

        <div class="save-row">
          <div class="actions">
            <button class="ss-utility-btn secondary"
              (click)="onClickCancelMatchupRule()">Cancel</button>
            <button class="ss-utility-btn"
              (click)="onClickSaveMatchupRule()"
              [disabled]="matchupRuleValue < 0 || !matchupRuleMatch">Add Rule</button>
          </div>
        </div>

      </div>

    </div>

  </div>


  <!-- Z IMPORTER -->
  <rules-importer *ngIf="showImportModal"
    [userId]="jobData.userId"
    [jobType]="jobData.jobType"
    [sport]="jobData.sport"
    [draftGroupId]="jobData.draftGroupId"
    (handleImportPlayerRules)="handleImportPlayerRules($event)"
    [importMode]="importMode"
    (handleImportTeamRules)="handleImportTeamRules($event)"
    (handleImportMatchupRules)="handleImportMatchupRules($event)"
    (handleCloseImportModal)="handleCloseImportModal()"></rules-importer>

</div>