import { Component, Input, OnInit } from '@angular/core';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, updateMetadata } from "firebase/storage";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StateService } from 'src/app/services/state.service';
import { JobsService } from 'src/app/services/jobs.service';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';

import setJobLineupHeaders from '../../../../job-utilities/set-job-lineups-headers';

interface OptimizedLineup {
  Lineup: Array<any>;
}

@Component({
  selector: 'universal-lineups-import',
  templateUrl: './universal-lineups-import.component.html',
  styleUrls: ['./universal-lineups-import.component.scss']
})
export class UniversalLineupsImportComponent {
  @Input() jobData: any;
  @Input() jobDraftGroup: any;

  headerTemplate: any;
  positionsString: any;

  userSubscription: any;

  storage: any;
  fullFilePath: string;
  fileURL: string;
  fileError: string;
  recordsAdded: any;

  fileUploadProgress: any;
  isFileAdded: boolean;
  isFileUploading: any;
  isDownloading: boolean;

  isFileValidating: boolean;
  validationComplete: boolean;
  validationResponse: any;

  availableOptoJobs: any;
  totalSelectedLinups: number;

  isSelectingOptoJobs: boolean;
  selectedOptoJobs: any;

  isSubscriptionLimit: boolean;

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private jobsService: JobsService,
    private subMonitor: SubMonitorService
  ) { }

  ngOnInit(): void {
    this.setCsvStrings();
    if (this.jobData?.lineups !== null) {
      this.fileURL = this.jobData.lineups.fileURL;
      this.recordsAdded = this.jobData.lineups.totalRecords;
      this.validationComplete = true;
    }
    this.storage = getStorage();

    if (this.jobData.contest) {
      this.checkForOptimizedLineups();
    }

    this.subMonitor.getSubscription().subscribe((data) => {
      if (data) {
        this.userSubscription = data;
      }
    });
  }

  async setCsvStrings() {
    this.headerTemplate = await setJobLineupHeaders(this.jobData.sport, this.jobData.site, this.jobData.jobSubType, false);
    this.positionsString = await setJobLineupHeaders(this.jobData.sport, this.jobData.site, this.jobData.jobSubType, true);
  }

  generateLineupsTemplateCSV() {
    let csv: string;

    if (this.headerTemplate) {
      csv = this.headerTemplate;
    } else {
      csv = "An Error Occurred Defining this Template";
    }

    this.downloadCSV(csv, `Job_Lineups_Template_${this.jobData.sport}.csv`);
  }

  private downloadCSV(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async readFileContent(file: File): Promise<void> {
    this.isFileAdded = true;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      const lineCount = this.countCsvRows(csvData);
      if ((this.userSubscription === 'ACE_SINGLE' || this.userSubscription === 'ACE_SINGLE_YEARLY' || this.userSubscription === 'ACE_BASIC' || this.userSubscription === 'ACE_BASIC_YEARLY') && lineCount > 2001) {
        this.isSubscriptionLimit = true;
        this.isFileAdded = false;
        return;
      }
      if (lineCount > 100001) {
        alert('Too Many Lineups - File contains more than 100,000 Lineups');
        return;
      }
      this.recordsAdded = lineCount - 1;
      this.handleFileUpload(file);
    };
    reader.readAsText(file);
  }

  countCsvRows(csvData: string): number {
    const lines = csvData.split('\n');
    const nonEmptyLines = lines.filter(line => line.trim().length > 0);
    return nonEmptyLines.length;
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  handleFileUpload(file: File): void {
    this.isFileUploading = true;
    this.stateService.setWizardDisabled(true);

    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const dateString = `${month}_${year}`;
    const fileUUID = this.generateUUID();

    const filePath = `job-assets/${dateString}/${this.jobData.userId}_${fileUUID}_Lineups`;
    const storageRef = ref(this.storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        this.fileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log('error with upload' + error)
        this.isFileUploading = false;
        this.stateService.setWizardDisabled(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.fullFilePath = uploadTask.snapshot.ref.fullPath;
          this.fileURL = downloadURL;
          const fileInfo = {
            fileURL: downloadURL,
            fullPath: this.fullFilePath,
            fileUUID: fileUUID,
            totalRecords: this.recordsAdded,
          };
          this.jobData.lineups = fileInfo;
          this.jobsService.saveJobDataSnapshot(this.jobData);
          this.isFileUploading = false;
          this.handleFileValidation();
        })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  }

  async downloadFile() {
    if (this.isDownloading === true) {
      return;
    }
    this.isDownloading = true;
    const filePath = this.jobData.lineups.fullPath;
    const fileName = `Job_Lineups_Processed_${this.jobData.slate}.csv`;

    const storageRef = ref(this.storage, filePath);

    const metadata = {
      contentDisposition: `inline; filename="${fileName}"`,
    };

    try {
      await updateMetadata(storageRef, metadata);

      // Get the download URL and initiate the download
      const url = await getDownloadURL(storageRef);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
      URL.revokeObjectURL(link.href);
      this.isDownloading = false;
    } catch (error) {
      // Handle any errors
      console.error('Error downloading file:', error);
      this.isDownloading = false;
    }
  }

  handleFileValidation() {
    this.isFileValidating = true;
    const selectedPlayers = this.jobData.draftGroup.filter((p) => p.selected === true);

    const validationPayload = {
      userId: this.jobData.userId,
      sport: this.jobData.sport,
      subType: this.jobData.jobSubType,
      site: this.jobData.site,
      contestId: this.jobData.contest.id,
      players: selectedPlayers,
      validationType: 'Lineups',
      fileURL: this.fileURL,
      filePath: this.fullFilePath,
      totalFileRows: this.recordsAdded,
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${environment.apiURL}/validate_lineups_data`, validationPayload, { headers }).subscribe(
      (response) => {
        this.validationResponse = response;
        if (response?.validatedFileURL) {
          this.jobData.lineups.fileURL = response.validatedFileURL;
          this.jobsService.saveJobDataSnapshot(this.jobData);
        }
        setTimeout(() => {
          this.isFileValidating = false;
          this.validationComplete = true;
        }, 500);
      },
      (error) => {
        // Handle errors here
        console.error('Error:', error);
      }
    );
  }

  clearCustomData() {
    this.jobData.lineups = null;
    this.fileURL = null;
    this.validationComplete = false;
    this.recordsAdded = null;
    this.isFileAdded = false;
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  checkForOptimizedLineups() {
    this.jobsService.getRecentUniversalJobs(this.jobData.userId, 10).then((data) => {
      const sportJobs = data.filter((j) => j.sport === this.jobData.sport);
      const optoJobs = sportJobs.filter((j) => j.jobType === 'optimizer');
      const matchingJobs = optoJobs.filter((j) => j.draftGroupId = this.jobData.draftGroupId);
      this.availableOptoJobs = matchingJobs;
      // if (this.jobData.optimizerSeed && this.fileURL == null) {
      //   this.handleOptimizerSeed();
      // }
    }).catch((e) => {
      console.log(e);
    })
  }

  onClickSelectOptoJobs() {
    this.availableOptoJobs.forEach((j) => {
      j.selected = false;
    })
    this.isSelectingOptoJobs = true;
    this.calcTotalSelectedLineups();
  }

  onSelectOptoJob(job) {
    if (job.selected) {
      this.availableOptoJobs.forEach((j) => {
        if (j.id === job.id) {
          job.selected = false;
        } else {
          job.selected = false;
        }
      })
    } else {
      this.availableOptoJobs.forEach((j) => {
        if (j.id === job.id) {
          job.selected = true;
        } else {
          job.selected = false;
        }
      })
    }
    this.calcTotalSelectedLineups();
  }

  onClickImportSelectedOptoLineups(isDownload) {
    const selectedJobs = this.availableOptoJobs.filter((j) => j.selected);
    let combinedLineups: Array<any> = [];
    for (const obj of selectedJobs) {
      if (obj.optoResult) {
        const lineups = [];
        obj.optoResult.forEach((result) => {
          lineups.push(result.Lineup);
        })

        combinedLineups = [...combinedLineups, ...lineups];
      }
    }
    this.generateOptoCSV(combinedLineups, isDownload);
    this.isSelectingOptoJobs = false;
  };

  generateOptoCSV(playerArrays, data) {
    const isDownload = data.download;
    const positionOrder = this.positionsString.split(',').map(s => s.trim());

    let csv = playerArrays
      .map((lineup) => {
  
        // Create an array to store players according to the position order
        const playersByPosition = positionOrder.map(position => {
          // Find the first player matching the current position
          const player = lineup.find(p => p.Position === position);
          
          // Remove the player from the lineup to ensure unique positions
          if (player) {
            // Remove the player from the lineup
            lineup.splice(lineup.indexOf(player), 1);
            return `${player.Name} (${player.Id})`;
          }
          return ''; // If no player found for this position
        });
  
        // Join the players for this lineup into a single line of CSV
        return playersByPosition.join(', ');
      })
      .join('\n');
  
    // Add header
    csv = `${this.positionsString}\n${csv}`;
  
    const fileName = `Job_Lineups_Optimized_${this.jobData.slate}.csv`;
    const blob = new Blob([csv], { type: 'text/csv;' });
    const file = new File([blob], fileName, { type: 'text/csv' });
  
    if (isDownload) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    } else {
      this.readFileContent(file);
    }
  }
  
  
  
  
  
  

  // generateOptoCSV(playerArrays, data) {
  //   const isDownload = data.download;

  //   const columnHeaders = this.positionsString;

  //   let csv = '';

  //   if (this.jobData.site === 'dk') {
  //     playerArrays.forEach((array) => {
  //       let row = '';
  //       array.forEach((player) => {
  //         row += `${player['Name']} (${player['PlayerId']}),`;
  //       });
  //       row = row.slice(0, -1);
  //       csv += `${row}\n`;
  //     });
  //   } else {
  //     playerArrays.forEach((array) => {
  //       let row = '';
  //       array.forEach((player) => {
  //         row += `${player['PlayerId']}:${player['Name']},`;
  //       });
  //       row = row.slice(0, -1);
  //       csv += `${row}\n`;
  //     });
  //   }

  //   csv = `${columnHeaders}\n${csv}`;

  //   const fileName = `Job_Lineups_Optimized_${this.jobData.contest.name}.csv`;
  //   const blob = new Blob([csv], { type: 'text/csv;' });
  //   const file = new File([blob], fileName, { type: 'text/csv' });

  //   if (isDownload) {
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = fileName;
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //   } else {
  //     this.readFileContent(file);
  //   }
  // }

  // handleOptimizerSeed() {
  //   this.isFileUploading = true;
  //   const jobId = this.jobData.optimizerSeed;

  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post<any>(`${environment.apiURL}/generate_lineups_from_optimizer_seed`, { jobId }, { headers }).subscribe(
  //     (response) => {
  //       const fileInfo = {
  //         fileURL: response.fileURL,
  //         fullPath: response.fullPath,
  //         totalRecords: response.totalRecords,
  //       }
  //       this.fileURL = response.fileURL;
  //       this.recordsAdded = response.totalRecords;
  //       this.jobData.lineups = fileInfo;
  //       this.jobsService.saveJobDataSnapshot(this.jobData);
  //       this.isFileUploading = false;
  //       this.handleFileValidation();
  //     },
  //     (error) => {
  //       // Handle errors here
  //       console.error('Error:', error);
  //     }
  //   );
  // }

  calcTotalSelectedLineups() {
    const selectedJobs = this.availableOptoJobs.filter((j) => j.selected === true);
    this.totalSelectedLinups = selectedJobs.reduce((total, job) => {
      return total + job.optoResult.length;
    }, 0);
  }

  handleCloseOverlay() {
    this.isSelectingOptoJobs = false;
    this.isSubscriptionLimit = false;
  }
}
