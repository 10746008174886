
export const KNOWN_NAME_HEADERS = [
    'Name',
    'Player',
    'Player Name',
    'dk_name',
    'fd_name',
]

export const KNOWN_PROJECTION_HEADERS = [
    'Projection',
    'DK Projection',
    'FD Projection',
    'Fpts',
    'Points',
    'total_points'
]

export const KNOWN_MINUTES_HEADERS = [
    'Minutes',
    'Min',
    'Player Minutes',
]

export const KNOWN_OWNERSHIP_HEADERS = [
    'Ownership',
    'FD Ownership',
    'DK Large Ownership',
    'Ownership %',
    'ProjOwn',
    'Own',
    'Own%',
    'projected_ownership'
]

export const KNOWN_FLEX_OWN_HEADERS = [
    'Flex Ownership',
    'FlexOwnership',
    'FlexOwn',
    'Flex Own',
    'UTIL %',
    'Ownership',
]

export const KNOWN_TOTAL_OWN_HEADERS = [
    'Total Ownership',
    'Total Own',
    'TotalOwn',
    'Own Total',
]

export const KNOWN_CPT_OWN_HEADERS = [
    'CPT Ownership',
    'CPT Own',
    'CPT OWN',
    'CPT %',
    'Captain Ownership',
    'Captain Own',
    'CptOwn',
    'CptnOwnership',
]

export const KNOWN_PRO_OWN_HEADERS = [
    'Pro Ownership',
    'PRO Ownership',
    'PRO Own',
    'ProOwnership',
    'Pro Own',
    'ProOwn',
]

export const KNOWN_STAR_OWN_HEADERS = [
    'Star Ownership',
    'STAR Ownership',
    'STAR Own',
    'StarOwnership',
    'Star Own',
    'StarOwn',
]

export const KNOWN_MVP_OWN_HEADERS = [
    'Mvp Ownership',
    'MVP Ownership',
    'MVP Own',
    'MvpOwnership',
    'Mvp Own',
    'MvpOwn',
]

export const KNOWN_CEILING_HEADERS = [
    'Ceiling',
    'ceiling',
    'DK Ceiling',
    'FD Ceiling',
]

export const KNOWN_STDDEV_HEADERS = [
    'StdDev',
    'stdDev',
    'Std Dev',
    'Standard Deviation',
    'std_dev',
]

export const KNOWN_FIELD_PTS_HEADERS = [
    'FieldPts',
    'Field Pts',
    'fieldPts',
]

export const KNOWN_MAKECUT_HEADERS = [
    'MakeCut',
    'Make Cut',
    'Make_Cut',
]

export const KNOWN_WINPROB_HEADERS = [
    'WinProb',
    'Win Prob',
    'Win_Prob',
]

export const KNOWN_KOPROB_HEADERS = [
    'KOProb',
    'KO Prob',
    'KO Prob',
    'KO_Prob',
    'KO%',
]

export const KNOWN_BATTINGORDER_HEADERS = [
    'BattingOrder',
    'Batting Order',
    'battingOrder',
    'batting order',
    'batting_order',
    'batting-order',
    'batting',
]

export const ALL_COMMON_HEADERS = [
    ...KNOWN_NAME_HEADERS,
    ...KNOWN_PROJECTION_HEADERS,
    ...KNOWN_MINUTES_HEADERS,
    ...KNOWN_OWNERSHIP_HEADERS,
    ...KNOWN_FLEX_OWN_HEADERS,
    ...KNOWN_TOTAL_OWN_HEADERS,
    ...KNOWN_CPT_OWN_HEADERS,
    ...KNOWN_PRO_OWN_HEADERS,
    ...KNOWN_STAR_OWN_HEADERS,
    ...KNOWN_MVP_OWN_HEADERS,
    ...KNOWN_CEILING_HEADERS,
    ...KNOWN_STDDEV_HEADERS,
    ...KNOWN_FIELD_PTS_HEADERS,
    ...KNOWN_MAKECUT_HEADERS,
    ...KNOWN_WINPROB_HEADERS,
    'Position',
    'PlayerId',
];
