import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalPlaces'
})
export class DecimalPlacesPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) {
      return ''; // Handle NaN values
    }
    return value.toFixed(3);
  }
}