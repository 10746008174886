// V2 File
export const NFL_LINEUP_FILTERS_OPTO = [
    {
        name: 'Player Exposure',
        key: 'playerExposure',
        max: 100,
        min: 0,
    },
    {
        name: 'Projection',
        key: 'fpts',
        max: 10000,
        min: 1,
    },
    {
        name: 'StdDev',
        key: 'stddev',
        max: 100,
        min: 0,
    },
    {
        name: 'Salary',
        key: 'salary',
        max: 50000,
        min: 1,
    },
    {
        name: 'Geo Mean',
        key: 'geoMean',
        max: 1,
        min: 0,
    },
    {
        name: 'Own Sum',
        key: 'ownSum',
        max: 1000,
        min: 1,
    },
];

export const NFL_LINEUP_FILTERS_SIM = [
    {
        name: 'Player Exposure',
        key: 'playerExposure',
        max: 100,
        min: 0,
    },
    {
        name: 'Projection',
        key: 'fpts',
        max: 1000,
        min: 1,
    },
    {
        name: 'Field Projection',
        key: 'fieldFpts',
        max: 1000,
        min: 1,
    },
    {
        name: 'ROI',
        key: 'roi',
        max: 1000,
        min: 0,
    },
    {
        name: 'Ceiling',
        key: 'ceiling',
        max: 1000,
        min: 1,
    },
    {
        name: 'Avg Cash Won',
        key: 'avgCashWon',
        max: 10000,
        min: -100,
    },
    {
        name: 'Salary',
        key: 'salary',
        max: 60000,
        min: 1,
    },
    {
        name: 'Geo Mean',
        key: 'geoMean',
        max: 1,
        min: 0,
    },
    {
        name: 'Sim Dupes',
        key: 'simDupes',
        max: 1000,
        min: 0,
    },
    {
        name: 'Contest Generations',
        key: 'numFields',
        max: 25,
        min: 1,
    },
    {
        name: 'Own Sum',
        key: 'ownSum',
        max: 10000,
        min: 1,
    },
    {
        name: 'Cash Prob',
        key: 'cashProb',
        max: 100,
        min: 0,
    },
    {
        name: 'Top 1 Prob',
        key: 'top1Prob',
        max: 100,
        min: 0,
    },
    {
        name: 'Win Prob',
        key: 'winProb',
        max: 100,
        min: 0,
    },
    {
        name: 'numFields',
        key: 'numFields',
        max: 1000,
        min: 0.01,
    },
    {
        name: 'fieldsKept',
        key: 'fieldsKept',
        max: 1000,
        min: 0.01,
    },
    {
        name: 'lineupScore',
        key: 'lineupScore',
        max: 1000,
        min: 0.01,
    },
    {
        name: 'medianROI',
        key: 'medianROI',
        max: 10000,
        min: 0,
    },
    {
        name: 'stdevROI',
        key: 'stdevROI',
        max: 10000,
        min: 0,
    },
    {
        name: 'minROI',
        key: 'minROI',
        max: 10000,
        min: 0,
    },
    {
        name: 'maxROI',
        key: 'maxROI',
        max: 10000,
        min: 0,
    },
    {
        name: 'medianDupes',
        key: 'medianDupes',
        max: 10000,
        min: 0,
    },
    {
        name: 'stdDevDupes',
        key: 'stdDevDupes',
        max: 10000,
        min: 0,
    },
    {
        name: 'minDupes',
        key: 'minDupes',
        max: 10000,
        min: 0,
    },
    {
        name: 'maxDupes',
        key: 'maxDupes',
        max: 10000,
        min: 0,
    }
]