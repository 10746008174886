import { Component, Input } from '@angular/core';

@Component({
  selector: 'player-stats',
  templateUrl: './player-stats.component.html',
  styleUrls: ['./player-stats.component.scss']
})
export class PlayerStatsComponent {
  @Input() jobData: any;

  columns = [
    { prop: 'playerName', name: 'Player Name', tooltip: null },
    { prop: 'playerPosition', name: 'Position', tooltip: null },
    { prop: 'simFieldOwn', name: 'Field Own', tooltip: 'Player ownership in the simulated contest field' },
    { prop: 'simProjOwn', name: 'Proj Own', tooltip: 'Player projected ownership' },
    { prop: 'simROI', name: 'ROI', tooltip: 'Average roi of lineups featuring that player' },
    { prop: 'simt1PercentProb', name: 'Top 1% Prob', tooltip: 'Percent of times a lineup featuring that player appeared in the top 1% finishes of a sim' },
    { prop: 'simWinProb', name: 'Win Prob', tooltip: 'Percent of times a lineup featuring that player won a sim' },
    { prop: 'top150Ownership', name: 'Top 150 Own', tooltip: 'Player ownership in the top 150 lineups by ROI' },
  ];

  isCopyingCSV: boolean = false;

  generatePlayerSummaryCsv(download) {
    const fileData = this.jobData.playerData.map((data) => ({
      playerName: data.playerName,
      playerPosition: data.playerPosition,
      simFieldOwn: data.simFieldOwn,
      simProjOwn: data.simProjOwn,
      simROI: data.simROI,
      simt1PercentProb: data.simt1PercentProb,
      simWinProb: data.simWinProb,
      top150Ownership: data.top150Ownership
    }))
    const csv = this.convertArrayToCSV(fileData);

    if (download) {
      this.downloadCSV(csv, `Player_Summary_${this.jobData.slate}.csv`);
    } else {
      this.isCopyingCSV = true;
      setTimeout(() => {
        const textarea = document.createElement('textarea');
        textarea.value = csv;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setTimeout(() => {
          this.isCopyingCSV = false;
        }, 700);
      }, 1000);
    }
  };

  private convertArrayToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
    return header + rows.join('');
  }

  private downloadCSV(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }

}
