import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsService } from 'src/app/services/jobs.service';
import { getStorage, ref, getDownloadURL, updateMetadata } from "firebase/storage";

@Component({
  selector: 'app-job-results',
  templateUrl: './job-results.component.html',
  styleUrls: ['./job-results.component.scss']
})
export class JobResultsComponent {

  userId: any;
  jobId: any;
  jobType: any;
  isLoadingJob: boolean = true;
  jobData: any;
  engineId: string = ''

  showProgress: boolean;
  showResults: boolean;
  jobSubscription: any;

  //full file
  storage: any;
  isDownloading: boolean;

  constructor(
    private route: ActivatedRoute,
    private jobsService: JobsService,
    private router: Router,
  ) {}


  ngOnInit() {
    this.storage = getStorage();
    this.generateEngineId();
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.jobType = params.get('jobType');
      this.jobId = params.get('jobId');
    });

    if (this.jobId && this.userId) {
      this.handleLoadJobData();
    }
  }

  generateEngineId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    this.engineId = result;
  }

  handleLoadJobData() {
    this.jobsService.getUniversalJob(this.userId, this.jobId).then((data) => {
      if (data) {
      this.handleJobData(data);
      }
    }).catch((e) => {
      console.log('error pulling optimizer job data', e);
    })
  };

  handleJobData(data) {
    this.jobData = data;
    console.log('existing job data', data);
    this.isLoadingJob = false;

    switch (this.jobData.status) {
      case 'complete':
        this.showProgress = false;
        this.showResults = true;
        break;
      case 'building':
      case 'Job Routed':
      case 'running-job':
        this.subscribeToJob();
        break;
      case 'failed':
        alert('the job failed, handle failure');
    }
  }

  subscribeToJob() {
    this.jobsService.subscribeToUniversalJob(this.userId, this.jobId, this.updateJobData.bind(this));
    this.showProgress = true;
  }

  updateJobData(documentSubscription) {
    if (documentSubscription !== null) {
      this.jobSubscription = documentSubscription;
      if (documentSubscription.status === 'complete') {
        this.jobData = this.jobSubscription;
        setTimeout(() => {
          this.showProgress = false;
          this.showResults = true;
        }, 4000);
        setTimeout(() => {
          this.jobsService.stopListeningToUniversalJob();
        }, 10000);
      }
    } else {
      console.log('Job not found');
    }
  }

  // File functions
  async onClickDownloadAll() {
    if (this.isDownloading) { return; };

    let extension = 'csv';
    if (this.jobData?.isSummaryZip === true) {
      extension = 'zip';
    }
    this.isDownloading = true;

    let filePath = `opto_results/${this.jobData.userId}_${this.jobData.jobId}.csv`;
    let fileName = `Optimizer_Results_${this.jobData.sport}.csv`;
    if (this.jobData.jobType === 'simulator') {
      filePath = `sim_results/${this.jobData.userId}_${this.jobData.jobId}.${extension}`;
      fileName = `Simulation_Results_${this.jobData.contest.name}.${extension}`;
    }

    const storageRef = ref(this.storage, filePath);

    const metadata = {
      contentDisposition: `inline; filename="${fileName}"`,
    };

    try {
      await updateMetadata(storageRef, metadata);

      // Get the download URL and initiate the download
      const url = await getDownloadURL(storageRef);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
      URL.revokeObjectURL(link.href);
      setTimeout(() => {
        this.isDownloading = false;
      }, 1000);
    } catch (error) {
      // Handle any errors
      console.error('Error downloading file:', error);

      setTimeout(() => {
        this.isDownloading = false;
      }, 1000);
    }
  }

  onCloseJob() {
    this.router.navigate(['results'])
  }

  ngOnDestroy() {
    this.jobsService.stopListeningToUniversalJob();
  }

}
