import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { getAuth } from 'firebase/auth';

import setJobOptions from 'src/app/pages/portal/job-creator/job-utilities/set-job-options';

import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'mlb-sim-options',
  templateUrl: './mlb-sim-options.component.html',
  styleUrls: ['./mlb-sim-options.component.scss']
})
export class MlbSimOptionsComponent {
  @Input() jobData: any;

  user: any;

  totalSimulations: number;

  activeGroup: string = 'settings';

  // MLB SPECIFIC
  teamStackConfig: any;

  availablePresets: any;
  isDefaultApplied: boolean;
  presetName: string;
  selectedPreset: any = null;
  showPresets: boolean;
  showSavePreset: boolean;
  deletePresetIndex: any;
  isMakingPresetDefault: boolean;
  defaultPresetExists: boolean;
  makePresetDefaultIndex: any;
  isSavingPreset: boolean;
  isDeletingPreset: boolean;
  valuesDirty: boolean;

  siteSalaryMax: any;
  isSalaryMaxEntryLimit: boolean;

  showImportModal: boolean = false;
  importMode: string = null;

  previousSlateId: any;

  updatedTeamStackCount: any;

  constructor(
    private jobsService: JobsService,
  ) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
      // Prevent the wheel event for number inputs
      event.preventDefault();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['jobData'] && changes['jobData'].currentValue) {
      this.checkSlateIdChange();
    }
  }

  ngDoCheck() {
    this.checkSlateIdChange();
  }

  private checkSlateIdChange() {
    if (this.previousSlateId !== this.jobData?.slateId) {
      const isSecondaryLoad = this.previousSlateId != null;
      if (this.jobData.options.teamStackConfig) {
        this.teamStackConfig = this.jobData.options.teamStackConfig;
      } else {
        this.generateTeamStackConfig();
      }
      this.previousSlateId = this.jobData.slateId;
    }
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = auth.currentUser;
    this.getUserOptionPresets({ skipMatch: false, setDefaults: false });
  }

  ngAfterContentInit() {

    if (this.jobData?.contest?.maximumEntries > 100000) {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 49800;
      } else {
        this.siteSalaryMax = 59500;
      }
      this.isSalaryMaxEntryLimit = true;
    } else {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 50000;
      } else {
        this.siteSalaryMax = 60000;
      }
    }

  }

  generateTeamStackConfig() {
    const uniqueTeams = [];

    this.jobData.draftGroup.forEach((player) => {
      const team = {
        name: player.Team,
        value: 100,
      };
      const teamExists = uniqueTeams.some(existingTeam => existingTeam.name === team.name);
      if (!teamExists) {
        uniqueTeams.push(team);
      }
    });

    this.teamStackConfig = uniqueTeams;
    this.jobData.options.teamStackConfig = uniqueTeams;
  }

  onValueChange(options) {
    if (options.userChange) {
      this.valuesDirty = true;
    }

    const isValid = this.validateOptionValues();
    if (isValid) {
      this.jobData.optionsValid = true;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    } else {
      this.jobData.optionsValid = false;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    }
  };

  // TODO: save specific errors for showing user what's wrong
  validateOptionValues() {
    const conditions = [
      { condition: true, min: 0, max: 3, value: this.jobData.options.maxDistanceBetweenBatters },
      { condition: true, min: 0, max: 4, value: this.jobData.options.maxBattersVsPitcher },
      { condition: true, min: 0, max: null, value: this.jobData.options.projectionMinimum },
      { condition: true, min: 0, max: 1000, value: this.jobData.options.randomness },
      { condition: true, min: 0, max: this.siteSalaryMax, value: this.jobData.options.minLineupSalary },
      { condition: true, min: 0, max: 100, value: this.jobData.options.maxPctOffOptimal },
      { condition: this.jobData.jobSubType === 'classic', min: 0, max: 21, value: this.jobData.options.maxPlayersPerTeam },
      { condition: true, min: 0, max: 100, value: this.jobData.options.defaultPositionVariance },
      { condition: true, min: 0, max: 100, value: this.jobData.options.usingStacksPercent },
      { condition: true, min: 0, max: 100, value: this.jobData.options.usingMaxStackLenPercent },
      { condition: true, min: 0, max: 100, value: this.jobData.options.secondaryStackPercent },
      { condition: true, min: 0, max: 100, value: this.jobData.options.defaultHitterVar },
      { condition: true, min: 0, max: 100, value: this.jobData.options.defaultPitcherVar },
      { condition: this.jobData.options.lineupGenerations < 1 || this.jobData.options.lineupGenerations > 25 || this.jobData.options.lineupGenerations == null },
      { condition: this.jobData.options.totalSimulations < 100 || this.jobData.options.totalSimulations > 100000 || this.jobData.options.totalSimulations == null },
      { condition: this.jobData.options.topLineupsPct < 0 || this.jobData.options.topLineupsPct > 100 || this.jobData.options.topLineupsPct == null },
    ];

    for (const { condition, min, max, value } of conditions) {
      if (condition && (value == null || value < min || (max != null && value > max))) {
        return false;
      }
    }

    // if (this.jobData.options?.teamStackConfig && this.jobData.options?.teamStackConfig.some(t => t.value > 100 || t.value < 0 || t.value == null)) {
    //   return false;
    // }

    return true;
  }

  getUserOptionPresets(options) {
    this.jobsService.getJobOptionPresetsByUserId(this.user.uid).then((data) => {
      if (data) {
        const presets = data;
        const applicablePresets = presets.filter((set) =>
          set.data.sport === this.jobData.sport
          && set.data.jobType === this.jobData.jobType &&
          set.data.jobSubType === this.jobData.jobSubType)
        this.availablePresets = applicablePresets.sort((a, b) => {
          return b.data.updatedAt - a.data.updatedAt;
        });
        this.defaultPresetExists = applicablePresets.some((set) => set.data.isDefault);
        if (this.jobData.optionPresetId && !options.skipMatch) {
          const jobPreset = this.availablePresets.find((set) => set.id === this.jobData.optionPresetId);
          if (jobPreset !== undefined) {
            this.presetName = jobPreset.data.name;
            this.onSelectOptionPreset(jobPreset.data, jobPreset.id);
          } else {
            this.presetName = 'Preset Deleted - Data Still Applied'
          }
        } else if (this.defaultPresetExists && !options.skipMatch) {
          const defaultPreset = applicablePresets.find((set) => set.data.isDefault);
          this.onSelectOptionPreset(defaultPreset.data, defaultPreset.id);
          this.isDefaultApplied = true;
          setTimeout(() => {
            this.isDefaultApplied = false;
          }, 4200);
        } else if (!options.skipMatch) {
          this.presetName = 'Default Options';
          if (options?.setDefaults !== false) {
            this.onClickRestoreDefaults();
          }
        }
      }
    })
  }

  onSelectOptionPreset(preset, id) {
    this.presetName = preset.name;
    this.selectedPreset = {
      id: id,
      preset: preset,
    };
    const newValues = preset.presetValues;

    // Merge newOptions with jobData.options, using existing values for missing keys
    const newOptions = Object.assign({}, this.jobData.options, newValues);
  
    this.jobData.optionPresetId = id;
    this.jobData.options = newOptions;
  
    this.onValueChange({ userChange: false });
    this.showPresets = false;
  }

  onClickShowSave() {
    this.showSavePreset = true;
  }

  onCancelSavePreset() {
    this.showSavePreset = false;
  }

  onRemovePreset(id, index) {
    if (this.isDeletingPreset) {
      return;
    }
    this.deletePresetIndex = index;
    this.isDeletingPreset = true;
    this.jobsService.deleteOptionPresetById(this.user.uid, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletePresetIndex = null;
          this.isDeletingPreset = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onClickSetPresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: true,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.user.uid, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.isMakingPresetDefault = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onSaveJobPreset(name) {
    this.isSavingPreset = true;
  
    const presetValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});
  
    const preset = {
      isDefault: false,
      presetValues: presetValues,
      name: name,
    };
  
    this.jobsService.createJobOptionPreset(
      this.jobData.userId,
      this.jobData.sport,
      this.jobData.jobType,
      this.jobData.jobSubType,
      preset,
    ).then((res) => {
      if (res) {
        this.presetName = name;
        this.valuesDirty = false;
        this.selectedPreset = {
          id: res,
          preset: preset,
        };
  
        this.jobData.optionPresetId = res;
        this.jobsService.saveJobDataSnapshot(this.jobData);
        setTimeout(() => {
          this.getUserOptionPresets({ skipMatch: true });
          this.showSavePreset = false;
          this.isSavingPreset = false;
        }, 1000);
      }
    });
  }

  onClickUpdateExistingPreset() {
    this.isSavingPreset = true;

    const newValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});

    this.jobsService.updateJobOptionPresetValues(this.jobData.userId, this.selectedPreset.id, newValues).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.isSavingPreset = false;
          this.valuesDirty = false;
          this.getUserOptionPresets({ skipMatch: true, setDefaults: false });
        }, 700);
      }
    })
  }

  onClickRemovePresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: false,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.user.uid, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
        setTimeout(() => {
          this.isMakingPresetDefault = false;
        }, 800)
      }
    })
  }

  onClickShowPresets() {
    if (this.showPresets) {
      return;
    }
    setTimeout(() => {
      this.showPresets = true;
    }, 200);
  }

  async onClickRestoreDefaults() {
    this.presetName = 'Default Options';
    this.selectedPreset = null;
    this.showPresets = false;

    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;
    this.jobData.options.teamStackConfig = this.teamStackConfig;
    this.jobData.optionPresetId = null;

    this.onValueChange({ userChange: false });
  }

  preventScroll(event: WheelEvent) {
    event.preventDefault();
  }

  onToggleGroup(group) {
    this.activeGroup = group;
  }

  async handleTeamStackFile(file: File): Promise<void> {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      console.log(csvData);
      this.processCSVData(csvData);

    };
    reader.readAsText(file);
  }

  processCSVData(csvData: string): void {
    // Split the CSV data into lines
    const lines = csvData.split('\n');

    // Process each line
    this.updatedTeamStackCount = 0; // Reset the count
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.length === 0) continue; // Skip empty lines

      const values = line.split(',').map(value => value.trim());
      if (values.length < 2) continue; // Skip lines that don't have enough columns

      const teamAbbreviation = values[0];
      const percentage = parseFloat(values[1]);

      // Find the team in the existing data
      const team = this.jobData.options.teamStackConfig.find(t => t.name === teamAbbreviation);

      if (team) {
        team.value = percentage;
        this.updatedTeamStackCount++;
      }
    }
    this.jobsService.saveJobDataSnapshot(this.jobData);
    setTimeout(() => {
      this.updatedTeamStackCount = null;
    }, 4000);
  }

  downloadCSV(): void {
    const csvContent = this.convertToCSV(this.jobData.options.teamStackConfig);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Team-Stack-Config-Template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // Convert team data to CSV format
  convertToCSV(data: { name: string; value: number }[]): string {
    const header = 'name,value';
    const rows = data.map(item => `${item.name},${item.value}`);
    return [header, ...rows].join('\n');
  }

  onToggleKeepUserLineups(event) {
    this.jobData.options.keepUserLineups = event;
  }

}

