import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ace-base-modal',
  templateUrl: './ace-base-modal.component.html',
  styleUrls: ['./ace-base-modal.component.scss']
})
export class AceBaseModalComponent {
  @Output() handleModalClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() modalHeader: string;

  onClickClose() {
    this.handleModalClose.emit();
  }
}
