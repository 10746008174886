import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSelectAllText]',
})
export class SelectAllTextDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('focus')
  onFocus(): void {
    setTimeout(() => {
      this.el.nativeElement.select();
    });
  }
}
