import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'toggle-switch',
  template: `
  <div class="toggle-switch" [class.on]="checked" (click)="toggle()">
  <div class="toggle-switch-track">
    <div class="toggle-switch-thumb"></div>
  </div>
</div>
  `,
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {
  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.checked) {
  //    console.log(this.checked);
  //   }
  // }

  toggle(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }

}
