import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JobsService } from 'src/app/services/jobs.service';
import anime from 'animejs/lib/anime.es.js';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { StateService } from 'src/app/services/state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent {

  user: any;
  isAuthenticated: any;

  isLoading = true;
  isLoadingMore: boolean;
  isLoadingJobs: boolean;
  userSubscription: any;
  jobCache: any;

  jobDescriptionIndex: any;
  selectedJob: any;
  jobDescriptionString: any;
  isSavingDescription: boolean;

  allUniversalJobs: any = [];

  private subscription: Subscription;

  constructor(
    private router: Router,
    private jobsService: JobsService,
    private titleService: Title,
    private metaService: Meta,
    private stateService: StateService,
  ) {
    this.titleService.setTitle('Ace Mind');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'AceMind'
      }
    );
    this.subscription = this.stateService.allJobCache$.subscribe(
      value => {
        this.jobCache = value;
      }
    );
  }

  ngOnInit(): void {
    const auth = getAuth();
    window.scrollTo(0,0);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isAuthenticated = true;
        this.isLoading = false;
        this.isLoadingJobs = true;
        this.getRecentJobs(user.uid, {});
        anime({
          targets: '.ss-dash-card',
          translateY: ['16px', 0],
          opacity: [0, 1],
          duration: 700,
          direction: 'normal',
          delay: anime.stagger(100),
        });
      } else {
        console.log('no authenticated user')
        this.isLoading = false;
        this.isAuthenticated = false;
      }
    });
  }

  getRecentJobs(userId, options) {
    this.isLoadingJobs = true;
    if (this.jobCache.length > 0 && !options?.cashBurst) {
      this.allUniversalJobs = this.jobCache;
      this.isLoadingJobs = false;
    } else {
      this.jobsService.getRecentUniversalJobs(userId, 11).then((data) => {
        const filteredJobs = data.filter((j) => j.status === 'complete' || j.status === 'running-job' || j.status === 'job-failed');
        this.allUniversalJobs = filteredJobs;
        this.stateService.setAllJobCache(data);
        this.isLoadingJobs = false;
      }).catch((e) => {
        console.log(e);
      })
    }
  }

  onClickViewMoreJobs() {
    this.isLoadingMore = true;
    this.jobsService.getRecentUniversalJobs(this.user.uid, 50).then((data) => {
      const filteredJobs = data.filter((j) => j.status === 'complete' || j.status === 'running-job' || j.status === 'job-failed');
      this.allUniversalJobs = filteredJobs;
      this.stateService.setAllJobCache(data);
      this.isLoadingMore = false;
    }).catch((e) => {
      console.log(e);
    })
  }


  onClickJob(jobData) {
    if (jobData.status !== 'job-failed') {
      const key = jobData.jobType === 'simulator' ? 'sim' : 'opto';
      this.router.navigate([`results/${key}`, this.user.uid, jobData.jobId]);
    }
  }

  onClickManageJobDescription(job, index) {
    if (job?.description) {
      this.jobDescriptionString = job.description;
    } else {
      this.jobDescriptionString = null;
    }
    this.selectedJob = job;
    this.jobDescriptionIndex = index;    
  }

  onClickCancelManageJobDescription() {
    this.selectedJob = null;
    this.jobDescriptionIndex = null;
    this.isSavingDescription = false;   
  }

  onClickSaveJobDescription(job) {
    this.isSavingDescription = true;
    const update = {
      description: this.jobDescriptionString,
    }
    this.jobsService.updateUniversalJob(this.user.uid, job.jobId, update).then(() => {
      this.isSavingDescription = false;
      this.selectedJob = null;
      this.jobDescriptionIndex = null;
      this.isLoadingJobs = true;
      this.getRecentJobs(this.user.uid, {cashBurst: true});
    })
  }

  onClickDeleteJobDescription(job) {
    const update = {
      description: null,
    }
    this.jobsService.updateUniversalJob(this.user.uid, job.jobId, update).then(() => {
      this.selectedJob = null;
      this.jobDescriptionIndex = null;
      this.isLoadingJobs = true;
    })
  }

  translateStatus(status) {
    switch (status) {
      case 'focused':
        return 'Projections';
      case 'in-progress':
        return 'Configuring';
      case 'running-job':
        return 'Running';
      case 'job-failed':
        return 'Failed';
      case 'complete':
        return 'Complete';
      default:
        return 'Waiting for Engine';
    }
  }


}
