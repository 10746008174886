<div class="ss-report-card">
    <div class="report-card-header">
        <div class="report-card-title">Lineups Summary</div>
    </div>

    <div class="report-card-body">

        <div class="mt8 mb8">
            <toggle-bar [segments]="[
            { label: 'Your Lineups', value: 'projected', isDefault: this.lineupsList === 'projected' ? true : false },
            { label: 'Generated', value: 'generated', isDefault: this.lineupsList === 'generated' ? true : false },
        ]" [barSize]="'100%'" [textSize]="12" (toggleChange)="handleLineupToggle($event)"></toggle-bar>
        </div>

        <section *ngIf="lineupsList === 'generated' ">

            <div
                *ngIf="!jobData.lineupSummary?.generated || (jobData.lineupSummary?.generated && jobData.lineupSummary?.generated.length === 0)">
                <div class="mt24 pb32" style="color: var(--ss-text-medium-mute); font-size: 0.813rem;">No
                    Additional Lineups Generated</div>
            </div>

            <div class="pb32"
                *ngIf="jobData.lineupSummary?.generated && jobData.lineupSummary?.generated.length > 0">
                <div class="summary-block" *ngFor="let p of jobData.lineupSummary?.generated">
                    <div class="summary-title">
                        {{p.name}}
                    </div>
                    <div class="stat-row">
                        <div class="stat">
                            <div class="label">
                                Min
                            </div>
                            <div class="value">
                                {{p.min | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Max
                            </div>
                            <div class="value">
                                {{p.max | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Median
                            </div>
                            <div class="value">
                                {{p.median | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Mean
                            </div>
                            <div class="value">
                                {{p.mean | decimalPlaces}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>


        <section *ngIf="lineupsList === 'projected' ">

            <div
                *ngIf="!jobData.lineupSummary?.opto || (jobData.lineupSummary?.opto && jobData.lineupSummary?.opto.length === 0)">
                <div class="mt24 pb32" style="color: var(--ss-text-medium-mute); font-size: 0.813rem;">No
                    Lineups Added to Job. See Generated Lineups</div>
            </div>

            <div class="pb32" *ngIf="jobData.lineupSummary?.opto && jobData.lineupSummary?.opto.length > 0">
                <div class="summary-block" *ngFor="let p of jobData.lineupSummary?.opto">
                    <div class="summary-title">
                        {{p.name}}
                    </div>
                    <div class="stat-row">
                        <div class="stat">
                            <div class="label">
                                Min
                            </div>
                            <div class="value">
                                {{p.min | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Max
                            </div>
                            <div class="value">
                                {{p.max | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Median
                            </div>
                            <div class="value">
                                {{p.median | decimalPlaces}}
                            </div>
                        </div>
                        <div class="stat">
                            <div class="label">
                                Mean
                            </div>
                            <div class="value">
                                {{p.mean | decimalPlaces}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>


    </div>
</div>
