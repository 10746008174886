import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchList'
})
export class SearchListPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter(item => {
            return Object.values(item).some(value => {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(searchText);
                } else if (typeof value === 'object') {
                    return Object.values(value).some(innerValue => {
                        if (typeof innerValue === 'string') {
                            return innerValue.toLowerCase().includes(searchText);
                        }
                        return false;
                    });
                }
                return false;
            });
        });
    }
}