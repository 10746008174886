/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  linkValid: boolean;
  submitted: boolean;
  token: string;
  password: string;
  confirmPassword: string;
  auth: any;

  updateError: any;

  userForm: UntypedFormGroup;
  successMsg = '';
  errorMsg = '';


  errorMsgs = {
    password: [
      {
        type: 'required',
        message: 'Password is required.'
      },
      {
        type: 'minlength',
        message: '8 Characters or More Required'
      }
    ]
  };

  constructor (
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.auth = getAuth();
    this.route
    .queryParams
    .subscribe(params => {
        this.token = params['oobCode'];
        this.verifyPasswordResetCode(this.token);
    });

    this.userForm = this.fb.group({
      password: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        this.regexValidator(new RegExp('(?=.*[$@$!%*?&])'), { specialChar : true}),
        this.regexValidator(new RegExp('(?=.*[0-9])'), { hasNumber : true})
      ])),
      confirmPassword: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required
      ])),
    });

  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  async verifyPasswordResetCode(
    code: string
  ): Promise<any> {
    return await verifyPasswordResetCode(this.auth, code)
      .then((email) => {
        this.linkValid = true;
      }).catch((error) => {
        console.log(error.message);
      });
  }

  onSubmitNewPassword() {
    confirmPasswordReset(
      this.auth,
      this.token,
      this.confirmPassword
    ).then(resp => {
      // Password reset has been confirmed and new password updated.
      this.submitted = true;
    }).catch(e => {
       this.updateError = e;
      });
  }

}
