import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'presets-manager',
  templateUrl: './presets-manager.component.html',
  styleUrls: ['./presets-manager.component.scss']
})
export class PresetsManagerComponent {
    @Input() showSavePreset: boolean = false;
    @Input() isDefaultApplied: boolean = false;
    @Input() presetName: string = '';
    @Input() isSavingPreset: boolean = false;
    @Input() selectedPreset: any = null;
    @Input() valuesDirty: boolean = false;
    @Input() showPresets: boolean = false;
    @Input() availablePresets: any[] = [];
    @Input() jobData: any;
    @Input() makePresetDefaultIndex: number = -1;
    @Input() deletePresetIndex: number = -1;
    @Input() isMakingPresetDefault: boolean = false;
    @Input() isDeletingPreset: boolean = false;
    @Input() defaultPresetExists: boolean = false;
  
    @Output() onClickShowPresets = new EventEmitter<void>();
    @Output() onClickUpdateExistingPreset = new EventEmitter<void>();
    @Output() onClickShowSave = new EventEmitter<void>();
    @Output() onSaveJobPreset = new EventEmitter<{name: any}>();
    @Output() onCancelSavePreset = new EventEmitter<void>();
    @Output() onClickRestoreDefaults = new EventEmitter<void>();
    @Output() onSelectOptionPreset = new EventEmitter<{ data: any, id: string }>();
    @Output() onClickSetPresetDefault = new EventEmitter<{ id: string, index: number, data: any }>();
    @Output() onClickRemovePresetDefault = new EventEmitter<{ id: string, index: number, data: any }>();
    @Output() onRemovePreset = new EventEmitter<{ id: string, index: number }>();

    @ViewChild('presetList', { static: false }) presetList: ElementRef;

    newPresetName: string;

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
      if (this.showPresets && !this.isDeletingPreset && !this.isMakingPresetDefault && !this.presetList.nativeElement.contains(event.target)) {
        this.showPresets = false;
      }
    }
  
    handleShowPresetsClick(event) {
      event.stopPropagation();
      this.showPresets = !this.showPresets;
    }
  
    handleUpdateExistingPresetClick() {
      this.onClickUpdateExistingPreset.emit();
    }
  
    handleShowSaveClick() {
      this.onClickShowSave.emit();
    }
  
    handleSaveJobPresetClick() {
      console.log(this.newPresetName);
      this.onSaveJobPreset.emit({name: this.newPresetName});
    }
  
    handleCancelSavePresetClick() {
      this.onCancelSavePreset.emit();
      this.newPresetName = '';
    }
  
    handleRestoreDefaultsClick() {
      this.onClickRestoreDefaults.emit();
      this.showPresets = false;
    }
  
    handleSelectOptionPreset(data: any, id: string) {
      this.onSelectOptionPreset.emit({ data, id });
      this.showPresets = false;
    }
  
    handleSetPresetDefaultClick(id: string, index: number, data: any) {
      this.onClickSetPresetDefault.emit({ id, index, data });
    }
  
    handleRemovePresetDefaultClick(id: string, index: number, data: any) {
      this.onClickRemovePresetDefault.emit({ id, index, data });
    }
  
    handleRemovePresetClick(id: string, index: number) {
      this.onRemovePreset.emit({ id, index });
    }
  }
  
