import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'file-drop', // Use the selector 'file-drop'
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent {
  @Input() isProcessingFile: boolean;
  @Input() minHeight: string = 'auto';
  @Output() fileDropped: EventEmitter<File[]> = new EventEmitter<File[]>();

  constructor() {}

  onFileDrop(event: any) {
    event.preventDefault();
    event.currentTarget.classList.remove('drag-over');
    const files: File[] = event.dataTransfer.files;
    this.handleFiles(files);
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.currentTarget.classList.add('drag-over');
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.currentTarget.classList.remove('drag-over');
  }

  onFileSelected(event: any) {
    const files: File[] = event.target.files;
    this.handleFiles(files);
  }

  handleFiles(files: File[]) {
    this.fileDropped.emit(files);
  }
}
