import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueMatchupRule'
})
export class UniqueMatchupRulePipe implements PipeTransform {
  transform(rules: any[]): any[] {
    // Your logic to filter out items based on matchup type and value
    return rules.filter((rule, index, self) => {
      const isDuplicate = self.findIndex((otherRule) => this.areMatchupRulesEqual(rule, otherRule)) === index;
      return isDuplicate;
    });
  }

  private areMatchupRulesEqual(rule1, rule2): boolean {
    // Your logic to compare matchup rules based on matchup type and value
    // For simplicity, let's assume 'matchup', 'type', and 'value' are the properties to compare
    return (
      rule1.matchup === rule2.matchup &&
      rule1.type === rule2.type &&
      rule1.value === rule2.value
    );
  }
}
