import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { KNOWN_FIELD_PTS_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import { StateService } from 'src/app/services/state.service';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'fieldpts-sniper',
  templateUrl: './fieldpts-sniper.component.html',
  styleUrls: ['./fieldpts-sniper.component.scss']
})
export class FieldptsSniperComponent implements DoCheck, OnInit {
  @Input() draftGroup: any;
  @Input() jobData: any;
  @Input() csvData: any;
  @Input() playerNameKey: any;
  @Input() userProfile: any;

  @Output() handleUseProjectionValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitValid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitInvalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeyAdditions: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() handleUpdateUserKeysToRemove: EventEmitter<any> = new EventEmitter<any>(); 

  showSniper: boolean = false;
  valueToMap: string = 'FieldPts';
  valueToValidate: string = 'fieldPts';
  snipedValue: any;
  isManualSelection: boolean = false;
  keysToSelect: string[] = [];

  userHeaderMappings: any; 
  isCustomMapping: boolean = false; 
  mlbImportMatchingMethod: any;
  canImportById: any;

  sniperMessage: string = 'Analyzing...'
  isLoading: boolean = true;
  isKeyValid: boolean = false;
  previousIsKeyValid: boolean = false;

  fixOptions: any = [
    { name: 'Choose one of my columns', action: 'select-column'},
    { name: 'Use Projection value', action: 'skip'}
  ]

  private importMethodSub: Subscription;
  private importByIdSub: Subscription;

  constructor(private stateService: StateService) {
    this.importMethodSub = this.stateService.mlbImportMatchingMethod$.subscribe((value) => {
      this.mlbImportMatchingMethod = value;
    });
    this.importByIdSub = this.stateService.canImportById$.subscribe((value) => {
      this.canImportById = value;
    });
  }

  ngDoCheck(): void {
    if (this.isKeyValid !== this.previousIsKeyValid) {
      if (this.isKeyValid) {
        this.handleUnitValid.emit(this.valueToMap);
      } else {
        this.handleUnitInvalid.emit(this.valueToMap);
      }
      this.previousIsKeyValid = this.isKeyValid;
    }
  }

  ngOnInit() {
    this.userHeaderMappings = this.userProfile?.importMappings?.[this.valueToValidate] || [];
  }

  selectKey(key: string): void {
    this.snipedValue = key;
    this.handleUpdateUserKeyAdditions.emit({ type: this.valueToValidate, value: key });
    this.isKeyValid = true;
    this.sniperMessage = `Column: ${this.snipedValue}`;
    this.isManualSelection = true;

    this.snipeValue();
  }

  snipeValue() {
    this.isKeyValid = false;
    this.isLoading = true;
    this.showSniper = true;

    setTimeout(() => {
      const knownKeys = KNOWN_FIELD_PTS_HEADERS.concat(this.userHeaderMappings);

      let matchingKey;
      if (this.isManualSelection) {
        // USER SELECTED KEY USED
        matchingKey = this.snipedValue;
      } else {
        this.isManualSelection = false;
        matchingKey = knownKeys.find(key => this.csvData[0].hasOwnProperty(key));
      }

      // Use the selected key to process data objects
      this.csvData.forEach((dataObj) => {
        if (matchingKey) {
          if (this.userHeaderMappings.some((k) => k === matchingKey)) {
            this.isCustomMapping = true;
            this.snipedValue = matchingKey;
          }
          this.isKeyValid = true;
          this.sniperMessage = `Column: ${matchingKey}`;
          this.processDataObject(dataObj, matchingKey);
        } else {
          this.snipedValue = null;
          this.isKeyValid = false;
          this.sniperMessage = `No ${this.valueToMap} Column Detected`;
        }

        this.keysToSelect.push(...Object.keys(dataObj));
      });

      this.keysToSelect = Array.from(new Set(this.keysToSelect));
      this.isLoading = false;
    }, 200);
  }

  // HANDLE MLB PRECISE MAPPING
  handleMLBMatching(dataObj) {
    let matchingProjectionObj = null;

    if (this.canImportById) {
      // MATCH BY ID VAL
      const matchingId = dataObj[this.mlbImportMatchingMethod];
      matchingProjectionObj = this.draftGroup.find(obj => obj.PlayerId.toString() === matchingId.toString());
    } else {
      // MATCH BY NAME + TEAM VAL
      const namePlusTeam = (dataObj[this.playerNameKey] + dataObj[this.mlbImportMatchingMethod]).trim().toLowerCase();
      const draftGroupNamesPlusTeam = this.draftGroup.map(player => (player.Name + player.Team).trim().toLowerCase());
      const match = stringSimilarity.findBestMatch(namePlusTeam, draftGroupNamesPlusTeam);
      if (match.bestMatch.rating > 0.5) {
        matchingProjectionObj = this.draftGroup.find(obj => (obj.Name + obj.Team).trim().toLowerCase() === match.bestMatch.target);
      }
    }

    return matchingProjectionObj;
  }

  // SIMPLE FUZZY MATCH
  handleNameOnlyMatch(dataObj) {
    let matchingProjectionObj = null;
    const name = dataObj[this.playerNameKey].trim().toLowerCase();
    const draftGroupNames = this.draftGroup.map(player => player.Name.trim().toLowerCase());
    const match = stringSimilarity.findBestMatch(name, draftGroupNames);
    if (match.bestMatch.rating > 0.5) {
      matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
    }

    return matchingProjectionObj;
  }

  processDataObject(dataObj, matchingKey) {
    let value = null;
    let matchingProjectionObj = null;
    const rawValue = dataObj[matchingKey];
    if (rawValue !== null && rawValue !== undefined) {
      const parsedValue = parseFloat(rawValue);
      if (!isNaN(parsedValue)) {
        value = Number(parsedValue.toFixed(4));
      } else {
        value = 0;
      }
    }

    if (this.jobData.sport === 'MLB') {
      matchingProjectionObj = this.handleMLBMatching(dataObj);
    } else {
      matchingProjectionObj = this.handleNameOnlyMatch(dataObj);
    }

    if (matchingProjectionObj) {
      matchingProjectionObj[this.valueToMap] = value;
    }
  }

  handleRemoveCustomMapping() {
    this.handleUpdateUserKeysToRemove.emit({ type: this.valueToValidate, value: this.snipedValue });
  }

  onClickChangeKey() {
    // LEAVING FOR FURTHER REFACTOR WHEN TIME ALLOWS
  }

  handleErrorOption(option) {
    if (option === 'skip') {
      this.sniperMessage = "Using Projection Value";
      this.isKeyValid = true;
      this.handleUseProjectionValue.emit(true);
    }
  }
}
