import { Component } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'ace-fs-loader-overlay',
  templateUrl: './ace-fs-loader-overlay.component.html',
  styleUrls: ['./ace-fs-loader-overlay.component.scss']
})
export class AceFsLoaderOverlayComponent {

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupTimeline();
    }, 400);

    setTimeout(() => {
      this.startFlashers();
    }, 2000)
  }

  setupTimeline(): void {

    const timeline = anime.timeline({
      autoplay: true,
      loop: false,
    });

    timeline
      .add({
        targets: '#white-border',
        scale: [0, 1],
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        duration: 800,
        delay: anime.stagger(100),
      })
      .add({
        targets: '#skull',
        translateY: [20, 0],
        opacity: [0, 1],
        duration: 800
      }, '-=200')
      .add({
        targets: '.animation-name',
        opacity: [0, 1],
        duration: 800
      }, 700)
      .add({
        targets: '#top-teeth',
        opacity: [0, 1],
        duration: 800
      }, 700)
      .add({
        targets: '#bottom-teeth',
        opacity: [0, 1],
        duration: 800
      }, 800)
      .add({
        targets: '.brainz',
        opacity: [0, 1],
        scale: [2, 1],
        duration: 1000,
      }, 1000)
      .add({
        targets: '#digital-eye',
        opacity: [0, 1],
        duration: 300,
      }, '-=400')
      .add({
        targets: '.call-to-action *',
        opacity: [0, 1],
        duration: 500,
        delay: anime.stagger(50),
      }, '-=400')
      .add({
        targets: '.fs-animation-wrapper',
        opacity: [1, 0.5],
        translateY: [0, 3000],
        duration: 500,
        easing: 'easeInOutQuad'
      }, 3000);
  }

  startFlashers() {
    const flashers = anime.timeline({
      autoplay: true,
      loop: false,
    });

    flashers
      .add({
        targets: '.tl, .tr, .br, .bl',
        opacity: [0, 0.8],
        duration: 4000,
      })
  }

}
