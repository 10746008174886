<div class="ss-form-group" style="margin-bottom: 0px;">
    <label>{{showSavePreset ? 'New Preset Name' : 'My Settings Presets'}} <span class="preset-applied" *ngIf="isDefaultApplied">✓ Default Preset Applied</span></label>
    <div class="preset-input-row" *ngIf="!showSavePreset">
      <div class="mock-preset-input" (click)="handleShowPresetsClick($event)">
        <div class="text">{{ presetName | slice:0:80 }}{{ presetName?.length > 80 ? '...' : '' }}</div>
        <div class="icon">
          <svg-icon [svgStyle]="{ 'height.px':8 }" src="/assets/icons/chev-down.svg"></svg-icon>
        </div>
      </div>
    </div>
    <div *ngIf="!showPresets && !showSavePreset" class="save-row">
      <div class="ss-spinner passive" style="height: 18px; width: 18px;" *ngIf="isSavingPreset"></div>
      <div *ngIf="selectedPreset !== null && valuesDirty && !isSavingPreset" class="save-text mr16" (click)="handleUpdateExistingPresetClick()">Update Current Preset</div>
      <div *ngIf="!isSavingPreset" class="save-text" (click)="handleShowSaveClick()">Save Values as Preset</div>
    </div>
    <div *ngIf="showSavePreset" class="save-preset-wrapper">
      <input [(ngModel)]="newPresetName" class="ss-input expanded" placeholder="Name your preset..." [disabled]="isSavingPreset" maxlength="65" />
      <div class="save-preset-btn" (click)="handleSaveJobPresetClick()" *ngIf="!isSavingPreset">Save</div>
      <div class="cancel-preset-btn" (click)="handleCancelSavePresetClick()" *ngIf="!isSavingPreset">Cancel</div>
      <div class="ss-spinner passive" style="min-width: 30px;" *ngIf="isSavingPreset"></div>
    </div>
  </div>
  <ul #presetList *ngIf="showPresets">
    <li>
      <div class="preset-item-row">
        <div class="name" (click)="handleRestoreDefaultsClick()">App Defaults</div>
      </div>
    </li>
    <li class="preset-definition" *ngIf="availablePresets?.length > 0">
      Presets for {{jobData.sport}} / <span class="cap-me-bro">{{jobData.jobType}}</span> / <span class="cap-me-bro">{{jobData.jobSubType}}</span>
    </li>
    <li class="preset-definition empty" *ngIf="availablePresets?.length === 0">
      No Presets for: {{jobData.sport}} / <span class="cap-me-bro">{{jobData.jobType}}</span> / <span class="cap-me-bro">{{jobData.jobSubType}}</span>
    </li>
    <li *ngFor="let set of availablePresets; let i = index">
      <div class="preset-item-row">
        <div class="name" (click)="handleSelectOptionPreset(set.data, set.id)">{{set.data.name | slice:0:42 }}{{ set.data.name.length > 42 ? '...' : '' }}</div>
        <div class="actions">
          <div class="default-wrapper" *ngIf="!set.data.isDefault && !defaultPresetExists">
            <div class="default-text" (click)="handleSetPresetDefaultClick(set.id, i, set.data)" *ngIf="makePresetDefaultIndex !== i && deletePresetIndex !== i">Make Default</div>
            <div class="ss-spinner passive" *ngIf="isMakingPresetDefault && makePresetDefaultIndex === i"></div>
          </div>
          <div class="default-wrapper" *ngIf="set.data.isDefault && deletePresetIndex !== i">
            <div class="default-text active" *ngIf="!isMakingPresetDefault && makePresetDefaultIndex !== i">Current Default</div>
            <div class="delete-chip" (click)="handleRemovePresetDefaultClick(set.id, i, set.data)" *ngIf="!isMakingPresetDefault">
              <svg-icon [svgStyle]="{ 'height.px':20 }" src="/assets/icons/square-close.svg"></svg-icon>
            </div>
            <div class="ss-spinner passive" *ngIf="isMakingPresetDefault && makePresetDefaultIndex === i"></div>
          </div>
          <div class="delete-wrapper" (click)="handleRemovePresetClick(set.id, i)">
            <div class="icon" *ngIf="deletePresetIndex !== i">
              <svg-icon [svgStyle]="{ 'height.px':20 }" src="/assets/icons/trash-square.svg"></svg-icon>
            </div>
            <div class="ss-spinner passive" *ngIf="isDeletingPreset && deletePresetIndex === i"></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  