import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as csvtojson from 'csvtojson';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'modal-csv-capture',
  templateUrl: './modal-csv-capture.component.html',
  styleUrls: ['./modal-csv-capture.component.scss']
})
export class ModalCsvCaptureComponent {
  @Output() csvProjectionData: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileAdded: EventEmitter<any> = new EventEmitter<any>();
  @Input() returnJSON: boolean;
  @Input() minHeight: any;

  constructor(private stateService: StateService) { }

  fileError: string;
  isProcessingFile: boolean;

  onFileAdded(fileList: any): void {
    if (this.isProcessingFile) { return };
    this.isProcessingFile = true;
    this.fileError = null;
    if (fileList) {
      const file = fileList[0];
      const fileSizeLimit = 30 * 1024 * 1024;

      if (file.size > fileSizeLimit) {
        this.fileError = 'File exceeds the 20MB Limit'
        console.error('File size exceeds the allowed limit of 20 MB.');
        return;
      }

      const fileName: string = file.name;
      const fileExtension: string = fileName.split('.').pop().toLowerCase();

      if (fileExtension === 'csv') {
        if (this.returnJSON) {
          this.readFileContent(file);
        } else {
          this.isProcessingFile = false;
          this.fileAdded.emit(file);
        }
      } else {
        this.fileError = 'Must be a CSV File'
        console.error('Invalid file type. Please select a .csv file.');
        this.isProcessingFile = false;
      }
    }
  }

  async readFileContent(file: File): Promise<void> {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      const jsonData = await this.convertCsvToJson(csvData); // Wait for the conversion
      this.isProcessingFile = false;
      this.csvProjectionData.emit(jsonData);
    };
    reader.readAsText(file);
  }

  async convertCsvToJson(csvData: string): Promise<any> {
    const data = await csvtojson().fromString(csvData);
    return data;
  }

}
