<div
  class="table-container"
  #tableWrapper
  [ngStyle]="{
    'max-height': jobData.jobType === 'optimizer' && 'calc(100vh - 196px)'
  }"
>
  <table cellspacing="0">
    <thead>
      <tr>
        <th *ngIf="selectable && rows.length > 0" style="width: 38px">
          <label class="custom-checkbox">
            <input
              type="checkbox"
              class="hidden-checkbox"
              (change)="selectAllRows($event)"
              [checked]="allRowsSelected"
            />
            <span class="checkbox-icon"></span>
          </label>
        </th>
        <th *ngFor="let column of columns" (click)="toggleSort(column)">
          <div class="header-cell">
            <div>{{ column.name }}</div>
            <div class="info-icon" *ngIf="column.tooltip">
              i
              <div class="tooltip">{{ column.tooltip }}</div>
            </div>
            <div
              class="sort-icon"
              [ngClass]="{ visible: sortColumn === column.prop }"
            >
              {{ sortDirection === 'asc' ? '▲' : '▼' }}
            </div>
          </div>
        </th>
      </tr>
      <tr *ngIf="showAdvancedHeader">
        <th class="utility" *ngFor="let column of columns">
          <div
            class="utility-search-wrapper"
            *ngIf="column.searchable === true"
          >
            <svg-icon
              class="utility-search-icon"
              [svgStyle]="{ 'height.px': 11 }"
              src="/assets/icons/spyglass.svg"
            ></svg-icon>
            <input
              class="utility-input"
              name="column-search"
              [(ngModel)]="inputValues[column.prop]"
              (input)="handleColumnSortSearch($event, column.prop)"
              autocomplete="off"
            />
          </div>
          <div class="utility-filter-wrapper" *ngIf="column.filterValue">
            <div
              class="filter-btn cursor-p"
              (click)="onSelectFilterColumn(column.filterValue, $event)"
            >
              <div class="filter-feedback-wrap">
                <div
                  class="feedback-bubble"
                  *ngIf="
                    filterCountMap && filterCountMap[column.filterValue] > 0
                  "
                >
                  {{ filterCountMap[column.filterValue] }}
                </div>
                <svg-icon
                  class="utility-search-icon"
                  [svgStyle]="{ 'height.px': 14 }"
                  src="/assets/icons/filter-solo.svg"
                ></svg-icon>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="rows.length === 0">
        <td class="empty">
          <div class="empty-presentation">
            <svg-icon
              class="utility-search-icon"
              [svgStyle]="{ 'height.px': 14 }"
              src="/assets/icons/filter-solo.svg"
            ></svg-icon
            >No Matching Lineups. Relax your filters to view lineups.
          </div>
        </td>
      </tr>
      <tr *ngFor="let row of rows; let i = index">
        <td *ngIf="selectable" style="width: 38px" style="text-overflow: clip">
          <label class="custom-checkbox">
            <input
              type="checkbox"
              class="hidden-checkbox"
              (change)="toggleRowSelection(row)"
              [checked]="row.selected"
            />
            <span class="checkbox-icon"></span>
          </label>
        </td>
        <td
          *ngFor="let column of columns"
          [ngClass]="{
            'lineup-cell': column.prop === 'Lineup',
            'player-highlight':
              (column.noFrontId || column.noId) &&
              row[column.prop] &&
              row[column.prop].includes(playerHighlight)
          }"
        >
          <ng-container *ngIf="column.prop === 'Lineup'">
            <div [innerHTML]="capitalizeAndBreakLines(row[column.prop])"></div>
          </ng-container>

          <ng-container *ngIf="column.prop !== 'Lineup'">
            <ng-container
              *ngIf="
                !column.editable ||
                editingRow !== i ||
                editingColumn !== column.prop
              "
            >
              <span *ngIf="column?.prefix">{{ column.prefix }}</span>

              <span
                class="cursor-p"
                *ngIf="column.noId"
                (click)="
                  onClickLineupPlayer(row[column.prop], { idType: 'back' })
                "
              >
                <span
                  *ngIf="showTeams && jobData.draftGroup && jobData.sport === 'NFL' "
                  [innerHtml]="getPlayerTeam(row[column.prop]) | tableTeamBadgePrefix"
                ></span>
                {{ row[column.prop] | removeParentheses }}
              </span>

              <span
                class="cursor-p"
                *ngIf="column.noFrontId"
                (click)="
                  onClickLineupPlayer(row[column.prop], { idType: 'front' })
                "
                >
                <span
                *ngIf="showTeams && jobData.draftGroup && jobData.sport === 'NFL' "
                [innerHtml]="getPlayerTeam(row[column.prop]) | tableTeamBadgePrefix"
              ></span>
                {{ removeFrontId(row[column.prop] || null) }}</span
              >

              <span
                *ngIf="column.matchup"
                [innerHTML]="generateMatchupTemplate(row[column.prop])"
              ></span>

              <span
                *ngIf="
                  !column.noId &&
                  !column.noFrontId &&
                  !column.hideSystemValues &&
                  !column.editable &&
                  !column.matchup
                "
                >{{ row[column.prop] }}</span
              >

              <span *ngIf="column?.suffix && !column.editable">{{
                column.suffix
              }}</span>

              <div
                class="table-input-chip"
                *ngIf="column.editable"
                (click)="enableEdit(i, column.prop)"
              >
                {{ row[column.prop] }}
                <span *ngIf="column?.suffix">{{ column.suffix }}</span>
              </div>

              <div
                class="exposure-bar-wrapper"
                *ngIf="(column.noId || column.noFrontId) && showExposure"
              >
                <div
                  class="exposure-bar"
                  [ngStyle]="{
                    width: getExposureWidth(row[column.prop]) + '%'
                  }"
                ></div>
                <div
                  *ngIf="showTooltipFlag && hoveredPlayer === row[column.prop]"
                  class="tool-tip"
                >
                  {{ tooltipValue }}%
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                column.editable &&
                editingRow === i &&
                editingColumn === column.prop
              "
            >
              <input
                *ngIf="!column.editAsString"
                #editInput
                type="number"
                class="ss-table-input"
                [(ngModel)]="row[column.prop]"
                (blur)="onEditComplete()"
              />
              <input
                *ngIf="column.editAsString"
                #editInput
                type="text"
                class="ss-table-input"
                [(ngModel)]="row[column.prop]"
                (blur)="onEditComplete()"
              />
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="filter-modal-wrapper" *ngIf="filterModalMode">
  <div class="filter-modal" #filterModal>
    <div class="modal-header">
      <div class="header-text">Filter: {{ filterModalMode }}</div>
      <div class="close-icon" (click)="handleCloseFilterModal()">
        <svg-icon
          class="icon"
          [svgStyle]="{ 'height.px': 18 }"
          src="/assets/icons/close-square.svg"
        ></svg-icon>
      </div>
    </div>

    <section
      *ngIf="
        filterModalMode !== 'primaryStack' &&
        filterModalMode !== 'secondaryStack' &&
        !filterModalMode.includes('position')
      "
    >
      <div class="filter-body">
        <div *ngFor="let f of existingFiltersOfType">
          <filter-item
            [filter]="f"
            [filters]="filters"
            (onRemoveFilter)="onClickRemoveFilter($event)"
          ></filter-item>
        </div>

        <new-filter-item
          [newFilter]="stagedFilter"
          *ngIf="stagedFilter"
        ></new-filter-item>

        <button
          *ngIf="
            (stagedFilter && existingFiltersOfType.length < 1) ||
            (!stagedFilter &&
              existingFiltersOfType &&
              existingFiltersOfType.length + 1 <= 2)
          "
          class="add-filter-btn ss-utility-btn"
          (click)="onClickCreateAdditionalFilter(filterModalMode, $event)"
        >
          Add Another
        </button>
      </div>
      <div class="p16">
        <button
          (click)="onClickApplyFilters()"
          class="ss-secondary-btn expanded"
        >
          Apply
        </button>
        <div class="add-filter-error" *ngIf="addFilterError">
          {{ addFilterError }}
        </div>
      </div>
    </section>

    <section
      *ngIf="
        filterModalMode === 'primaryStack' ||
        filterModalMode === 'secondaryStack'
      "
    >
      <div class="filter-body">
        <div class="player-filter-instruction">
          Uncheck to exclude from results
        </div>

        <div class="filter-search-wrapper">
          <input
            class="player-search ss-input expanded"
            [(ngModel)]="playerModalQuery"
            placeholder="Find Stack..."
          />
        </div>

        <div class="filter-stack-list" *ngIf="filterModalStacks">
          <div
            class="flex stack-filter-item"
            *ngFor="
              let s of filterModalStacks | filterPlayers : playerModalQuery
            "
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                class="hidden-checkbox"
                [checked]="s.selected"
                (change)="toggleStackColFilter(s)"
              />
              <span class="checkbox-icon"></span>
            </label>
            <div class="stack-label">
              {{ s.name }}
            </div>
          </div>
        </div>

        <div>
          <button
            (click)="onClickApplyColStackFilters()"
            class="ss-secondary-btn expanded"
          >
            Apply
          </button>
        </div>
      </div>
    </section>

    <section *ngIf="filterModalMode.includes('position')">
      <div class="filter-body">
        <div class="player-filter-instruction">
          Uncheck to exclude from results
        </div>

        <div class="filter-search-wrapper">
          <input
            class="player-search ss-input expanded"
            [(ngModel)]="playerModalQuery"
            placeholder="Find player..."
          />
        </div>

        <div class="filter-player-list" *ngIf="jobData.filterModalPlayers">
          <div
            class="flex player-filter-item"
            *ngFor="
              let p of jobData.filterModalPlayers
                | filterPlayers : playerModalQuery
            "
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                class="hidden-checkbox"
                [checked]="p.selected"
                (change)="togglePlayerPositionFilter(p)"
              />
              <span class="checkbox-icon"></span>
            </label>
            <div class="player-label">
              {{ p.name }}
            </div>
          </div>
        </div>

        <div>
          <button
            (click)="onClickApplyColumnFilters()"
            class="ss-secondary-btn expanded"
          >
            Apply
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
