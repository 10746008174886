<div class="import-card">
    <div class="main-row">
        <div class="info">
            <div class="icon">
                <div *ngIf="isLoading" class="ss-spinner import-card-spinner"></div>
                <div class="warning" *ngIf="showFix && !isLoading && !isOptional">
                    <svg-icon *ngIf="!isOptional" [svgStyle]="{ 'height.px':24 }" src="/assets/icons/octo-warning.svg"></svg-icon>
                </div>
                <div class="optional" *ngIf="showFix && !isLoading && isOptional">
                    <svg-icon *ngIf="isOptional" [svgStyle]="{ 'height.px':24 }" src="/assets/icons/info-alert.svg"></svg-icon>
                </div>
                <div class="success" *ngIf="isMappingValid  && !isLoading">
                    <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/clipboard-check.svg"></svg-icon>
                </div>
            </div>
            <div class="details">
                <div class="card-title">{{title}} <span class="optional-tag" *ngIf="isOptional">Optional</span></div>
                <div class="card-sub-title" *ngIf="subTitle">{{subTitle}}</div>
            </div>
        </div>

        <div class="actions" *ngIf="isEditable && !isLoading">
            <div class="edit" *ngIf="!showFix && !showAvailableKeys" (click)="onClickEditKey()">Edit</div>
            <div class="edit" *ngIf="!showFix && showAvailableKeys" (click)="onClickCancelEditKey()">Cancel</div>
            <div class="fix" [ngClass]="{'optional' : isOptional}" *ngIf="showFix && !hasSelectedKey" (click)="onClickShowKeys()">{{isOptional ? 'Define' : 'Fix Issue'}}</div>
            <div class="fix" [ngClass]="{'optional' : isOptional}" *ngIf="showFix && hasSelectedKey" (click)="onClickEditKey()">{{isOptional ? 'Define' : 'Fix Issue'}}</div>
        </div>

    </div>

    <div class="fix-wrapper" *ngIf="showAvailableKeys">
        <section>
            <div class="message">{{fixMessage}}</div>
            <div class="fix-pills-grid" *ngIf="availableKeys.length > 0">
                <div class="fix-pill" *ngFor="let key of availableKeys" (click)="selectKey(key)">{{ key }}</div>
            </div>

            <section *ngIf="isCustomMapping">
                <div class="custom-matching-message">{{customKey ? 'Column: ' + customKey : subTitle}} was auto-mapped based on your previous imports.</div>
                <div class="fix-options-wrapper">
                    <div class="fix-pill" (click)="onClickRemoveCustomOption()">Remove from My Mappings</div>
                </div>
            </section>
        </section>
    </div>

    <div class="fix-wrapper" *ngIf="showAvailableOptions">
        <section>
            <div class="message">{{optionMessage}}</div>
            <div class="fix-options-wrapper" *ngIf="fixOptions.length > 0">
                <div class="fix-pill" *ngFor="let o of fixOptions" (click)="onClickErrorOption(o.action)">{{ o.name }}</div>
            </div>
        </section>
    </div>

    <div class="fix-wrapper" *ngIf="showAvailableKeys && isOptional && hasSelectedKey">
        <section>
            <div class="fix-options-wrapper">
                <div class="fix-pill" (click)="onClickClearSelection()">Clear Selection</div>
            </div>
        </section>
    </div>

</div>