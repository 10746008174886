import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsService } from 'src/app/services/jobs.service';
import { LineupsService } from 'src/app/services/lineups.service';
import { setLineupsTable } from './utilities/lineups-sport-utilities';
import { generateExportFile } from './utilities/lineups-csv-utlities';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { handleOptimizerSeed } from './utilities/opto-sim-utilities';
import { cloneDeep } from 'lodash';
import { DraftGroupService } from 'src/app/services/draft-group.service';

@Component({
  selector: 'job-build-lineups',
  templateUrl: './job-build-lineups.component.html',
  styleUrls: ['./job-build-lineups.component.scss']
})
export class JobBuildLineupsComponent {
  @ViewChild('customCountInput') customCountInput!: ElementRef;
  @Input() jobData: any;

  isLoadingJobData: boolean = true;
  exposureMap: any;
  showTableExposure: boolean = true;

  showTableTeams: boolean = true;

  isLoadingTableInfo: boolean = true;
  isLoadingTableData: boolean = true;
  isFetchingTableData: boolean = false;
  isTableAvailable: boolean = false;
  loadingMessage: String = 'Getting Table Information...';
  errorQueryingLineups: any;

  //EXPORT PROPS
  isCopyingCSV: boolean = false;

  // TABLE PROPS
  columns: any = null;

  // FILTER PROPS
  numLineups: number = 20;
  selectedNumber: string = '20';
  selectedNumberInt: number = 20;
  customNumLineups: number = 250;
  isCustomNumLineups: boolean;

  numUniques: number = 1;
  selectedUniques: string = '1';
  selectedUniquesInt: number = 1;

  filters: any = [];
  // availableFilters: any;
  showFilterModal: boolean;
  searchablePlayers: any;
  selectedFilter: any;

  isCreatingOptoToSimData: boolean;

  private subscription: Subscription;
  private subscriptionTeam: Subscription;

  constructor(
    private router: Router,
    private lineupsService: LineupsService,
    private jobsService: JobsService,
    private stateService: StateService,
    private draftGroupService: DraftGroupService,
  ) {
    this.subscription = this.stateService.playerExposureFilters$.subscribe(
      value => {
        if (value.playerFilters.length || value.isReset) {
          const reducedFilters = this.filters.filter((f) => f.filterType !== 'playerExposure');
          this.filters = reducedFilters;
          const updatedPlayerFilters = value.isReset ? [] : value.playerFilters;
          const updatedFilters = [...this.filters, ...updatedPlayerFilters];
          this.filters = updatedFilters;
          if (this.jobData?.tableId) {
            this.handleQueryTable();
          }
        }
      }
    );
    this.subscriptionTeam = this.stateService.teamExposureFilters$.subscribe(
      value => {
        if (value.teamFilters.length || value.isReset) {
          const reducedFilters = this.filters.filter((f) =>
            f.filterType !== 'primaryGlobalTeamExposure' &&
            f.filterType !== 'secondaryGlobalTeamExposure' &&
            f.filterType !== 'primaryTeamStackLength' &&
            f.filterType !== 'secondaryTeamStackLength'
          );
          this.filters = reducedFilters;
          const updatedTeamFilters = value.isReset ? [] : value.teamFilters;
          const updatedFilters = [...this.filters, ...updatedTeamFilters];
          this.filters = updatedFilters;
          if (this.jobData?.tableId) {
            this.handleQueryTable();
          }
        }
      }
    );
  }

  ngOnInit() {
    this.checkLocalPrefs();
    const translatedJobType = this.jobData.jobType === 'simulator' ? 'sim' : 'opto';
    this.columns = setLineupsTable(this.jobData.sport, translatedJobType, this.jobData.site, this.jobData.jobSubType);

    if (this.jobData?.tableId == null) {
      this.loadingMessage = 'No Table Exists. Generating Table...'
      this.generateBqTable();
    } else if (this.isTableExpired(this.jobData)) {
      this.loadingMessage = 'Query Table Expired. Generating Table...'
      this.generateBqTable();
    } else {
      this.isLoadingTableInfo = false;
      this.getInitialTableData();
    }
  };

  checkLocalPrefs() {
    const showTeams = localStorage.getItem('entryBuilderShowTeams');
    const showExposure = localStorage.getItem('entryBuilderShowExposure');

    if (showTeams) {
      this.showTableTeams = showTeams === 'on' ? true : false;
    }
    if (showExposure) {
      this.showTableExposure = showExposure === 'on' ? true : false;
    }
  }

  generateBqTable() {
    this.lineupsService.createBqTable(this.jobData).subscribe(
      (data) => {
        if (data !== null) {
          this.loadingMessage = 'Table Ready For Filtering. Getting Data.'
          this.jobData.availableColumnValues = data.response.availableColumnValues;
          const jobUpdate = {
            // NEW
            tableId: data.response.tableId,  
            // PROD
            //  tableId: data.response,
            csvId: `${this.jobData.userId}_${this.jobData.jobId}`,
            availableColumnValues: data.response.availableColumnValues,
          };
          this.jobsService.updateUniversalJob(this.jobData.userId, this.jobData.jobId, jobUpdate).then((data) => {
            if (data !== null) {
              this.jobData.tableId = data.tableId;
              this.jobData.csvId = data.csvId;
              this.isLoadingTableInfo = false;
              this.getInitialTableData();
            }
          }).catch((e) => {
            console.log("error updating lineup job", e);
            this.isLoadingTableInfo = false;
          })
        }
      },
      (error) => {
        console.error('Error:', error);
        this.loadingMessage = 'Unable to Create Cloud Table'
        // Handle error
      }
    );
  };

  getInitialTableData() {
    this.errorQueryingLineups = null;
    this.isFetchingTableData = true;
    this.loadingMessage = 'Loading Initial Data...'
    this.lineupsService.queryBqTable(this.jobData, this.filters, this.numLineups, this.numUniques).subscribe((data) => {
      console.log('lineup data', data.response);
      this.jobData.secondaryStackSummary = data.secondaryStackSummary;
      this.jobData.primaryStackSummary = data.primaryStackSummary;
      this.jobData.currentLineups = data.response;
      const exposure = this.calculateExposure(data);
      this.exposureMap = exposure;
      this.isFetchingTableData = false;
      this.isLoadingTableData = false;
    },
      (error) => {
        console.error('BQ Error While Fetching Lineups:', error);
        this.errorQueryingLineups = `Unable to Fetch Initial Lineup Data: ${JSON.stringify(error)}`;
        this.isFetchingTableData = false;
        this.isLoadingTableData = false;
      });
  }

  handleUpdateFilters(filters) {
    this.filters = filters;
    this.stateService.setEntryBuilderFilters(filters);
  }

  handleQueryTable() {
    this.errorQueryingLineups = null;
    this.loadingMessage = 'Refining Your Entries...'
    this.isFetchingTableData = true;
    this.lineupsService.queryBqTable(this.jobData, this.filters, this.numLineups, this.numUniques).subscribe((data) => {
      console.log('lineup data', data.response);
      this.jobData.currentLineups = data.response;
      this.jobData.secondaryStackSummary = data.secondaryStackSummary;
      this.jobData.primaryStackSummary = data.primaryStackSummary;
      const exposure = this.calculateExposure(data);
      this.exposureMap = exposure;
      this.isFetchingTableData = false;
    },
      (error) => {
        console.error('BQ Error While Fetching Lineups:', error);
        this.errorQueryingLineups = `Unable to Query Lineup Data: ${JSON.stringify(error)}`;
        this.isFetchingTableData = false;
        this.isLoadingTableData = false;
      });
  }

  calculateExposure(data) {
    const players = data.response;
    const exposureMap = new Map<string, { count: number, showdownPosition: string }>();
  
    players.forEach((playerData) => {
      Object.entries(playerData).forEach(([key, value]) => {
        if (typeof value === 'string') {
          let match = null;
          let playerId = '';
          let playerName = '';
          let playerKey = '';
          let showdownPosition = '';
  
          if (this.jobData.site === 'dk') {
            match = value.match(/([^()]+)\s*\((\d+)\)/);
            if (match) {
              playerName = match[1].trim();
              playerId = match[2];
              playerKey = `${playerName} (${playerId})`;
              if (key === 'cpt') {
                showdownPosition = 'CPT';
              }
            }
          } else {
            match = value.match(/([\d-]+):(.+)/);
            if (match) {
              playerName = match[2].trim();
              playerId = match[1];
              playerKey = `${playerId}:${playerName}`;
              // if (key === 'mvp') {
              //   showdownPosition = 'MVP';
              // }
            }
          }
  
          if (match) {
            const existing = exposureMap.get(playerKey);
            if (existing) {
              exposureMap.set(playerKey, {
                count: existing.count + 1,
                showdownPosition: existing.showdownPosition || showdownPosition
              });
            } else {
              exposureMap.set(playerKey, { count: 1, showdownPosition });
            }
          }
        }
      });
    });
  
    // Calculate exposure percentage
    const totalPlayers = players.length;
    exposureMap.forEach((value, player) => {
      const exposurePercentage = (value.count / totalPlayers) * 100;
      const roundedExposurePercentage = Math.round(exposurePercentage);
      exposureMap.set(player, { count: roundedExposurePercentage, showdownPosition: value.showdownPosition });
    });
  
    this.transformExposureMapToArray(exposureMap);
    return exposureMap;
  }
  

  transformExposureMapToArray(exposureMap: Map<string, { count: number, showdownPosition: string }>) {
    const exposureArray: { name: string, playerId: string, exposure: number, minExposure: any, maxExposure: any, showdownPosition: string }[] = [];
  
    exposureMap.forEach((value, playerKey) => {
      let match = null;
      let playerId = '';
      let playerName = '';
  
      if (this.jobData.site === 'dk') {
        match = playerKey.match(/([^()]+)\s*\((\d+)\)/);
        if (match) {
          playerName = match[1].trim();
          playerId = match[2];
        }
      } else {
        match = playerKey.match(/([\d-]+):(.+)/);
        if (match) {
          playerName = match[2].trim();
          playerId = match[1];
        }
      }
  
      if (match) {
        exposureArray.push({
          name: playerName,
          playerId: playerId,
          exposure: value.count,
          minExposure: null,
          maxExposure: null,
          showdownPosition: value.showdownPosition
        });
      }
    });
  
    const preSortedExposureObject = exposureArray.sort((a, b) => b.exposure - a.exposure);
    this.jobData.currentPlayers = preSortedExposureObject;
    this.handleNonPortfolioPlayers();
  }
  

  handleNonPortfolioPlayers() {
    this.draftGroupService.getDraftGroupById(this.jobData.site, this.jobData.draftGroupId, this.jobData.sport.toLowerCase(), this.jobData.jobType, this.jobData.jobSubType).then((response) => {
      if (response != null) {
        if (response.length === 0) {
          // no players in the draftgroup
        } else {
          const uniqueTeams = [];
          const allPlayers = response;
          this.jobData.draftGroup = response;
          allPlayers.forEach((player) => {
            const team = {
              name: player.Team,
            };
            const teamExists = uniqueTeams.some(existingTeam => existingTeam.name === team?.name);
            if (!teamExists) {
              uniqueTeams.push(team);
            }
          });

          this.jobData.currentTeams = uniqueTeams;
          this.handleTeamExposure();

          const nonPortfolioPlayers = allPlayers.filter((p) =>
            !this.jobData.currentPlayers.some((player) => player.playerId === p.PlayerId.toString())
          );

          if (nonPortfolioPlayers.length) {
            const mappedPlayersWithExposure = nonPortfolioPlayers.map((p) => ({
              name: p.Name,
              playerId: p.PlayerId.toString(),
              minExposure: null,
              maxExposure: null,
              exposure: 0,
            }));
            this.jobData.currentPlayers = [...this.jobData.currentPlayers, ...mappedPlayersWithExposure];
            // this.stateService.setEntryBuilderFilters(this.filters);
          }
          this.stateService.setEntryBuilderFilters(this.filters);
        }
      }
    }).catch((e) => {
      console.log('error grabbing draft group', e);
    });
  }

  resetPlayerElements() {
    this.jobData.currentPlayers = null;
    this.jobData.playerHighlight = null;
    this.jobData.selectedExposurePlayer = null;
  }

  resetTeamElements() {
    this.jobData.currentTeams = null;
    this.jobData.teamHighligh = null;
    this.jobData.selectedExposureTeam = null;
  }

  handleTeamExposure() {
    this.jobData.currentTeams.forEach((team) => {
      const name = team.name;
      team.minPrimaryExposure = null;
      team.maxPrimaryExposure = null;
      team.minSecondaryExposure = null;
      team.maxSecondaryExposure = null;
      team.primaryGlobalExposure = this.getPrimaryGlobalTeamExposure(name);
      team.secondaryGlobalExposure = this.getSecondaryGlobalTeamExposure(name);
      team.primaryStackExposure = this.getPrimaryStackExposure(name);
      team.secondaryStackExposure = this.getSecondaryStackExposure(name);
      team.primaryStackLengths = this.getTeamStackLengths(team, 'primary');
      team.secondaryStackLengths = this.getTeamStackLengths(team, 'secondary');
    });

    this.jobData.currentTeams.forEach((team) => {
      team.primaryGlobalExposurePct = (team.primaryGlobalExposure / this.jobData.currentLineups.length * 100).toFixed();
      team.secondaryGlobalExposurePct = (team.secondaryGlobalExposure / this.jobData.currentLineups.length * 100).toFixed();
    });

    this.jobData.currentTeams.sort((a, b) => b.primaryGlobalExposure - a.primaryGlobalExposure);
  }

  getTeamStackLengths(team, type) {
    const stackExposure = type === 'primary'
      ? team.primaryStackExposure
      : team.secondaryStackExposure;

    const filtersArray = ['two', 'three', 'four', 'five'].map((stack) => ({
      name: `${stack.charAt(0).toUpperCase()}${stack.slice(1)} Stack`,
      min: null,
      max: null,
      currentValue: stackExposure[`${stack}Stack`],
    }));

    return filtersArray;
  }

  getPrimaryGlobalTeamExposure(name) {
    const globalExposure = this.jobData.primaryStackSummary.globalTeamExposure[name];
    if (globalExposure) {
      return globalExposure;
    } else {
      return 0;
    }
  }

  getSecondaryGlobalTeamExposure(name) {
    const globalExposure = this.jobData.secondaryStackSummary.globalTeamExposure[name];
    if (globalExposure) {
      return globalExposure;
    } else {
      return 0;
    }
  }

  getPrimaryStackExposure(abbreviation: string): any {
    const teamValues: any = {
      twoStack: 0,
      threeStack: 0,
      fourStack: 0,
      fiveStack: 0
    };

    const valueMap = ['two', 'three', 'four', 'five'];

    // Loop through each key in the teamStackExposure object
    for (const key in this.jobData.primaryStackSummary.teamStackExposure) {
      if (key.startsWith(abbreviation)) {
        // Extract the number part (2, 3, 4, 5) from the key
        const newKey = parseInt(key.replace(abbreviation + ' ', ''), 10) - 2;
        if (newKey >= 0 && newKey < valueMap.length) {
          const newKeyString = `${valueMap[newKey]}Stack`;
          // Set the value in teamValues object
          teamValues[newKeyString] = this.jobData.primaryStackSummary.teamStackExposure[key];
        }
      }
    }

    return teamValues;
  }


  getSecondaryStackExposure(abbreviation: string): any {
    const teamValues: any = {
      twoStack: 0,
      threeStack: 0,
      fourStack: 0,
      fiveStack: 0
    };

    const valueMap = ['two', 'three', 'four', 'five'];

    // Loop through each key in the teamStackExposure object
    for (const key in this.jobData.secondaryStackSummary.teamStackExposure) {
      if (key.startsWith(abbreviation)) {
        // Extract the number part (2, 3, 4, 5) from the key
        const newKey = parseInt(key.replace(abbreviation + ' ', ''), 10) - 2;
        if (newKey >= 0 && newKey < valueMap.length) {
          const newKeyString = `${valueMap[newKey]}Stack`;
          // Set the value in teamValues object
          teamValues[newKeyString] = this.jobData.secondaryStackSummary.teamStackExposure[key];
        }
      }
    }

    return teamValues;
  }

  onSelectNumLineups(event) {
    const value = event.target.value;
    if (value === 'custom') {
      this.isCustomNumLineups = true;
      setTimeout(() => {
        this.customCountInput.nativeElement.focus();
      }, 250);
      return;
    }
    this.resetPlayerElements();
    this.resetTeamElements();
    const number = parseInt(value);
    this.selectedNumber = value;
    this.selectedNumberInt = parseInt(value);
    this.numLineups = number;
    this.handleQueryTable();
  }

  onClickGetCustomLineupNumber() {
    const value = this.customNumLineups;
    this.resetTeamElements();
    this.resetPlayerElements();
    this.numLineups = value;
    this.handleQueryTable();
  }

  onEnterGetCustomLineupNumber(event) {
    if (event.key === 'Enter') {
      if (this.customNumLineups > 1 && this.customNumLineups < 500 && !this.isFetchingTableData && !this.isLoadingTableData) {
        this.onClickGetCustomLineupNumber();
      }
    }
  }

  onClickCancelCustomNumber() {
    this.resetTeamElements();
    this.resetPlayerElements();
    this.numLineups = 20;
    this.selectedNumber = '20';
    this.selectedNumberInt = 20;
    this.customNumLineups = 250;
    this.isCustomNumLineups = false;
    this.handleQueryTable();
  }

  onSelectNumUniques(event) {
    this.resetTeamElements();
    this.resetPlayerElements();
    const value = event.target.value;
    const number = parseInt(value);
    this.selectedUniques = value;
    this.selectedUniquesInt = parseInt(value);
    this.numUniques = number;
    this.handleQueryTable();
  }

  onClickExportCurrentLineups() {
    if (this.jobData.currentLineups == null) {
      return;
    }
    generateExportFile(this.jobData.sport, this.jobData, this.jobData.currentLineups, true);
  }

  async onClickSimOptoEntries() {
    this.isCreatingOptoToSimData = true;
    if (this.isLoadingTableData && this.isFetchingTableData) {
      return;
    }
    if (this.jobData.currentLineups == null) {
      return;
    }
    const seedData = cloneDeep(this.jobData);
    const optoToSimJobData = await handleOptimizerSeed(seedData);
    this.jobsService.saveJobDataSnapshot(optoToSimJobData);
    setTimeout(() => {
      this.router.navigate(['/run']);
    }, 500);
  }

  // Utility Functions

  isTableExpired(job) {
    const { seconds, nanoseconds } = job.updatedAt;

    const jobTimestamp = (seconds * 1000) + (nanoseconds / 1_000_000);
    const jobDate = new Date(jobTimestamp);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - jobDate.getTime();
    const sixDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000; // change back to 1 later

    return timeDifference > sixDaysInMilliseconds;
  }

  onToggleTableExposure() {
    this.showTableExposure = !this.showTableExposure;
    localStorage.setItem('entryBuilderShowExposure', this.showTableExposure ? 'on' : 'off');
  }

  onToggleShowTeams() {
    this.showTableTeams = !this.showTableTeams;
    localStorage.setItem('entryBuilderShowTeams', this.showTableTeams ? 'on' : 'off');
  }

  ngOnDestroy() {
    this.subscription = null;
    this.subscriptionTeam = null;
  }


}

