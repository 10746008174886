<import-widget
*ngIf="showSniper"
title="Pro Ownership"
[subTitle]="sniperMessage"
[isLoading]="isLoading"
[showFix]="!isKeyValid"
[fixOptions]="fixOptions"
fixMessage="Select the column containing player's Pro Ownership value"
optionMessage="How would you like to fix this value?"
[availableKeys]="keysToSelect"
[isMappingValid]="isKeyValid"
(handleMappingSelected)="selectKey($event)"
(handleEditMapping)="onClickChangeKey()"
[isCustomMapping]="isCustomMapping"
(handleRemoveCustomMapping)="handleRemoveCustomMapping()"
>
</import-widget>

<!-- [fixOptions]="fixOptions" -->
<!-- (handleErrorOption)="handleErrorOption($event)" -->