import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MLB_TEAM_LOGO_IDS } from 'src/app/constants/team-images/MLB/mlb-team-image-map';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'team-filter-widget',
  templateUrl: './team-filter-widget.component.html',
  styleUrls: ['./team-filter-widget.component.scss']
})
export class TeamFilterWidgetComponent {

  @Input() jobData: any;
  @Input() match: any;

  awayTeam: any = "";
  awayImgURL: any = "";
  homeTeam: any = "";
  homeImgURL: any = "";


  constructor(
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.generateData(this.match);
  }

  generateData(matchupString: any) {
    if (matchupString && matchupString.name) {
      const [teams, timePart, timeZone] = matchupString.name.split(' ');
      const [awayTeam, homeTeam] = teams.split('@');

      this.homeTeam = homeTeam;
      this.awayTeam = awayTeam;

      let awayTeamId
      let homeTeamId

      switch (this.jobData.sport) {
        case 'MLB':
          awayTeamId = MLB_TEAM_LOGO_IDS[awayTeam] || 'default-id';
          homeTeamId = MLB_TEAM_LOGO_IDS[homeTeam] || 'default-id';
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${awayTeamId}.svg`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${homeTeamId}.svg`;
          break;
        case 'MMA':
          const awayFighter = this.jobData.draftGroup.find((p) => p.Name.includes(awayTeam));
          awayTeamId = awayFighter.Name || 'default-id';
          const homeFighter = this.jobData.draftGroup.find((p) => p.Name.includes(homeTeam));
          homeTeamId = homeFighter.Name
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/ufc-fighter-images/${awayTeamId}.jpg`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/ufc-fighter-images/${homeTeamId}.jpg`;
          break;
        case 'NFL':
          this.awayImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${awayTeam}.png`;
          this.homeImgURL = `https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${homeTeam}.png`;
          break;
        default:
          console.log('no team img generation match found');
      }
    }
  }

  // FILTER FUNCS

  onClickSortByMatch() {
    this.jobData.currentSelectedMatch = this.match;
    if (this.jobData?.currentSort === this.match) {
      this.jobData.currentSort = null;
    } else {
      this.jobData.currentSort = this.match;
      const key = `${this.awayTeam}@${this.homeTeam}`;

      const sortedPlayers = this.jobData.draftGroup.sort((a, b) => {
        const aIncludesTeam = a.GameInfo.includes(key);
        const bIncludesTeam = b.GameInfo.includes(key);

        if (aIncludesTeam && !bIncludesTeam) {
          return -1;
        } else if (!aIncludesTeam && bIncludesTeam) {
          return 1;
        } else {
          return 0;
        }
      });

      this.jobData.draftGroup = sortedPlayers;
      this.stateService.setProjectionDraftGroup(sortedPlayers);
    }
  }
}
