export const setPositionFilters = (key) => {

    switch (key) {
        case 'mlb-optimizer-dk':
        case 'mlb-optimizer-fd':
        case 'mlb-simulator-dk':
        case 'mlb-simulator-fd':
            return ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];
        case 'nfl-optimizer-dk':
        case 'nfl-optimizer-fd':
        case 'nfl-simulator-dk':
        case 'nfl-simulator-fd':
            return ['QB', 'RB', 'TE', 'WR', 'DST'];
        default:
            console.log('Positional Filters Not Supported for: ' + key );
    }

}