const BASE_COLUMNS = [
    { prop: 'Name', name: 'Name' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'Projection', name: 'Projection', editable: true },
    { prop: 'StdDev', name: 'StdDev', editable: true },
    { prop: 'Ownership', name: 'Ownership', editable: true },
];

const SIM_COLUMNS = [
    ...BASE_COLUMNS,
    { prop: 'FieldPts', name: 'Field Projection', editable: true },
];

const TOURNAMENT_COLUMNS = [
    ...BASE_COLUMNS,
    { prop: 'MakeCut', name: 'Make Cut Prob', suffix:"%", editable: true, tooltip: '0-100 percentage chance that player makes tournament cut' },
    { prop: 'WinProb', name: 'Win Prob', suffix:"%", editable: true,  tooltip: '0-100 percentage chance that player wins tournament' },
    { prop: 'FieldPts', name: 'Field Projection', editable: true },
]

export const PGA_TABLES_SIM = {
    projections_showdown_dk: SIM_COLUMNS,
    projections_showdown_fd: SIM_COLUMNS,
    projections_classic: SIM_COLUMNS,
    projections_classic_tournament: TOURNAMENT_COLUMNS,
};

export const PGA_TABLES_OPTO = {
    projections_showdown_dk: BASE_COLUMNS,
    projections_showdown_fd: BASE_COLUMNS,
    projections_classic: BASE_COLUMNS,
};