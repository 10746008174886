import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { setAvailableFilters } from '../../../../pages/portal/job-results/shared-components/job-build-lineups/utilities/lineups-sport-utilities';
import { MLB_TEAM_LOGO_IDS } from 'src/app/constants/team-images/MLB/mlb-team-image-map';

@Component({
  selector: 'entry-builder-table',
  templateUrl: './entry-builder-table.component.html',
  styleUrls: ['./entry-builder-table.component.scss']
})
export class EntryBuilderTableComponent {
  // TODO: Refeactor and remove unused base table props
  @Input() columns: any;
  @Input() rows: any;
  @Input() playerHighlight: string = '';
  @Input() jobData: any;
  @Input() filters: any;
  @Input() allRowsSelected: boolean;
  @Input() selectable: boolean;
  @Input() tableLabel: any;
  @Input() tableLabelCount: any;
  @Input() searchKey: string;
  @Input() showDownload: boolean;
  @Input() showCopy: boolean;
  @Input() isCopyingData: boolean;
  @Input() showAdvancedHeader: boolean;
  @Input() showImport: boolean = false;
  @Input() exposureMap: any = null;
  @Input() showExposure: boolean = false;
  @Input() showTeams: boolean = true;
  @Input() iLoadingData: boolean = false;
  @Input() hideToolsRow: boolean = false;
  @Output() handleSelectAllRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleSelectSingleRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() hanleEditComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCopyTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportData: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleQueryTable = new EventEmitter<void>();
  @Output() handleUpdateFilters = new EventEmitter<void>();

  @ViewChild('editInput') editInput!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('tableWrapper') tableWrapper: ElementRef;
  @ViewChild('filterModal', { static: false }) filterModal!: ElementRef;

  sortColumn: string = '';
  sortDirection: string = 'asc';
  tableRows: any;
  searchValue: string;

  tooltipValue: string | undefined;
  showTooltipFlag: boolean = false;

  editingRow: number | null = null;
  editingColumn: string | null = null;

  inputValues: { [key: string]: string } = {};
  checkedExposures: number = 0;
  tableRendered: boolean = false;

  hoveredPlayer: string | null = null;
  exposureWidthCache: Map<string, number> = new Map<string, number>();

  availableFilters: any;
  filterCountMap: any;
  existingFiltersOfType: any = [];
  addFilterError: any;
  filterModalMode: any;
  filterModalPosition: any;
  playerModalQuery: any;
  filterModalStacks: any;
  stagedFilter: any;
  stackLengthFilters: any[] = [];

  constructor() { }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (this.filterModalMode && !this.filterModal.nativeElement.contains(event.target)) {
      this.filterModalMode = null;
    }
  }

  ngOnInit() {
    const type = this.jobData.jobType === 'simulator' ? 'sim' : 'opto';
    this.availableFilters = setAvailableFilters(this.jobData.sport, type, this.jobData.site);
  }

  ngAfterContentInit() {
    this.tableRendered = true;

    console.log('job Data', this.jobData);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.handleFilterCountMap();
    }
  }

  capitalizeAndBreakLines(text: string): string {
    return text.replace(/\)/g, ')<br>');
  }

  removeFrontId(text) {
    if (text && text.length > 6) {
      return text.replace(/^\d+-\d+:(.*)$/, '$1');
    } else {
      return '<- Update Configuration -->';
    }
  }

  selectAllRows(data) {
    this.handleSelectAllRows.emit(data);
  }

  toggleRowSelection(row) {
    this.handleSelectSingleRow.emit(row);
  }

  handleTableDownload() {
    this.downloadTable.emit();
  }

  handleTableCopy() {
    this.handleCopyTable.emit();
  }

  enableEdit(row: any, column: string) {
    this.editingRow = row;
    this.editingColumn = column;

    setTimeout(() => {
      this.editInput.nativeElement.focus();
      this.editInput.nativeElement.select();
    }, 100);
  }

  onEditComplete() {
    this.editingRow = null;
    this.editingColumn = null;
    this.hanleEditComplete.emit()
  }

  toggleSort(column: any) {
    this.jobData.playerHighlight = null;
    this.inputValues = {};
    if (column?.disableSort) {
      return;
    }
    this.tableWrapper.nativeElement.scrollTop = 0;
    if (this.searchKey && column !== this.searchKey) {
      this.searchValue === null;
      this.searchInput.nativeElement.value = '';
    }
    if (this.sortColumn === column.prop) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column.prop;
      this.sortDirection = 'asc';
    }

    this.rows.sort((a, b) => {
      const aValue = a[this.sortColumn];
      const bValue = b[this.sortColumn];

      if (aValue < bValue) return this.sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  handleSortQuery(event) {
    this.jobData.playerHighlight = null;
    this.toggleSort(this.searchKey)
    const query = event;

    // Sort the rows array by fuzzy matching the name property
    this.rows.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a[this.searchKey], query);
      const similarityB = this.calculateFuzzyMatchScore(b[this.searchKey], query);

      return similarityB - similarityA;
    });
  }

  handleColumnSortSearch(event, column) {
    this.sortColumn = column;
    this.sortDirection = 'desc';
    const query = event.target.value;

    this.inputValues[column] = event.target.value;

    this.rows.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a[column], query);
      const similarityB = this.calculateFuzzyMatchScore(b[column], query);

      return similarityB - similarityA;
    });

    Object.keys(this.inputValues).forEach(key => {
      if (key !== column) {
        this.inputValues[key] = null;
      }
    });
  }

  calculateFuzzyMatchScore(str1, str2) {

    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    if (str1.includes(str2)) {
      return str2.length / str1.length;
    }

    return 0;
  }

  onClickImportData() {
    this.handleImportData.emit();
  }

  // EXPOSURE INLINE

  getExposureWidth(playerNameWithId: string): number | undefined {
    if (this.exposureWidthCache.has(playerNameWithId)) {
      return this.exposureWidthCache.get(playerNameWithId);
    } else {
      for (const [player, exposure] of this.exposureMap) {
        if (player.startsWith(playerNameWithId)) {
          const width = Math.round(exposure.count); // Multiply by 100 and round
          // Cache the result
          this.exposureWidthCache.set(playerNameWithId, width);
          return width;
        }
      }
      return undefined;
    }
  };

  onChangeShowExposure(value) {
    this.showExposure = value;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showTooltipFlag = false;
    this.hoveredPlayer = null;
  }

  // ADJUST FOR OTHER SPORTS
  generateMatchupTemplate(matchupString: string): string {
    const [teams, datePart, timePart, timeZone] = matchupString.split(' ');
    const [awayTeam, homeTeam] = teams.split('@');

    // ADD PLACEHOLDER ICON TO STORAGE like default.svg
    const awayTeamId = MLB_TEAM_LOGO_IDS[awayTeam] || 'default-id';
    const homeTeamId = MLB_TEAM_LOGO_IDS[homeTeam] || 'default-id';

    const formattedTimePart = timePart.startsWith('0') ? timePart.slice(1) : timePart;

    return `
        <div class="table-team-row">
        <img class="away" src="https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${awayTeamId}.svg" />
        <div class="middle-data">
        <div class="at-symbol">@</div>
        <div class="time">${formattedTimePart} ${timeZone}</div>
        </div>
        <img class="home" src="https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${homeTeamId}.svg" />
        </div
      `;
  }

  onClickLineupPlayer(name, options) {
    let cleanedName = name.replace(/\([^)]*\)/g, '').trim();
    if (options.idType === 'front') {
      cleanedName = this.removeFrontId(name);
    }
    if (this.jobData.selectedExposurePlayer === cleanedName) {
      this.jobData.selectedExposurePlayer = null;
    } else {
      this.jobData.selectedExposurePlayer = cleanedName;
    }
  }

  // COLUMN FILTER FUNCTIONS
  onSelectFilterColumn(filterValue, event) {
    if (this.jobData.availableColumnValues == null) { return; }
    this.filterModalPosition = null;
    this.playerModalQuery = null;
    event.stopPropagation();

    if (filterValue === 'primaryStack' || filterValue === 'secondaryStack') {
      const availableStacks = [];
      const stacksOfType = this.jobData.availableColumnValues[filterValue];
      stacksOfType.forEach((s) => {
        const filter = {
          name: s,
          selected: this.getStackFilterStatus(s, filterValue),
        }
        availableStacks.push(filter);
      });
      this.filterModalStacks = availableStacks;
    }

    this.stagedFilter = null;
    this.existingFiltersOfType = null;
    const existingFiltersOfType = this.filters.filter((f) => f.filterType === filterValue);
    this.existingFiltersOfType = existingFiltersOfType;

    if (existingFiltersOfType.length === 0) {
      const selectedFilterObject = this.availableFilters.find((f) => f.key === filterValue);
      const newFilter = {
        ...selectedFilterObject,
        id: this.filters.length > 0 ? (this.filters.length + 1) : 0,
        value: 1,
        operator: 'greater-than-or-equal-to',
      }
      this.stagedFilter = newFilter;
    }
    this.filterModalMode = filterValue;

    if (this.filterModalMode.includes('position')) {
      const positionParts = this.filterModalMode.split(' ');
      this.filterModalPosition = positionParts[1];
      const playerFilterArray = [];
      const playersToFilter = this.jobData.availableColumnValues[this.filterModalPosition];
      playersToFilter.forEach((p) => {
        const filter = {
          name: p,
          selected: this.getFilterStatus(p),
        }
        playerFilterArray.push(filter);
      })
      this.jobData.filterModalPlayers = playerFilterArray;
    }
  }

  getStackFilterStatus(s, filterValue) {
    const currentStackColFilters = this.filters.filter((f) => f.filterType === filterValue);
    return !currentStackColFilters.some((f) => f.value === s);
  }

  getFilterStatus(p) {
    const currentPlayerColFilters = this.filters.filter((f) => f.filterType === this.filterModalPosition);
    return !currentPlayerColFilters.some((f) => f.value === p);
  }

  togglePlayerPositionFilter(player) {
    const position = this.filterModalPosition;
    if (player.selected) {
      this.createExclusionFilter(player);
      const matchingPlayer = this.jobData.filterModalPlayers.find((p) => p.name === player.name);
      matchingPlayer.selected = false;
    } else {
      console.log('need to remove the exclusion filter');
      const matchingPlayer = this.jobData.filterModalPlayers.find((p) => p.name === player.name);
      matchingPlayer.selected = true;
      this.filters = this.filters.filter((f) => !(f.filterType === this.filterModalPosition && f.value === player.name));
      this.handleUpdateFilters.emit(this.filters);
    }
  }

  toggleStackColFilter(stack) {
    if (stack.selected) {
      this.createExclusionStackFilter(stack);
      const matchingStack = this.filterModalStacks.find((s) => s.name === stack.name);
      matchingStack.selected = false;
    } else {
      const matchingStack = this.filterModalStacks.find((s) => s.name === stack.name);
      matchingStack.selected = true;
      this.filters = this.filters.filter((f) => !(f.filterType === this.filterModalMode && f.value === stack.name));
      this.handleUpdateFilters.emit(this.filters);
    }
  }

  createExclusionFilter(player) {
    const newExclusionFilter = {
      filterType: this.filterModalPosition,
      operator: 'exclude',
      value: player.name,
      filterValue: '',
    }
    this.filters.push(newExclusionFilter);
    this.handleUpdateFilters.emit(this.filters);
  }

  createExclusionStackFilter(stack) {
    const newExclusionFilter = {
      filterType: this.filterModalMode,
      operator: 'exclude',
      value: stack.name,
      filterValue: this.filterModalMode,
    }
    this.filters.push(newExclusionFilter);
    this.handleUpdateFilters.emit(this.filters);
  }

  onClickApplyColumnFilters() {
    this.handleQueryTable.emit();
  }

  onClickApplyColStackFilters() {
    this.handleQueryTable.emit();
  }

  constructFilterToAdd() {
    const cleanedFilter = {
      id: this.filters.length > 0 ? (this.filters.length + 1) : 0,
      filterType: this.stagedFilter.key,  // this.stagedFilter.key
      filterValue: '', //this.stagedFilter.filterValue || null
      operator: this.stagedFilter.operator,
      value: this.stagedFilter.value,
      min: this.stagedFilter.min,
      max: this.stagedFilter.max,
    }
    return cleanedFilter;
  }

  onClickCreateAdditionalFilter(filterValue, event) {
    event.stopPropagation();
    // save the current one
    if (this.stagedFilter) {
      const filterToSave = this.constructFilterToAdd();
      this.filters.push(filterToSave);
    }
    // then make another
    const selectedFilterObject = this.availableFilters.find((f) => f.key === filterValue);
    const newFilter = {
      ...selectedFilterObject,
      id: this.filters.length > 0 ? (this.filters.length + 1) : 0,
      value: 1,
      operator: 'greater-than-or-equal-to',
    }
    const existingFiltersOfType = this.filters.filter((f) => f.filterType === this.filterModalMode);
    this.existingFiltersOfType = existingFiltersOfType;
    this.stagedFilter = newFilter;
  }

  validateExistingFilters(): boolean {
    const combinedFilters = [...this.existingFiltersOfType];
    if (this.stagedFilter) {
      combinedFilters.push(this.stagedFilter);
    }
    if (combinedFilters) {
      return combinedFilters.every((f) => {
        return f.value !== null && f.value >= f.min && f.value <= f.max;
      });
    } else {
      return true;
    }
  }

  handleFilterCountMap() {
    const countMap = {};
    const nflPositions = ['qb', 'rb1', 'rb2', 'wr1', 'wr2', 'wr3', 'te', 'flex', 'dst',
    'cpt', 'flex1', 'flex2', 'flex3', 'flex4', 'flex5', 'mvp'];
    const mlbPositions = ['p1', 'p2', 'p', 'c1b', '2b', '3b', 'ss', 'of1', 'of2', 'of3'];
    const pgaPositions = ['g1', 'g2', 'g3', 'g4', 'g5', 'g6'];
    const mmaPositions = ['f1', 'f2', 'f3', 'f4', 'f5', 'f6'];
    const positionTypes = [...nflPositions, ...mlbPositions, ...pgaPositions, ...mmaPositions];
  
    this.filters.forEach((f) => {
      let filterType = f.filterType;
  
      if (positionTypes.some(pos => pos === filterType)) {
        filterType = `position ${filterType}`;
      }

      if (countMap[filterType]) {
        countMap[filterType] = countMap[filterType] + 1;
      } else {
        countMap[filterType] = 1;
      }
    });
  
    this.filterCountMap = countMap;
  }

  onClickApplyFilters() {
    this.addFilterError = null;
    if (this.stagedFilter) {
      const filterToSave = this.constructFilterToAdd();
      this.filters.push(filterToSave);
    }
    const filtersValid = this.validateExistingFilters();
    if (filtersValid) {
      this.handleQueryTable.emit();
      this.filterModalMode = null;
      this.addFilterError = null;
      this.handleFilterCountMap();
    } else {
      this.addFilterError = 'Your Filters Contain Errors';
      setTimeout(() => {
        this.addFilterError = null;
      }, 4000);
    }
  }

  onSelectFilterOperator(event) {
    this.stagedFilter.operator = event.target.value;
  }

  getFiltersOfType(filterValue: string): any[] {
    return this.filters.filter(f => f.filterType === filterValue);
  }

  onClickRemoveFilter(eventData: { filterId: number, event: Event }) {
    eventData.event.stopPropagation();
    this.filters = this.filters.filter((f) => f.id !== eventData.filterId);
    this.handleUpdateFilters.emit(this.filters);
    const existingFiltersOfType = this.filters.filter((f) => f.filterType === this.filterModalMode);
    this.existingFiltersOfType = existingFiltersOfType;
  }

  handleCloseFilterModal() {
    this.filterModalMode = null;
    this.stagedFilter = null;
    this.handleFilterCountMap();
  }

  getPlayerTeam(player) {
    if (this.jobData.draftGroup) {
      let match = null;
      let playerName = null;
      let playerId = null;

      if (this.jobData.site === 'dk') {
        match = player.match(/([^()]+)\s*\((\d+)\)/);
        if (match) {
          playerName = match[1].trim();
          playerId = match[2];
        }
      } else {
        match = player.match(/([\d-]+):(.+)/);
        if (match) {
          playerName = match[2].trim();
          playerId = match[1];
        }
      }

      if (playerId) {
        const playerIdInt = parseInt(playerId, 10);
        const matchingObject = this.jobData.draftGroup.find((p) => p.PlayerId === playerIdInt);
        if (matchingObject) {
          return matchingObject.Team;
        } else {
          const fallbackMatchingObject = this.jobData.draftGroup.find((p) => p.Name === playerName);
          if (fallbackMatchingObject) {
            return fallbackMatchingObject.Team;
          }
        }
      }

    }
  }

}


