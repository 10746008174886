import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationComponent } from './pages/auth/registration/registration.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { AccountComponent } from './pages/portal/account/account.component';
import { DashboardComponent } from './pages/portal/dashboard/dashboard.component';
import { SubscribeComponent } from './pages/portal/subscribe/subscribe.component';
import { PrivacyComponent } from './pages/public/privacy/privacy.component';
import { TermsComponent } from './pages/public/terms/terms.component';
import { AuthGuard } from './services/auth-gaurd.service';
import { SubGuard } from './services/sub-gaurd.service';
import { JobsComponent } from './pages/portal/jobs/jobs.component';
import { PalermoComponent } from './pages/auth/palermo/palermo.component';
import { JobCreatorComponent } from './pages/portal/job-creator/job-creator.component';
import { JobResultsComponent } from './pages/portal/job-results/job-results.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    component: DashboardComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: RegistrationComponent
  },
  {
    path: 'reset',
    component: ResetComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'run',
    component: JobCreatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'results',
    component: JobsComponent,
    canActivate: [AuthGuard, SubGuard]
  },
  {
    path: 'results/:jobType/:userId/:jobId',
    component: JobResultsComponent,
    canActivate: [AuthGuard, SubGuard]
  },
  {
    path: 'onboard/:id1',
    component: SubscribeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard, SubGuard]
  },
  {
    path: 'palermo',
    component: PalermoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
    canActivate: [AuthGuard, SubGuard]
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
