import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gameTimeSort'
})
export class GameTimeSortPipe implements PipeTransform {
  transform(items: any[]): any[] {
    if (!items || items.length <= 1) {
      return items;
    }
    
    return items.sort((a, b) => {
      const dateA = new Date(a.summary.game_datetime);
      const dateB = new Date(b.summary.game_datetime);
      return dateA.getTime() - dateB.getTime();
    });
  }
}