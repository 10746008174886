import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KNOWN_FLEX_OWN_HEADERS, KNOWN_TOTAL_OWN_HEADERS, KNOWN_CPT_OWN_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'flexownership-sniper',
  templateUrl: './flexownership-sniper.component.html',
  styleUrls: ['./flexownership-sniper.component.scss']
})
export class FlexownershipSniperComponent implements DoCheck, OnInit {
  @Input() draftGroup: any;
  @Input() csvData: any;
  @Input() playerNameKey: any;
  @Input() userProfile: any; 

  @Output() handleUnitValid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitInvalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeyAdditions: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() handleUpdateUserKeysToRemove: EventEmitter<any> = new EventEmitter<any>(); 

  showSniper: boolean = false;
  snipedValue: any;
  isManualSelection: boolean = false;
  keysToSelect: string[] = [];

  userHeaderMappings: any; 
  isCustomMapping: boolean = false; 

  sniperMessage: string = 'Analyzing...'
  isLoading: boolean = true;
  isKeyValid: boolean = false;
  previousIsKeyValid: boolean = false;

  isErrorCalculating: boolean = false;

  fixOptions: any = [
    { name: 'Choose one of my columns', action: 'select-column'},
    { name: 'Skip and leave blank', action: 'skip'}
  ]

  constructor() { }

  ngDoCheck(): void {
    if (this.isKeyValid !== this.previousIsKeyValid) {
      if (this.isKeyValid) {
        this.handleUnitValid.emit('FlexOwnership');
      } else {
        this.handleUnitInvalid.emit('FlexOwnership');
      }
      this.previousIsKeyValid = this.isKeyValid;
    }
  }

  ngOnInit() {
    this.userHeaderMappings = this.userProfile?.importMappings?.ownership || [];
  }

  snipeValue() {
    this.isKeyValid = false;
    this.isLoading = true;
    this.showSniper = true;
    
    setTimeout(() => {

      this.csvData.forEach((dataObj) => {
        const name = dataObj[this.playerNameKey].trim().toLowerCase();
        let value = null;

        const knownKeys = KNOWN_FLEX_OWN_HEADERS.concat(this.userHeaderMappings);

        const matchingKey = knownKeys.find(key => dataObj.hasOwnProperty(key));
    
        if (matchingKey) {
          if (this.userHeaderMappings.some((k) => k === matchingKey)) { this.isCustomMapping = true; } 
          const rawValue = dataObj[matchingKey];
          if (rawValue !== null && rawValue !== undefined) {
            const parsedValue = parseFloat(rawValue);
            if (!isNaN(parsedValue)) {
              value = Number(parsedValue.toFixed(4));
            } else {
              value = 0;
            }
          }
          this.snipedValue = matchingKey;
        } else {
          this.snipedValue = null;
        }

        this.keysToSelect.push(...Object.keys(dataObj));

        if (value !== null) {
          this.isKeyValid = true;
          this.isLoading = false;
          this.sniperMessage = `Column: ${this.snipedValue}`;

          // Fuzzy matching using string-similarity
          const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
          if (match.bestMatch.rating > 0.5) { // Adjust threshold as needed
              const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
              if (matchingProjectionObj) {
                  matchingProjectionObj.Ownership = value;
              }
          }
        } else {
          // this.isKeyValid = false;
          this.sniperMessage = "Attempting to Calculate...";
          this.calculateValue();
        }
      });

      // Remove duplicates from the keys array
      this.keysToSelect = Array.from(new Set(this.keysToSelect));
    }, 200);
}


  selectKey(key: string): void {

    this.snipedValue = key;
    this.isKeyValid = true;
    this.sniperMessage = `Column: ${this.snipedValue}`;
    this.csvData.forEach((dataObj) => {
      if (dataObj.hasOwnProperty(key)) {

        const name = dataObj[this.playerNameKey].trim().toLowerCase();
        const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
        if (match.bestMatch.rating > 0.5) {
          const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
          if (matchingProjectionObj) {
            let value = null;
            const rawValue = dataObj[key];
            if (rawValue !== null && rawValue !== undefined) {
              const parsedValue = parseFloat(rawValue);
              if (!isNaN(parsedValue)) {
                value = Number(parsedValue.toFixed(4));
              } else {
                value = 0;
              }
              matchingProjectionObj.Ownership = value;
            }
          }
        }

      }
    });
    this.isManualSelection = true;
    this.handleUpdateUserKeyAdditions.emit({ type: 'ownership', value: key }); 
  }

  handleRemoveCustomMapping() { 
    this.handleUpdateUserKeysToRemove.emit({ type: 'ownership', value: this.snipedValue }); 
  }

  onClickChangeKey() {
    // this.snipedValue = null; 
    // this.snipeValue();
  }

  calculateValue() {

    let errorCount = 0;

    this.csvData.forEach((dataObj) => {
      const name = dataObj[this.playerNameKey].trim().toLowerCase();
      let totalOwn = null;
      let cptOwn = null;

      const knownTotalKeys = KNOWN_TOTAL_OWN_HEADERS;
      const knownCptOwnKeys = KNOWN_CPT_OWN_HEADERS;

      const matchingTotalKey = knownTotalKeys.find(key => dataObj.hasOwnProperty(key));
      const matchingCptOwnKey = knownCptOwnKeys.find(key => dataObj.hasOwnProperty(key));
  
      if (matchingTotalKey && matchingCptOwnKey) {
        totalOwn = parseInt(dataObj[matchingTotalKey], 10);
        cptOwn = parseInt(dataObj[matchingCptOwnKey], 10);
      }

      if (totalOwn !== null && cptOwn !== null) {
        this.sniperMessage = "Calculating...";
        
        // Fuzzy matching using string-similarity
        const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
        if (match.bestMatch.rating > 0.5) { // Adjust threshold as needed
            const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
            if (matchingProjectionObj) {
                const flexOwnValue = totalOwn - cptOwn;
                matchingProjectionObj.Ownership = parseFloat(flexOwnValue.toFixed(2))
            }
        }
      } else {
        errorCount++;
      }
    });

    if (errorCount > 0 ) {
      this.isErrorCalculating = true;
      this.sniperMessage = "Unable to Auto Calculate";
    } else {
      this.sniperMessage = "Calculated from Existing Values";
      this.isKeyValid = true;
    }

    this.isLoading = false;
}


  handleErrorOption(option) {
    this.sniperMessage = "Value Skipped";
    this.isKeyValid = true;
  }
}
