import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { KNOWN_WINPROB_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'winprob-sniper',
  templateUrl: './winprob-sniper.component.html',
  styleUrls: ['./winprob-sniper.component.scss']
})
export class WinProbSniperComponent implements OnInit {
  @Input() draftGroup: any;
  @Input() csvData: any;
  @Input() playerNameKey: any;
  @Input() userProfile: any; 

  @Output() handleUnitValid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitInvalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUserDefinedProjection: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeyAdditions: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeysToRemove: EventEmitter<any> = new EventEmitter<any>(); 

  showSniper: boolean = false;
  snipedValue: any;
  isManualSelection: boolean = false;
  keysToSelect: string[] = [];

  userHeaderMappings: any; 
  isCustomMapping: boolean = false; 

  sniperMessage: string = 'Analyzing...'
  isLoading: boolean = true;
  isKeyValid: boolean = false;
  previousIsKeyValid: any;

  constructor() { }


  ngOnInit() {
    this.userHeaderMappings = this.userProfile?.importMappings?.winProb || [];
  }

  snipeValue() {
    this.isKeyValid = false;
    this.isLoading = true;
    this.showSniper = true;

    setTimeout(() => {

        this.csvData.forEach((dataObj) => {
            const playerName = dataObj[this.playerNameKey].trim().toLowerCase();
            let value = null;

            // Define known keys
            const knownKeys = KNOWN_WINPROB_HEADERS.concat(this.userHeaderMappings);

            // Find the matching key in the data object
            const matchingKey = knownKeys.find(key => dataObj.hasOwnProperty(key));

            if (matchingKey) {
                if (this.userHeaderMappings.some((k) => k === matchingKey)) { this.isCustomMapping = true; }
                const rawValue = dataObj[matchingKey];
                if (rawValue !== null && rawValue !== undefined) {
                    const parsedValue = parseFloat(rawValue);
                    if (!isNaN(parsedValue)) {
                        value = Number(parsedValue.toFixed(4));
                    } else {
                        value = 0;
                    }
                }
                this.snipedValue = matchingKey;
            } else {
                this.snipedValue = null;
            }

            this.keysToSelect.push(...Object.keys(dataObj));

            if (value !== null) {
                this.isKeyValid = true;
                this.sniperMessage = `Column: ${this.snipedValue}`;

                // Fuzzy matching to find the closest player name in the draftGroup
                const match = stringSimilarity.findBestMatch(playerName, this.draftGroup.map(player => player.Name.toLowerCase()));
                if (match.bestMatch.rating > 0.5) { // Threshold set to 0.8
                    const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
                    if (matchingProjectionObj) {
                        matchingProjectionObj.WinProb = value;
                    }
                } else {
                    this.isKeyValid = false;
                    this.sniperMessage = "No WinProb Column Detected";
                }
            }
        });

        // Remove duplicates from the keys array
        this.keysToSelect = Array.from(new Set(this.keysToSelect));
        this.isLoading = false;
    }, 200);
}


  selectKey(key: string): void {

    this.snipedValue = key;
    this.handleUserDefinedProjection.emit(key);
    this.isKeyValid = true;
    this.sniperMessage = `Column: ${this.snipedValue}`;
    this.csvData.forEach((dataObj) => {
      if (dataObj.hasOwnProperty(key)) {

        const name = dataObj[this.playerNameKey].trim().toLowerCase();
        const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
        if (match.bestMatch.rating > 0.5) {
          const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
          if (matchingProjectionObj) {
            let value = null;
            const rawValue = dataObj[key];
            if (rawValue !== null && rawValue !== undefined) {
              const parsedValue = parseFloat(rawValue);
              if (!isNaN(parsedValue)) {
                value = Number(parsedValue.toFixed(4));
              } else {
                value = 0;
              }
              matchingProjectionObj.WinProb = value;
            }
          }
        }
        
      }
    });
    this.isManualSelection = true;
    this.handleUpdateUserKeyAdditions.emit({ type: 'winProb', value: key }); 
  }

  handleRemoveCustomMapping() { 
    this.handleUpdateUserKeysToRemove.emit({ type: 'winProb', value: this.snipedValue }); 
  }

  onClickChangeKey() {
    // this.snipedValue = null; 
    // this.snipeValue();
  }

  handleErrorOption(option) {
    if (option === 'clear') {
        this.sniperMessage = "No Make Cut Column Selected";
        this.isKeyValid = false;
        this.handleUpdateUserKeysToRemove.emit({ type: 'winProb', value: this.snipedValue || '' });
        this.csvData.forEach((dataObj) => {
            if (dataObj.hasOwnProperty(this.snipedValue)) {
                const playerName = dataObj[this.playerNameKey].trim().toLowerCase();
                const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === playerName);
                if (matchingProjectionObj) {
                    matchingProjectionObj.WinProb = 0;
                }
            }
        });
    }
}


}
