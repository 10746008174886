const nflTeamColors = {
  BUF: '#0958e5',   // Buffalo Bills - Royal Blue (kept, as it is a bright color)
  MIA: '#00B2A9',   // Miami Dolphins - Bright Aqua
  NE:  '#003B6F',   // New England Patriots - Bright Nautical Blue
  NYJ: '#2D6A4F',   // New York Jets - Brighter Green
  
  BAL: '#6D28D9',   // Baltimore Ravens - Brighter Purple
  CIN: '#FF6F00',   // Cincinnati Bengals - Brighter Orange
  CLE: '#8B5E3C',   // Cleveland Browns - Lighter Brown
  PIT: '#FFD700',   // Pittsburgh Steelers - Brighter Gold
  
  HOU: '#0269a3',   // Houston Texans - Brighter Deep Steel Blue
  IND: '#205bd8',   // Indianapolis Colts - Brighter Speed Blue
  JAX: '#00A3C4',   // Jacksonville Jaguars - Brighter Teal
  TEN: '#6EB0F2',   // Tennessee Titans - Brighter Titans Blue
  
  DEN: '#F57F17',   // Denver Broncos - Brighter Orange
  KC:  '#F22E2B',   // Kansas City Chiefs - Brighter Red
  LV:  '#a6adaf',   // Las Vegas Raiders - Darker Grey (kept, as it contrasts well)
  LAC: '#00A3E0',   // Los Angeles Chargers - Brighter Powder Blue
  
  DAL: '#0570f4',   // Dallas Cowboys - Brighter Royal Blue
  NYG: '#0033A0',   // New York Giants - Brighter Blue
  PHI: '#009B77',   // Philadelphia Eagles - Brighter Midnight Green
  WAS: '#C8102E',   // Washington Commanders - Brighter Burgundy
  
  CHI: '#fe3802',   // Chicago Bears - Brighter Navy Blue
  DET: '#0085CA',   // Detroit Lions - Brighter Honolulu Blue
  GB:  '#2E6B3F',   // Green Bay Packers - Brighter Dark Green
  MIN: '#7528ee',   // Minnesota Vikings - Brighter Purple
  
  ATL: '#D32F2F',   // Atlanta Falcons - Brighter Red
  CAR: '#00A3E0',   // Carolina Panthers - Brighter Panther Blue
  NO:  '#F5B200',   // New Orleans Saints - Brighter Old Gold
  TB:  '#D32F2F',   // Tampa Bay Buccaneers - Brighter Red
  
  ARI: '#C8102E',   // Arizona Cardinals - Brighter Cardinal Red
  LAR: '#0033A0',   // Los Angeles Rams - Brighter Royal Blue
  SF:  '#C8102E',   // San Francisco 49ers - Brighter Scarlet
  SEA: '#00A3E0'    // Seattle Seahawks - Brighter College Navy
};
  
  export const getTeamColorNFL = (abbreviation) => {
    return nflTeamColors[abbreviation] || 'Color not found';
  }
  