<div class="ace-base-modal-wrapper">
    <div class="modal" #modalWrapper>
      <div class="modal-header">
        <div class="modal-header-text">{{modalHeader}}</div>
        <div class="close-icon" (click)="onClickClose()">
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 18 }"
            src="/assets/icons/close-square.svg"
          ></svg-icon>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>