import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { cloneDeep } from 'lodash';

import setJobOptions from 'src/app/pages/portal/job-creator/job-utilities/set-job-options';

import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'mlb-opto-options',
  templateUrl: './mlb-opto-options.component.html',
  styleUrls: ['./mlb-opto-options.component.scss']
})
export class MlbOptoOptionsComponent {
  @Input() jobData: any;

  user: any;

  activeGroup: string = 'settings';

  // BASIC OPTIONS
  projectionMinimum: number;
  randomness: number;
  minLineupSalary: number;

  //OPTO 
  numLineups: number;
  numUniques: number;
  globalTeamLimit: number;

  availableMatches: any;
  availableTeams: any;

  // MLB SPECIFIC
  maxDistanceBetweenBatters: number;
  maxBattersVsPitcher: number;

  totalSelectedPlayers: any;
  searchablePlayers: any;
  siteSalaryMax: any;
  isSalaryMaxEntryLimit: boolean;

  // rules: any = [];
  ruleError: string;
  showRuleForm: boolean;
  ruleType: string = 'at-least';
  ruleValue: number = 1;
  rulePlayers: any = [];

  // teamRules: any = [];
  teamRuleError: string;
  showTeamRuleForm: boolean;
  teamRuleType: string = 'team-limit';
  teamRuleValue: number = 1;
  teamRuleTeam: any;

  // matchupRules: any = [];
  matchupRuleError: string;
  showMatchupRuleForm: boolean;
  matchupRuleType: string = 'matchup-limit';
  matchupRuleValue: number = 1;
  matchupRuleMatch: any;

  // stackRulePairs: any = [];
  // stackRuleLimits: any = [];
  stackRulesError: string;
  showStackRulesForm: boolean;
  stackRuleType: string = 'pair';
  stackRuleKey: string = 'PG';
  stackRulePositions: any = [];
  stackRuleValue: number = 1;
  stackRuleScope: string = 'same-team';
  stackRuleExcludeTeams: any = [];
  stackRuleUnlessPositions: any = [];
  stackRulesUnlessPositionsScope: 'same-team';

  // primaryStackConfig: any;
  primaryStackError: any;
  secondaryStackConfig: any;
  secondaryStackError: any;
  stackConfigValLimit: number;

  availablePresets: any;
  isDefaultApplied: boolean;
  presetName: string;
  selectedPreset: any = null;
  showPresets: boolean;
  showSavePreset: boolean;
  deletePresetIndex: any;
  isMakingPresetDefault: boolean;
  defaultPresetExists: boolean;
  makePresetDefaultIndex: any;
  isSavingPreset: boolean;
  isDeletingPreset: boolean;
  valuesDirty: boolean;

  showImportModal: boolean = false;
  importMode: string = null;

  previousSlateId: any;

  constructor(
    private jobsService: JobsService,
  ) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
      // Prevent the wheel event for number inputs
      event.preventDefault();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['jobData'] && changes['jobData'].currentValue) {
      this.checkSlateIdChange();
    }
  }

  ngDoCheck() {
    this.checkSlateIdChange();
  }

  private checkSlateIdChange() {
    if (this.previousSlateId !== this.jobData?.slateId) {
      const isSecondaryLoad = this.previousSlateId != null;
      if (!isSecondaryLoad) {
        this.createTeamsAndMatches({ updateTeams: false });
      } else {
        this.createTeamsAndMatches({ updateTeams: true });
        this.jobData.rules = [];
        this.jobData.teamRules = [];
        this.jobData.matchupRules = [];
      }
      this.previousSlateId = this.jobData.slateId;
    }
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = auth.currentUser;

    this.mapDraftGroupData();
    // this.createTeamsAndMatches();
    this.getUserOptionPresets({ skipMatch: false, setDefaults: false });
    this.stackConfigValLimit = this.jobData.site === 'fd' ? 4 : 5;
  };

  ngAfterContentInit() {
    if (this.jobData?.contest?.maximumEntries > 100000) {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 49500;
      } else {
        this.siteSalaryMax = 59500;
      }
      this.isSalaryMaxEntryLimit = true;
    } else {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 50000;
      } else {
        this.siteSalaryMax = 60000;
      }
    }
  }

  handleUpdateJobSnapshot() {
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  // PLAYER RULE
  onClickAddRule() {
    this.ruleType = 'at-least';
    this.ruleValue = 1;
    this.rulePlayers = [];
    this.showRuleForm = true;
  }

  onClickSaveRule() {
    const newRule = {
      type: this.ruleType,
      value: this.ruleValue,
      players: this.rulePlayers,
    }
    const rulesToUpdate = this.jobData.rules || []; // might need to be defined at the top;
    rulesToUpdate.push(newRule);
    this.jobData.rules = rulesToUpdate;
    this.handleUpdateJobSnapshot();
    this.showRuleForm = false;
  }

  onClickRemoveRule(index) {
    this.jobData.rules.splice(index, 1);
    this.handleUpdateJobSnapshot();
    // this.handleRulesUpdate();
  }

  onClickCancelRule() {
    this.showRuleForm = false;
  }

  // TEAM RULE
  onClickAddTeamRule() {
    this.teamRuleType = 'team-limit';
    this.teamRuleValue = 1;
    this.teamRuleTeam = null;
    this.showTeamRuleForm = true;
  }

  onClickSaveTeamRule() {
    const newRule = {
      type: this.teamRuleType,
      value: this.teamRuleValue,
      team: this.teamRuleTeam,
    }
    const teamRulesToUpdate = this.jobData.teamRules || [];
    teamRulesToUpdate.push(newRule);
    this.jobData.teamRules = teamRulesToUpdate;
    this.handleUpdateJobSnapshot();
    // this.handleRulesUpdate();
    this.showTeamRuleForm = false;
  }

  onClickRemoveTeamRule(index) {
    this.jobData.teamRules.splice(index, 1);
    this.handleUpdateJobSnapshot();
    // this.handleRulesUpdate();
  }

  onClickCancelTeamRule() {
    this.showTeamRuleForm = false;
    this.teamRuleTeam = null;
  }

  onSelectTeam(data) {
    this.teamRuleTeam = data.name;
  }

  // END TEAM RULE


  // MATCHUP RULE
  onClickAddMatchupRule() {
    this.matchupRuleType = 'matchup-limit';
    this.matchupRuleValue = 1;
    this.matchupRuleMatch = null;
    this.showMatchupRuleForm = true;
  }

  onClickSaveMatchupRule() {
    const newRule = {
      type: this.matchupRuleType,
      value: this.matchupRuleValue,
      matchup: this.matchupRuleMatch,
    }
    const existingMatchupRulesToUpdate = this.jobData.matchupRules = [];
    existingMatchupRulesToUpdate.push(newRule);
    this.jobData.matchupRules = existingMatchupRulesToUpdate;
    this.handleUpdateJobSnapshot();
    this.showMatchupRuleForm = false;
  }

  onClickRemoveMatchupRule(index) {
    this.jobData.matchupRules.splice(index, 1);
    this.handleUpdateJobSnapshot();
  }

  onClickCancelMatchupRule() {
    this.showMatchupRuleForm = false;
    this.matchupRuleMatch = null;
  }

  onSelectMatchup(data) {
    this.matchupRuleMatch = data.name;
  }
  // END MATCHUP RULE

  // STACK RULE
  onClickAddStackRule() {
    this.stackRuleType = 'pair';
    this.stackRuleValue = 1;
    this.stackRuleKey = 'PG';
    this.stackRulePositions = [];
    this.stackRuleScope = 'same-team';
    this.stackRulesUnlessPositionsScope = 'same-team';
    this.showStackRulesForm = true;
    this.stackRuleExcludeTeams = [];
    this.stackRuleUnlessPositions = [];
  }

  onClickSaveStackRule() {
    if (this.stackRuleType === 'pair') {
      const newRule = {
        key: this.stackRuleKey,
        positions: this.stackRulePositions,
        value: this.stackRuleValue,
        scope: this.stackRuleScope,
        excludeTeams: this.stackRuleExcludeTeams,
      }
      const existingPairsToUpdate = this.jobData.stackRules.pair || [];
      existingPairsToUpdate.push(newRule);
      this.jobData.stackRules.pair = existingPairsToUpdate;
    } else {
      const newRule = {
        positions: this.stackRulePositions,
        scope: this.stackRuleScope,
        value: this.stackRuleValue,
        unlessPositions: this.stackRuleUnlessPositions,
        unlessPositionsType: this.stackRulesUnlessPositionsScope,
        excludeTeams: this.stackRuleExcludeTeams,
      }
      const existingLimitsToUpdate = this.jobData.stackRules.limit || [];
      existingLimitsToUpdate.push(newRule);
      this.jobData.stackRules.limit = existingLimitsToUpdate;
    }
    this.handleUpdateJobSnapshot();
    this.showStackRulesForm = false;
  }

  onClickRemoveStackRulePair(index) {
    this.jobData.stackRules.pair.splice(index, 1);
    this.handleUpdateJobSnapshot();
  }

  onClickRemoveStackRuleLimit(index) {
    this.jobData.stackRules.limit.splice(index, 1);
    this.handleUpdateJobSnapshot();
  }

  onClickCancelStackRule() {
    this.showStackRulesForm = false;
  }

  onSelectStackPosition(data) {
    this.stackRulePositions.push(data.name);
  }

  onRemoveStackPosition(index) {
    this.stackRulePositions.splice(index, 1);
  }

  onSelectStackUnlessPosition(data) {
    this.stackRuleUnlessPositions.push(data.name);
  }

  onRemoveStackUnlessPosition(index) {
    this.stackRuleUnlessPositions.splice(index, 1);
  }

  onRemoveStackTeam(index) {
    this.stackRuleExcludeTeams.splice(index, 1);
  }

  onClickRemoveStagedPlayer(index) {
    this.rulePlayers.splice(index, 1);
  }

  onSelectPlayer(data) {
    this.ruleError = null;
    const playerExists = this.rulePlayers.some(player => player === data);
    if (playerExists) {
      this.ruleError = 'Player Already Exists';
      return;
    }
    this.rulePlayers.push(data);
  }

  // PRIMARY STACK AND SECONDARY STACK
  onClickRemoveStackTeam(index, stackType) {
    if (stackType === 'primary') {
      this.jobData.options.primaryStackConfig.teams.splice(index, 1);
    } else {
      this.jobData.options.secondaryStackConfig.teams.splice(index, 1);
    }
    this.onValueChange({ userChange: false });
  };

  onSelectStackTeam(selection, stackType) {
    if (stackType === 'primary') {
      const playerExists = this.jobData.options.primaryStackConfig?.teams && this.jobData.options.primaryStackConfig?.teams.some(team => team.name === selection.name);
      if (playerExists) {
        this.primaryStackError = 'Player Already Exists';
        return;
      }
      const existingTeamsToUpdate = this.jobData.options.primaryStackConfig.teams || [];
      existingTeamsToUpdate.push(selection);
      this.jobData.options.primaryStackConfig.teams = existingTeamsToUpdate;
    } else {
      const playerExists = this.jobData.options.optionssecondaryStackConfig?.teams && this.jobData.optionssecondaryStackConfig?.teams.some(team => team.name === selection.name);
      if (playerExists) {
        this.secondaryStackError = 'Player Already Exists';
        return;
      }
      const existingTeamsToUpdate = this.jobData.options.secondaryStackConfig.teams || [];
      existingTeamsToUpdate.push(selection);
      this.jobData.options.secondaryStackConfig.teams = existingTeamsToUpdate;
    }
    this.onValueChange({ userChange: false });
  };

  onClickClearStackTeams(stackType) {
    if (stackType === 'primary') {
      this.jobData.options.primaryStackConfig.teams = [];
    } else {
      this.jobData.options.secondaryStackConfig.teams = [];
    }
    this.onValueChange({ userChange: false });
  }

  onClickRestoreStackTeams(stackType) {
    if (stackType === 'primary') {
      this.jobData.options.primaryStackConfig.teams = cloneDeep(this.availableTeams);
    } else {
      this.jobData.options.secondaryStackConfig.teams = cloneDeep(this.availableTeams);
    }
    this.onValueChange({ userChange: false });
  }

  onValueChange(options) {
    const isValid = this.validateOptionValues();
    if (isValid) {
      this.jobData.optionsValid = true;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    } else {
      this.jobData.optionsValid = false;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    }
  }

  validateOptionValues() {
    const conditions = [
      { condition: true, min: 0, max: null, value: this.jobData.options.projectionMinimum },
      { condition: true, min: 0, max: 1000, value: this.jobData.options.randomness },
      { condition: true, min: 0, max: this.siteSalaryMax, value: this.jobData.options.minLineupSalary },
      { condition: true, min: 0, max: 3, value: this.jobData.options.maxDistanceBetweenBatters },
      { condition: true, min: 0, max: 4, value: this.jobData.options.maxBattersVsPitcher },
      { condition: true, min: 0, max: 5000, value: this.jobData.options.numLineups },
      { condition: true, min: 1, max: 5, value: this.jobData.options.numUniques },
      { condition: true, min: 0, max: 20, value: this.jobData.options.globalTeamLimit },
      { condition: this.jobData.options.primaryStackConfig.teams.length > 0, min: 1, max: this.stackConfigValLimit, value: this.jobData.options.primaryStackConfig.min },
      { condition: this.jobData.options.primaryStackConfig.teams.length > 0, min: 1, max: this.stackConfigValLimit, value: this.jobData.options.primaryStackConfig.max },
      { condition: this.jobData.options.secondaryStackConfig.teams.length > 0, min: 1, max: this.stackConfigValLimit, value: this.jobData.options.secondaryStackConfig.min },
      { condition: this.jobData.options.secondaryStackConfig.teams.length > 0, min: 1, max: this.stackConfigValLimit, value: this.jobData.options.secondaryStackConfig.max },
    ];
  
    for (const { condition, min, max, value } of conditions) {
      if (condition && (value == null || value < min || (max != null && value > max))) {
        return false;
      }
    }
  
    return true;
  }
  

  mapDraftGroupData() {
    const searchData = this.jobData.draftGroup.map(player => ({
      name: player.Name,
      id: player.PlayerId,
      ...(player.Position ? { position: player.Position } : {}),
    }));
    this.searchablePlayers = searchData;
  };

  createTeamsAndMatches(options) {
    const uniqueTeams = [];
    const uniqueMatches = [];

    this.jobData.draftGroup.forEach((player) => {
      const team = {
        name: player.Team,
      };
      const matchup = {
        name: player.GameInfo.substring(0, player.GameInfo.indexOf(' '))
      };

      const teamExists = uniqueTeams.some(existingTeam => existingTeam.name === team?.name);
      const matchupExists = uniqueMatches.some(existingMatch => existingMatch.name === matchup.name);

      if (!teamExists) {
        uniqueTeams.push(team);
      }

      if (!matchupExists) {
        uniqueMatches.push(matchup);
      }
    });

    if (this.jobData.options.primaryStackConfig?.teams.length === 0 || options.updateTeams) {
      const alphaOrderTeams = uniqueTeams.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  
      if (this.jobData.options.primaryStackConfig && this.jobData.options.primaryStackConfig.teams) {
        this.jobData.options.primaryStackConfig.teams = cloneDeep(alphaOrderTeams);
      }
      if (this.jobData.options.secondaryStackConfig && this.jobData.options.secondaryStackConfig.teams) {
        this.jobData.options.secondaryStackConfig.teams = cloneDeep(alphaOrderTeams);
      }
    }

    this.availableTeams = cloneDeep(uniqueTeams);
    this.availableMatches = uniqueMatches;
  }

  getUserOptionPresets(options) {
    this.jobsService.getJobOptionPresetsByUserId(this.jobData.userId).then((data) => {
      if (data) {
        const presets = data;
        const applicablePresets = presets.filter((set) =>
          set.data.sport === this.jobData.sport
          && set.data.jobType === this.jobData.jobType &&
          set.data.jobSubType === this.jobData.jobSubType)
        this.availablePresets = applicablePresets.sort((a, b) => {
          return b.data.updatedAt - a.data.updatedAt;
        });
        this.defaultPresetExists = applicablePresets.some((set) => set.data.isDefault);
        if (this.jobData.optionPresetId && !options.skipMatch) {
          const jobPreset = this.availablePresets.find((set) => set.id === this.jobData.optionPresetId);
          if (jobPreset !== undefined) {
            this.presetName = jobPreset.data.name;
            this.onSelectOptionPreset(jobPreset.data, jobPreset.id);
          } else {
            this.presetName = 'Preset Deleted - Data Still Applied'
          }
        } else if (this.defaultPresetExists && options.skipMatch === false) {
          const defaultPreset = applicablePresets.find((set) => set.data.isDefault);
          this.onSelectOptionPreset(defaultPreset.data, defaultPreset.id);
          this.isDefaultApplied = true;
          setTimeout(() => {
            this.isDefaultApplied = false;
          }, 4200);
        } else if (!options.skipMatch) {
          this.presetName = 'Default Options'
          if (options.setDefaults !== false) {
            this.onClickRestoreDefaults();
          }
        }
      }
    })
  }

  onSelectOptionPreset(preset, id) {
    this.presetName = preset.name;
    this.selectedPreset = {
      id: id,
      preset: preset,
    };
    this.jobData.options = {
      ...preset.presetValues,
      primaryStackConfig: this.jobData.options.primaryStackConfig,
      secondaryStackConfig: this.jobData.options.secondaryStackConfig,
    };
    this.jobData.optionPresetId = id;

    this.onValueChange({ userChange: false });
    this.showPresets = false;

  }

  onClickShowSave() {
    this.showSavePreset = true;
  }

  onCancelSavePreset() {
    this.showSavePreset = false;
  }

  onRemovePreset(id, index) {
    if (this.isDeletingPreset) {
      return;
    }
    this.deletePresetIndex = index;
    this.isDeletingPreset = true;
    this.jobsService.deleteOptionPresetById(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletePresetIndex = null;
          this.isDeletingPreset = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onClickSetPresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: true,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.isMakingPresetDefault = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onSaveJobPreset(name) {
    this.isSavingPreset = true;
  
    const presetValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});
  
    const preset = {
      isDefault: false,
      presetValues: presetValues,
      name: name,
    };
  
    this.jobsService.createJobOptionPreset(
      this.jobData.userId,
      this.jobData.sport,
      this.jobData.jobType,
      this.jobData.jobSubType,
      preset,
    ).then((res) => {
      if (res) {
        this.presetName = name;
        this.valuesDirty = false;
        this.selectedPreset = {
          id: res,
          preset: preset,
        };
  
        this.jobData.optionPresetId = res;
        this.jobsService.saveJobDataSnapshot(this.jobData);
        setTimeout(() => {
          this.getUserOptionPresets({ skipMatch: true });
          this.showSavePreset = false;
          this.isSavingPreset = false;
        }, 1000);
      }
    });
  }

  onClickUpdateExistingPreset() {
    this.isSavingPreset = true;

    const newValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});

    this.jobsService.updateJobOptionPresetValues(this.jobData.userId, this.selectedPreset.id, newValues).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.isSavingPreset = false;
          this.valuesDirty = false;
          this.getUserOptionPresets({ skipMatch: true, setDefaults: false });
        }, 700);
      }
    })
  }

  onClickRemovePresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: false,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
        setTimeout(() => {
          this.isMakingPresetDefault = false;
        }, 800)
      }
    })
  }

  onClickShowPresets() {
    if (this.showPresets) {
      return;
    }
    setTimeout(() => {
      this.showPresets = true;
    }, 200);
  }

  async onClickRestoreDefaults() {
    this.presetName = 'Default Options';
    this.selectedPreset = null;
    this.showPresets = false;

    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;
    this.jobData.optionPresetId = null;

    this.onValueChange({ userChange: false });

  }

  preventScroll(event: WheelEvent) {
    event.preventDefault();
  }

  // RULE IMPORT 
  onClickImportPlayerRules() {
    this.showImportModal = true;
    this.importMode = 'playerRules';
  }

  handleImportPlayerRules(importRules) {
    importRules.forEach((c) => {
      if (this.jobData.rules.some((rule) => rule === c)) {
        console.log('Rule already exists');
      } else {
        this.jobData.rules.push(c);
      }
    })
    this.handleUpdateJobSnapshot();
    this.handleCloseImportModal();
  }

  onClickImportTeamRules() {
    this.showImportModal = true;
    this.importMode = 'teamRules';
  }

  handleImportTeamRules(importRules) {
    importRules.forEach((c) => {
      if (this.jobData.teamRules.some((rule) => rule === c)) {
        console.log('Rule already exists');
      } else {
        this.jobData.teamRules.push(c);
      }
    })
    this.handleUpdateJobSnapshot();
    this.handleCloseImportModal();
  }

  onClickImportMatchupRules() {
    this.showImportModal = true;
    this.importMode = 'matchupRules';
  }

  handleImportMatchupRules(importRules) {
    importRules.forEach((c) => {
      if (this.jobData.matchupRules.some((rule) => rule === c)) {
        console.log('Rule already exists');
      } else {
        this.jobData.matchupRules.push(c);
      }
    })
    this.handleUpdateJobSnapshot();
    this.handleCloseImportModal();
  }

  handleCloseImportModal() {
    this.showImportModal = false;
    this.importMode = null;
  }

  onToggleGroup(group) {
    this.activeGroup = group;
  }

}

