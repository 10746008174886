const BASE_SIM_OPTIONS = {
    totalSimulations: 50000,
    lineupGenerations: 5,
    topLineupsPct: 5,
    keepUserLineups: true,
    lineupSortWeights: {
        roi: 100,
        projection: 0,
        ownership: 0,
        top1Percent: 0,
        wins: 0,
    },
    projectionMinimum: 1,
    randomness: 100,
    maxPctOffOptimal: 30,
    defaultPositionVariance: 20,
    maxPlayersPerTeam: 3,
    maxDistanceBetweenBatters: 0,
    maxBattersVsPitcher: 0,
    usingStacksPercent: 70,
    usingMaxStackLenPercent: 50,
    secondaryStackPercent: 70,
    defaultHitterVar: 50,
    defaultPitcherVar: 50,
};

const BASE_OPTO_OPTIONS = {
    projectionMinimum: 3,
    randomness: 100,
    numLineups: 150,
    numUniques: 1,
    maxDistanceBetweenBatters: 0,
    maxBattersVsPitcher: 0,
    primaryStackConfig: {
        teams: [],
    },
    secondaryStackConfig: {
        teams: [],
    },
};

export const MLB_SIM_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 49200,
    },
    CLASSIC_FD: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 34200,
    },
    SHOWDOWN_DK: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 49200,
    },
    SHOWDOWN_FD: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 34200,
    },
};

export const MLB_OPTO_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_OPTO_OPTIONS,
        globalTeamLimit: 5,
        minLineupSalary: 49200,
        primaryStackConfig: {
            min: 4,
            max: 5,
            teams: [],
        },
        secondaryStackConfig: {
            min: 2,
            max: 3,
            teams: [],
        },
    },
    CLASSIC_FD: {
        ...BASE_OPTO_OPTIONS,
        globalTeamLimit: 4,
        minLineupSalary: 34000,
        primaryStackConfig: {
            min: 3,
            max: 4,
            teams: [],
        },
        secondaryStackConfig: {
            min: 2,
            max: 4,
            teams: [],
        },
    },
    SHOWDOWN_DK: {
        ...BASE_OPTO_OPTIONS,
        globalTeamLimit: 5,
        minLineupSalary: 49200,
        primaryStackConfig: {
            min: 4,
            max: 5,
            teams: [],
        },
        secondaryStackConfig: {
            min: 2,
            max: 3,
            teams: [],
        },
    },
    SHOWDOWN_FD: {
        ...BASE_OPTO_OPTIONS,
        globalTeamLimit: 4,
        minLineupSalary: 34000,
        primaryStackConfig: {
            min: 3,
            max: 4,
            teams: [],
        },
        secondaryStackConfig: {
            min: 2,
            max: 4,
            teams: [],
        },
    },
    CLASSIC_IKB: {
        ...BASE_OPTO_OPTIONS,
        globalTeamLimit: 5,
        minLineupSalary: 49200,
        primaryStackConfig: {
            min: 4,
            max: 5,
            teams: [],
        },
        secondaryStackConfig: {
            min: 2,
            max: 3,
            teams: [],
        },
    },
};
