<div class="job-results-grid" [ngClass]="{ 'hide-settings': !showSettings }">
  <div class="job-results-lineups-wrapper" *ngIf="jobData">
    <job-build-lineups [jobData]="jobData"></job-build-lineups>
  </div>

  <div class="job-results-config-wrapper">
    <job-results-side-panel
      [jobData]="jobData"
      (handleHideConfig)="handleCollapseSidePanel()"
    ></job-results-side-panel>

    <div *ngIf="!showSettings" class="closed-panel-label">
      Exposure Filters and Settings
    </div>
  </div>
</div>
