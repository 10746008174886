import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastWord'
})
export class LastWordPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    
    const words = value.trim().split(' ');
    return words[words.length - 1];
  }
}