<div style="position: relative;">
    <div class="importer-desc" style="max-width: 88%;">Add up to 2 files (one at a time) and map your data to our columns. We auto
        detect popular files from ETR, Stokastic, and more. </div>
    <file-drop minHeight="130px" (fileDropped)="handleFileInput($event)"
        [isProcessingFile]="isProcessingFile"></file-drop>
    <div class="file-error" *ngIf="fileError">{{fileError}}</div>

    <div class="file-list-wrapper" *ngIf="fileList.length > 0 ">
        <div class="file-card" *ngFor="let file of fileList">
            <div class="file-detail">
                <div class="file-icon">
                    <svg-icon [svgStyle]="{ 'height.px':20 }" src="/assets/icons/csv-doc.svg"></svg-icon>
                </div>
                <div class="file-name">
                    {{file.name}}
                </div>
            </div>
            <div class="remove-wrapper" style="color: var(--ss-text-primary)" *ngIf="!isPreparingData">
                <div (click)="onClickRemoveFile(file.name)">
                    <svg-icon [svgStyle]="{ 'height.px':18 }" src="/assets/icons/square-close.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>

    <section *ngIf="fileList.length > 0">
        <button class="ss-primary-btn" (click)="onClickProcessImport()" [disabled]="isPreparingData">
            <span *ngIf="!isPreparingData">Import {{fileList.length}} {{fileList.length > 1 ? 'Files' : 'File'}}</span>
            <div class="ss-spinner btn-spinner" *ngIf="isPreparingData"></div>
        </button>

        <hr class="mt24 mb16">
    </section>

</div>


<div class="draft-group-import-wrapper" *ngIf="showImportTools">

    <div class="cancel-btn-wrapper" (click)="onClickCancelImport()">
        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/close-square.svg"></svg-icon>
    </div>

    <div class="import-content">
        <div class="import-title">Import Player Data</div>

        <import-widget title="File" subTitle="File Analyzed" [isLoading]="isPreparingData" [showFix]="false"
            [isMappingValid]="true" [isEditable]="false">
        </import-widget>

        <import-widget title="Players" [subTitle]="playerMessage" [isLoading]="isPreparingData"
            [showFix]="!isPlayerKeyValid" fixMessage="Select the column containing player's first and last name"
            [availableKeys]="keysToSelect" [isMappingValid]="isPlayerKeyValid"
            (handleMappingSelected)="selectKey($event)" (handleEditMapping)="onClickRetryNameColumn()"
            [isCustomMapping]="isCustomMapping" (handleRemoveCustomMapping)="handleRemoveCustomMapping()"
            [customKey]="customKey">
        </import-widget>

        <app-message-prompt *ngIf="performanceWarning" type="warning" mb="16px" [messageBody]="performanceWarning">
        </app-message-prompt>

        <section *ngIf="isPlayerKeyValid && !isPlayerMatchError" style="padding-bottom: 8rem;">

            <projection-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" [jobData]="jobData"
                (handleUserDefinedProjection)="handleUserDefinedProjection($event)"
                (handleUnitInvalid)="handleUnitInvalid($event)" [userProfile]="userProfile"
                (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></projection-sniper>

            <minutes-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></minutes-sniper>

            <ownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"
                [jobData]="jobData"></ownership-sniper>

            <proownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></proownership-sniper>

            <starownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></starownership-sniper>

            <mvpownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></mvpownership-sniper>

            <stddev-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" [userDefinedProjectionValue]="userDefinedProjectionValue"
                (handleUnitInvalid)="handleUnitInvalid($event)" [userProfile]="userProfile"
                (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                [requirements]="requiredValidations"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"
                [jobData]="jobData"></stddev-sniper>

            <flexownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></flexownership-sniper>

            <cptownership-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></cptownership-sniper>

            <makecut-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)"
                (handleUserDefinedProjection)="handleUserDefinedProjection($event)"
                (handleUnitInvalid)="handleUnitInvalid($event)" [userProfile]="userProfile"
                (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></makecut-sniper>

            <winprob-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)"
                (handleUserDefinedProjection)="handleUserDefinedProjection($event)"
                (handleUnitInvalid)="handleUnitInvalid($event)" [userProfile]="userProfile"
                (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></winprob-sniper>

            <ko-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)"
                (handleUserDefinedProjection)="handleUserDefinedProjection($event)"
                (handleUnitInvalid)="handleUnitInvalid($event)" [userProfile]="userProfile"
                (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"></ko-sniper>

            <fieldpts-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUseProjectionValue)="handleUseProjectionValue($event)"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"
                [jobData]="jobData"></fieldpts-sniper>

            <battingorder-sniper [draftGroup]="draftGroup" [csvData]="csvDataArray" [playerNameKey]="playerNameKey"
                (handleUnitValid)="handleUnitValid($event)" (handleUnitInvalid)="handleUnitInvalid($event)"
                [userProfile]="userProfile" (handleUpdateUserKeyAdditions)="handleUpdateUserKeyAdditions($event)"
                (handleUpdateUserKeysToRemove)="handleUpdateUserKeysToRemove($event)"
                [jobData]="jobData"></battingorder-sniper>

            <!-- <div class="loader-wrapper" *ngIf="!isImportValid">
                <div class="ss-spinner page"></div>
            </div> -->

            <section class="ss-importer-save-section" *ngIf="isImportValid && !isPreparingData">
                <div class="ss-form-group">
                    <label for="contestType">Name this projection set:</label>
                    <input class="ss-input expanded" id="presetName" placeholder="Name for reference later..."
                        maxlength="100" [(ngModel)]="draftGroupPresetName" autocomplete="off" />
                </div>

                <app-message-prompt *ngIf="importError" type="warning" mb="24px" messageBody="{{ importError }}">
                </app-message-prompt>

                <button class="ss-primary-btn" (click)="onClickImportData()" [disabled]="isImportingData">
                    <span *ngIf="!isImportingData">Import Player Data</span>
                    <div class="ss-spinner btn-spinner" *ngIf="isImportingData"></div>
                </button>
            </section>

        </section>

    </div>

</div>