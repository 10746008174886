export const NBA_LINEUP_GEN_TABLES = {
    DK_CLASSIC: [
        { prop: 'pg', name: 'PG', tooltip: null, searchable: true, noId: true},
        { prop: 'sg', name: 'SG', tooltip: null, searchable: true, noId: true},
        { prop: 'sf', name: 'SF', tooltip: null, searchable: true, noId: true },
        { prop: 'pf', name: 'PF', tooltip: null, searchable: true, noId: true },
        { prop: 'c', name: 'C', tooltip: null, searchable: true, noId: true },
        { prop: 'g', name: 'G', tooltip: null, searchable: true, noId: true },
        { prop: 'f', name: 'F', tooltip: null, searchable: true, noId: true },
        { prop: 'util', name: 'UTIL', tooltip: null, searchable: true, noId: true },
        { prop: 'fpts', name: 'Projection', tooltip: 'Lineup projection' },
        { prop: 'minutes', name: 'Minutes', tooltip: 'Projected Minutes' },
        { prop: 'stddev', name: 'Std Dev', tooltip: null },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total salary of the Lineup" },
        { prop: 'stack', name: 'Stack', tooltip: null },
        { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
      ],
}