import { Component, OnInit } from '@angular/core';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es.js';
import { JobsService } from 'src/app/services/jobs.service';
import { StateService } from 'src/app/services/state.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { DiscordService } from 'src/app/services/discord.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: any;
  userProfile: any;
  isAuthenticated: any;

  discordUpdateMessages: any;
  isLoadingDiscordData: boolean = true;
  discordError: any;

  isLoading = true;
  isLoadingJobs: boolean;
  userSubscription: any;
  quickStartMode: string = 'simulator';
  jobListType: string = 'simulator';

  isLateSwapActive: boolean;
  private subscription: Subscription;

  recentJobs: any;
  recentOptimizerJobs: any;

  isSingleSport: boolean = false;
  isProfileSportChecked: boolean = false;
  isProfileCheckComplete: boolean = false;
  showInvalidSportModal: boolean = false;
  showSelectSportModal: boolean = false;
  singleSportSelection: string = null;
  subscribedSport: string = null;
  subscribedSportEndDate: any = null;

  constructor(
    private router: Router,
    private jobsService: JobsService,
    private titleService: Title,
    private metaService: Meta,
    private stateService: StateService,
    private userService: UserService,
    private datePipe: DatePipe,
    private subMonitor: SubMonitorService,
    private discordService: DiscordService,
  ) {
    this.titleService.setTitle('Ace Mind');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'AceMind'
      }
    );
    this.subscription = this.stateService.isLateSwapActive$.subscribe(
      value => {
        this.isLateSwapActive = value;
      }
    );
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = auth.currentUser;
    window.scrollTo(0, 0);
    this.resetStateValues();
    this.getDiscordData();

    this.subMonitor.getSubscription().subscribe((data) => {
      if (data != null) {
        if (data === 'ACE_SINGLE' || data === 'ACE_SINGLE_YEARLY') {
          this.isSingleSport = true;
          this.checkSingleSportStatus();
        } else {
          this.isProfileCheckComplete = true;
        }
      }
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isAuthenticated = true;
        this.isLoading = false;
        this.isLoadingJobs = true;
        this.getRecentJobs(user.uid);
        anime({
          targets: '.ss-dash-card',
          translateY: ['16px', 0],
          opacity: [0, 1],
          duration: 700,
          direction: 'normal',
          delay: anime.stagger(100),
        });
      } else {
        console.log('no authenticated user')
        this.isLoading = false;
        this.isAuthenticated = false;
      }
    });

  }

  async getDiscordData() {
    setTimeout(() => {
      this.discordService.getDiscordData('channels/1122258826192761053/messages?limit=10').subscribe(
        (data) => {
          this.discordUpdateMessages = data;
          this.isLoadingDiscordData = false;
        },
        (error) => {
          console.error(error);
          this.isLoadingDiscordData = false;
          this.discordError = 'Unable to Fetch Server Data';
        }
      );
    }, 800);
  }

  getAvatarUrl(userId: string, avatarHash: string): string {
    return `https://cdn.discordapp.com/avatars/${userId}/${avatarHash}.png`;
  }

  getRecentJobs(userId) {
    this.jobsService.getRecentUniversalJobs(userId, 6).then((data) => {
      this.recentJobs = data;
      this.isLoadingJobs = false;
    }).catch((e) => {
      console.log(e);
    })
  };

  handleJobTypeToggle(type) {
    this.jobListType = type;
  }

  onClickJob(jobData) {
    const key = jobData.jobType === 'simulator' ? 'sim' : 'opto';
    this.router.navigate([`results/${key}`, this.user.uid, jobData.jobId]);
  }

  resetStateValues() {
    this.stateService.setDailyJobCountReached(false);
    this.stateService.setAllJobCache([]);
  }

  translateStatus(status) {
    switch (status) {
      case 'focused':
        return 'Projections';
      case 'in-progress':
        return 'Configuring';
      case 'running-job':
        return 'Running';
      case 'job-failed':
        return 'Failed';
      case 'complete':
        return 'Complete';
      case 'Job Routed':
        return 'Created';
      default: 'Created';
    }
  }

  checkSingleSportStatus() {
    if (!this.isProfileSportChecked && this.user?.uid) {
      this.isProfileSportChecked = true;
      this.userService.getUserPublicProfileById(this.user.uid).then((data) => {  // NEW
        if (data != null) {
          this.userProfile = data;
          this.subscribedSport = data?.singleSportSub?.sport || null;
          this.subscribedSportEndDate = data?.singleSportSub?.lockedUntilDate || null;
          if (this.subscribedSportEndDate != null) {
            const formattedDate = this.datePipe.transform(
              new Date(this.subscribedSportEndDate.seconds * 1000 + this.subscribedSportEndDate.nanoseconds / 1000000),
              'MM/dd/yy @ hh:mm a'
            );
            this.subscribedSportEndDate = formattedDate;
            this.isProfileCheckComplete = true;
          } else {
            this.isProfileCheckComplete = true;
          }
        }
      });
    }
  }

  onClickCloseSingleSportModal() {
    this.showInvalidSportModal = false;
  }

  handleCloseSingSportOverlay(updateStatus) {
    this.showSelectSportModal = false;
    if (updateStatus) {
      this.isProfileSportChecked = false;
      this.checkSingleSportStatus();
    }
  }

  onClickOpto() {
    this.stateService.setHomeJobPayload({
      jobType: 'optimizer'
    })
    this.router.navigate(['/run'])
  }

  onClickSim() {
    this.stateService.setHomeJobPayload({
      jobType: 'simulator'
    })
    this.router.navigate(['/run'])
  }

  openDiscordInvite(): void {
    const inviteUrl = 'https://discord.gg/cnWsfFuuxU';
    this.openUrlInNewTab(inviteUrl);
  }

  private openUrlInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAnimatedEmoji(id) {
    return `https://cdn.discordapp.com/emojis/${id}.gif`;
  }

  getEmoji(id) {
    return `https://cdn.discordapp.com/emojis/${id}.png`;
  }

}
