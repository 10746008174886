import { Component, OnInit } from '@angular/core';
import { SubscriptionTiers } from 'src/app/constants/subscription-tiers';
import {
  collection,
  getFirestore,
  addDoc,
  onSnapshot
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { StripeService } from 'src/app/services/stripe.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  private subscription: Subscription;
  isSubscribed: boolean;
  database: any;
  products: any;
  user: any;
  isPreparingStore = false;
  isPreparingStoreTrial = false;
  isCheckingActiveSubscription: boolean = true;
  analytics = getAnalytics();
  isAffirmedBYOP: boolean;
  isTrialAvailable: boolean = true;
  userId: string = null;

  selectedTrialToUnlock: any;

  isShowVerifyPhoneNumber: boolean;
  isPhoneFormatValid: boolean;
  isVerifyingPhone: boolean;
  verificationError: boolean;
  isPhoneVerified: boolean;
  phoneNumber: any;
  formattedSystemPhoneNumber: any;
  isPhoneNumberUnique: boolean = true;
  isSuspectedVoip: boolean = false;
  voipAttemptCount: number = 0;
  isSendingCode: boolean;
  isCodeSent: boolean;
  verificationCode: any;
  isVerifyingCode: boolean;

  isUpdatingUserAccount: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private stripeService: StripeService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('id1');
    });

    this.checkAffirmation();

    anime({
      targets: '.ss-subscribe-page-wrapper',
      translateY: ['16px', 0],
      opacity: [0, 1],
      duration: 600,
      delay: 900,
    });

    if (this.userId) {
      this.getStripeSubscriptionData(this.userId);
      this.userService.getUserPublicProfileById(this.userId)
        .then((profile) => {
          this.isPhoneVerified = profile?.isPhoneVerified || false;
          this.voipAttemptCount = profile?.voipAttemptCount || 0;
          if (profile?.grandfathered === true) {
            this.router.navigate(['dashboard']);
          }
          if (profile?.firstSubscribedAt != null) {
            this.isTrialAvailable = false;
          }
        });
    }
    this.products = SubscriptionTiers.filter((p) => p.subRole !== null);
    this.products.forEach((p) => {
      p.showAlternatePricing = false;
    })
    const auth = getAuth();
    this.user = auth.currentUser;
    this.database = getFirestore();
    logEvent(this.analytics, 'screen_view', {
      firebase_screen: 'SUBSCRIBE',
      firebase_screen_class: 'customViewLog'
    });

  };

  getStripeSubscriptionData(userId) {
    this.stripeService.getUserActiveSubscriptions(userId).then(
      (subscriptionsObservable) => {
        subscriptionsObservable.subscribe(
          (subscriptions) => {
            if (subscriptions == null) {
              this.isCheckingActiveSubscription = false;
              return;
            }
            if (subscriptions.length > 0) {
              this.router.navigate(['/dashboard']);
            } else {
              this.isCheckingActiveSubscription = false;
            }
          },
          (error) => {
            // Handle error
            this.isCheckingActiveSubscription = false;
            console.error('Error fetching user active subscriptions:', error);
          }
        );
      },
      (error) => {
        // Handle error if the initial promise is rejected
        this.isCheckingActiveSubscription = false;
        console.error('Error fetching user active subscriptions:', error);
      }
    );
  };


  async subscribe(product, options) {

    if (product.subRole === null) {
      this.router.navigate(['dashboard']);
      return;
    }

    let priceCodeString;

    if (options.isTrial) {
      // use price code with trial
      this.isPreparingStoreTrial = true;
      if (product.alternatePricing !== null && product.showAlternatePricing) {
        priceCodeString = product.alternatePricing.priceCode;
      } else {
        priceCodeString = product.priceCode;
      }
    } else {
      // use price code without trial
      this.isPreparingStore = true;
      if (product.alternatePricing !== null && product.showAlternatePricing) {
        priceCodeString = product.alternatePricing.nonTrialPriceCode;
      } else {
        priceCodeString = product.nonTrialPriceCode;
      }
    }

    logEvent(this.analytics, 'select_content', {
      content_type: 'SUBSCRIPTION_SELECTED',
      content_id: 'TIER - ' + product.name
    });

    const docRef = await addDoc(collection(this.database, `customers/${this.user.uid}/checkout_sessions`), {
      price: priceCodeString,
      mode: product.type === 'one_time' ? 'payment' : 'subscription',
      success_url: window.location.origin + '/dashboard',
      cancel_url: window.location.origin + (this.userId === null ? '/subscribe' : `/onboard/${this.userId}`),
      allow_promotion_codes: true,
    });

    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        this.isPreparingStore = false;
        alert(`An error: ${error.message}`);
      }
      if (url) {
        window.location.assign(url);
      }
    })
  }

  onClickShowAltPricing(p, event) {
    p.showAlternatePricing = event;
  }

  checkAffirmation() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'isAffirmedBYOP' && value === 'true') {
        this.isAffirmedBYOP = true;
        break;
      }
    }
  }

  onClickAffirmBYOP() {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 365); // Cookie expires in 365 days
    document.cookie = 'isAffirmedBYOP=true; expires=' + expirationDate.toUTCString();
    this.isAffirmedBYOP = true;
  }

  onClickUnlockTrial(product) {
    this.verificationError = false;
    if (this.isPhoneVerified) {
      this.subscribe(product, { isTrial: true })
    } else {
      this.selectedTrialToUnlock = product;
      this.isShowVerifyPhoneNumber = true;
      this.phoneNumber = undefined;
      this.formattedSystemPhoneNumber = undefined;
    }
  }

  onPhoneNumberEntry(value: string): void {
    // Check if the input has exactly 10 digits
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length !== 10) {
      this.isPhoneFormatValid = false;
      return;
    }

    const areaCode = numericValue.substring(0, 3);
    const exchangeCode = numericValue.substring(3, 6);

    // Basic check for valid area and exchange codes
    if (this.isValidAreaCode(areaCode) && this.isValidExchangeCode(exchangeCode)) {
      this.isPhoneFormatValid = true;
    } else {
      this.isPhoneFormatValid = false;
    }
  }

  private isValidAreaCode(areaCode: string): boolean {
    // Check if the area code does not start with 0 or 1
    return /^[2-9]\d{2}$/.test(areaCode);
  }

  private isValidExchangeCode(exchangeCode: string): boolean {
    // Check if the exchange code does not start with 0 or 1
    return /^[2-9]\d{2}$/.test(exchangeCode);
  }

  async onClickConfirmPhoneNumber() {
    this.verificationError = false;
    this.isSuspectedVoip = false;
    this.isPhoneNumberUnique = true;
    this.isSendingCode = true;
    const phoneNumberWithoutSpaces = this.phoneNumber.replace(/\s+/g, '');
    this.formattedSystemPhoneNumber = `+1${phoneNumberWithoutSpaces}`
    const isNumberUnique = await this.userService.isPhoneNumberUnique(phoneNumberWithoutSpaces);
    if (isNumberUnique) {
      this.userService.sendVerificationCode(this.formattedSystemPhoneNumber)
        .subscribe(response => {
          console.log('Verification and Sending:', response);
           if (response.isCodeSent) {
            this.isCodeSent = true;
            this.isSendingCode = false;
          }
        }, error => {
          if (error.error.isVoip) {
            const newCount = this.voipAttemptCount + 1;
            this.voipAttemptCount = newCount;
            const update = {
              id: this.userId,
              voipAttemptCount: this.voipAttemptCount,
            };
            this.userService.updateUserPublicProfile(update);
            this.isSuspectedVoip = true;
            this.isSendingCode = false;
          } else {
            console.error('Error sending code:', error);
            this.verificationError = true;
          }
          this.isSendingCode = false;
        });
    } else {
      this.isPhoneNumberUnique = false;
      this.isSendingCode = false;
    }
  }

  onCodeChange(event) {
    const verificationCode = event;
    if (verificationCode.length === 6) {
      this.verificationCode = verificationCode;
      this.isVerifyingCode = true;
      this.handleVerifyCode();
    }

  }

  handleVerifyCode() {
    this.isVerifyingCode = true;
    this.userService.verifyCode(this.formattedSystemPhoneNumber, this.verificationCode)
      .subscribe(response => {
        console.log('Verification result:', response);
        if (response.isCodeVerified) {
          this.isVerifyingCode = false;
          this.isUpdatingUserAccount = true;
          this.handleVerificationCodeSuccess();
        } else {
          this.isVerifyingCode = false;
          this.verificationError = true;
        }
      }, error => {
        console.error('Error verifying code:', error);
        this.verificationError = true;
        this.isVerifyingCode = false;
      });
  }

  handleVerificationCodeSuccess() {
    const numberIdToSave = this.formattedSystemPhoneNumber.slice(2);
    this.userService.savePhoneNumberWithUserId(numberIdToSave, this.userId);
    const update = {
      id: this.userId,
      isPhoneVerified: true,
      verifiedPhone: this.formattedSystemPhoneNumber,
    }
    this.userService.updateUserPublicProfile(update).then((data) => {
        this.isVerifyingCode = false;
        this.isPhoneVerified = true;
        this.isUpdatingUserAccount = false;
        this.subscribe(this.selectedTrialToUnlock, { isTrial: true });
    }).catch((e) => {
      console.log('error updating user profile', e);
      this.verificationError = true;
      this.isVerifyingCode = false;
    })
  }

  onRetryNumber() {
    this.phoneNumber = undefined;
    this.formattedSystemPhoneNumber = undefined;
    this.isCodeSent = false;
    this.verificationError = false;
  }

  onClickCancelVerification() {
    this.phoneNumber = undefined;
    this.formattedSystemPhoneNumber = undefined;
    this.isCodeSent = false;
    this.verificationError = false;
    this.isShowVerifyPhoneNumber = false;
  }


}
