import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgPipesModule } from 'ngx-pipes';
import { NgCircleProgressModule } from 'ng-circle-progress';

import * as firebase from 'firebase/app';
import { NavComponent } from './components/navigation/nav/nav.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationComponent } from './pages/auth/registration/registration.component';
import { DashboardComponent } from './pages/portal/dashboard/dashboard.component';
import { JobsComponent } from './pages/portal/jobs/jobs.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { MessagePromptComponent } from './components/data-display/message-prompt/message-prompt.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { FullTextSearchPipe } from './pipes/fullTextSearch.pipe';
import { DecimalPlacesPipe } from './pipes/decimal-places.pipe';
import { AccountComponent } from './pages/portal/account/account.component';
import { SubscribeComponent } from './pages/portal/subscribe/subscribe.component';

import { SortByPipe } from './pipes/sortByDate.pips';
import { LastWordPipe } from './pipes/last-word.pipe';
import { GameTimeSortPipe } from './pipes/game-time-sort.pipe';
import { SearchListPipe } from './pipes/search-list.pipe';

import { TermsComponent } from './pages/public/terms/terms.component';
import { PrivacyComponent } from './pages/public/privacy/privacy.component';
import { ClipboardModule } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';

import { ToggleSwitchComponent } from './components/ui-kit/toggle-switch/toggle-switch.component';
import { ToggleBarComponent } from './components/ui-kit/toggle-bar/toggle-bar.component';

import { UniquePlayerRulePipe } from './pipes/uniquePlayerRules.pipe';
import { UniqueTeamRulePipe } from './pipes/uniqueTeamRules.pipe';
import { UniqueMatchupRulePipe } from './pipes/uniqueMatchupRules.pipe';

import { ModalCsvCaptureComponent } from './components/csv-capture/modal/modal-csv-capture/modal-csv-capture.component';
import { TypeAheadComponent } from './components/ui-kit/type-ahead/type-ahead.component';
import { PlayerTypeAheadComponent } from './components/ui-kit/player-type-ahead/player-type-ahead.component';
import { FileDropComponent } from './components/ui-kit/file-drop/file-drop.component';
import { TabBarComponent } from './components/ui-kit/tab-bar/tab-bar.component';
import { BaseTableComponent } from './components/ui-kit/base-table/base-table.component';
import { AceFsLoaderOverlayComponent } from './components/overlays/animation-overlays/ace-fs-loader-overlay/ace-fs-loader-overlay.component';
import { BaseEditOverlayComponent } from './components/overlays/base-edit-overlay/base-edit-overlay.component';
import { PillTypeAheadComponent } from './components/ui-kit/pill-type-ahead/pill-type-ahead.component';
import { SelectAllTextDirective } from './directives/select-all-text.directive';
import { PalermoComponent } from './pages/auth/palermo/palermo.component';
import { ToolTipComponent } from './components/ui-kit/tool-tip/tool-tip.component';
import { DraftGroupImporterComponent } from './components/shared-job/draft-group-importer/draft-group-importer.component';
import { ProjectionSniperComponent } from './components/shared-job/draft-group-importer/import-components/projection-sniper/projection-sniper.component';
import { StddevSniperComponent } from './components/shared-job/draft-group-importer/import-components/stddev-sniper/stddev-sniper.component';
import { OwnershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/ownership-sniper/ownership-sniper.component';
import { CptownershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/cptownership-sniper/cptownership-sniper.component';
import { FieldptsSniperComponent } from './components/shared-job/draft-group-importer/import-components/fieldpts-sniper/fieldpts-sniper.component';
import { MinutesSniperComponent } from './components/shared-job/draft-group-importer/import-components/minutes-sniper/minutes-sniper.component';
import { KoSniperComponent } from './components/shared-job/draft-group-importer/import-components/ko-sniper/ko-sniper.component';
import { ProownershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/proownership-sniper/proownership-sniper.component';
import { StarownershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/starownership-sniper/starownership-sniper.component';
import { MvpownershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/mvpownership-sniper/mvpownership-sniper.component';
import { ImportWidgetComponent } from './components/shared-job/draft-group-importer/import-components/import-widget/import-widget.component';
import { FlexownershipSniperComponent } from './components/shared-job/draft-group-importer/import-components/flexownership-sniper/flexownership-sniper.component';
import { MakeCutSniperComponent } from './components/shared-job/draft-group-importer/import-components/makecut-sniper/makecut-sniper.component';
import { WinProbSniperComponent } from './components/shared-job/draft-group-importer/import-components/winprob-sniper/winprob-sniper.component';
import { RulesImporterComponent } from './components/shared-job/configuration/rules-importer/rules-importer.component';
import { SingleSportSelectionComponent } from './components/subscription/single-sport-selection/single-sport-selection.component';

import { RemoveParenthesesPipe } from './pipes/remove-parenthesis.pipe';
import { GetPlayerExposurePipe } from './pipes/get-exposure.pipe';
import { FriendlyFilterPipe } from './pipes/friendlyFilter';
import { BattingOrderSniperComponent } from './components/shared-job/draft-group-importer/import-components/battingorder-sniper/battingorder-sniper.component';

// V2 COMPONENTS

import { JobCreatorComponent } from './pages/portal/job-creator/job-creator.component';
import { DraftGroupProjectionsComponent } from './pages/portal/job-creator/primary-components/draft-group-projections/draft-group-projections.component';
import { JobConfigurationSectionComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-configuration-section.component';

// DISTINCT COMPONENTS
import { InputGroupComponent } from './components/ui-kit/input-group/input-group.component';
import { PresetsManagerComponent } from './components/ui-kit/presets-manager/presets-manager.component';
import { JobResultsComponent } from './pages/portal/job-results/job-results.component';
import { JobProgressLogComponent } from './pages/portal/job-results/primary-components/job-progress-log/job-progress-log.component';
import { DraftGroupDataComponent } from './pages/portal/job-creator/primary-components/draft-group-projections/sections/draft-group-data/draft-group-data.component';

// V2 OPTIONS
import { MlbSimOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/MLB/sim/mlb-sim-options.component';
import { MlbOptoOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/MLB/opto/mlb-opto-options.component';
import { PgaSimOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/PGA/sim/pga-sim-options.component';
import { PgaOptoOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/PGA/opto/pga-opto-options.component';
import { MmaSimOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/MMA/sim/mma-sim-options.component';
import { MmaOptoOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/MMA/opto/mma-opto-options.component';
import { NflOptoOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/NFL/opto/nfl-opto-options.component';
import { NflSimOptionsComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/job-options-sections/NFL/sim/nfl-sim-options.component';

// V2 LINEUPS
import { UniversalLineupsImportComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/universal-sections/universal-lineups-import/universal-lineups-import.component';
import { EmptyStateSkullAnimationComponent } from './components/animations/empty-state-skull-animation/empty-state-skull-animation.component';
import { JobResultsOptoDataComponent } from './pages/portal/job-results/primary-components/job-results-opto-data/job-results-opto-data.component';
import { JobResultsSimDataComponent } from './pages/portal/job-results/primary-components/job-results-sim-data/job-results-sim-data.component';
import { TtlComponentComponent } from './components/distinct/time/ttl-component/ttl-component.component';
import { JobBuildLineupsComponent } from './pages/portal/job-results/shared-components/job-build-lineups/job-build-lineups.component';
import { JobResultsSidePanelComponent } from './pages/portal/job-results/shared-components/job-results-side-panel/job-results-side-panel.component';
import { EntryBuilderTableComponent } from './components/distinct/tables/entry-builder-table/entry-builder-table.component';
import { SidePanelPlayersComponent } from './pages/portal/job-results/shared-components/job-results-side-panel/sections/side-panel-players/side-panel-players.component';
import { SidePanelTeamsComponent } from './pages/portal/job-results/shared-components/job-results-side-panel/sections/side-panel-teams/side-panel-teams.component';
import { CometContainerComponent } from './components/distinct/loaders/comet-container/comet-container.component';
import { NewFilterItemComponent } from './components/distinct/tables/entry-builder-table/components/new-filter-item/new-filter-item.component';
import { FilterItemComponent } from './components/distinct/tables/entry-builder-table/components/filter-item/filter-item.component';
import { PlayerStatsComponent } from './pages/portal/job-results/primary-components/job-results-sim-data/components/player-stats/player-stats.component';
import { LineupStatsComponent } from './pages/portal/job-results/primary-components/job-results-sim-data/components/lineup-stats/lineup-stats.component';
import { TeamFilterWidgetComponent } from './pages/portal/job-creator/primary-components/draft-group-projections/components/team-filter-widget/team-filter-widget.component';
import { TeamFilterBarComponent } from './pages/portal/job-creator/primary-components/draft-group-projections/components/team-filter-bar/team-filter-bar.component';
import { SquareAnimeWidgetComponent } from './components/distinct/visual/square-anime-widget/square-anime-widget.component';
import { CircleAnimeWidgetComponent } from './components/distinct/visual/circle-anime-widget/circle-anime-widget.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { PhoneFormatDirective } from './directives/phone-format.directive';
import { AceBaseModalComponent } from './components/overlays/ace-base-modal/ace-base-modal.component';
import { LineupSortWeightsComponent } from './components/shared-job/configuration/lineup-sort-weights/lineup-sort-weights.component';
import { TableTeamBadgePipe } from './pipes/table-team-badge.pipe';
import { UniversalLateSwapComponent } from './pages/portal/job-creator/primary-components/job-configuration-section/universal-sections/universal-late-swap/universal-late-swap.component';
import { FilterPlayersPipe } from './pipes/filter-modal-players.pipe';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    JobsComponent,
    ResetComponent,
    MessagePromptComponent,
    ResetPasswordComponent,
    OnboardComponent,
    FullTextSearchPipe,
    LastWordPipe,
    SearchListPipe,
    DecimalPlacesPipe,
    GameTimeSortPipe,
    AccountComponent,
    SubscribeComponent,
    SortByPipe,
    RemoveParenthesesPipe,
    FriendlyFilterPipe,
    GetPlayerExposurePipe,
    TermsComponent,
    PrivacyComponent,
    ModalCsvCaptureComponent,
    ToggleSwitchComponent,
    TypeAheadComponent,
    PlayerTypeAheadComponent,
    FileDropComponent,
    ToggleBarComponent,
    TabBarComponent,
    BaseTableComponent,
    AceFsLoaderOverlayComponent,
    BaseEditOverlayComponent,
    PillTypeAheadComponent,
    SelectAllTextDirective,
    PalermoComponent,
    ToolTipComponent,
    DraftGroupImporterComponent,
    ProjectionSniperComponent,
    StddevSniperComponent,
    OwnershipSniperComponent,
    CptownershipSniperComponent,
    FieldptsSniperComponent,
    MinutesSniperComponent,
    ProownershipSniperComponent,
    StarownershipSniperComponent,
    MvpownershipSniperComponent,
    BattingOrderSniperComponent,
    ImportWidgetComponent,
    FlexownershipSniperComponent,
    PgaOptoOptionsComponent,
    PgaSimOptionsComponent,
    MmaOptoOptionsComponent,
    MmaSimOptionsComponent,
    NflOptoOptionsComponent,
    NflSimOptionsComponent,
    MakeCutSniperComponent,
    WinProbSniperComponent,
    KoSniperComponent,
    RulesImporterComponent,
    UniquePlayerRulePipe,
    UniqueTeamRulePipe,
    UniqueMatchupRulePipe,
    FilterPlayersPipe,
    SingleSportSelectionComponent,
    MlbSimOptionsComponent,
    MlbOptoOptionsComponent,
    JobCreatorComponent,
    DraftGroupProjectionsComponent,
    JobConfigurationSectionComponent,
    InputGroupComponent,
    PresetsManagerComponent,
    JobResultsComponent,
    JobProgressLogComponent,
    DraftGroupDataComponent,
    UniversalLineupsImportComponent,
    EmptyStateSkullAnimationComponent,
    JobResultsOptoDataComponent,
    JobResultsSimDataComponent,
    TtlComponentComponent,
    JobBuildLineupsComponent,
    JobResultsSidePanelComponent,
    EntryBuilderTableComponent,
    SidePanelPlayersComponent,
    SidePanelTeamsComponent,
    CometContainerComponent,
    NewFilterItemComponent,
    FilterItemComponent,
    PlayerStatsComponent,
    LineupStatsComponent,
    TeamFilterWidgetComponent,
    TeamFilterBarComponent,
    SquareAnimeWidgetComponent,
    CircleAnimeWidgetComponent,
    PhoneFormatDirective,
    AceBaseModalComponent,
    LineupSortWeightsComponent,
    TableTeamBadgePipe,
    UniversalLateSwapComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgCircleProgressModule.forRoot(),
    ClipboardModule,
    NgOtpInputModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    firebase.initializeApp(environment.firebase);
  }
}
