import { Component, Input } from '@angular/core';

@Component({
  selector: 'tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss']
})
export class ToolTipComponent {
  @Input() explanation: string;
  @Input() isFeatured: boolean = false;
  @Input() position: string = ''

}
