import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { getAuth, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, getRedirectResult, signInWithPopup, sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { UserService } from 'src/app/services/user.service';
import { serverTimestamp } from 'firebase/firestore'
import { CreationService } from 'src/app/services/creation.service';
import { StateService } from 'src/app/services/state.service';
import { Title, Meta } from '@angular/platform-browser';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  userForm: UntypedFormGroup;
  errorMsg = '';
  isProcessing = false;
  hasAnimated = false;
  auth: any;
  provider = new GoogleAuthProvider();
  isProcessingProvider = false;
  isStandardRegistration = false;

  errorMsgs = {
    name: [
      {
        type: 'required',
        message: 'Enter your name...'
      },
    ],
    email: [
      {
        type: 'required',
        message: 'You kind of need one...'
      },
      {
        type: 'pattern',
        message: 'Email is not valid.'
      }
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.'
      },
      {
        type: 'minlength',
        message: 'Password length should be 6 characters long.'
      }
    ]
  };

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private creationService: CreationService,
    private titleService: Title,
    private metaService: Meta,
    private stateService: StateService,
    private subMonitor: SubMonitorService,
  ) {
    this.titleService.setTitle('AceMind');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Create your account.'
      }
    );
  }

  ngOnInit() {
    this.auth = getAuth();

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        setTimeout(() => {
          this.subMonitor.getSubscription().subscribe((data) => {
            if (data) {
              this.router.navigate(['dashboard']);
            } else {
              this.router.navigate(['/onboard', user.uid]);
            }
          });
        }, 700);
      }
    });

    this.userForm = this.fb.group({
      name: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        this.regexValidator(new RegExp('(?=.*[$@$!%*?&])'), { specialChar : true}),
        this.regexValidator(new RegExp('(?=.*[0-9])'), { hasNumber : true})
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ])),
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  signUp(name, email, password) {
    this.isStandardRegistration = true;
    this.isProcessing = true;
    createUserWithEmailAndPassword(this.auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      this.errorMsg = '';
      updateProfile(this.auth.currentUser, {
        displayName: name
      }).then(() => {
        sendEmailVerification(this.auth.currentUser);
        this.stateService.setFullScreenAnimation(true);
        this.creationService.createInitialUserContent(user).then((res) => {
          if (res = 'success') {
            this.userService.createUserMilestones({registered: true}, user.uid);
            this.router.navigate(['onboard', user.uid]);
            this.isProcessingProvider = false;
            this.isStandardRegistration = false
          }
        });
      }).catch((error) => {
        this.errorMsg = error.message;
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      if (error.message = 'Firebase: Error (auth/email-already-in-use).') {
        this.errorMsg = 'Unable to create an account with those credentials';
      } else {
        this.errorMsg = error.message;
      }
      this.isProcessing = false;
      this.isStandardRegistration = false
    });
  }

  signUpGoogle() {
    signInWithPopup(this.auth, this.provider)
    .then((result) => {
      this.stateService.setFullScreenAnimation(true);
      if (result != null && this.isStandardRegistration === false) {
      this.isProcessingProvider = true;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      this.userService.getUserMilestonesById(user.uid).then((data) => {
        if (data != null) {
          this.router.navigate(['dashboard']);
          this.isProcessingProvider = false;
        } else {
          updateProfile(this.auth.currentUser, {
            displayName: user.displayName
          }).then(() => {
            this.creationService.createInitialUserContent(user).then((res) => {
              if (res = 'success') {
                this.userService.createUserMilestones({registered: true}, user.uid);
                this.router.navigate(['onboard', user.uid]);
                this.isProcessingProvider = false;
              }
            });
          }).catch((error) => {
            this.errorMsg = error.message;
            this.isProcessingProvider = false;
          });
        }
      })
      } else {
        this.isProcessingProvider = false;
      }
    }).catch((error) => {
      console.log('REDIRECT ERROR ' + error)
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    });
  }

  onFieldIn() {
    this.errorMsg = '';
  }

  onPasswordFieldIn() {
    this.errorMsg = '';
  }

  onPasswordFieldOut() {
    this.errorMsg = '';
  }

  // quickRegisterRemoveMe(e,p) {
  //   this.userForm.setValue({
  //     email: e,
  //     password: p
  //   });
  //   this.signUp(this.userForm.value.email, this.userForm.value.password);
  // }

}
