import { Component, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'comet-container',
  templateUrl: './comet-container.component.html',
  styleUrls: ['./comet-container.component.scss']
})
export class CometContainerComponent implements AfterViewInit {
  @ViewChild('container') container!: ElementRef;

  ngAfterViewInit(): void {
    this.initAnimation();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.initAnimation();
  }

  private initAnimation(): void {
    const containerWidth = this.container.nativeElement.offsetWidth;
    const containerHeight = this.container.nativeElement.offsetHeight;

    anime({
      targets: '.ball',
      keyframes: [
        { translateX: containerWidth - 5, duration: 600 }, // Move right faster
        { translateY: containerHeight - 5, duration: 300 }, // Move down slower
        { translateX: -5, duration: 600 }, // Move left faster
        { translateY: -5, duration: 300 } // Move up slower
      ],
      easing: 'easeInOutSine',
      loop: true,
      update: function (anim) {
        const targets = anim.animatables[0].target;
        const offset = (anim.currentTime % 4000) / 4000;
        const shadowLength = 100 * offset;
        targets.style.boxShadow = `0 0 ${shadowLength}px rgb(255 245 190 / 50%)`;
      }
    });
  }
}

