import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { StateService } from 'src/app/services/state.service';
import { StripeService } from 'src/app/services/stripe.service';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-palermo',
  templateUrl: './palermo.component.html',
  styleUrls: ['./palermo.component.scss']
})
export class PalermoComponent implements OnInit {
  user: any;
  stripeSubscriptionTierName: any;

  constructor(
    private router: Router,
    private subMonitor: SubMonitorService,
    private stripeService: StripeService,
    private userService: UserService,
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.getProfile();
  }

  async getProfile() {
    const auth = await getAuth();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.user = user;
        this.checkUserForGrandfather();
      }
    });
  }

  checkUserForGrandfather() {
    this.userService.getUserPublicProfileById(this.user.uid).then((data) => {
      if (data) {
        if (data.grandfathered === true) {
          this.setSubscriptionAndFlag('FREE');
          this.router.navigate(['dashboard']);
        } else {
          this.checkSubscription();
        }
      }
    })
  }

  checkSubscription() {
    this.stripeService.getUserSubscriptionTier(this.user.uid).then((data) => {
      this.stripeSubscriptionTierName = data;

      const subscription = this.stripeSubscriptionTierName;

      if (subscription !== null) {
        this.setSubscriptionAndFlag(subscription);
        setTimeout(() => {
          this.router.navigate(['dashboard']);
        }, 200);
      } else {
        this.setSubscriptionAndFlag(null);
        console.log('no subscription');
        this.router.navigate(['onboard', this.user.uid]);
      }
    });
  }

  setSubscriptionAndFlag(subscription) {
    this.subMonitor.setSubscription(subscription);
    this.stateService.setSubscriptionChecked(true);
  }

}
