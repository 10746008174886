import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubMonitorService {

    private subscription = new BehaviorSubject<any>(null);

    setSubscription(data: any) {
        this.subscription.next(data);
    }

    getSubscription(): BehaviorSubject<any> {
        return this.subscription;
    }
}