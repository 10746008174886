import { BASE_ENTRY_TABLE_SIM_COLUMNS } from "./shared-config/shared-table-data"

export const NFL_LINEUP_GEN_TABLES_OPTO = {
    DK_CLASSIC: [
        { prop: 'qb', name: 'QB', tooltip: null, noId: true, filterValue: 'position qb' },
        { prop: 'rb1', name: 'RB', tooltip: null, noId: true, filterValue: 'position rb1' },
        { prop: 'rb2', name: 'RB', tooltip: null, noId: true, filterValue: 'position rb2' },
        { prop: 'wr1', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr1' },
        { prop: 'wr2', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr2' },
        { prop: 'wr3', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr3' },
        { prop: 'te', name: 'TE', tooltip: null, noId: true, filterValue: 'position te' },
        { prop: 'flex', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex' },
        { prop: 'dst', name: 'DST', tooltip: null, noId: true, filterValue: 'position dst' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
        { prop: 'stack', name: 'Stack' },
    ],
    FD_CLASSIC: [
        { prop: 'qb', name: 'QB', tooltip: null, noFrontId: true, filterValue: 'position qb' },
        { prop: 'rb1', name: 'RB', tooltip: null, noFrontId: true, filterValue: 'position rb1' },
        { prop: 'rb2', name: 'RB', tooltip: null, noFrontId: true, filterValue: 'position rb2' },
        { prop: 'wr1', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr1' },
        { prop: 'wr2', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr2' },
        { prop: 'wr3', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr3' },
        { prop: 'te', name: 'TE', tooltip: null, noFrontId: true, filterValue: 'position te' },
        { prop: 'flex', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex' },
        { prop: 'dst', name: 'DST', tooltip: null, noFrontId: true, filterValue: 'position dst' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
        { prop: 'stack', name: 'Stack' },
    ],
    DK_SHOWDOWN: [
        { prop: 'cpt', name: 'CPT', tooltip: null, noId: true, filterValue: 'position cpt' },
        { prop: 'flex1', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex1' },
        { prop: 'flex2', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex2' },
        { prop: 'flex3', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex3' },
        { prop: 'flex4', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex4' },
        { prop: 'flex5', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex5' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
        { prop: 'stack', name: 'Stack' },
    ],
    FD_SHOWDOWN: [
        { prop: 'mvp', name: 'MVP', tooltip: null, noFrontId: true, filterValue: 'position mvp' },
        { prop: 'flex1', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex1' },
        { prop: 'flex2', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex2' },
        { prop: 'flex3', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex3' },
        { prop: 'flex4', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex4' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
        { prop: 'stack', name: 'Stack' },
    ],
}

export const NFL_LINEUP_GEN_TABLES_SIM = {
    DK_CLASSIC: [
        { prop: 'qb', name: 'QB', tooltip: null, noId: true, filterValue: 'position qb' },
        { prop: 'rb1', name: 'RB', tooltip: null, noId: true, filterValue: 'position rb1' },
        { prop: 'rb2', name: 'RB', tooltip: null, noId: true, filterValue: 'position rb2' },
        { prop: 'wr1', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr1' },
        { prop: 'wr2', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr2' },
        { prop: 'wr3', name: 'WR', tooltip: null, noId: true, filterValue: 'position wr3' },
        { prop: 'te', name: 'TE', tooltip: null, noId: true, filterValue: 'position te' },
        { prop: 'flex', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex' },
        { prop: 'dst', name: 'DST', tooltip: null, noId: true, filterValue: 'position dst' },
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
        { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
        { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
        { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'lineupType', name: 'Type', tooltip: null },
        { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
        { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
        { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
        { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
        { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
        { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
        { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

        // New columns
        { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup', filterValue: 'medianROI' }, // new
        { prop: 'stdevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI', filterValue: 'stdevROI' }, // new
        { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup', filterValue: 'minROI' }, // new
        { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup', filterValue: 'maxROI' }, // new
        { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates', filterValue: 'medianDupes' }, // new
        { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates', filterValue: 'stdDevDupes' }, // new
        { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates', filterValue: 'minDupes' }, // new
        { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates', filterValue: 'maxDupes' }, // new

        ...BASE_ENTRY_TABLE_SIM_COLUMNS,
    ],
    FD_CLASSIC: [
        { prop: 'qb', name: 'QB', tooltip: null, noFrontId: true, filterValue: 'position qb' },
        { prop: 'rb1', name: 'RB', tooltip: null, noFrontId: true, filterValue: 'position rb1' },
        { prop: 'rb2', name: 'RB', tooltip: null, noFrontId: true, filterValue: 'position rb2' },
        { prop: 'wr1', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr1' },
        { prop: 'wr2', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr2' },
        { prop: 'wr3', name: 'WR', tooltip: null, noFrontId: true, filterValue: 'position wr3' },
        { prop: 'te', name: 'TE', tooltip: null, noFrontId: true, filterValue: 'position te' },
        { prop: 'flex', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex' },
        { prop: 'dst', name: 'DST', tooltip: null, noFrontId: true, filterValue: 'position dst' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
        { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
        { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
        { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'lineupType', name: 'Type', tooltip: null },
        { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
        { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
        { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
        { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
        { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
        { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
        { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

        // New columns
        { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
        { prop: 'stdevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdevROI' }, // new
        { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
        { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
        { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
        { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
        { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
        { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

        ...BASE_ENTRY_TABLE_SIM_COLUMNS,
    ],
    DK_SHOWDOWN: [
        { prop: 'cpt', name: 'CPT', tooltip: null, noId: true, filterValue: 'position cpt' },
        { prop: 'flex1', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex1' },
        { prop: 'flex2', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex2' },
        { prop: 'flex3', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex3' },
        { prop: 'flex4', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex4' },
        { prop: 'flex5', name: 'FLEX', tooltip: null, noId: true, filterValue: 'position flex5' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
        { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
        { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
        { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'lineupType', name: 'Type', tooltip: null },
        { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
        { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
        { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
        { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
        { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
        { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
        { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

        // New columns
        { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
        { prop: 'stdevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdevROI' }, // new
        { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
        { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
        { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
        { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
        { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
        { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

        ...BASE_ENTRY_TABLE_SIM_COLUMNS,
    ],
    FD_SHOWDOWN: [
        { prop: 'mvp', name: 'MVP', tooltip: null, noFrontId: true, filterValue: 'position mvp' },
        { prop: 'flex1', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex1' },
        { prop: 'flex2', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex2' },
        { prop: 'flex3', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex3' },
        { prop: 'flex4', name: 'FLEX', tooltip: null, noFrontId: true, filterValue: 'position flex4' },        
        { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections', filterValue: 'fpts' },
        { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
        { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
        { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
        { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
        { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
        { prop: 'lineupType', name: 'Type', tooltip: null },
        { prop: 'primaryStack', name: 'Primary Stack', tooltip: null, filterValue: 'primaryStack' },
        { prop: 'secondaryStack', name: 'Secondary Stack', tooltip: null, filterValue: 'secondaryStack' },
        { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
        { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
        { prop: 'numFields', name: 'Contest Generations', tooltip: 'Number of Times Lineup was Seen in Contest Simulations', filterValue: 'numFields' },
        { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
        { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
        { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
        { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },

         // New columns
         { prop: 'medianROI', name: 'Median ROI', tooltip: 'Median ROI of Lineup across Contest Simulations', filterValue: 'medianROI' }, // new
         { prop: 'stdevROI', name: 'Std Dev ROI', tooltip: 'Standard Deviation of ROI across Contest Simulations', filterValue: 'stdevROI' }, // new
         { prop: 'minROI', name: 'Min ROI', tooltip: 'Minimum ROI of Lineup across Contest Simulations', filterValue: 'minROI' }, // new
         { prop: 'maxROI', name: 'Max ROI', tooltip: 'Maximum ROI of Lineup across Contest Simulations', filterValue: 'maxROI' }, // new
         { prop: 'medianDupes', name: 'Median Dupes', tooltip: 'Median Number of Duplicates across Contest Simulations', filterValue: 'medianDupes' }, // new
         { prop: 'stdDevDupes', name: 'Std Dev Dupes', tooltip: 'Standard Deviation of Duplicates across Contest Simulations', filterValue: 'stdDevDupes' }, // new
         { prop: 'minDupes', name: 'Min Dupes', tooltip: 'Minimum Number of Duplicates across Contest Simulations', filterValue: 'minDupes' }, // new
         { prop: 'maxDupes', name: 'Max Dupes', tooltip: 'Maximum Number of Duplicates across Contest Simulations', filterValue: 'maxDupes' }, // new

        ...BASE_ENTRY_TABLE_SIM_COLUMNS,
    ],
}