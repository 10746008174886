const BASE_COLUMNS = [
    { prop: 'Name', name: 'Name' },
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'BattingOrder', name: 'Batting Order', editable: true, editAsString: true, hideSystemValues: true, tooltip: 'Batting order if known. 9999 is used for players not in the lineup' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
    { prop: 'FieldPts', name: 'Field Projection', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
];

const BASE_COLUMNS_OPTO = [
    { prop: 'Name', name: 'Name' },
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'BattingOrder', name: 'Batting Order', editable: true, editAsString: true, hideSystemValues: true, tooltip: 'Batting order if known. 9999 is used for players not in the lineup' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
];

const BASE_SHOWDOWN_DK_COLUMNS = [
    ...BASE_COLUMNS,
    { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' }
];

const BASE_SHOWDOWN_FD_COLUMNS = [
    ...BASE_COLUMNS,
    { prop: 'StarOwnership', name: 'StarOwnership', editable: true, tooltip: null },
    { prop: 'MvpOwnership', name: 'MvpOwnership', editable: true, tooltip: null },
];

const BASE_SHOWDOWN_DK_COLUMNS_OPTO = [
    ...BASE_COLUMNS_OPTO,
    { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' }
];

const BASE_SHOWDOWN_FD_COLUMNS_OPTO = [
    ...BASE_COLUMNS_OPTO,
    { prop: 'ProOwnership', name: 'ProOwnership', editable: true, tooltip: null },
    { prop: 'StarOwnership', name: 'StarOwnership', editable: true, tooltip: null },
    { prop: 'MvpOwnership', name: 'MvpOwnership', editable: true, tooltip: null },
];

export const MLB_TABLES_SIM = {
    projections_showdown_dk: BASE_SHOWDOWN_DK_COLUMNS,
    projections_showdown_fd: BASE_SHOWDOWN_FD_COLUMNS,
    projections_classic: BASE_COLUMNS
};

export const MLB_TABLES_OPTO = {
    projections_showdown_dk: BASE_SHOWDOWN_DK_COLUMNS_OPTO,
    projections_showdown_fd: BASE_SHOWDOWN_FD_COLUMNS_OPTO,
    projections_classic: BASE_COLUMNS_OPTO,
};

// export const MLB_SUMMARY_PLAYER_DATA = [
//     { prop: 'playerName', name: 'Player Name', tooltip: null },
//     { prop: 'playerPosition', name: 'Position', tooltip: null },
//     { prop: 'simFieldOwn', name: 'Field Own', tooltip: 'Player ownership in the simulated contest field' },
//     { prop: 'simProjOwn', name: 'Proj Own', tooltip: 'Player projected ownership' },
//     { prop: 'simROI', name: 'ROI', tooltip: 'Average roi of lineups featuring that player' },
//     { prop: 'simt1PercentProb', name: 'Top 1% Prob', tooltip: 'Percent of times a lineup featuring that player appeared in the top 1% finishes of a sim' },
//     { prop: 'simWinProb', name: 'Win Prob', tooltip: 'Percent of times a lineup featuring that player won a sim' },
//     { prop: 'top150Ownership', name: 'Top 150 Own', tooltip: 'Player ownership in the top 150 lineups by ROI' },
// ];