import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getTeamColorNFL } from '../constants/team-colors/nfl-team-colors-dictionary';

@Pipe({
  name: 'tableTeamBadge'
})
export class TableTeamBadgePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(team: any): SafeHtml {
    const teamColor = getTeamColorNFL(team);
    return this.sanitizer.bypassSecurityTrustHtml(
      `&nbsp;&nbsp;&nbsp;<span class="table-team-chip" style="border: 1px solid ${teamColor}">${team}</span>`
    );
  }
}