import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPlayerExposure'
})
export class GetPlayerExposurePipe implements PipeTransform {
  transform(playerNameWithId: string, exposureMap: Map<string, number>): any {
    console.log(playerNameWithId);
    console.log(exposureMap);
    for (const [player, exposure] of exposureMap) {
        if (player.startsWith(playerNameWithId)) {
          console.log('exposure determined at', exposure);
            return exposure;
        }
    }
    console.log('exposure pipe failed')
    return 'Exp Err';
  }
}