<div class="ss-page-wrapper auth">
    <div class="ss-auth-content ss-modal-card">

        <section *ngIf="linkValid && !submitted">
            <div class="ss-auth-header mb24">
                Set New Password
            </div>
            <div class="ss-auth-form">
                <form class="form" [formGroup]="userForm" (ngSubmit)="onSubmitNewPassword()">

                    <div class="ss-form-group">
                        <input class="ss-input expanded bordered" type="password" formControlName="password" required
                            autocomplete="new-password" placeholder="New Passowrd" />
                        <div class="validator-bar">
                            <div>
                                <span class="value"
                                    [ngClass]="{'valid' : !userForm.get('password').hasError('specialChar') && userForm.get('password').value.length > 0}">Special
                                    Char</span>
                                &nbsp;/&nbsp;
                                <span class="value"
                                    [ngClass]="{'valid' : !userForm.get('password').hasError('hasNumber') && userForm.get('password').value.length > 0}">Number</span>
                            </div>
                            <div class="value" [ngClass]="{'valid' : userForm.get('password').value.length >= 8}">
                                8+ Characters
                            </div>
                        </div>

                    </div>

                    <div class="ss-form-group">
                        <input class="ss-input expanded bordered" type="password" formControlName="confirmPassword"
                            [(ngModel)]="confirmPassword" required placeholder="Confirm It" />
                        <div class="validator-bar">
                            <div>
                                <span class="value"
                                    [ngClass]="{'valid' : userForm.get('password').value === confirmPassword}">
                                    Passwords Match
                                </span>
                            </div>
                        </div>
                    </div>

                    <button class="ss-primary-btn"
                        [disabled]="!userForm.valid || ( userForm.get('password').value !== confirmPassword )">Submit</button>

                </form>
            </div>
        </section>

        <section *ngIf="linkValid && submitted">
            <div class="ss-auth-header">
                All Set
            </div>
            <div class="auth-sub-header">Your new password is all ready to go.</div>
        </section>

        <section *ngIf="!linkValid">
            <div class="ss-auth-header">
                Awkward...
            </div>
            <div class="auth-sub-header">Hate to tell you... but the link you are trying to use is straight garbage.
            </div>
        </section>
    </div>

</div>