import { Component, OnInit, ViewChild } from '@angular/core';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { getAuth } from 'firebase/auth';


import setJobOptions from './job-utilities/set-job-options';

import { TypeAheadComponent } from 'src/app/components/ui-kit/type-ahead/type-ahead.component';
import { DraftGroupProjectionsComponent } from './primary-components/draft-group-projections/draft-group-projections.component';

import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { DraftGroupService } from 'src/app/services/draft-group.service';
import { JobsService } from 'src/app/services/jobs.service';
import { ContestsService } from 'src/app/services/contests.service';
import validateJobDoc from './job-utilities/validate-job-doc';
import { JobThrottleService } from 'src/app/services/job-throttle.service';

@Component({
  selector: 'app-job-creator',
  templateUrl: './job-creator.component.html',
  styleUrls: ['./job-creator.component.scss']
})
export class JobCreatorComponent implements OnInit {
  @ViewChild(TypeAheadComponent) typeaheadComponent: TypeAheadComponent;
  @ViewChild(DraftGroupProjectionsComponent) projectionsComponent: DraftGroupProjectionsComponent;

  user: any;
  private jobCountSubscription: Subscription;
  private homeJobPayload: Subscription;
  dailyJobCount: number = 0;

  userSubscription: any;
  showProBadges: boolean;
  isContestStarted: boolean;
  isLateSwap: boolean = false;

  isLoading: boolean = true;
  isLoadingSiteData: boolean = true;
  isLoadingSlate: boolean = false;
  isDraftGroupOutOfDate: boolean;

  isCreatingJob: boolean;
  creationError: null;
  isMissingDraftGroupData: boolean = false;
  jobCompleteEventRecieved: boolean;

  userId: string;
  jobId: string;
  jobData: any = {};
  jobDescription: any;

  isHomeClick: boolean = false;
  homeClickType: any;

  // contestType: string;
  contestSlate: any;
  isFormValid = false;

  allContests: any;
  allSlates: any = [];
  availableSlates: any;
  classicSlates: any = [];
  showdownSlates: any = [];
  availableContests: any = [];
  selectedContest: any;

  showSettings: boolean = true;
  showResetJob: boolean;
  jobExceptions: any = [];

  showOptoSimModal: boolean;
  optoLineupCount: number = 0;
  isLoadingOptomizedLineups: boolean;
  isPreparingOptoToSim: boolean;
  storage: any;


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private jobsService: JobsService,
    private contestsService: ContestsService,
    private stateService: StateService,
    private jobThrottleService: JobThrottleService,
    private subMonitor: SubMonitorService,
    private draftGroupService: DraftGroupService,
    private router: Router,
  ) {
    this.jobCountSubscription = this.stateService.dailyJobCount$.subscribe((value) => {
      this.dailyJobCount = value;
    });
    this.homeJobPayload = this.stateService.homeJobPayload$.subscribe(
      value => {
        if (value && value.jobType !== null) {
          this.isHomeClick = true;
          this.homeClickType = value.jobType;
          this.stateService.setHomeJobPayload({ jobType: null })
        }
      }
    );
  }

  ngOnInit() {
    const auth = getAuth();
    this.user = auth.currentUser;

    this.storage = getStorage();

    this.checkSubscription();

    setTimeout(() => {
      this.initializeBuilder();
    }, 200);
  };

  initializeBuilder() {
    const localJobdataSnapshot = this.jobsService.loadJobDataSnapshot();

    let isValidVersion = true;
    if (localJobdataSnapshot) {
       isValidVersion = localJobdataSnapshot.lineupBuilderVersion == environment.lineupBuilderVersion;

       if (!isValidVersion) {
        alert('Sim/Opto tool updated. The config is resetting to ensure new features are available.')
       }
    }

    if (localJobdataSnapshot && localJobdataSnapshot.jobType && !this.isHomeClick && isValidVersion) {
      this.jobData = localJobdataSnapshot;
      this.jobData.currentSelectedMatch = null;
      this.jobData.currentSort = null;
      this.generateAvailableSlates();
      this.isLoading = false;
    } else {
      const jobPref = this.jobsService.getJobPrefferedSport();
      this.jobData.lineupBuilderVersion = environment.lineupBuilderVersion;
      this.jobData.userId = this.user.uid; // wrap this into a utility when time allows
      this.jobData.sport = jobPref ? jobPref : "NFL";
      this.jobData.site = 'dk';
      this.jobData.jobType = this.homeClickType ? this.homeClickType : 'simulator';
      this.jobData.jobSubType = 'classic';
      this.jobData.status = 'building';
      this.jobData.optionsValid = true;
      this.jobData.lineups = null;
      this.jobData.lateSwapData = null;
      this.jobData.isLateSwap = false;
      this.jobData.lateSwapEntriesFile = null;
      this.jobData.customCorrelations = null,
        this.jobData.playerCorrelations = null,
        this.jobData.stackRules = {
          pair: [],
          limit: []
        }
      this.isLoading = false;
      this.generateAvailableSlates();
    }
  }

  setTempJobData() {
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  resetTempJobData() {
    this.jobsService.clearJobDataSnapshot();
    window.location.reload();
  }

  checkSubscription() {
    this.subMonitor.getSubscription().subscribe((data) => {
      if (data) {
        this.userSubscription = data;
        if (data === 'ACE_SINGLE' || data === 'ACE_SINGLE_YEARLY' || data === 'ACE_BASIC' || data === 'ACE_BASIC_YEARLY') {
          this.showProBadges = true;
        }
      }
    });
  };

  resetJobData() {
    this.jobData.slate = undefined;
    this.jobData.slateId = undefined;
    this.jobData.draftGroup = undefined;
    this.jobData.options = undefined;
    this.contestSlate = undefined;
    this.selectedContest = undefined;
  }

  onChangeJobType(event) {
    if (this.jobData.slate && this.jobData.draftGroup) {
      this.handleDraftGroupOnTypeChange();
      this.projectionsComponent.setProjectionsTable();
    } else {
      this.jobData.draftGroup = null;
      this.jobData.slateId = undefined;
      this.jobData.slate = undefined;
    }
    this.isLoadingSiteData = true;
    this.selectedContest = null;
    this.contestSlate = null;
    this.jobData.optionPresetId = null;
    this.jobData.jobType = event.target.value;
    if (this.jobData.sport) {
      this.generateAvailableSlates();
    }
    this.selectedContest = null;
    this.setTempJobData();
    this.showSettings = true;
  }

  onChangeSport(event) {
    this.resetJobData();
    this.isLoadingSiteData = true;
    this.jobData.draftGroup = null;
    this.jobData.sport = event.target.value;
    this.jobData.slateId = undefined;
    this.generateAvailableSlates();
    this.setTempJobData();
    this.jobsService.saveJobPrefferedSport(event.target.value);
    this.showSettings = true;
  }

  onChangeSite(event) {
    this.isLoadingSiteData = true;
    this.jobData.site = event.target.value;
    this.jobData.draftGroup = null;
    this.generateAvailableSlates();
    this.jobData.slateId = undefined;
    this.jobData.slate = undefined;
    this.selectedContest = null;
    this.contestSlate = null;
    this.setTempJobData();
  }

  onChangeContestType(selectElement: any) {
    this.jobData.draftGroup = null;
    this.selectedContest = null;
    this.contestSlate = null;
    this.jobData.slateId = undefined;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const optionName = selectedOption.getAttribute('name');
    this.jobData.jobSubType = optionName;
    this.setTempJobData();
  }

  async onSelectSlate(event) {
    this.showSettings = true;
    this.isLoadingSlate = true;
    this.jobData.draftGroup = null;
    this.isMissingDraftGroupData = false;
    this.jobData.contest = null;
    this.jobData.lineups = null;
    this.jobData.lateSwapData = null;
    this.jobExceptions = [];
    this.jobData.currentSelectedMatch = null;
    if (this.jobData.options?.teamStackConfig) {
      this.jobData.options.teamStackConfig = null;
    }
    if (this.selectedContest && this.typeaheadComponent) {
      setTimeout(() => {
        this.typeaheadComponent.clearTypeAhead();
      }, 300);
    }
    this.selectedContest = null;
    const selectedId = event.target.value;
    const selection = this.allSlates.find((slate) => slate.gameSetKey === selectedId)
    this.contestSlate = selection;
    this.jobData.slateId = selectedId;
    this.jobData.draftGroupId = selection.contests[0].draftGroupId;
    this.jobData.slate = this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name,
      this.contestSlate.contests.sort((a, b) => b.totalPayouts - a.totalPayouts);

    const now = new Date().toISOString();
    if (environment.production || !environment.production) { // CHANGE WHEN TESTING NEEDED
      if (now > selection?.contestStartTime) {
        this.isContestStarted = true;
      } else {
        this.isContestStarted = false;
      }
    } else {
      this.isContestStarted = true;
    }

    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;

    let dynamicJobType = this.jobData.jobType;
    if (this.jobData.sport === 'PGA' && selection.contests[0]?.isCutEvent) {
      dynamicJobType = `${this.jobData.jobType}-tournament`;
      this.jobData.isTournament = true;
    }  else if (this.jobData?.isTournament) {
      this.jobData.isTournament = false;
    }

    this.draftGroupService.getDraftGroupById(this.jobData.site, this.jobData.draftGroupId, this.jobData.sport.toLowerCase(), dynamicJobType, this.jobData.jobSubType).then((response) => {
      if (response != null) {
        if (response.length === 0) {
          // no players in slate draft group
          this.isMissingDraftGroupData = true;
          if (environment.production) {
            const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
            this.postMessageToDiscord(message);
          }
          this.jobData.slate = null;
          this.jobData.slateId = undefined;
          this.contestSlate = null;
          this.isLoadingSlate = false;
          this.jobData.draftGroup = null;
          this.setTempJobData();
        } else {
          let sortedPlayersByProjection = response.sort((a,b) => b.Projection - a.Projection);
          if (sortedPlayersByProjection.some((p) => p.Projection > 0)) {
            sortedPlayersByProjection.forEach((p) => {
              if (p.Projection == 0) {
                p.selected = false;
              }
            });
          }
          this.jobData.draftGroup = sortedPlayersByProjection;
          this.isLoadingSlate = false;
          setTimeout(() => {
            this.projectionsComponent.updateTotalSelections();
            this.setTempJobData();
          }, 300);
        }
      }
    }).catch((e) => {
      console.log('error grabbing draft group', e);
      this.isMissingDraftGroupData = true;
      if (environment.production) {
        const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
        this.postMessageToDiscord(message);
      }
      this.jobData.slate = null;
      this.jobData.slateId = undefined;
      this.contestSlate = null;
      this.jobData.draftGroup = null;
      this.isLoadingSlate = false;
      this.setTempJobData();
    });

  };

  async handleDraftGroupOnTypeChange() {
    this.isLoadingSlate = true;
    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;

    let dynamicJobType = this.jobData.jobType;
    if (this.jobData.sport === 'PGA' && this.jobData?.isTournament) {
      dynamicJobType = `${this.jobData.jobType}-tournament`;
      this.jobData.isTournament = true;
    } else if (this.jobData?.isTournament) {
      this.jobData.isTournament = false;
    }
    
    this.draftGroupService.getDraftGroupById(this.jobData.site, this.jobData.draftGroupId, this.jobData.sport.toLowerCase(), dynamicJobType, this.jobData.jobSubType).then((response) => {
      if (response != null) {
        if (response.length === 0) {
          // no players in slate draft group
          this.isMissingDraftGroupData = true;
          if (environment.production) {
            const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
            this.postMessageToDiscord(message);
          }
          this.jobData.slate = null;
          this.jobData.slateId = undefined;
          this.contestSlate = null;
          this.isLoadingSlate = false;
          this.jobData.draftGroup = null;
          this.setTempJobData();
        } else {
          this.jobData.draftGroup = response;
          this.jobData.draftGroup.forEach((player) => {
            if (player.Projection === 0) {
              player.selected = false;
            }
          });
          this.jobData.draftGroup.sort((a, b) => b.selected - a.selected);
          this.isLoadingSlate = false;
          setTimeout(() => {
            this.projectionsComponent.updateTotalSelections();
            this.setTempJobData();
          }, 300);
        }
      }
    }).catch((e) => {
      console.log('error grabbing draft group', e);
      this.isMissingDraftGroupData = true;
      if (environment.production) {
        const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
        this.postMessageToDiscord(message);
      }
      this.jobData.slate = null;
      this.jobData.slateId = undefined;
      this.contestSlate = null;
      this.jobData.draftGroup = null;
      this.isLoadingSlate = false;
      this.setTempJobData();
    });
  }

  onSelectContest(event) {
    const contestId = event.id;
    const selection = this.contestSlate.contests.find((c) => c.id === contestId);
    this.selectedContest = selection; // might be able to remove based on change below
    this.jobData.contest = {
      name: this.selectedContest.name,
      id: this.selectedContest.id,
      entries: this.selectedContest.entries,
      maximumEntries: this.selectedContest.maximumEntries,
      entryFee: this.selectedContest.entryFee,
      payoutSummary: this.selectedContest.payoutSummary,
      contestStartTime: this.selectedContest.contestStartTime,
      draftGroupId: this.selectedContest.draftGroupId,
      gameTypeId: this.selectedContest.gameTypeId,
      isCutEvent: this.selectedContest.isCutEvent || false,
    },
      this.jobData.slate = this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name;
    this.setTempJobData();
  }

  // likely moving to results component MOVE THE JOB COUNT TRACKING TO THE RESULTS COMPONENT THESE ARE THE REVELANT COMPLETE JOB BITS
  //     mixpanel.track(
  //       "Sim Job Completed",
  //       { "Sport": this.jobData.sport, "Site": this.jobData.site, "SubType": this.jobData.jobSubType, "FromOpto": this.jobData.optimizerSeed ? 'true' : 'false' }
  //     );
  //     const update = {
  //       id: this.jobData.userId,
  //       dailyJobCount: {
  //         date: monitorDate,
  //         count: updatedCount,
  //       }
  //     }
  //     this.userService.updateUserPublicProfile(update).catch((e) => {
  //       console.log(e);
  //     })

  convertIsoToString(isoDatetime: string): string {
    const dt = new Date(isoDatetime);
    const month = dt.toLocaleString('default', { month: 'short' }); // Short month name
    const day = dt.getDate(); // Day of the month
    const time = dt.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }); // HH:MM AM/PM format
    return `${month} ${day} ${time}`;
  }

  generateAvailableSlates() {
    if (this.jobData?.optimizerSeed) {
      this.contestSlate = this.jobData.slate;

      this.contestsService.getContestsByDraftGroupId(this.jobData.site, this.jobData.sport.toLowerCase(), this.jobData.optimizerSeed.optimizerDraftGroupId)
        .then((data) => {
          if (data && data.length > 0) {
            this.processContestsData(data);
            // this.handleSportSlates(this.jobData.sport);
          }
        })
        .catch((e) => console.log(e));

    } else {
      this.contestsService.getAllContests(this.jobData.site, this.jobData.sport.toLowerCase())
        .then((data) => {
          if (data !== null) {
            this.processContestsData(data);
            // this.handleSportSlates(this.jobData.sport);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  processContestsData(data) {
    const groupedData = new Map();
    for (const obj of data) {
      const key = `${obj.gameSetKey}-${obj.gameTypeId}-${obj.contestStartTime}`;
      if (groupedData.has(key)) {
        groupedData.get(key).push(obj);
      } else {
        groupedData.set(key, [obj]);
      }
    }
    this.allSlates = Array.from(groupedData, ([key, value]) => {
      const [gameSetKey, gameTypeId, ...timestampParts] = key.split('-');
      const contestStartTime = timestampParts.join('-');
      const formattedStartTime = this.convertIsoToString(contestStartTime);
      const name = `${formattedStartTime}`;
      return {
        name,
        gameSetKey,
        gameTypeId,
        contestStartTime,
        contests: value,
        aceGameType: value[0].aceGameType || null,
      };
    });

    if (this.jobData.slateId) {
      const matchingSlate = this.allSlates.find((s) => s.gameSetKey === this.jobData.slateId);
      if (matchingSlate) {
        const now = new Date().toISOString();
        if (environment.production || !environment.production) { // CHANGE ONCE INITIAL TESTING IS DONE
          if (now > matchingSlate?.contestStartTime) {
            this.isContestStarted = true;
          } else {
            this.isContestStarted = false;
          }
        } else {
          this.isContestStarted = true;
        }
      }
    }

    this.handleSportSlates(this.jobData.sport);

    if (!this.jobData?.optimizerSeed) {
      this.classicSlates = this.allSlates.filter(slate => slate.aceGameType === 'classic');
      this.showdownSlates = this.allSlates.filter(slate => slate.aceGameType === 'showdown');
      // this.isLoadingSiteData = false;
      this.checkForExistingContest();
    }
  };

  checkForExistingContest() {
    if (this.jobData?.contest != null && this.jobData.slateId && this.jobData.jobType === 'simulator') {
      setTimeout(() => {
        if (this.typeaheadComponent) {
          this.typeaheadComponent.setTypeAheadValue(this.jobData.contest.name);
          this.selectedContest = this.jobData.contest;
        }
      }, 1000);
    }
    this.isLoadingSiteData = false;
  }

  async handleSportSlates(sport) {
    const sportToLower = sport.toLowerCase();
    const slatePromises = this.allSlates.map(async (slate) => {
      const site = this.jobData.site;
      try {
        const data = await this.contestsService.getSlateGameCount(site, slate.gameSetKey, sportToLower);
        let firstValue;
        for (const key in data) {
          firstValue = data[key];
          break;
        }
        slate.gameCount = sport !== 'PGA' ? firstValue.contest_games || firstValue.contestGames || 0 : null;
      } catch (error) {
        console.error('Error:', error);
        this.isLoadingSiteData = false;
      }
    });

    await Promise.all(slatePromises);
    this.classicSlates = this.allSlates.filter(slate => slate.aceGameType === 'classic');
    this.showdownSlates = this.allSlates.filter(slate => slate.aceGameType === 'showdown');

    this.extractMatchups();

    if (this.jobData?.optimizerSeed) {
      // this.jobData.jobSubType = this.jobData.optimizerSeed.jobSubType;
      const matchingSlate = this.allSlates.find(slate => `${slate.name} ${slate.gameCount} Games` === this.jobData.slate);
      if (matchingSlate) {
        this.contestSlate = matchingSlate;
        this.jobData.options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
        this.loadSeedDraftGroup();
        if (this.jobData.lineups == null) {
          this.handleOptimizedLineups();
        }
      } else {
        this.isDraftGroupOutOfDate = true;
      }
    }

    if (this.jobData.slateId) {
      const selection = this.allSlates.find((slate) => slate.gameSetKey === this.jobData.slateId)
      this.contestSlate = selection;
    }

    this.isLoadingSiteData = false;
  }

  // OPTO TO SIM

  handleOptimizedLineups() {
    this.showOptoSimModal = true;
    this.isLoadingOptomizedLineups = true;
    const csv = this.jobData.optimizerSeed.optoLineupCsvString;
    const fileName = `Job_Lineups_Optimized_${this.jobData.slate}.csv`;
    const blob = new Blob([csv], { type: 'text/csv;' });
    const file = new File([blob], fileName, { type: 'text/csv' });
    this.readFileContent(file);
  }

  async readFileContent(file: File): Promise<void> {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      const lineCount = this.countCsvRows(csvData);
      if (lineCount > 100001) {
        alert('Too Many Lineups - File contains more than 100,000 Lineups');
        return;
      }
      this.optoLineupCount = lineCount - 1;
      this.handleFileUpload(file);
    };
    reader.readAsText(file);
  }

  countCsvRows(csvData: string): number {
    const lines = csvData.split('\n');
    const nonEmptyLines = lines.filter(line => line.trim().length > 0);
    return nonEmptyLines.length;
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  handleFileUpload(file: File): void {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const dateString = `${month}_${year}`;
    const fileUUID = this.generateUUID();

    const filePath = `job-assets/${dateString}/${this.jobData.userId}_${fileUUID}_Lineups`;
    const storageRef = ref(this.storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log('error with upload' + error)
        this.isLoadingOptomizedLineups = false;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const fullFilePath = uploadTask.snapshot.ref.fullPath;
          const fileInfo = {
            fileURL: downloadURL,
            fullPath: fullFilePath,
            fileUUID: fileUUID,
            totalRecords: this.optoLineupCount,
          };
          this.jobData.lineups = fileInfo;
          this.jobsService.saveJobDataSnapshot(this.jobData);
          setTimeout(() => {
            this.isLoadingOptomizedLineups = false;
            this.isPreparingOptoToSim = true;
            setTimeout(() => {
              this.isPreparingOptoToSim = false;
              this.showOptoSimModal = false;
            }, 2000);
          }, 1800);

        })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  }

  loadSeedDraftGroup() {
    let dynamicJobType = this.jobData.jobType;
    if (this.jobData.sport === 'PGA' && this.jobData.contest?.isCutEvent) {
      dynamicJobType === `${this.jobData.jobType}_tournament`;
      this.jobData.isTournament = true;
    };
    this.draftGroupService.getDraftGroupById(this.jobData.site, this.jobData.draftGroupId, this.jobData.sport.toLowerCase(), dynamicJobType, this.jobData.jobSubType).then((response) => {
      if (response != null) {
        if (response.length === 0) {
          // no players in slate draft group
          this.isMissingDraftGroupData = true;
          if (environment.production) {
            const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
            this.postMessageToDiscord(message);
          }
          this.jobData.slate = null;
          this.jobData.slateId = undefined;
          this.contestSlate = null;
          this.isLoadingSlate = false;
          this.jobData.draftGroup = null;
          this.setTempJobData();
        } else {
          this.jobData.draftGroup = response;
          this.isLoadingSlate = false;
          setTimeout(() => {
            this.projectionsComponent.updateTotalSelections();
            this.jobData.draftGroup.forEach((player) => {
              if (player.Projection === 0) {
                player.selected = false;
              }
            });
            this.jobData.draftGroup.sort((a, b) => b.selected - a.selected);
            this.setTempJobData();
          }, 300);
        }
      }
    }).catch((e) => {
      console.log('error grabbing draft group', e);
      this.isMissingDraftGroupData = true;
      if (environment.production) {
        const message = `🚨 Draft Group Data Issue: ${this.jobData.site} - ${this.jobData.sport} - ${this.jobData.jobSubType} - ${this.contestSlate.gameCount ? `${this.contestSlate.name} ${this.contestSlate.gameCount} Games` : this.contestSlate.name}`;
        this.postMessageToDiscord(message);
      }
      this.jobData.slate = null;
      this.jobData.slateId = undefined;
      this.contestSlate = null;
      this.jobData.draftGroup = null;
      this.isLoadingSlate = false;
      this.setTempJobData();
    });
  }

  // END OPTO SIM

  extractMatchups() {
    const teamPattern = /\(([^)]{0,10})\)$/;
    this.showdownSlates.forEach((slate) => {
      const teamString = slate.contests[0].name;
      const match = teamPattern.exec(teamString);
      slate.matchup = match && match.length > 1 && match[1].length <= 10 ? match[1] : "";
    });
  }


  onToggleLateSwap(value) {
    this.isLateSwap = value;
  }

  async onClickRunJob() {
    console.log('on job run', this.jobData);

    const exceptions = await validateJobDoc(this.jobData);
    if (exceptions && exceptions.length) {
      this.jobExceptions = exceptions;
    } else {

      if (!this.jobThrottleService.canSubmitJob()) {
        console.log("Job submission blocked due to throttling limits.");
        return;
      }

      this.handleGenerateUserJob();
    }
  }

  handleGenerateUserJob() {
    this.isCreatingJob = true;
    this.creationError = null;

    // handle any additional job data attributes
    this.jobData.attemptCount = 0;
    this.jobData.jobProgressLog = [];

    // track the job creation
    mixpanel.track("Job Created", {
      "Type": this.jobData.jobType,
      "Sport": this.jobData.sport,
      "Site": this.jobData.site,
      "SubType": this.jobData.jobSubType,
      "FromOpto": this.jobData.optimizerSeed ? 'true' : 'false'
    });

    const { draftGroup, ...jobDataWithoutDraftGroup } = this.jobData;

    // Create the job
    this.jobsService.createUniversalJobFromSnapshot(this.user.uid, jobDataWithoutDraftGroup).then((jobDocData) => {
      if (jobDocData) {
        this.prepareJobForResults(jobDocData);
      }
      // Ensure isCreatingJob is reset
    }).catch((e) => {
      console.log('error generating initial job', e);
      this.creationError = e; // Set the error to provide feedback if necessary
      this.isCreatingJob = false; // Ensure isCreatingJob is reset
      this.isLoading = false; // Ensure isLoading is reset
    });
  };


  prepareJobForResults(jobDocData) {
    // TODO: CREATE A USER DRAFT GROUP PRESET FOR USE LATER
    const updatedData = {
      ...jobDocData,
      draftGroup: this.jobData.draftGroup
    }
    const jobKey = this.jobData.jobType === 'simulator' ? 'sim' : 'opto';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${environment.apiURL}/${jobKey}_controller`, updatedData, { headers }).subscribe(
      (response) => {
        console.log('Response:', response);
        this.router.navigate(['results', jobKey, this.user.uid, jobDocData.jobId]);
        this.isCreatingJob = false;
      },
      (error) => {
        console.error('Error posting job data:', error);
      }
    );
  };

  // handle draft group loading errors;
  postMessageToDiscord(message: string) {
    const webhookUrl = 'https://discord.com/api/webhooks/1239922202145591327/egNdUznrvyG0wfZYFHuTQoukVASJWKHTV5q5iQ8bwDPO0hWMH9VB-mwjp4d7e89ljsqR';
    const payload = { content: message };
    this.http.post(webhookUrl, payload).subscribe(
      () => console.log('Alert Posted to Error Monitoring.'),
      (error) => console.error('Failed to Post Error Monitoring:', error)
    );
  }

  handleCloseResetJob() {
    this.showResetJob = false;
  }

  handleCollapseSettings() {
    this.showSettings = !this.showSettings;
  }

  ngOnDestroy() {
    this.jobCountSubscription.unsubscribe();
    this.homeJobPayload.unsubscribe();
  }

}
