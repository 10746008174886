import { Component, Input } from '@angular/core';

@Component({
  selector: 'job-results-opto-data',
  templateUrl: './job-results-opto-data.component.html',
  styleUrls: ['./job-results-opto-data.component.scss']
})
export class JobResultsOptoDataComponent {
  @Input() jobData: any;
  showSettings: boolean = true;

  
    ngOnInit() {
     
    }

    handleCollapseSidePanel() {
      this.showSettings = !this.showSettings;
    }

}
