<div>

  <div class="settings-wrapper">

    <div class="centered-form">

      <section *ngIf="jobData.jobSubType">
        <presets-manager [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="onClickSetPresetDefault($event.id, $event.index, $event.data)"
          (onClickRemovePresetDefault)="onClickRemovePresetDefault($event.id, $event.index, $event.data)"
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"></presets-manager>
      </section>

      <hr style="margin-bottom: 12px; margin-top: 24px">

      <div class="inline-inputs">

          <!-- TOTAL SIMS -->
          <input-group
          label="Simulations Per Field Generation"
          tooltip="# of Simulations to run for each Generated Contest Field"
          [(ngModel)]="jobData.options.totalSimulations"
          [min]="100"
          [max]="100000"
          [errorMessage]="'100-100K'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.totalSimulations < 100 ||
              jobData.options.totalSimulations == null ||
              jobData.options.totalSimulations > 100000
          }"
        >
        </input-group>

        <!-- LINEUP GENERATIONS -->
        <input-group
          label="Number of Generations"
          tooltip="# of Contest Field Generations to Run (1-25)"
          [(ngModel)]="jobData.options.lineupGenerations"
          [min]="1"
          [max]="25"
          [errorMessage]="'1-25'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.lineupGenerations < 1 ||
              jobData.options.lineupGenerations == null ||
              jobData.options.lineupGenerations > 25
          }"
        >
        </input-group>

        <!-- TOP LINEUPS PERCENT -->
        <input-group
          label="Lineup Holdover Percent"
          tooltip="% of Lineups to Keep between Contest Field Generations"
          [(ngModel)]="jobData.options.topLineupsPct"
          [min]="0"
          [max]="100"
          [errorMessage]="'0-100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.topLineupsPct < 0 ||
              jobData.options.topLineupsPct == null ||
              jobData.options.topLineupsPct > 100
          }"
        >
        </input-group>

        <!--KEEP USER LINEUPS-->
        <div class="ss-form-group">
          <div class="toggle-wrapper">
            <div class="flex items-center">
              <div class="label">Keep User Lineups?</div>
              <tool-tip explanation="Forces Algorithm to Keep All User Lineups Between Runs" position="right"></tool-tip>
            </div>
            <toggle-switch
              [checked]="jobData.options.keepUserLineups"
              (checkedChange)="onToggleKeepUserLineups($event)"
            ></toggle-switch>
          </div>
        </div>

        <!--NEW SORT WEIGHTS -->
        <lineup-sort-weights *ngIf="jobData && jobData.options" [jobData]="jobData"></lineup-sort-weights>

        <input-group label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [errorMessage]="'This field is required'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.projectionMinimum < 0 || jobData.options.projectionMinimum == null }">
        </input-group>

        <input-group label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.randomness < 0 || jobData.options.randomness > 1000 || jobData.options.randomness == null }">
        </input-group>

        <input-group label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.minLineupSalary < 0 || jobData.options.minLineupSalary > siteSalaryMax || jobData.options.minLineupSalary == null }">
        </input-group>

        <input-group label="Max % off Optimal"
          tooltip="Sets the maximum percentage off of the optimal lineup (by fpts) that the field generator is allowed to create. If the optimal is 100 and this value is 20, the minimum lineup fpts allowed to be created by the generator is 80"
          [(ngModel)]="jobData.options.maxPctOffOptimal"
          [min]="1"
          [max]="100"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isInline]="true"
          appendText="%"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.maxPctOffOptimal < 1 || jobData.options.maxPctOffOptimal > 100 || jobData.options.maxPctOffOptimal == null }">
        </input-group>

        <input-group label="Default Variance"
          tooltip="If no standard deviation is provided in your projections, this value (divided by 100) is multiplied by the player projection to estimate standard deviation"
          [(ngModel)]="jobData.options.defaultVar"
          [min]="0"
          [max]="100"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isInline]="true"
          appendText="%"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.defaultVar < 0 || jobData.options.defaultVar > 100 || jobData.options.defaultVar == null }">
        </input-group>

      </div>

    </div>

  </div>