import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'import-widget',
  templateUrl: './import-widget.component.html',
  styleUrls: ['./import-widget.component.scss']
})
export class ImportWidgetComponent {
  @Output() handleEditMapping: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleMappingSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleErrorOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleRemoveCustomMapping: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() title: any;
  @Input() subTitle: any;
  @Input() isLoading: any;
  @Input() isEditable: boolean = true;
  @Input() isOptional: boolean = false;

  @Input() showFix: boolean;
  @Input() availableKeys: any;
  @Input() fixMessage: any;
  @Input() optionMessage: any;
  @Input() fixOptions: any;
  @Input() fixError: any;

  @Input() isMappingValid: boolean;
  @Input() isCustomMapping: boolean;
  @Input() customKey: any;


  
  showAvailableOptions: boolean = false;
  showAvailableKeys: boolean = false;
  hasSelectedKey: boolean = false;

  ngOnInit() {
    if (this.availableKeys) {
      const trimmedTitle = this.title.trim();

      this.availableKeys.sort((a, b) => {
        const trimmedA = a.trim();
        const trimmedB = b.trim();
        const similarityA = stringSimilarity.compareTwoStrings(trimmedTitle, trimmedA);
        const similarityB = stringSimilarity.compareTwoStrings(trimmedTitle, trimmedB);
        return similarityB - similarityA;
      });
    }
  }

  onClickShowKeys() {
    this.showAvailableKeys = false;
    if (this.fixOptions && this.fixOptions.length > 0) {
      this.showAvailableOptions = !this.showAvailableOptions;
    } else {
      this.showAvailableKeys = !this.showAvailableKeys;
    }
  }

  selectKey(key) {
    this.handleMappingSelected.emit(key);
    this.showAvailableKeys = false;
    this.hasSelectedKey = true;
  }

  onClickEditKey() {
    this.handleEditMapping.emit();
    this.showAvailableKeys = true;
  }

  onClickCancelEditKey() {
    this.showAvailableKeys = false;
  }

  onClickErrorOption(action) {
    if (action === 'select-column') {
      this.showAvailableKeys = true;
      this.showAvailableOptions = false;
    } else {
      this.handleErrorOption.emit(action);
      this.showAvailableOptions = false;
    }
  }

  onClickRemoveCustomOption() {
    this.handleRemoveCustomMapping.emit();
    this.showAvailableKeys = false;
    this.isCustomMapping = false;
  }

  onClickClearSelection() {
    this.handleErrorOption.emit('clear');
    this.showAvailableKeys = false;
  }

}
