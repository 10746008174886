
export const mapPresetToSport = async (sport, jobData, setData) => {

    await map[sport](setData, jobData);

    return jobData.draftGroup;
}

/// SPORT SPECIFIC MAP FUNCTIONS

const mapMLB = async (setData, jobData) => {
    await Promise.all(jobData.draftGroup.map(async (p) => {
      const projections = setData.projectionData.find((s) => s.PlayerId === p.PlayerId);
      if (projections !== null) {
        if (jobData.jobSubType === 'classic') {
          p.Projection = projections.Projection;
          p.StdDev = projections.StdDev || 0;
          p.Ownership = projections.Ownership || 0;
          p.FieldPts = projections.FieldPts || 0;
          p.BattingOrder = projections.BattingOrder || '';
          p.selected = projections.Selected || 0;
        } else {
          if (jobData.site === 'dk') {
            p.Projection = projections.Projection || 0;
            p.StdDev = projections.StdDev || 0;
            p.Ownership = projections.Ownership || 0;
            p.CptnOwnership = projections.CptnOwnership || 0;
            p.FieldPts = projections.FieldPts || 0;
            p.BattingOrder = projections.BattingOrder || '';
            p.selected = projections.Selected;
          } else {
            p.Projection = projections.Projection || 0;
            p.StdDev = projections.StdDev || 0;
            p.Ownership = projections.Ownership || 0;
            p.ProOwnership = projections.ProOwnership || 0;
            p.StarOwnership = projections.StarOwnership || 0;
            p.MvpOwnership = projections.MvpOwnership || 0;
            p.FieldPts = projections.FieldPts || 0;
            p.BattingOrder = projections.BattingOrder || '';
            p.selected = projections.Selected;
          }
        }
      }
    }));
  };

  const mapPGA = async (setData, jobData) => {
    await Promise.all(jobData.draftGroup.map(async (p) => {
      const projections = setData.projectionData.find((s) => s.PlayerId === p.PlayerId);
      if (projections !== null) {
        p.Projection = projections.Projection;
        p.StdDev = projections.StdDev || 0;
        p.Ownership = projections.Ownership || 0;
        p.MakeCut = projections.MakeCut || 0;
        p.WinProb = projections.WinProb || 0;
        p.FieldPts = projections.FieldPts || 0;
        p.selected = projections.Selected || 0;
      }
    }));
  }

  const mapMMA = async (setData, jobData) => {
    await Promise.all(jobData.draftGroup.map(async (p) => {
      const projections = setData.projectionData.find((s) => s.PlayerId === p.PlayerId);
      if (projections !== null) {
        p.Projection = projections.Projection;
        p.StdDev = projections.StdDev || 0;
        p.Ownership = projections.Ownership || 0;
        p.KOProb = projections.KOProb || 0;
        p.FieldPts = projections.FieldPts || 0;
        p.selected = projections.Selected || 0;
      }
    }));
  }

  const mapNFL = async (setData, jobData) => {
    await Promise.all(jobData.draftGroup.map(async (p) => {
      const projections = setData.projectionData.find((s) => s.PlayerId === p.PlayerId);
      if (projections !== null) {
        if (jobData.jobSubType === 'classic') {
          p.Projection = projections.Projection;
          p.StdDev = projections.StdDev || 0;
          p.Ownership = projections.Ownership || 0;
          p.FieldPts = projections.FieldPts || 0;
          p.selected = projections.Selected || 0;
        } else {
          if (jobData.site === 'dk') {
            p.Projection = projections.Projection || 0;
            p.StdDev = projections.StdDev || 0;
            p.Ownership = projections.Ownership || 0;
            p.CptnOwnership = projections.CptnOwnership || 0;
            p.FieldPts = projections.FieldPts || 0;
            p.selected = projections.Selected;
          } else {
            p.Projection = projections.Projection || 0;
            p.StdDev = projections.StdDev || 0;
            p.Ownership = projections.Ownership || 0;
            p.ProOwnership = projections.ProOwnership || 0;
            p.StarOwnership = projections.StarOwnership || 0;
            p.MvpOwnership = projections.MvpOwnership || 0;
            p.FieldPts = projections.FieldPts || 0;
            p.selected = projections.Selected;
          }
        }
      }
    }));
  }

  const map = {
    'MLB': mapMLB,
    'PGA': mapPGA,
    'NFL': mapNFL,
    'MMA': mapMMA,
};