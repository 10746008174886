<div class="ss-dash-page-wrapper">
  <div *ngIf="user" class="ss-dash-welcome-header">
    Welcome <span class="name">{{ user?.displayName }}</span>
  </div>

  <div class="ss-dash-chart-row-grid">
    <div class="ss-dash-card" style="align-self: flex-start; min-height: 33rem">
      <div class="dash-card-header">
        <div class="dash-card-title">Recent Results</div>
        <div class="dash-card-actions"></div>
      </div>

      <div
        class="dash-card-body mt32 flex"
        style="justify-content: center"
        *ngIf="isLoadingJobs"
      >
        <div class="ss-spinner passive"></div>
      </div>

      <div class="dash-card-body" *ngIf="!isLoadingJobs">
        <div *ngIf="recentJobs && recentJobs.length == 0">
          <div class="empty-wrapper">
            <svg-icon
              [svgStyle]="{ 'height.px': 88 }"
              src="/assets/illustrations/empty-template.svg"
            ></svg-icon>
            <div class="message">No Jobs Created</div>
          </div>
        </div>
        <div class="template-row" *ngFor="let j of recentJobs">
          <div class="template-item">
            <div class="info" (click)="onClickJob(j)">
              <div>
                <svg-icon
                  *ngIf="j.site === 'dk'"
                  [svgStyle]="{ 'height.px': 32 }"
                  src="/assets/logos/dk/dk-logo-icon.svg"
                ></svg-icon>
                <svg-icon
                  *ngIf="j.site === 'fd'"
                  [svgStyle]="{ 'height.px': 32 }"
                  src="/assets/logos/fd/fd-logo-icon.svg"
                ></svg-icon>
                <svg-icon
                  *ngIf="j.site === 'ikb'"
                  [svgStyle]="{ 'height.px': 32 }"
                  src="/assets/logos/ikb/ikb-logo.svg"
                ></svg-icon>
              </div>
              <div>
                <div class="job-name">
                  {{ j.sport }}
                  {{ j.jobType === 'simulator' ? 'Simulator' : 'Optimizer' }} -
                  {{
                    j.site === 'dk'
                      ? 'DraftKings'
                      : j.site === 'fd'
                      ? 'FanDuel'
                      : 'IKB'
                  }}
                  - <span class="cap-it-bro">{{ j.jobSubType }}</span>
                </div>
                <div class="job-detail">
                  {{ j.slate }}
                  <span *ngIf="j?.contest"> | {{ j.contest.name }} </span>
                </div>
                <div class="ss-job-list-timestamp">
                  Created:
                  {{ j.createdAt.toDate() | date : 'MM/dd/yyyy hh:mm a' }}
                </div>
              </div>
            </div>
            <div class="ss-job-list-status">
              <div class="status-chip">
                {{ translateStatus(j.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="ss-dash-card" style="align-self: baseline; min-height: 2rem">
        <div class="dash-card-header">
          <div class="dash-card-title">Quick Start</div>
          <div class="dash-card-actions"></div>
        </div>

        <div class="dash-card-body mt8">

          <div class="ss-base-card qs-card mb16 cursor-p" (click)="onClickOpto()">
            <div class="flex items-center space-between">
              <div class="flex items-center">
                <square-anime-widget></square-anime-widget>
                <div>
                  <div class="ss-sport-card-name">New Optimizer</div>
                </div>
              </div>
              <svg-icon
                [svgStyle]="{ 'height.px': 16 }"
                src="/assets/icons/box-arrow-right-solid.svg"
              ></svg-icon>
            </div>
          </div>

          <div class="ss-base-card qs-card cursor-p" (click)="onClickSim()">
            <div class="flex items-center space-between">
              <div class="flex items-center">
                <circle-anime-widget></circle-anime-widget>
                <div>
                  <div class="ss-sport-card-name">New Simulator</div>
                </div>
              </div>
              <svg-icon
                [svgStyle]="{ 'height.px': 16 }"
                src="/assets/icons/box-arrow-right-solid.svg"
              ></svg-icon>
            </div>
          </div>

        </div>
      </div>

      <div class="ss-dash-card mt24" style="align-self: baseline">
        <div class="dash-card-header">
          <div class="feature-title">
            <div class="icon">
              <svg-icon
                [svgStyle]="{ 'height.px': 24 }"
                src="/assets/logos/discord-logo.svg"
              ></svg-icon>
            </div>
          </div>
          <div class="dash-card-actions" (click)="openDiscordInvite()">
            <div class="join-btn">JOIN</div>
          </div>
        </div>

        <div class="dash-card-body mt8">
          <div class="discord-message" *ngIf="isLoadingDiscordData">
            Connecting to Discord Server..
          </div>
          <div class="discord-message" *ngIf="discordError">
            {{discordError}}
          </div>
          <div
            class="posts"
            *ngIf="!isLoadingDiscordData && discordUpdateMessages && discordUpdateMessages.length"
          >
            <div class="post" *ngFor="let p of discordUpdateMessages">
              <div class="user">
                <div class="avatar">
                  <img
                    class="avatar-img"
                    [src]="getAvatarUrl(p.author.id, p.author.avatar)"
                    alt="User Avatar"
                  />
                </div>
                <div class="name">@{{ p.author.username }}</div>
              </div>

              <div class="content">
                <div class="text">
                  {{ p.content }}
                </div>
                <div class="emotes" *ngIf="p.reactions">
                  <div class="emote" *ngFor="let e of p.reactions">
                    <div class="character" *ngIf="e.emoji.id == null">
                      {{ e.emoji.name }}
                    </div>
                    <div class="custom-emoji" *ngIf="e.emoji.id !== null && e.emoji.animated">
                      <img [src]="getAnimatedEmoji(e.emoji.id)" height="16px" width="16px" />
                    </div>
                    <div class="custom-emoji" *ngIf="e.emoji.id !== null && !e.emoji.animated">
                      <img [src]="getEmoji(e.emoji.id)" height="16px" width="16px" />
                    </div>
                    <div class="count">{{ e.count }}</div>
                  </div>
                </div>
                <div class="url" *ngIf="p.embeds.length">
                  <a [href]="p.embeds[0].url" target="_blank">{{
                    p.embeds[0].url
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- SINGLE SPORT LIKELY GOING AWAY -->

  <!-- <base-edit-overlay *ngIf="showInvalidSportModal && !isLoading" (closeOverlay)="onClickCloseSingleSportModal()">
        <div class="ss-upgrade-modal-wrapper">
            <div class="logo">
                <svg-icon [svgStyle]="{ 'height.px':88 }" src="/assets/logos/ace-mind-logo-mono.svg"></svg-icon>
            </div>
            <div class="title">Single Sport Mode</div>
            <div class="sub-title">This is not your current sport. You are subscribed to <span
                    style="font-weight: 600; color: var(--ss-brand-primary)">{{subscribedSport ?
                    subscribedSport : 'Undefined'}}</span>
                until {{subscribedSportEndDate ? subscribedSportEndDate :'mm/dd/yy'}} or until we mark the season as
                closed.
            </div>
            <div class="sub-title mt16">
                You can manage your Single Sport or upgrade to all sports from your account page.
            </div>
            <button class="ss-secondary-btn mt24" style="font-weight: 400; color: var(--ss-text-soft-mute);"
                routerLink="/account">Go to My Account</button>
        </div>
    </base-edit-overlay> -->

  <!-- <single-sport-selection *ngIf="showSelectSportModal" [userProfile]="userProfile" [currentSport]="subscribedSport"
        [currentSportEndData]="subscribedSportEndDate" [preSelectedSport]="singleSportSelection"
        (handleCloseOverlay)="handleCloseSingSportOverlay($event)" [userId]="user.uid"></single-sport-selection> -->
</div>
