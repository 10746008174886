<div>

  <div class="config-tab-control">
    <div class="tab-links">
      <div class="link"
        (click)="onToggleGroup('settings')"
        [class.active]="activeGroup === 'settings'">
        Settings
      </div>
      <div class="link 2"
        (click)="onToggleGroup('player_rules')"
        [class.active]="activeGroup === 'player_rules'">
        Player Rules
      </div>
    </div>
  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'settings'">

    <div class="centered-form">

      <section *ngIf="jobData.jobSubType">
        <presets-manager [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="onClickSetPresetDefault($event.id, $event.index, $event.data)"
          (onClickRemovePresetDefault)="onClickRemovePresetDefault($event.id, $event.index, $event.data)"
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"></presets-manager>
      </section>

      <hr style="margin-bottom: 12px; margin-top: 24px">

      <div class="inline-inputs">

        <input-group label="Number of Lineups"
          tooltip="Number of lineups you want to generate"
          [(ngModel)]="jobData.options.numLineups"
          [min]="0"
          [max]="5000"
          [errorMessage]="'Value between 0 and 5,000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.numLineups < 0 || jobData.options.numLineups == null || jobData.options.numLineups > 5000 }">
        </input-group>

        <input-group label="Min Unique Players"
          tooltip="Determines the minimum number of players that will be different between each lineup"
          [(ngModel)]="jobData.options.numUniques"
          [min]="1"
          [max]="5"
          [errorMessage]="'Value between 1 and 5'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.numUniques < 1 || jobData.options.numUniques == null || jobData.options.numUniques > 5 }">
        </input-group>

        <input-group label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [errorMessage]="'This field is required'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.projectionMinimum < 0 || jobData.options.projectionMinimum == null }">
        </input-group>

        <input-group label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.randomness < 0 || jobData.options.randomness > 1000 || jobData.options.randomness == null }">
        </input-group>

        <input-group label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{ 'error': jobData.options.minLineupSalary < 0 || jobData.options.minLineupSalary > siteSalaryMax || jobData.options.minLineupSalary == null }">
        </input-group>


      </div>

    </div>

  </div>

  <div class="settings-wrapper"
    *ngIf="activeGroup === 'player_rules'">
    <div class="rules-list p16">

      <div class="flex space-between">
        <div class="job-options-header"
          style="margin-bottom: 8px;">
          Player Rules
        </div>
        <div class="import-setting-link"
          (click)="onClickImportPlayerRules()">Import Recent</div>
      </div>

      <hr style="margin-bottom: 20px;">

      <div class="rule-wrapper"
        *ngFor="let rule of jobData.rules; let i = index">
        <div class="info">
          <div class="title">
            <span>{{rule.type === 'at-least' ? 'At Least' : (rule.type === 'at-most' ? 'At Most' : 'Exactly')}} -
              {{rule.value}}</span>
          </div>
          <div class="players-wrapper">
            <div class="player"
              *ngFor="let player of rule.players">{{player.name}}</div>
          </div>
        </div>
        <div class="icon"
          (click)="onClickRemoveRule(i)">
          <svg-icon class="icon"
            [svgStyle]="{ 'height.px':20 }"
            src="/assets/icons/close-square.svg"></svg-icon>
        </div>
      </div>

      <button class="ss-utility-btn secondary fluid"
        (click)="onClickAddRule()"
        *ngIf="!showRuleForm">Add a
        Rule</button>

      <div *ngIf="showRuleForm">

        <div class="input-row">

          <div class="ss-form-group">
            <label for="contestSlate">Rule Type:</label>
            <select class="ss-input expanded"
              id="type"
              [(ngModel)]="ruleType">
              <option value="at-least">At Least</option>
              <option value="at-most">At Most</option>
              <option value="exactly">Exactly</option>
            </select>
          </div>

          <div class="ss-form-group">
            <label for="contestType">Number:</label>
            <input appSelectAllText
              class="ss-input expanded"
              id="value"
              placeholder="Number"
              type="number"
              maxlength="100"
              [(ngModel)]="ruleValue" />
          </div>

        </div>

        <div class="staged-players"
          *ngIf="rulePlayers.length > 0">
          <div class="player-chip"
            *ngFor="let player of rulePlayers; let i = index">
            <div class="text">
              {{player.name}}
            </div>
            <div class="icon"
              (click)="onClickRemoveStagedPlayer(i)">
              <svg-icon class="icon"
                [svgStyle]="{ 'height.px':18 }"
                src="/assets/icons/close-square.svg"></svg-icon>
            </div>
          </div>
        </div>

        <player-type-ahead [items]="searchablePlayers"
          (itemSelected)="onSelectPlayer($event)"
          label="Select Player:"
          placeholder="Search Players..."></player-type-ahead>

        <div class="base-error-text mb16">
          {{ruleError}}
        </div>

        <div class="save-row">
          <div class="actions">
            <button class="ss-utility-btn secondary"
              (click)="onClickCancelRule()">Cancel</button>
            <button class="ss-utility-btn"
              (click)="onClickSaveRule()"
              [disabled]="rulePlayers.length === 0 || !ruleValue">Add Rule</button>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- Z IMPORTER -->
  <rules-importer *ngIf="showImportModal"
    [userId]="jobData.userId"
    [jobType]="jobData.jobType"
    [sport]="jobData.sport"
    [draftGroupId]="jobData.draftGroupId"
    (handleImportPlayerRules)="handleImportPlayerRules($event)"
    [importMode]="importMode"
    (handleCloseImportModal)="handleCloseImportModal()"></rules-importer>

</div>