import { Component, Input } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'empty-state-skull-animation',
  templateUrl: './empty-state-skull-animation.component.html',
  styleUrls: ['./empty-state-skull-animation.component.scss']
})
export class EmptyStateSkullAnimationComponent {
  @Input() isLoading: boolean;
  @Input() showText: boolean = true;
  @Input() animateOnRender: boolean = true;

  timeline: any;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.animateOnRender) {
        this.setupTimeline();
      }
    }, 400);
  }

  setupTimeline(): void {

    this.timeline = anime.timeline({
      autoplay: true,
      loop: true,
    });

    this.timeline
      .add({
        targets: '#skull',
        duration: 800
      }, '-=200')
      .add({
        targets: '#digital-eye',
        opacity: [0.2, 1, 0.2],
        duration: 1800,
        easing: 'easeInOutQuad',
      }, '-=400')
  }

  ngOnDestroy(): void {
    this.destroyTimeline();
  }

  destroyTimeline(): void {
    if (this.timeline) {
      this.timeline.pause();
      this.timeline = null;
    }
  }

}
