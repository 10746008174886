import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  addDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  getDocsFromServer,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  database = getFirestore();
  unsubscribeUniversalJob: any;

  constructor() { }

  // leave until opto-sim is figured out
  async createOptimizedSimulationJob(userId, sport, optimizerJobId, optimizerJobName, site, slate, contestType, draftGroupId, jobSubType) {
    const timestamp = serverTimestamp();
    const jobData = {
      status: 'genesis',
      createdAt: timestamp,
      sport: sport,
      site: site,
      slate: slate,
      contestType: contestType,
      optimizerSeed: {
        jobId: optimizerJobId,
        jobName: optimizerJobName,
        optimizerDraftGroupId: draftGroupId,
        jobSubType: jobSubType,
      }
    }
    
    return jobData;
  }

  // LEAVE UNTIL DECISIONS ON JOB NAME OR NOTES
  updateJobName(jobName) {
    const clonePattern = /Clone (\d+)$/;
    const match = jobName.match(clonePattern);
  
    if (match) {
      const currentCloneNumber = parseInt(match[1], 10);
      const newCloneNumber = currentCloneNumber + 1;
      const updatedJobName = jobName.replace(clonePattern, `Clone ${newCloneNumber}`);
      return updatedJobName;
    } else {
      return `${jobName} Clone 1`;
    }
  }

  // JOB PRESETS 

  async createJobOptionPreset(userId, sport, jobType, jobSubType, preset ) {
    const timestamp = serverTimestamp();
    const presetData = {
      name: preset.name,
      createdAt: timestamp,
      updatedAt: timestamp,
      sport: sport,
      jobType: jobType,
      jobSubType: jobSubType,
      presetValues: preset.presetValues
    }
    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'job-option-presets'), presetData);
    return docRef.id;
  }

  async getJobOptionPresetsByUserId(userId) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'job-option-presets');
    const querySnapshot = await getDocs(collectionRef);
    const presetsWithId = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    return presetsWithId;
  }

  async updateJobOptionPreset(userId, docId, preset) {
    const timestamp = serverTimestamp();
    const jobUpdate = {
      ...preset,
      updatedAt: timestamp,
      isDefault: preset.isDefault,
    }
    const docRef = await doc(this.database, "user-profiles", userId, 'job-option-presets', docId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);
    return updatedDocSnapshot.data();
  }

  async updateJobOptionPresetValues(userId, docId, values) {
    const timestamp = serverTimestamp();
    const jobUpdate = {
      updatedAt: timestamp,
      presetValues: values,
    }
    const docRef = await doc(this.database, "user-profiles", userId, 'job-option-presets', docId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);
    return updatedDocSnapshot.data();
  }

  async deleteOptionPresetById(userId, docId) {
    const docRef = doc(this.database, "user-profiles", userId, 'job-option-presets', docId);
    try {
      await deleteDoc(docRef);
      const data = {
        message: 'document deleted'
      }
      return data;
    } catch (error) {
      console.error(`Error deleting document with ID ${docId}:`, error);
    }
  }


  // JOB CORR PRESETS 

  async createJobCorrelationPreset(userId, sport, jobType, jobSubType, preset ) {
    const timestamp = serverTimestamp();
    const presetData = {
      name: preset.name,
      createdAt: timestamp,
      updatedAt: timestamp,
      sport: sport,
      jobType: jobType,
      jobSubType: jobSubType,
      presetValues: preset.presetValues
    }
    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'job-correlation-presets'), presetData);
    return docRef.id;
  }

  async getJobCorrelationPresetsByUserId(userId, sport) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'job-correlation-presets');
    const q = query(collectionRef, where("sport", "==", sport));
    const querySnapshot = await getDocs(q);
    const presetsWithId = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
  
    return presetsWithId;
  }

  async updateJobCorrelationPreset(userId, docId, preset) {
    const timestamp = serverTimestamp();
    const jobUpdate = {
      ...preset,
      updatedAt: timestamp,
      isDefault: preset.isDefault,
    }
    const docRef = await doc(this.database, "user-profiles", userId, 'job-correlation-presets', docId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);
    return updatedDocSnapshot.data();
  }

  async deleteCorrelationPresetById(userId, docId) {
    const docRef = doc(this.database, "user-profiles", userId, 'job-correlation-presets', docId);
    try {
      await deleteDoc(docRef);
      const data = {
        message: 'document deleted'
      }
      return data;
    } catch (error) {
      console.error(`Error deleting document with ID ${docId}:`, error);
    }
  };

  // LOCAL JOB DATA SNAPSHOT
  private storageKey = 'jobDataSnapshot';

  saveJobDataSnapshot(jobData: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(jobData));
  }

  loadJobDataSnapshot(): any | null {
    const jobDataString = localStorage.getItem(this.storageKey);
    if (jobDataString) {
      return JSON.parse(jobDataString);
    }
    return null;
  }

  clearJobDataSnapshot(): void {
    localStorage.removeItem(this.storageKey);
  }

  private preferenceKey = 'jobPrefferedSport';

  saveJobPrefferedSport(sport: any): void {
    localStorage.setItem(this.preferenceKey, sport);
  }

  getJobPrefferedSport(): any | null {
    const jobPrefString = localStorage.getItem(this.preferenceKey);
    if (jobPrefString) {
      return jobPrefString;
    }
    return null;
  }


  // UNIVERSAL JOBS

  async cleanupStaleUniversalJobs(userId) {
    const timeNow = Date.now();
    const sixtyDaysAgo = new Date(timeNow - 60 * 24 * 60 * 60 * 1000); // 60 days ago
    const templatesRef = collection(this.database, "user-profiles", userId, 'universal-jobs');
    const q = query(templatesRef,
      where('updatedAt', '<=', sixtyDaysAgo));
    const querySnapshot = await getDocsFromServer(q);
  
    const deletePromises = querySnapshot.docs.map(async (doc) => {
      await deleteDoc(doc.ref);
    });
    await Promise.all(deletePromises);
  }

  async createUniversalJobFromSnapshot(userId, jobSnapshot) {
    await this.cleanupStaleUniversalJobs(userId);
    const timestamp = serverTimestamp();
    const jobData = {
      universalBuilder: true,
      createdAt: timestamp,
      updatedAt: timestamp,
      ...jobSnapshot,
    };
  
    // Create the document and get the reference
    const docRef = await addDoc(collection(this.database, "user-profiles", userId, 'universal-jobs'), jobData);
    
    // Add the document ID to the newly created document
    const jobId = docRef.id;
    await updateDoc(docRef, { jobId });
  
    // Retrieve the updated document
    const updatedDoc = await getDoc(docRef);
    
    // Return the entire document data
    return { jobId, ...updatedDoc.data() };
  };

  async getUniversalJob(userId, jobId) {
    const docRef = await doc(this.database, "user-profiles", userId, 'universal-jobs', jobId);
    const docSnap = await getDoc(docRef);
    const jobData = docSnap.data();

    delete jobData.draftGroup;

    return jobData;
  }

  subscribeToUniversalJob(userId, jobId, updateCallback) {
    const docRef = doc(this.database, "user-profiles", userId, "universal-jobs", jobId);
    this.unsubscribeUniversalJob = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const jobData = docSnap.data();
        console.log("New job data:", jobData);
        updateCallback(jobData);
      } else {
        console.log("Document not found");
        updateCallback(null);
      }
    });
  }

  stopListeningToUniversalJob() {
    if (this.unsubscribeUniversalJob) {
      this.unsubscribeUniversalJob();
      this.unsubscribeUniversalJob = null;
    }
  }

  async updateUniversalJob(userId, jobId, jobData) {
    const timestamp = serverTimestamp();
    const jobUpdate = {
      updatedAt: timestamp,
      ...jobData
    }
    const docRef = await doc(this.database, "user-profiles", userId, 'universal-jobs', jobId);
    await updateDoc(docRef, jobUpdate);
    const updatedDocSnapshot = await getDoc(docRef);
    return updatedDocSnapshot.data();
  }

  async getRecentUniversalJobs(userId, count) {
    // this.cleanupOldOptoJobs(userId);
    // this.cleanupOldSimJobs(userId);
    const collectionRef = collection(this.database, "user-profiles", userId, 'universal-jobs');
    const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(count));
    const querySnapshot = await getDocs(q);
    const recentJobs = querySnapshot.docs.map((doc) => doc.data());
    return recentJobs;
  }

  async getRecentUniversalJobsForConfigImport(userId) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'universal-jobs');
    const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(20));
    const querySnapshot = await getDocs(q);
    const recentJobs = querySnapshot.docs.map((doc) => doc.data());
    return recentJobs;
  }


  async cleanupOldOptoJobs(userId: string) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'optimizer-jobs');
    const batchSize = 20;
  
    while (true) {
      try {
        // Fetch the next batch of documents
        const snapshot = await getDocs(query(collectionRef, limit(batchSize)));
  
        if (snapshot.empty) {
          console.log('Opto history cleaned');
          break; // Exit the loop if no more documents
        }
  
        // Delete each document in the current batch
        const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
  
        console.log(`Opto history updated ${snapshot.size} elements.`);
      } catch (error) {
        console.error('Error Cleaning Opto History: ', error);
        break; // Exit the loop if an error occurs
      }
    }
  } 

  async cleanupOldSimJobs(userId: string) {
    const collectionRef = collection(this.database, "user-profiles", userId, 'sim-jobs');
    const batchSize = 20;
  
    while (true) {
      try {
        // Fetch the next batch of documents
        const snapshot = await getDocs(query(collectionRef, limit(batchSize)));
  
        if (snapshot.empty) {
          console.log('Sim history cleaned');
          break; // Exit the loop if no more documents
        }
  
        // Delete each document in the current batch
        const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
  
        console.log(`Sim history updated: ${snapshot.size} elements.`);
      } catch (error) {
        console.error('Error Cleaning Sim History: ', error);
        break; // Exit the loop if an error occurs
      }
    }
  }

}
