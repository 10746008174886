<div class="sport-select-wrapper">

    <div class="cancel-btn-wrapper" (click)="handleCloseOrCancel(false)">
        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/close-square.svg"></svg-icon>
    </div>

    <div class="wrapper-content">
        <div class="title">Set Your Single Sport</div>

        <div id="sport-text" *ngIf="selectedSport" class="sport-callout">
            {{selectedSport}}
        </div>

        <div class="explanation">
            Your current subscription allows you to use one sport. You can change your sport every 30 days or when the
            season ends.
        </div>

        <div class="explanation">
            By selecting the sport above, you will only be able to use this sport until: {{lockedUntilDate}} or season
            end.
        </div>

        <div class="ss-form-group">
            <div class="flex space-between">
                <label for="phrase">Type {{selectedSport}} below to confirm this sport</label>
            </div>
            <input type="text" class="ss-input expanded" id="phrase" placeholder="Enter {{selectedSport}} "
                [(ngModel)]="confirmationText" autocomplete="off" />
        </div>

        <button class="ss-primary-btn" (click)="onClickConfirmSport()" *ngIf="confirmationText === selectedSport && !isWarningLock">
            <span *ngIf="!isLoading">LOCK MY SPORT FOR 30 DAYS</span>
            <div class="ss-spinner btn-spinner" *ngIf="isLoading"></div>
        </button>

        <div class="warning-wrapper" *ngIf="isWarningLock">
            <div class="number">
                {{countdown}}
            </div>
            <div class="message">Countdown to Sport Lock</div>
        </div>

        <button class="ss-secondary-btn mt24" (click)="handleCloseOrCancel(false)" *ngIf="isWarningLock">
            <span>I Changed My Mind</span>
        </button>

    </div>
</div>