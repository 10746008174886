import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import * as csvtojson from 'csvtojson';
import { KNOWN_NAME_HEADERS, ALL_COMMON_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import * as stringSimilarity from 'string-similarity';
import { DraftGroupService } from 'src/app/services/draft-group.service';
import { JobsService } from 'src/app/services/jobs.service';
import _ from 'lodash';

//SUB COMPONENTS
import { ProjectionSniperComponent } from './import-components/projection-sniper/projection-sniper.component';
import { FlexownershipSniperComponent } from './import-components/flexownership-sniper/flexownership-sniper.component';
import { CptownershipSniperComponent } from './import-components/cptownership-sniper/cptownership-sniper.component';
import { OwnershipSniperComponent } from './import-components/ownership-sniper/ownership-sniper.component';
import { StddevSniperComponent } from './import-components/stddev-sniper/stddev-sniper.component';
import { FieldptsSniperComponent } from './import-components/fieldpts-sniper/fieldpts-sniper.component';
import { MinutesSniperComponent } from './import-components/minutes-sniper/minutes-sniper.component';
import { ProownershipSniperComponent } from './import-components/proownership-sniper/proownership-sniper.component';
import { StarownershipSniperComponent } from './import-components/starownership-sniper/starownership-sniper.component';
import { MvpownershipSniperComponent } from './import-components/mvpownership-sniper/mvpownership-sniper.component';
import { MakeCutSniperComponent } from './import-components/makecut-sniper/makecut-sniper.component';
import { WinProbSniperComponent } from './import-components/winprob-sniper/winprob-sniper.component';
import { KoSniperComponent } from './import-components/ko-sniper/ko-sniper.component';
import { BattingOrderSniperComponent } from './import-components/battingorder-sniper/battingorder-sniper.component';
import { UserService } from 'src/app/services/user.service';
import { StateService } from 'src/app/services/state.service';


@Component({
  selector: 'draft-group-importer',
  templateUrl: './draft-group-importer.component.html',
  styleUrls: ['./draft-group-importer.component.scss'],
})
export class DraftGroupImporterComponent {
  @ViewChild(ProjectionSniperComponent) projectionSniperComponent: ProjectionSniperComponent;
  @ViewChild(MinutesSniperComponent) minutesSniperComponent: MinutesSniperComponent;
  @ViewChild(OwnershipSniperComponent) ownershipSniperComponent: OwnershipSniperComponent;
  @ViewChild(FlexownershipSniperComponent) flexownershipSniperComponent: FlexownershipSniperComponent;
  @ViewChild(CptownershipSniperComponent) cptownershipSniperComponent: CptownershipSniperComponent;
  @ViewChild(StddevSniperComponent) stddevSniperComponent: StddevSniperComponent;
  @ViewChild(FieldptsSniperComponent) fieldptsSniperComponent: FieldptsSniperComponent;
  @ViewChild(ProownershipSniperComponent) proownershipSniperComponent: ProownershipSniperComponent;
  @ViewChild(StarownershipSniperComponent) starownershipSniperComponent: StarownershipSniperComponent;
  @ViewChild(MvpownershipSniperComponent) mvpownershipSniperComponent: MvpownershipSniperComponent;
  @ViewChild(MakeCutSniperComponent) makeCutSniperComponent: MakeCutSniperComponent;
  @ViewChild(WinProbSniperComponent) winProbSniperComponent: WinProbSniperComponent;
  @ViewChild(KoSniperComponent) koSniperComponent: KoSniperComponent;
  @ViewChild(BattingOrderSniperComponent) battingOrderSniperComponent: BattingOrderSniperComponent;

  @Input() jobData: any;
  @Input() draftGroup: any;
  @Input() draftGroupId: any;
  @Input() importMode: any;

  @Output() importComplete: EventEmitter<any> = new EventEmitter<any>();

  isProcessingFile: boolean = false;
  isPreparingData: boolean = false;
  fileList: File[] = [];
  csvDataArray: any;
  fileError: any;
  performanceWarning: any;
  importError: any = null;

  keysToSelect: string[] = [];
  playerNameKey: any = null;
  mlbMatchingKeyId: any;
  mlbMatchingKeyTeam: any;

  draftGroupCopy: any;
  totalDraftGroupPlayers: number;
  matchingPlayerCount: number = 0;
  isPlayerMatchError: boolean = false;
  isPlayerKeyValid: boolean = false;
  playerMessage: string = 'Analyzing...';

  showImportTools: boolean = false;
  useProjectionAsStdDev: boolean = false;

  requiredValidations: any = [];
  completedValidations: any = [];

  draftGroupPresetName: any;
  isSavingDraftGroupPreset: boolean = false;

  isImportingData: boolean = false;
  isImportValid: boolean = false;

  userDefinedProjectionValue: string;
  userProfile: any;
  userKeyAdditions: any = [];
  userKeysToRemove: any = [];
  userProfileMappingUpdate = null;
  userNameHeaderMappings: any = [];
  isCustomMapping: boolean = false;
  customKey: any = null;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private draftGroupService: DraftGroupService,
    private jobsService: JobsService,
    private userService: UserService,
    private stateService: StateService,
  ) { }

  ngOnInit() {
    const id = this.draftGroupId;
    this.checkForUserImportMappings();
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}/${currentDate.getFullYear().toString().slice(-2)} @${formattedHours.toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')} ${amPm}`;
    this.draftGroupPresetName = `DraftGroup: ${id} - ${formattedDate}`;
  }

  checkForUserImportMappings() {
    this.userService.getUserPublicProfileById(this.jobData.userId).then((data) => {
      this.userProfile = data;
      if (data != null) {
        this.userNameHeaderMappings = data?.importMappings?.name || []
        this.userProfile = {
          ...this.userProfile,
          importMappings: {
            name: data?.importMappings?.name || [],
            projection: data?.importMappings?.projection || [],
            ownership: data?.importMappings?.ownership || [],
            stdDev: data?.importMappings?.stdDev || [],
            makeCut: data?.importMappings?.makeCut || [],
            winProb: data?.importMappings?.winProb || [],
            koProb: data?.importMappings?.koProb || [],
            fieldPts: data?.importMappings?.fieldPts || [],
            minutes: data?.importMappings?.minutes || [],
            proOwnership: data?.importMappings?.proOwnership || [],
            starOwnership: data?.importMappings?.starOwnership || [],
            cptOwnership: data?.importMappings?.cptOwnership || [],
            mvpOwnership: data?.importMappings?.mvpOwnership || [],
          }
        }
      }
    });
  }

  handleUpdateUserKeyAdditions(key) {
    if (ALL_COMMON_HEADERS.some(k => k === key.value)) {
      return;
    } else {
      const cleanedAdditions = this.userKeyAdditions.filter((item) => item.type !== key.type);
      this.userKeyAdditions = cleanedAdditions;
      this.userKeyAdditions.push(key);
      this.processUserKeyMappings();
    }
  }

  handleUpdateUserKeysToRemove(key) {
    this.userKeysToRemove.push(key);
    this.processUserKeyMappings();
  }

  processUserKeyMappings() {
    let mappings = null;

    if (this.userProfile?.importMappings != null) {
      mappings = _.cloneDeep({
        ...this.userProfile.importMappings,
      });

    } else {
      mappings = {
        name: [],
        projection: [],
        ownership: [],
        stdDev: [],
        makeCut: [],
        winProb: [],
        koProb: [],
        fieldPts: [],
        minutes: [],
        proOwnership: [],
        starOwnership: [],
        cptOwnership: [],
        owmvpOwnership: [],
      }

    }

    this.userKeyAdditions.forEach((key) => {
      mappings[key.type].push(key.value);
    });

    this.userKeysToRemove.forEach((key) => {
      if (mappings[key.type]) {
        const newValues = mappings[key.type].filter(item => item !== key.value);
        mappings[key.type] = newValues;
      }
    });

    // console.log('The new mappings object for profile is', mappings);
    this.userProfileMappingUpdate = mappings;
  }

  handleFileInput(fileList: FileList) {
    this.fileError = null;
    if (this.isPreparingData) {
      return;
    }
    if (fileList.length > 2 || this.fileList.length >= 2) {
      this.fileError = 'You can only merge up to 2 files. Try Again.';
      this.isProcessingFile = false;
      return;
    } else {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const ext: string = file.name.split('.').pop().toLowerCase();

        if (ext !== 'csv') {
          this.fileError = 'One of Your Files is not a CSV'
          this.isProcessingFile = false;
          return;
        }
        if (this.fileList.some((f) => f.name === file.name)) {
          this.fileError = 'You uploaded the same file twice';
          this.isProcessingFile = false;
          return;
        }
        if (this.fileList.some((f) => f.name === file.name)) {
          this.fileError = 'A file with the same name exists';
          this.isProcessingFile = false;
          return;
        }
        this.fileList.push(file);
      }
      this.isProcessingFile = false;
    }
  }

  onClickRemoveFile(fileName: string) {
    const indexToRemove = this.fileList.findIndex((file) => file.name === fileName);
    if (indexToRemove !== -1) {
      this.fileList.splice(indexToRemove, 1);
    }
  }

  async onClickProcessImport() {
    if (this.isPreparingData) {
      return;
    }
    this.isPreparingData = true;
    this.fileError = null;
    this.performanceWarning = null;

    if (this.fileList.length > 1) {
      this.handleMultiFile(this.fileList);
      return;
    }

    const file = this.fileList[0];

    const fileName: string = file.name;
    const fileExtension: string = fileName.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      try {

        const reader = new FileReader();
        reader.onload = async (e: any) => {
          const csvData = e.target.result;
          const jsonData = await this.convertCsvToJson(csvData); // Wait for the conversion
          this.csvDataArray = jsonData;
          this.draftGroupCopy = this.draftGroup;

          if (this.jobData.sport === 'MLB') {
            this.checkForRequiredColumns()
          } else {
            this.showImportTools = true;
            this.renderer.setStyle(document.body, 'overflow', 'hidden');
            this.locatePlayerNameValue();
          }
        };
        reader.readAsText(file);

      } catch (error) {
        this.fileError = 'Error Parsing the CSV File.';
        this.isPreparingData = false;
      }
    } else {
      this.fileError = 'Invalid File. Add a CSV File'
      this.isPreparingData = false;
    }
  }

  async handleMultiFile(fileList) {
    const file1 = fileList[0];
    const file2 = fileList[1];
    const ext1: string = file1.name.split('.').pop().toLowerCase();
    const ext2: string = file2.name.split('.').pop().toLowerCase();

    if (ext1 !== 'csv' || ext2 !== 'csv') {
      this.fileError = 'One of Your Files is not a CSV'
      this.isPreparingData = false;
      return;
    }

    const [csvData1, csvData2] = await Promise.all([
      this.readFileAsText(file1),
      this.readFileAsText(file2),
    ]);

    const jsonData1 = await this.convertCsvToJson(csvData1);
    const jsonData2 = await this.convertCsvToJson(csvData2);

    const mergedJsonData = this.mergeJsonData(jsonData1, jsonData2);

    if (mergedJsonData !== null) {
      this.csvDataArray = mergedJsonData;
      this.draftGroupCopy = this.draftGroup;

      if (this.jobData.sport === 'MLB') {
        this.checkForRequiredColumns()
      } else {
        this.showImportTools = true;
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
        this.locatePlayerNameValue();
      }
    } else {
      this.fileError = 'Files need matching Headers for player names to merge.'
      this.isPreparingData = false;
      return;
    }

  }

  mergeJsonData(jsonData1: any[], jsonData2: any[]): any[] {
    let nameKey = null;
    const dataObj = jsonData1[0];
    const knownKeys = KNOWN_NAME_HEADERS;

    const matchingKey = knownKeys.find(key => dataObj.hasOwnProperty(key));

    if (matchingKey) {
      nameKey = matchingKey;
    } else {
      return null;
    }
    const result = [];

    for (const item1 of jsonData1) {
      for (const item2 of jsonData2) {
        if (item1[nameKey] === item2[nameKey]) {
          const mergedItem = { ...item1, ...item2 };
          for (const key in item1) {
            if (mergedItem[key] !== undefined && mergedItem[key] !== item1[key]) {
              mergedItem[`${key}_2`] = item1[key];
            }
          }
          result.push(mergedItem);
        }
      }
    }

    if (result.length > 0) {
      return result;
    } else {
      return null;
    }

  }

  async readFileAsText(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = (event: any) => {
        reject(event.target.error);
      };
      reader.readAsText(file);
    });
  }

  async convertCsvToJson(csvData: string): Promise<any> {
    const data = await csvtojson().fromString(csvData);
    return data;
  }

  checkForRequiredColumns() {
    this.mlbMatchingKeyId = null;
    this.mlbMatchingKeyTeam = null;
    const dataObj = this.csvDataArray[0];
    const knownPlayerIdKeys = ['player id', 'playerid', 'PlayerId', 'Player Id', 'Player ID'];
    const matchingPlayerIdKey = knownPlayerIdKeys.find(key => dataObj.hasOwnProperty(key));

    if (matchingPlayerIdKey) {
      this.stateService.setMlbImportMatchingMethod(matchingPlayerIdKey);
      this.stateService.setCanImportById(true);
      this.mlbMatchingKeyId = matchingPlayerIdKey;
      this.showImportTools = true;
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
      this.locatePlayerNameValue();
    } else {
      const knownTeamKeys = ['team', 'Team', "teams", "Teams"];
      const matchingTeamKey = knownTeamKeys.find(key => dataObj.hasOwnProperty(key));
      if (matchingTeamKey) {
        this.stateService.setMlbImportMatchingMethod(matchingTeamKey);
        this.stateService.setCanImportById(false);
        this.mlbMatchingKeyTeam = matchingTeamKey;
        this.showImportTools = true;
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
        this.locatePlayerNameValue();
      } else {
        this.fileError = 'MLB requires at least PlayerId column with DK or FD player ids or a Team column containing team abbreviation. This is to ensure we can confidently map your projection data.';
        this.isPreparingData = false;
      }
    }
  }

  locatePlayerNameValue(): void {
    const dataObj = this.csvDataArray[0];

    const knownKeys = KNOWN_NAME_HEADERS.concat(this.userNameHeaderMappings);
    const matchingKey = knownKeys.find(key => dataObj.hasOwnProperty(key));

    if (matchingKey) {
      if (this.userNameHeaderMappings.some((k) => k === matchingKey)) {
        this.customKey = this.userNameHeaderMappings.find((k) => k === matchingKey);
        this.isCustomMapping = true;
      }
      this.playerNameKey = matchingKey;
    } else {
      this.playerNameKey = null;
    }

    this.keysToSelect.push(...Object.keys(dataObj));

    const fuzzyKey = 'player';

    this.keysToSelect.sort((a, b) => {
      const trimmedA = a.trim();
      const trimmedB = b.trim();
      const similarityA = stringSimilarity.compareTwoStrings(fuzzyKey, trimmedA);
      const similarityB = stringSimilarity.compareTwoStrings(fuzzyKey, trimmedB);
      return similarityB - similarityA;
    });

    if (this.playerNameKey !== null) {
      this.checkNameKeyValid();
    } else {
      this.isPreparingData = false;
      console.log('no player col detected');
      this.playerMessage = "No Player Column Detected"
    }
  }

  checkNameKeyValid() {
    const draftGroupNames = this.draftGroup.map(player => player.Name.toLowerCase());

    this.matchingPlayerCount = 0;

    if (this.jobData.sport === 'MLB') {

      // TODO: Refactor to produce a cleaned array of only the matching players down to the sniper components.
      this.csvDataArray.forEach((dataObj) => {
        if (this.mlbMatchingKeyId != null) {
          const matchingId = dataObj[this.mlbMatchingKeyId];
          if (matchingId) {
            this.matchingPlayerCount += 1;
          }
        } else {
          const draftGroupNamesWithTeam = this.draftGroup.map(player => (player.Name + player.Team).toLowerCase());
          const uploadedNameWithTeam = (dataObj[this.playerNameKey].trim().toLowerCase() + ' ' + dataObj[this.mlbMatchingKeyTeam].trim().toLowerCase());
          const match = stringSimilarity.findBestMatch(uploadedNameWithTeam, draftGroupNamesWithTeam);
          if (match.bestMatch.rating > 0.5) {
            this.matchingPlayerCount += 1;
          }
        }
      });
    } else {
      this.csvDataArray.forEach((dataObj) => {
        const uploadedName = dataObj[this.playerNameKey].trim().toLowerCase();
        const match = stringSimilarity.findBestMatch(uploadedName, draftGroupNames);
        if (match.bestMatch.rating > 0.5) {
          this.matchingPlayerCount += 1;
        }
      });
    }

    if (this.matchingPlayerCount === 0) {
      this.isPlayerMatchError = true;
      this.isPlayerKeyValid = false;
      this.playerMessage = "Unable to Find Matching Players";
      this.isPreparingData = false;
    } else if (this.matchingPlayerCount < (this.csvDataArray.length - this.matchingPlayerCount)) {
      this.performanceWarning = "Warning. There are many players in your file that are not in this draftgroup, performance of the import may be impacted. We recommended only having players in your file that are in this draft group.";
      this.startImport()
    } else {
      this.isPlayerKeyValid = true;
      this.startImport();
    }
  }

  startImport() {
    this.showImportTools = true;
    setTimeout(() => {
      this.handleImportMode();
      this.isPreparingData = false;
      this.playerMessage = `${this.matchingPlayerCount} Matching Players`;
    }, 1800);
  }

  selectKey(key: string): void {
    this.isPreparingData = true;
    this.playerMessage = "Analyzing..."
    setTimeout(() => {
      this.playerNameKey = key;
      this.keysToSelect = [];
      this.checkNameKeyValid();
    }, 1000)
    this.handleUpdateUserKeyAdditions({ type: 'name', value: key });
  }

  handleRemoveCustomMapping() {
    this.handleUpdateUserKeysToRemove({ type: 'name', value: this.playerNameKey });
  }

  onClickRetryNameColumn() {
    this.playerNameKey = null;
    this.keysToSelect = [];
    this.isPlayerMatchError = false;
    this.isPlayerKeyValid = true;
    this.locatePlayerNameValue();
  }

  onClickCancelImport() {
    this.draftGroup = this.draftGroupCopy;
    this.playerNameKey = null;
    this.showImportTools = false;
    this.renderer.setStyle(document.body, 'overflow', 'visible');
    this.keysToSelect = [];
    this.isPlayerMatchError = false;
    this.isPlayerKeyValid = false;
    this.totalDraftGroupPlayers = 0;
    this.matchingPlayerCount = 0;
    this.userDefinedProjectionValue = undefined;
  }

  handleUseProjectionValue(value) {
    this.useProjectionAsStdDev = value;
  }

  handleUserDefinedProjection(value) {
    this.userDefinedProjectionValue = value;
  }

  handleUnitValid(unit) {
    this.completedValidations.push(unit);
    this.validateUnits();
  }

  handleUnitInvalid(unit) {
    const invalidUnit = this.completedValidations.indexOf(unit);
    this.completedValidations.splice(invalidUnit, 1);
    this.validateUnits();
  }

  validateUnits() {
    if (!this.requiredValidations.length) {
      return;
    }
    if (this.requiredValidations.length !== this.completedValidations.length) {
      this.isImportValid = false;
      return;
    }

    const copy1 = this.requiredValidations;
    const copy2 = this.completedValidations;
    copy1.sort();
    copy2.sort();

    for (let i = 0; i < copy1.length; i++) {
      if (copy1[i] !== copy2[i]) {
        this.isImportValid = false;
        return;
      }
    }
    this.isImportValid = true;
  }

  async onClickImportData() {
    this.importError = null;
    this.isImportingData = true;
    if (!this.isImportValid) {
      this.importError = 'There are still some items to fix';
      this.isImportingData = false;
      return;
    }

    if (this.useProjectionAsStdDev) {
      this.draftGroup.forEach((player) => {
        player.FieldPts = player.Projection;
      })
    }
    
    this.draftGroup.forEach((player) => {
      const matchingPlayer = this.csvDataArray.find((p) => p[this.playerNameKey] === player.Name);
      if (player.Projection === 0) {
        player.selected = false;
      } else if (!matchingPlayer && player.Projection > 0) {
        player.selected = false;
      } else {
        player.selected = true;
      }

      for (const key in player) {
        if (
          player.hasOwnProperty(key) &&
          key !== 'BattingOrder' &&
          (player[key] === '' || player[key] === 'N/A' || player[key] === 'NaN')
        ) {
          player[key] = 0;
        } else if (
          key === 'BattingOrder' &&
          (player[key] === '' || player[key] === 'N/A' || player[key] === 'NaN')
        ) {
          player[key] = 'NS';
        }
      }
    });

    this.isSavingDraftGroupPreset = true;
    const playerValues = await this.mapPlayerPreset();

    if (this.userProfileMappingUpdate !== null) {
      console.log('updating user preferred mappings');
      const profileUpdate = {
        ...this.userProfile,
        importMappings: this.userProfileMappingUpdate,
      }
      this.userService.updateUserPublicProfile(profileUpdate);
    }

    // playerValues.forEach((obj, index) => {
    //   for (const key in obj) {
    //     if (obj[key] === undefined) {
    //       console.log(`Object at index ${index} has undefined value for key '${key}'`);
    //     }
    //   }
    // });

    this.draftGroupService.createUserDraftGroupPreset(
      this.jobData.userId,
      this.jobData.sport,
      playerValues,
      this.jobData.contest ? this.jobData.contest.draftGroupId : parseInt(this.jobData.draftGroupId),
      this.jobData.site,
      this.draftGroupPresetName,
    ).then((data) => {
      if (data !== null) {
        this.jobData.draftGroupPresetName = this.draftGroupPresetName;
        this.jobData.draftGroupPresetId = data;
        this.jobData.draftGroup = this.draftGroup;
        this.isSavingDraftGroupPreset = false;
        this.renderer.setStyle(document.body, 'overflow', 'visible');
        this.isImportingData = false;
        document.documentElement.scrollTo(0, 0);
        this.draftGroupPresetName = '';
        this.jobData.draftGroup.sort((a, b) => b.selected - a.selected);
        this.jobsService.saveJobDataSnapshot(this.jobData);
        this.stateService.setProjectionDraftGroup(this.jobData.draftGroup);
        this.showImportTools = false;
        this.importComplete.emit();
      } else {
        this.importError = 'Unable to Import Data. Try Again.'
      }
    })

  }

  mapPlayerPreset() {
    return this.draftGroup.map(player => ({
      Name: player.Name,
      PlayerId: player.PlayerId,
      Projection: player.Projection,
      Minutes: player.Minutes || 0,
      StdDev: player.StdDev || 0,
      Ownership: player.Ownership,
      CptnOwnership: player.CptnOwnership || 0,
      StarOwnership: player.StarOwnership || 0,
      MvpOwnership: player.MvpOwnership || 0,
      MakeCut: player.MakeCut || 0,
      WinProb: player.WinProb || 0,
      KOProb: player.KOProb || 0,
      FieldPts: player.FieldPts || 0,
      BattingOrder: player.BattingOrder || 9999,
      Selected: player.selected,
    }));
  }

  handleImportMode() {
    this.completedValidations = [];
    switch (this.importMode) {
      // SIM NFL
      case 'sim-nfl-dk-classic':
      case 'sim-nfl-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-nfl-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-nfl-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'MvpOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      // SIM NBA
      case 'sim-nba-dk-classic':
      case 'sim-nba-fd-classic':
      case 'sim-nba-ikb-classic':
        this.requiredValidations = ['Projection', 'Minutes', 'Ownership', 'StdDev', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-nba-dk-showdown':
      case 'sim-nba-ikb-showdown':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'FlexOwnership', 'CptOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-nba-fd-showdown':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'Ownership', 'ProOwnership', 'StarOwnership', 'MvpOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.proownershipSniperComponent.snipeValue();
        this.starownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      // SIM  & OPTO CBB (Looking for ways to cut down code now that things are more known)
      case 'sim-cbb-dk-classic':
      case 'sim-cbb-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'opto-cbb-dk-classic':
      case 'opto-cbb-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        break;
      case 'sim-cbb-dk-showdown':
      case 'opto-cbb-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-cbb-fd-showdown':
      case 'opto-cbb-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'ProOwnership', 'StarOwnership', 'MvpOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.proownershipSniperComponent.snipeValue();
        this.starownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      // SIM  & OPTO WNBA
      case 'sim-wnba-dk-classic':
      case 'sim-wnba-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'opto-wnba-dk-classic':
      case 'opto-wnba-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        break;
      case 'sim-wnba-dk-showdown':
      case 'opto-wnba-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-wnba-fd-showdown':
      case 'opto-wnba-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'ProOwnership', 'StarOwnership', 'MvpOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.proownershipSniperComponent.snipeValue();
        this.starownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      // SIM  & OPTO MLB
      case 'sim-mlb-dk-classic':
      case 'sim-mlb-fd-classic':
      case 'sim-mlb-ikb-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        this.battingOrderSniperComponent.snipeValue();
        break;
      case 'opto-mlb-dk-classic':
      case 'opto-mlb-fd-classic':
      case 'opto-mlb-ikb-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.battingOrderSniperComponent.snipeValue();
        break;
      case 'sim-mlb-dk-showdown':
      case 'opto-mlb-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-mlb-fd-showdown':
      case 'opto-mlb-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'ProOwnership', 'StarOwnership', 'MvpOwnership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.proownershipSniperComponent.snipeValue();
        this.starownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      // OPTO NFL
      case 'opto-nfl-dk-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        break;
      case 'opto-nfl-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'StdDev'];
        this.projectionSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        break;
      case 'opto-nfl-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        break;
      case 'opto-nfl-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'FlexOwnership', 'CptOwnership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        break;

      // OPTO NBA
      case 'opto-nba-dk-classic':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-nba-fd-classic':
      case 'opto-nba-ikb-classic':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-nba-dk-showdown':
      case 'opto-nba-ikb-showdown':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'FlexOwnership', 'CptOwnership'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.flexownershipSniperComponent.snipeValue();
        this.cptownershipSniperComponent.snipeValue();
        break;
      case 'opto-nba-fd-showdown':
        this.requiredValidations = ['Projection', 'Minutes', 'StdDev', 'Ownership', 'ProOwnership', 'StarOwnership', 'MvpOwnership'];
        this.projectionSniperComponent.snipeValue();
        this.minutesSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.proownershipSniperComponent.snipeValue();
        this.starownershipSniperComponent.snipeValue();
        this.mvpownershipSniperComponent.snipeValue();
        break;

      //  OPTO PGA
      case 'opto-pga-dk-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-pga-fd-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-pga-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-pga-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;

      //  SIM PGA
      case 'sim-pga-dk-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-pga-dk-classic-tournament':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.makeCutSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-pga-fd-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-pga-fd-classic-tournament':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.makeCutSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-pga-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-pga-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

      //  OPTO TEN
      case 'opto-ten-dk-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-ten-fd-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-ten-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-ten-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;

      //  SIM TEN
      case 'sim-ten-dk-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'sim-ten-fd-classic':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'sim-ten-dk-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'sim-ten-fd-showdown':
        this.requiredValidations = ['Projection', 'StdDev', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.stddevSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;

      //  OPTO MMA
      case 'opto-mma-dk-classic':
        this.requiredValidations = ['Projection', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-mma-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-mma-dk-showdown':
        this.requiredValidations = ['Projection', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;
      case 'opto-mma-fd-showdown':
        this.requiredValidations = ['Projection', 'Ownership'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        break;

      //  SIM MMA
      case 'sim-mma-dk-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-mma-fd-classic':
        this.requiredValidations = ['Projection', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-mma-dk-showdown':
        this.requiredValidations = ['Projection', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;
      case 'sim-mma-fd-showdown':
        this.requiredValidations = ['Projection', 'Ownership', 'FieldPts'];
        this.projectionSniperComponent.snipeValue();
        this.winProbSniperComponent.snipeValue();
        this.koSniperComponent.snipeValue();
        this.ownershipSniperComponent.snipeValue();
        this.fieldptsSniperComponent.snipeValue();
        break;

    }

  }

}
