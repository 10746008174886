import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscordService {
  private apiUrl = `${environment.apiURL}/ace_discord_proxy/`;

  constructor(private http: HttpClient) {}

  getDiscordData(urlFragment: string) {
    return this.http.post(this.apiUrl, { urlFragment });
  }
}
