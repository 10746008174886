import { Component, ElementRef, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { cloneDeep } from 'lodash';
import { NFL_CORRELATION_DEFAULTS } from 'src/app/pages/portal/job-creator/job-constants/NFL/nfl-option-defaults';

import setJobOptions from 'src/app/pages/portal/job-creator/job-utilities/set-job-options';

import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'nfl-sim-options',
  templateUrl: './nfl-sim-options.component.html',
  styleUrls: ['./nfl-sim-options.component.scss']
})
export class NflSimOptionsComponent {
  @Input() jobData: any;
  @ViewChild('corrPresetList') corrPresetList: ElementRef;

  user: any;

  totalSimulations: number;

  activeGroup: string = 'settings';

  availablePresets: any;
  isDefaultApplied: boolean;
  presetName: string;
  selectedPreset: any = null;
  showPresets: boolean;
  showSavePreset: boolean;
  deletePresetIndex: any;
  isMakingPresetDefault: boolean;
  defaultPresetExists: boolean;
  makePresetDefaultIndex: any;
  isSavingPreset: boolean;
  isDeletingPreset: boolean;
  valuesDirty: boolean;

  siteSalaryMax: any;
  isSalaryMaxEntryLimit: boolean;

  searchablePlayers: any;

  // CORR PRESETS
  correlationPresets: any = [];
  selectedCorrelationPreset: any;
  newCorrelationPresetName: string;
  isSavingCorrelationPreset: boolean;
  isMakingCorrelationPresetDefault: boolean;
  makingCorrelationIndex: any;
  isDeletingCorrelationPreset: boolean;
  deletingCorrelationIndex: any;
  showCorrelationPresets: boolean;
  showSaveCorrelationPreset: boolean;
  defaultCorrelationPresetExists: boolean;
  isDefaultCorrelationPresetApplied: boolean;
  isDefaultUpdated: boolean;
  existingDefaultCorrelationValues: any;

  correlations: any = [];
  correlationDefaults = NFL_CORRELATION_DEFAULTS;
  showAddCorrelation: boolean;
  correlationError: string;
  selectedCorrelationValues: any;
  selectedCorrelationPlayer: any;

  playerCorrelations: any = [];
  showAddPlayerCorrelation: boolean;
  playerCorrelationError: string;
  selectedPlayerCorrelationPlayer: any;
  selectedPlayerCorrelationPlayerSet: any = [];

  showImportModal: boolean = false;
  importMode: string = null;

  teamStackConfig: any;
  updatedTeamStackCount: any;
  previousSlateId: any;

  constructor(
    private jobsService: JobsService,
  ) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
      // Prevent the wheel event for number inputs
      event.preventDefault();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (this.showCorrelationPresets && !this.isDeletingCorrelationPreset && !this.isMakingCorrelationPresetDefault && !this.corrPresetList.nativeElement.contains(event.target)) {
      this.showCorrelationPresets = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['jobData'] && changes['jobData'].currentValue) {
      this.checkSlateIdChange();
    }
  }

  ngDoCheck() {
    this.checkSlateIdChange();
  }

  private checkSlateIdChange() {
    if (this.previousSlateId !== this.jobData?.slateId) {
      const isSecondaryLoad = this.previousSlateId != null;
      if (this.jobData.options.teamStackConfig) {
        this.teamStackConfig = this.jobData.options.teamStackConfig;
      } else {
        this.generateTeamStackConfig();
      }
      this.previousSlateId = this.jobData.slateId;
    }
  }

  ngOnInit(): void {
    const auth = getAuth();
    this.user = auth.currentUser;
    this.getUserOptionPresets({ skipMatch: false, setDefaults: false });
    this.correlations = this.jobData.customCorrelations || [];
    this.playerCorrelations = this.jobData.playerCorrelations || [];
    this.mapDraftGroupData();
  }

  generateTeamStackConfig() {
    const uniqueTeams = [];

    this.jobData.draftGroup.forEach((player) => {
      const team = {
        name: player.Team,
        value: 100,
      };
      const teamExists = uniqueTeams.some(existingTeam => existingTeam.name === team.name);
      if (!teamExists) {
        uniqueTeams.push(team);
      }
    });

    this.teamStackConfig = uniqueTeams;
    this.jobData.options.teamStackConfig = uniqueTeams;
  }

  mapDraftGroupData() {
    const searchData = this.jobData.draftGroup.map(player => ({
      name: player.Name,
      id: player.PlayerId,
      ...(player.Position ? { position: player.Position } : {}),
    }));
    this.searchablePlayers = searchData;
  };

  ngAfterContentInit() {

    if (this.jobData?.contest?.maximumEntries > 100000) {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 49500; // UPDATE FOR PGA
      } else {
        this.siteSalaryMax = 59500; // UPDATE FOR PGA
      }
      this.isSalaryMaxEntryLimit = true;
    } else {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 50000; // UPDATE FOR PGA
      } else {
        this.siteSalaryMax = 60000; // UPDATE FOR PGA
      }
    }

  }

  onValueChange(options) {
    if (options.userChange) {
      this.valuesDirty = true;
    }

    const isValid = this.validateOptionValues();
    if (isValid) {
      this.jobData.optionsValid = true;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    } else {
      this.jobData.optionsValid = false;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    }
  };

  validateOptionValues() {
    const conditions = [
      { condition: this.jobData.options.projectionMinimum < 0 || this.jobData.options.projectionMinimum == null },
      { condition: this.jobData.options.randomness < 0 || this.jobData.options.randomness > 1000 || this.jobData.options.randomness == null },
      { condition: this.jobData.options.minLineupSalary < 0 || this.jobData.options.minLineupSalary > this.siteSalaryMax || this.jobData.options.minLineupSalary == null },
      { condition: this.jobData.contestType === '6' && (this.jobData.options.pctFieldUsingStacks < 1 || this.jobData.options.pctFieldUsingStacks == null || this.jobData.options.pctFieldUsingStacks > 100) },
      { condition: this.jobData.contestType === '6' && (this.jobData.options.pctFieldDoubleStacks < 1 || this.jobData.options.pctFieldDoubleStacks > 100 || this.jobData.options.pctFieldDoubleStacks == null) },
      { condition: this.jobData.options.maxPctOffOptimal < 1 || this.jobData.options.maxPctOffOptimal > 100 || this.jobData.options.maxPctOffOptimal == null },
      { condition: this.jobData.options.defaultQbVar < 1 || this.jobData.options.defaultQbVar > 100 || this.jobData.options.defaultQbVar == null },
      { condition: this.jobData.options.defaultSkillposVr < 1 || this.jobData.options.defaultSkillposVr > 100 || this.jobData.options.defaultSkillposVr == null },
      { condition: this.jobData.options.defaultDefVar < 1 || this.jobData.options.defaultDefVar > 100 || this.jobData.options.defaultDefVar == null },
      { condition: this.jobData.options.numPlayersVsDef < 0 || this.jobData.options.numPlayersVsDef > 8 || this.jobData.options.numPlayersVsDef == null }, // NEW BELOW
      { condition: this.jobData.options.lineupGenerations < 1 || this.jobData.options.lineupGenerations > 25 || this.jobData.options.lineupGenerations == null },
      { condition: this.jobData.options.totalSimulations < 100 || this.jobData.options.totalSimulations > 100000 || this.jobData.options.totalSimulations == null },
      { condition: this.jobData.options.topLineupsPct < 0 || this.jobData.options.topLineupsPct > 100 || this.jobData.options.topLineupsPct == null },
    ];

    const isInvalid = conditions.some(({ condition }) => condition);

    return !isInvalid;
  }

  getUserOptionPresets(options) {
    this.jobsService.getJobOptionPresetsByUserId(this.jobData.userId).then((data) => {
      if (data) {
        const presets = data;
        const applicablePresets = presets.filter((set) =>
          set.data.sport === this.jobData.sport
          && set.data.jobType === this.jobData.jobType &&
          set.data.jobSubType === this.jobData.jobSubType)
        this.availablePresets = applicablePresets.sort((a, b) => {
          return b.data.updatedAt - a.data.updatedAt;
        });
        this.defaultPresetExists = applicablePresets.some((set) => set.data.isDefault);
        if (this.jobData.optionPresetId && !options.skipMatch) {
          const jobPreset = this.availablePresets.find((set) => set.id === this.jobData.optionPresetId);
          if (jobPreset !== undefined) {
            this.presetName = jobPreset.data.name;
            this.onSelectOptionPreset(jobPreset.data, jobPreset.id);
          } else {
            this.presetName = 'Preset Deleted - Data Still Applied'
          }
        } else if (this.defaultPresetExists && !options.skipMatch) {
          const defaultPreset = applicablePresets.find((set) => set.data.isDefault);
          this.onSelectOptionPreset(defaultPreset.data, defaultPreset.id);
          this.isDefaultApplied = true;
          setTimeout(() => {
            this.isDefaultApplied = false;
          }, 4200);
        } else if (!options.skipMatch) {
          this.presetName = 'Default Options';
          if (options?.setDefaults !== false) {
            this.onClickRestoreDefaults();
          }
        }
      }
    })
  }

  onSelectOptionPreset(preset, id) {
    this.presetName = preset.name;
    this.selectedPreset = {
      id: id,
      preset: preset,
    };
    const newValues = preset.presetValues;

    // Merge newOptions with jobData.options, using existing values for missing keys
    const newOptions = Object.assign({}, this.jobData.options, newValues);
  
    this.jobData.optionPresetId = id;
    this.jobData.options = newOptions;
  
    this.onValueChange({ userChange: false });
    this.showPresets = false;
  }

  onClickShowSave() {
    this.showSavePreset = true;
  }

  onCancelSavePreset() {
    this.showSavePreset = false;
  }

  onRemovePreset(id, index) {
    if (this.isDeletingPreset) {
      return;
    }
    this.deletePresetIndex = index;
    this.isDeletingPreset = true;
    this.jobsService.deleteOptionPresetById(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletePresetIndex = null;
          this.isDeletingPreset = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onClickSetPresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: true,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.isMakingPresetDefault = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onSaveJobPreset(name) {
    this.isSavingPreset = true;
  
    const presetValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});
  
    const preset = {
      isDefault: false,
      presetValues: presetValues,
      name: name,
    };
  
    this.jobsService.createJobOptionPreset(
      this.jobData.userId,
      this.jobData.sport,
      this.jobData.jobType,
      this.jobData.jobSubType,
      preset,
    ).then((res) => {
      if (res) {
        this.presetName = name;
        this.valuesDirty = false;
        this.selectedPreset = {
          id: res,
          preset: preset,
        };
  
        this.jobData.optionPresetId = res;
        this.jobsService.saveJobDataSnapshot(this.jobData);
        setTimeout(() => {
          this.getUserOptionPresets({ skipMatch: true });
          this.showSavePreset = false;
          this.isSavingPreset = false;
        }, 1000);
      }
    });
  }
  
  onClickUpdateExistingPreset() {
    this.isSavingPreset = true;

    const newValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});

    this.jobsService.updateJobOptionPresetValues(this.jobData.userId, this.selectedPreset.id, newValues).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.isSavingPreset = false;
          this.valuesDirty = false;
          this.getUserOptionPresets({ skipMatch: true, setDefaults: false });
        }, 700);
      }
    })
  }

  onClickRemovePresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: false,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
        setTimeout(() => {
          this.isMakingPresetDefault = false;
        }, 800)
      }
    })
  }

  onClickShowPresets() {
    if (this.showPresets) {
      return;
    }
    setTimeout(() => {
      this.showPresets = true;
    }, 200);
  }

  async onClickRestoreDefaults() {
    this.presetName = 'Default Options';
    this.selectedPreset = null;
    this.showPresets = false;

    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;
    this.jobData.options.teamStackConfig = this.teamStackConfig;
    this.jobData.optionPresetId = null;

    this.onValueChange({ userChange: false });
  }

  preventScroll(event: WheelEvent) {
    event.preventDefault();
  }

  onToggleGroup(group) {
    this.activeGroup = group;
  }

  // IMPORTS

  onClickImportCorrlations() {
    this.showImportModal = true;
    this.importMode = 'customCorrelations';
  }

  handleImportCorrelations(corrs) {
    corrs.forEach((c) => {
      if (this.correlations.some((p) => p.name === c.name)) {
        console.log('Rule already exists with player name');
      } else {
        this.correlations.push(c);
      }
    });
    this.jobData.customCorrelations = this.playerCorrelations;
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.handleCloseImportModal();
  }


  onClickImportPlayerCorrlations() {
    this.showImportModal = true;
    this.importMode = 'playerCorrelations';
  }

  handleImportPlayerCorrelations(corrs) {
    corrs.forEach((c) => {
      if (this.playerCorrelations.some((p) => p.name === c.name)) {
        console.log('Rule already exists with player name');
      } else {
        this.playerCorrelations.push(c);
      }
    })
    this.jobData.playerCorrelations = this.playerCorrelations;
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.handleCloseImportModal();
  }

  handleCloseImportModal() {
    this.showImportModal = false;
    this.importMode = null;
  }

  // CORR PRESETS LOGIC

  onClickShowCorrelationPresets() {
    if (this.showCorrelationPresets) {
      return;
    }
    setTimeout(() => {
      this.showCorrelationPresets = true;
    }, 200);
  }

  onClickShowSaveCorrelation() {
    this.showSaveCorrelationPreset = true;
  }

  onCancelCorrelationPreset() {
    this.showSaveCorrelationPreset = false;
    this.newCorrelationPresetName = '';
  }

  onSaveCorrelationPreset() {
    this.isSavingCorrelationPreset = true;
    const preset = {
      isDefault: false,
      presetValues: this.selectedCorrelationValues,
      name: this.newCorrelationPresetName,
    }
    this.jobsService.createJobCorrelationPreset(
      this.jobData.userId,
      this.jobData.sport,
      this.jobData.jobType,
      this.jobData.jobSubType,
      preset,
    ).then((res) => {
      if (res) {
        this.selectedCorrelationPreset = preset;
        this.jobData.optionPresetId = res;
        this.jobsService.saveJobDataSnapshot(this.jobData);
        setTimeout(() => {
          this.getUserCorrelationPresets({ skipMatch: true });
          this.showSaveCorrelationPreset = false;
          this.isSavingCorrelationPreset = false;
          this.newCorrelationPresetName = ''
        }, 1000);
      }
    })
  }

  getUserCorrelationPresets(options) {
    this.jobsService.getJobCorrelationPresetsByUserId(this.jobData.userId, this.jobData.sport).then((data) => {
      if (data !== null) {
        const presets = data;
        this.correlationPresets = presets.sort((a, b) => {
          return b.data.updatedAt - a.data.updatedAt;
        });
        this.defaultCorrelationPresetExists = presets.some((set) => set.data.isDefault);
        if (this.defaultCorrelationPresetExists) {
          const defaultPreset = presets.find((set) => set.data.isDefault);
          this.onSelectCorrelationPreset(defaultPreset.data, defaultPreset.id);
          this.isDefaultCorrelationPresetApplied = true;
          this.existingDefaultCorrelationValues = defaultPreset.data.presetValues;
          setTimeout(() => {
            this.isDefaultCorrelationPresetApplied = false;
          }, 4200);
        } else if (!options.skipMatch) {
          const defaultData = {
            name: 'Default Values',
          }
          this.selectedCorrelationPreset = defaultData;
          this.onClickRestoreCorrelationDefaults();
        }
      }
    })
  }

  onSelectCorrelationPreset(preset, id) {
    this.selectedCorrelationPreset = preset;
    this.selectedCorrelationValues = cloneDeep(preset.presetValues);
    this.showCorrelationPresets = false;
  }

  onClickRestoreCorrelationDefaults() {
    if (this.selectedCorrelationPlayer === null) {
      return;
    }
    let defaults = [];
    switch (this.selectedCorrelationPlayer.position) {
      case 'QB':
        defaults = this.correlationDefaults.QB;
        break;
      case 'RB':
        defaults = this.correlationDefaults.RB;
        break;
      case 'WR':
        defaults = this.correlationDefaults.WR;
        break;
      case 'TE':
        defaults = this.correlationDefaults.TE;
        break;
      case 'DST':
        defaults = this.correlationDefaults.DST;
        break;
      default:
        this.correlationError = 'Player Not Available for Correlation';
        return;
    }
    this.showCorrelationPresets = false;
    this.selectedCorrelationValues = cloneDeep(defaults);

  }

  onClickSetCorrelationPresetDefault(id, index, preset) {
    if (this.isMakingCorrelationPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: true,
    }
    this.makingCorrelationIndex = index;
    this.isMakingCorrelationPresetDefault = true;
    this.jobsService.updateJobCorrelationPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.isDefaultUpdated = true;
          this.makingCorrelationIndex = null;
          this.isMakingCorrelationPresetDefault = false;
          this.getUserCorrelationPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onClickRemoveCorrelationPresetDefault(id, index, preset) {
    if (this.isMakingCorrelationPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: false,
    }
    this.makingCorrelationIndex = index;
    this.isMakingCorrelationPresetDefault = true;
    this.jobsService.updateJobCorrelationPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makingCorrelationIndex = null;
          this.isDefaultUpdated = true;
          this.getUserCorrelationPresets({ skipMatch: true });
        }, 700);
        setTimeout(() => {
          this.isMakingCorrelationPresetDefault = false;
        }, 800)
      }
    })
  }

  onRemoveCorrelationPreset(id, index) {
    if (this.isDeletingCorrelationPreset) {
      return;
    }
    this.deletingCorrelationIndex = index;
    this.isDeletingCorrelationPreset = true;
    this.jobsService.deleteCorrelationPresetById(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletingCorrelationIndex = null;
          this.isDeletingCorrelationPreset = false;
          this.getUserCorrelationPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  // PLAYER CORRELATIONS

  onClickAddPlayerCorrelation() {
    this.selectedPlayerCorrelationPlayer = null;
    this.selectedPlayerCorrelationPlayerSet = [];
    this.showAddPlayerCorrelation = true;
  }

  onSelectPlayerCorrelationPlayer(player) {
    this.playerCorrelationError = null;
    this.selectedPlayerCorrelationPlayer = player;
  }

  validatePlayerCorrValue(value) {
    if (value == null || value > 1 || value < -1) {
      this.playerCorrelationError = 'Invalid Values.  Correlation values should be between -1 and 1'
    } else {
      this.playerCorrelationError = null;
    }
  }

  onClickAddPlayerToCorrelationSet(player) {
    this.playerCorrelationError = '';
    const playerExists = this.selectedPlayerCorrelationPlayerSet.some(p => p.id === player.id);
    if (playerExists) {
      this.playerCorrelationError = 'Player Already Exists';
      return;
    }

    const playerMatches = this.selectedPlayerCorrelationPlayer.id === player.id;
    if (playerMatches) {
      this.playerCorrelationError = 'You cannot select the initial player';
      return;
    }

    const newPlayer = {
      id: player.id,
      name: player.name,
      value: 0,
    }
    this.selectedPlayerCorrelationPlayerSet.push(newPlayer);
  }

  onClickSavePlayerCorrelation() {
    const newPlayerCor = {
      name: this.selectedPlayerCorrelationPlayer.name,
      id: this.selectedPlayerCorrelationPlayer.id,
      correlations: this.selectedPlayerCorrelationPlayerSet,
    }
    this.playerCorrelations.push(newPlayerCor);
    this.jobData.playerCorrelations = this.playerCorrelations;
    this.jobsService.saveJobDataSnapshot(this.jobData);

    this.selectedPlayerCorrelationPlayer = null;
    this.selectedPlayerCorrelationPlayerSet = [];
    this.showAddPlayerCorrelation = false;
  }

  onDeletePlayerFromCorrelationSet(index) {
    this.selectedPlayerCorrelationPlayerSet.splice(index, 1);
  }

  onClickRemovePlayerCorrelation(index) {
    this.playerCorrelations.splice(index, 1);
    this.jobData.playerCorrelations = this.playerCorrelations;
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  onClickCancelPlayerCorrelation() {
    this.showAddPlayerCorrelation = false;
  }

  // CUSTOM CORRELATIONS

  onClickAddCorrelation() {
    this.selectedCorrelationValues = null;
    this.selectedCorrelationPlayer = null;
    this.showAddCorrelation = true;
    if (this.correlationPresets.length == 0 || this.isDefaultUpdated) {
      this.isDefaultUpdated;
      const options = {
        skipMatch: false,
      }
      this.getUserCorrelationPresets(options);
    }
  }

  onSelectCorrelationPlayer(data) {
    this.correlationError = null;
    let defaults: any;

    const playerExists = this.correlations.some(correlation => correlation.name === data.name);
    if (playerExists) {
      this.correlationError = 'Player Already Exists';
      return;
    }

    if (this.defaultCorrelationPresetExists) {
      this.selectedCorrelationPlayer = data;
      const newValues = this.existingDefaultCorrelationValues;
      this.selectedCorrelationValues = cloneDeep(newValues);
      return;
    }

    switch (data.position) {
      case 'QB':
        defaults = this.correlationDefaults.QB;
        break;
      case 'RB':
        defaults = this.correlationDefaults.RB;
        break;
      case 'WR':
        defaults = this.correlationDefaults.WR;
        break;
      case 'TE':
        defaults = this.correlationDefaults.TE;
        break;
      case 'DST':
        defaults = this.correlationDefaults.DST;
        break;
      default:
        this.correlationError = 'Player Not Available for Correlation';
        return;
    }

    this.selectedCorrelationPlayer = data;
    this.selectedCorrelationValues = cloneDeep(defaults);
  }

  validateCorrValue(value) {
    if (value == null || value > 1 || value < -1) {
      this.correlationError = 'Every field needs a value between -1 and 1'
    } else {
      this.correlationError = null;
    }
  }

  onClickSaveCorrelation() {
    const newCorrelation = this.selectedCorrelationValues;
    const newRecord = {
      ...this.selectedCorrelationPlayer,
      correlations: newCorrelation
    }
    this.correlations.push(newRecord);
    this.selectedCorrelationPlayer = null;
    this.showAddCorrelation = false;
    this.jobData.customCorrelations = this.correlations;
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }


  onClickRemoveCorrelation(index) {
    this.correlations.splice(index, 1);
    this.jobData.customCorrelations = this.correlations;
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }

  onClickCancelCorrelation() {
    this.showAddCorrelation = false;
  }

  processCSVData(csvData: string): void {
    // Split the CSV data into lines
    const lines = csvData.split('\n');

    // Process each line
    this.updatedTeamStackCount = 0; // Reset the count
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.length === 0) continue; // Skip empty lines

      const values = line.split(',').map(value => value.trim());
      if (values.length < 2) continue; // Skip lines that don't have enough columns

      const teamAbbreviation = values[0];
      const percentage = parseFloat(values[1]);

      // Find the team in the existing data
      const team = this.jobData.options.teamStackConfig.find(t => t.name === teamAbbreviation);

      if (team) {
        team.value = percentage;
        this.updatedTeamStackCount++;
      }
    }
    this.jobsService.saveJobDataSnapshot(this.jobData);
    setTimeout(() => {
      this.updatedTeamStackCount = null;
    }, 4000);
  }

  async handleTeamStackFile(file: File): Promise<void> {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csvData = e.target.result;
      console.log(csvData);
      this.processCSVData(csvData);

    };
    reader.readAsText(file);
  }

  downloadCSV(): void {
    const csvContent = this.convertToCSV(this.jobData.options.teamStackConfig);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'Team-Stack-Config-Template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // Convert team data to CSV format
  convertToCSV(data: { name: string; value: number }[]): string {
    const header = 'name,value';
    const rows = data.map(item => `${item.name},${item.value}`);
    return [header, ...rows].join('\n');
  }

  onToggleKeepUserLineups(event) {
    this.jobData.options.keepUserLineups = event;
  }

}
