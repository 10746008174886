<div class="ss-account-content">
    <div class="ss-account-header-wrapper">
        <div class="ss-account-header" *ngIf="!isDeletingUser">
            My Account
        </div>
        <div class="ss-account-header" *ngIf="isDeletingUser">
            Good <span class="ss-header-highlight">Bye</span>
        </div>
    </div>
    <div *ngIf="user && !isDeletingUser" class="ss-account-wrapper">
        <div *ngIf="isLoading" class="account-loader">
            <div class="ss-spinner"></div>
        </div>

        <div class="account-detail">

            <div class="account-avatar">
                <img class="avatar-img" *ngIf="user.photoURL" src="{{user.photoURL}}">
                <svg-icon *ngIf="!user.photoURL" src="/assets/illustrations/avatar.svg"></svg-icon>
            </div>
            <div *ngIf="!isFileUploading" class="avatar-edit-chip" (click)="onSelectPhoto()">
                <svg-icon src="/assets/icons/camera.svg"></svg-icon>
            </div>
            <input class="avatar-input" id="photo-input" type="file" (change)="uploadImage($event.target.files)"
                style="transform: translate(32px, -36px);" hidden />
            <div class="upload-progress" *ngIf="fileUploadProgress && isFileUploading">{{fileUploadProgress | number:
                '1.0-0'}}%</div>
            <div class="ss-form-group">
                <input class="ss-input expanded bordered" type="text" autocomplete="off" placeholder="Your Name"
                    [(ngModel)]="accountDisplayName" maxlength="40" />
            </div>

            <div class="ss-form-group">
                <input class="ss-input expanded bordered" type="email" autocomplete="off"
                    placeholder="you@something.com" disabled autocomplete="username" [(ngModel)]="user.email" />
            </div>

            <div class="email-verify-wrapper" *ngIf="user">
                <div class="verified-email" *ngIf="user.emailVerified">
                    Email Verified <span *ngIf="user.providerData && user.providerData[0].providerId !== 'password'">by
                        Provider: {{user.providerData[0].providerId}}</span>
                </div>
                <div class="non-verified-email" *ngIf="!user.emailVerified && !verifyEmailSent">
                    Email Not Verified - <a (click)="resendVerificationEmail()">Resend Email</a>
                </div>
                <div class="verified-email" *ngIf="verifyEmailSent">
                    Sent - Check Your Email
                </div>
            </div>

            <button *ngIf="(accountDisplayName !== user.displayName && accountDisplayName.length > 1)"
                class="ss-primary-btn mt32" (click)="onSaveProfileChanges()">Save Changes</button>
        </div>

        <div class="subscription-detail">

            <div class="flex mt32" style="width: 100%; min-height: 22rem; justify-content: center;"
                *ngIf="!showSubscription">
                <div class="ss-spinner passive"></div>
            </div>

            <div class="subscription-info-wrapper" *ngIf="showSubscription">
                <div class="subscription-label">Your Subscription</div>

                <app-message-prompt *ngIf="canceledPlanAvailableUntilDate" type="warning" mb="24px"
                    messageTitle="Canceled"
                    messageBody="Your current plan was canceled. You can continue to use services until: {{canceledPlanAvailableUntilDate}}">
                </app-message-prompt>

                <div class="ss-subscription-widget" *ngIf="subscriptionDetail">
                    <div class="header">
                        <div class="tier">
                            {{subscriptionDetail.name}}
                        </div>
                    </div>

                    <div class="description">
                        {{subscriptionDetail.description}}
                    </div>

                    <div class="price-row">
                        <div class="price-feature">
                            <div *ngIf="!showAlternatePricing" class="price">
                                {{subscriptionDetail.price}}
                            </div>
                            <div *ngIf="subscriptionDetail.alternatePricing && showAlternatePricing" class="price">
                                {{subscriptionDetail.alternatePricing.price}}
                            </div>
                            <div class="frequency">
                                {{subscriptionDetail.frequency}}
                            </div>
                            <div *ngIf="userSubscription === 'ACE_BASIC_YEARLY' || userSubscription === 'ACE_PRO_YEARLY' "
                                class="frequency" style="line-height: 1; opacity: 0.5">
                                {{subscriptionDetail.alternatePricing.yearlyPrice}} Billed Annually</div>
                        </div>
                    </div>

                    <div *ngIf="subscriptionDetail.showUpgrade" style="margin-bottom:24px"></div>
                    <button *ngIf="subscriptionDetail.showManage" class="ss-secondary-btn subscribe-btn"
                        (click)="onManageSubscription()" [disabled]="isPreparingPortal">
                        <span *ngIf="!isPreparingPortal">Manage Subscription</span>
                        <div class="ss-spinner btn-spinner" *ngIf="isPreparingPortal"></div>
                    </button>

                    <div class="benefits">
                        <div class="feature" *ngFor="let b of subscriptionDetail.benefits">
                            <div class="icon">
                                <svg-icon [svgStyle]="{ 'height.px':16 }"
                                    src="/assets/icons/check-square.svg"></svg-icon>
                            </div>
                            <div class="name">
                                {{b}}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="ss-subscription-widget mt24" *ngIf="isSingleSport">
                    <div class="change-sport-wrapper">

                        <section *ngIf="subscribedSport !== null">
                            <div class="change-title">Current Sport</div>
                            <div class="change-desc">Your sport is locked as: {{subscribedSport}} until
                                {{subscribedSportEndDate}}. When the season ends, the button below will activate.</div>
                            <button class="ss-secondary-btn change-sport-btn" (click)="onClickUnlockSport()"
                                [disabled]="!isEligibleForSportChange || isUnlocking">
                                <span *ngIf="!isEligibleForSportChange">Not Eligible for Change</span>
                                <span *ngIf="isEligibleForSportChange">Unlock My Sport</span>
                            </button>
                        </section>

                        <section *ngIf="subscribedSport === null">
                            <div class="change-title">No Sport Locked In</div>
                            <div class="change-desc">Start any Sim or Opto job to lock in your Sport for 30 days. Choose
                                wisely, this cannot be changed once locked in.</div>
                        </section>

                    </div>
                </div>



            </div>

        </div>

        <div class="account-links">
            <div class="links-row">
                <div class="link-label">Need Help</div>
                <a class="link" href="https://discord.gg/D3hktW23Sc">
                    Collaborate on Discord
                </a>
            </div>

            <!-- <div class="links-row">
                <div class="link-label">Have an idea or feedback?</div>
                <a class="link"
                    href="mailto:support@acemind.io?subject=Feedback%20is%20Like%20Water&body=It%20makes%20things%20grow%20and%20we%20don't%20get%20enough%20of%20it%20each%20day!%20Let%20us%20know%20what%20is%20on%20your%20mind.%20%0A%0A-Team%20AddPass">
                    Let Us Know!
                </a>
            </div> -->

            <div class="links-row">
                <div class="link-label">The Legal Stuff</div>
                <a class="link" href="https://app.acemind.io/terms" routerLink="/terms">Terms of Use</a>
                <a class="link" href="https://app.acemind.io/privacy" routerLink="/privacy">Privacy</a>
            </div>

            <div class="links-row">
                <a *ngIf="!showDeleteWarning && !showDeleteConfirmation && !isDeletingUser" class="link red"
                    (click)="onClickDeleteAccount()">Delete My Account</a>
                <a *ngIf="showDeleteWarning && !showDeleteConfirmation && !isDeletingUser" class="link red"
                    (click)="onClickDeleteWarning()">If you really mean it, click me again.</a>
                <a *ngIf="!showDeleteWarning && showDeleteConfirmation && !isDeletingUser" class="link red"
                    (click)="onClickDeleteConfirmation()">This click will delete your account right now!</a>
            </div>
        </div>


    </div>

    <div class="ss-modal-bg" *ngIf="authenticationRequired">
        <div class="modal-container">
            <div class="message mb16">
                For security reasons, we need you to authenticate once more before this account can be deleted. Then you
                will be able to remove the account.
            </div>

            <a class="link" (click)="onReAuth()">Re-Authenticate</a>
        </div>
    </div>

    <div class="ss-modal-bg danger" *ngIf="showDeleteWarning && !isDeletingUser">
        <div class="destructive-confirm" *ngIf="showDeleteWarning && !showDeleteConfirmation && !isDeletingUser">
            <div class="warning-header">Woah There...</div>
            <div class="warning-text">
                You are about to delete your account and all the beautiful things within it...
            </div>
            <button class="ss-primary-btn warning mt32" (click)="onClickDeleteWarning()">Yep, Still Want To</button>
            <div class="cancel-text" (click)="onCancelDeleteWarning()">Cancel</div>
        </div>
        <div class="destructive-confirm" *ngIf="showDeleteWarning && showDeleteConfirmation && !isDeletingUser">
            <div class="warning-header">I can't watch...</div>
            <div class="warning-text">
                Pressing this button will immediately sign you out and remove your account from acemind.
            </div>
            <button class="ss-primary-btn warning mt32" (click)="onClickDeleteConfirmation()">Totally Still Want
                To</button>
            <div class="cancel-text" (click)="onCancelDeleteWarning()">Cancel</div>
        </div>
    </div>
</div>