<import-widget
*ngIf="showSniper"
title="Ownership"
[subTitle]="sniperMessage"
[isLoading]="isLoading"
[showFix]="!isKeyValid"
fixMessage="Select the column containing player's Ownership value"
[availableKeys]="keysToSelect"
[isMappingValid]="isKeyValid"
(handleMappingSelected)="selectKey($event)"
(handleEditMapping)="onClickChangeKey()"
[isCustomMapping]="isCustomMapping"
(handleRemoveCustomMapping)="handleRemoveCustomMapping()"
>
</import-widget>

<!-- [isOptional]="isOwnershipOptional" -->