import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MLB_TEAM_LOGO_IDS } from 'src/app/constants/team-images/MLB/mlb-team-image-map';
import { NFL_TEAM_LOGO_IDS } from 'src/app/constants/team-images/NFL/nfl-team-image-map';
import { StateService } from 'src/app/services/state.service';
import { debounce } from 'lodash'; 
import { getTeamColorNFL } from 'src/app/constants/team-colors/nfl-team-colors-dictionary';
import { DomSanitizer } from '@angular/platform-browser';
import { NFL_CORRELATION_DEFAULTS } from 'src/app/pages/portal/job-creator/job-constants/NFL/nfl-option-defaults';

@Component({
  selector: 'base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseTableComponent {
  @Input() columns: any;
  @Input() jobData: any = [];
  @Input() rows: any;
  @Input() allRowsSelected: boolean;
  @Input() selectable: boolean;
  @Input() tableLabel: any;
  @Input() tableLabelCount: any;
  @Input() searchKey: string;
  @Input() showDownload: boolean;
  @Input() showCopy: boolean;
  @Input() isCopyingData: boolean;
  @Input() showAdvancedHeader: boolean;
  @Input() showImport: boolean = false;
  @Input() exposureMap: any = null;
  @Input() showExposure: boolean = false;
  @Input() iLoadingData: boolean = false;
  @Input() hideToolsRow: boolean = false;
  @Input() featuredHeader: any = null;
  @Input() showPlayerBoost: boolean = false;
  @Input() maxHeightOverride: any = null;
  @Output() handleSelectAllRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleSelectSingleRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() hanleEditComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCopyTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editInput') editInput!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('tableWrapper') tableWrapper: ElementRef;

  sortColumn: string = '';
  sortDirection: string = 'asc';
  tableRows: any;
  searchValue: string;

  tooltipValue: string | undefined;
  showTooltipFlag: boolean = false;

  editingRow: number | null = null;
  editingColumn: string | null = null;

  inputValues: { [key: string]: string } = {};
  checkedExposures: number = 0;
  tableRendered: boolean = false;

  hoveredPlayer: string | null = null;
  exposureWidthCache: Map<string, number> = new Map<string, number>();

  isHandlingBoost: boolean;
  tableHighlightBoostId: any;
  tableHighlightLimitId: any;

  private subscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private sanitizer: DomSanitizer,
  ) {
    this.subscription = this.stateService.projectionDraftGroup$.subscribe(
      value => {
        // console.log('VALUE PASSED')
        if (value.length) {
          // console.log('VALUE OF LENGTH')
          this.rows = value;
          // this.cdr.detectChanges();
        }
      }
    );
  }

  ngOnInit() {
    if (this.showPlayerBoost && this.columns.every((c) => c.prop !== 'Boost')) {
      const boostColumn =   { prop: 'Boost', name: 'Boost', tooltip: 'Apply a 10% boost or limit to player projection. Correlations auto applied to other players' };
      this.columns.splice(2, 0, boostColumn);
    }
  }

  ngAfterContentInit() {
    this.tableRendered = true;
    // this.cdr.detectChanges();
  }

  trackById(index: number, item: any): any {
    return item.playerId; // or any unique identifier for the item
  }

  capitalizeAndBreakLines(text: string): string {
    return text.replace(/\)/g, ')<br>');
  }

  removeFrontId(text: string): string {
    return text.replace(/^\d+-\d+:(.*)$/, '$1');
  }

  selectAllRows(data) {
    this.handleSelectAllRows.emit(data);
  }

  toggleRowSelection(row) {
    this.handleSelectSingleRow.emit(row);
  }

  handleTableDownload() {
    this.downloadTable.emit();
  }

  handleTableCopy() {
    this.handleCopyTable.emit();
  }

  enableEdit(row: any, column: string) {
    this.editingRow = row;
    this.editingColumn = column;

    setTimeout(() => {
      this.editInput.nativeElement.focus();
      this.editInput.nativeElement.select();
    }, 100);
  }

  onEditComplete() {
    this.editingRow = null;
    this.editingColumn = null;
    this.hanleEditComplete.emit()
  }

  toggleSort(column: any) {
    this.jobData.currentSort = null;
    this.inputValues = {};
    if (column?.disableSort) {
      return;
    }
    this.tableWrapper.nativeElement.scrollTop = 0;
    if (this.searchKey && column !== this.searchKey && !this.hideToolsRow) {
      this.searchValue === null;
      this.searchInput.nativeElement.value = '';
    }
    if (this.sortColumn === column.prop) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column.prop;
      this.sortDirection = 'asc';
    }

    this.rows.sort((a, b) => {
      const aValue = a[this.sortColumn];
      const bValue = b[this.sortColumn];

      if (aValue < bValue) return this.sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
    // this.cdr.detectChanges();
  }

  debouncedHandleSortQuery = debounce((event) => {
    this.sortQuery(event);
  }, 300);

  handleSortQuery(event) {
    this.debouncedHandleSortQuery(event);
  }

  sortQuery(event) {
    this.toggleSort(this.searchKey)
    const query = event;

    // Sort the rows array by fuzzy matching the name property
    this.rows.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a[this.searchKey], query);
      const similarityB = this.calculateFuzzyMatchScore(b[this.searchKey], query);

      return similarityB - similarityA;
    });
  }

  handleColumnSortSearch(event, column) {
    this.sortColumn = column;
    this.sortDirection = 'desc';
    const query = event.target.value;

    this.inputValues[column] = event.target.value;

    this.rows.sort((a, b) => {
      const similarityA = this.calculateFuzzyMatchScore(a[column], query);
      const similarityB = this.calculateFuzzyMatchScore(b[column], query);

      return similarityB - similarityA;
    });

    Object.keys(this.inputValues).forEach(key => {
      if (key !== column) {
        this.inputValues[key] = null;
      }
    });
    // this.cdr.detectChanges();
  }

  calculateFuzzyMatchScore(str1, str2) {

    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    if (str1.includes(str2)) {
      return str2.length / str1.length;
    }

    return 0;
  }

  onClickImportData() {
    this.handleImportData.emit();
  }

  onChangeShowExposure(value) {
    this.showExposure = value;
  }

  hideTooltip(playerNameWithId: string) {
    if (this.exposureMap == null) { return; }
    if (this.hoveredPlayer === playerNameWithId) {
      this.showTooltipFlag = false;
      this.hoveredPlayer = null;
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showTooltipFlag = false;
    this.hoveredPlayer = null;
  }

  // ADJUST FOR OTHER SPORTS
  generateMatchupTemplate(matchupString: string): string {
    const [teams, datePart, timePart, timeZone] = matchupString.split(' ');
    const [awayTeam, homeTeam] = teams.split('@');
    let formattedTimePart = ''
    if (timePart) {
       formattedTimePart = timePart.startsWith('0') ? timePart.slice(1) : timePart;
    } else {
      return null
    }
    let awayTeamId = '';
    let homeTeamId = ''
    switch(this.jobData.sport) {
      case 'MLB':
        awayTeamId = MLB_TEAM_LOGO_IDS[awayTeam] || 'default-id';
        homeTeamId = MLB_TEAM_LOGO_IDS[homeTeam] || 'default-id';
        return `
        <div class="table-team-row">
        <img class="away" src="https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${awayTeamId}.svg" />
        <div class="middle-data">
        <div class="at-symbol">@</div>
        <div class="time">${formattedTimePart} ${timeZone}</div>
        </div>
        <img class="home" src="https://storage.googleapis.com/ace-mind-sport-logos/mlb-team-logos/${homeTeamId}.svg" />
        </div
      `;
      case 'NFL':
        awayTeamId = NFL_TEAM_LOGO_IDS[awayTeam] || 'default-id';
        homeTeamId = NFL_TEAM_LOGO_IDS[homeTeam] || 'default-id';
        return `
        <div class="table-team-row">
        <img class="away" src="https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${awayTeam}.png" />
        <div class="middle-data">
        <div class="at-symbol">@</div>
        <div class="time">${formattedTimePart} ${timeZone}</div>
        </div>
        <img class="home" src="https://storage.googleapis.com/ace-mind-sport-logos/nfl-team-logos/${homeTeam}.png" />
        </div
      `;


    }
  }

  // getColumnColor(row) {
  //   // const teamColor = getTeamColorNFL(row.Team);
  //   const teamColor = '#ed4758'
  //   const html = `<span class="table-team-chip" style="background: ${teamColor}">${row.Team}</span>&nbsp;&nbsp;`;
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  onClickBoost(row, index) {
    if (this.isHandlingBoost === true) { return };
    this.isHandlingBoost = true;
    const staticIndex = index;
    const selectedPlayerId = row.PlayerId;
    const selectedPlayer = this.jobData.draftGroup.find((p) => p.PlayerId === selectedPlayerId);
    if (selectedPlayer) {
      const baseValue = parseInt(selectedPlayer.Projection, 10);
      const boost = baseValue * 0.10;
      const newValue = baseValue + boost
      selectedPlayer.Projection = newValue.toFixed(2);
      const rowId = `table-row-${staticIndex}`;
      this.tableHighlightBoostId = rowId;
      this.handleCorrelationUpdates({isBoost: true}, selectedPlayer, row);
    }
  }

  onClickLimit(row, index) {
    if (this.isHandlingBoost === true) { return };
    this.isHandlingBoost = true;
    const staticIndex = index;
    const selectedPlayerId = row.PlayerId;
    const selectedPlayer = this.jobData.draftGroup.find((p) => p.PlayerId === selectedPlayerId);
    if (selectedPlayer) {
      const baseValue = parseInt(selectedPlayer.Projection, 10);
      const boost = baseValue * 0.10;
      const newValue = baseValue - boost
      selectedPlayer.Projection = newValue.toFixed(2);
      const rowId = `table-row-${staticIndex}`;
      this.tableHighlightLimitId = rowId;
      this.handleCorrelationUpdates({isBoost: false}, selectedPlayer, row);
    }
  }

  handleCorrelationUpdates(options, selectedPlayer, row) {
    const correlationData = this.getCorrelationDefaults();
    const [teams, datePart, timePart, timeZone] = row.GameInfo.split(' ');
    const [awayTeam, homeTeam] = teams.split('@');
    const isHomeTeamBoost = selectedPlayer.Team === homeTeam;

    let boostedTeam = null;
    let opposingTeam = null;
    if (isHomeTeamBoost) {
      boostedTeam = homeTeam;
      opposingTeam = awayTeam;
    } else {
      boostedTeam = awayTeam;
      opposingTeam = homeTeam;
    }

    this.jobData.draftGroup.forEach(player => {
      if (player.PlayerId === selectedPlayer.PlayerId) {
       player.Projection = player.Projection;
      } else {
        let correlation = 0;
        
        if (player.Team === boostedTeam) {
          // Same team correlation
          correlation = correlationData[selectedPlayer.Position].find(
            c => c.position === player.Position
          ).value;
        } else if (player.Team === opposingTeam) {
          // Opposing team correlation
          correlation = correlationData[selectedPlayer.Position].find(
            c => c.position === `Opp ${player.Position}`
          ).value;
        }
  
        if (options.isBoost) {
          player.Projection *= (1 + (correlation * 0.1));
        } else {
          player.Projection *= (1 - (correlation * 0.1));
        }
        player.Projection = Math.round(player.Projection * 100) / 100;
      }
    });

    setTimeout(() => {
      this.isHandlingBoost = false;
      this.tableHighlightBoostId = null;
      this.tableHighlightLimitId = null;
    }, 800);
  }



  getCorrelationDefaults() {
    switch(this.jobData.sport) {
      case 'NFL':
        return NFL_CORRELATION_DEFAULTS;
      case 'MLB':
        alert('MLB correlation defaults - update configuration');
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

