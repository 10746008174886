<import-widget
*ngIf="showSniper"
title="Mvp Ownership"
[subTitle]="sniperMessage"
[isLoading]="isLoading"
[showFix]="!isKeyValid"
fixMessage="Select the column containing player's Mvp Ownership value"
[availableKeys]="keysToSelect"
[isMappingValid]="isKeyValid"
(handleMappingSelected)="selectKey($event)"
(handleEditMapping)="onClickChangeKey()"
[isCustomMapping]="isCustomMapping"
(handleRemoveCustomMapping)="handleRemoveCustomMapping()"
>
</import-widget>