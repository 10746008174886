import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KNOWN_CPT_OWN_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'cptownership-sniper',
  templateUrl: './cptownership-sniper.component.html',
  styleUrls: ['./cptownership-sniper.component.scss']
})
export class CptownershipSniperComponent implements DoCheck, OnInit {
  @Input() draftGroup: any;
  @Input() csvData: any;
  @Input() playerNameKey: any;
  @Input() userProfile: any; 

  @Output() handleUnitValid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitInvalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeyAdditions: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() handleUpdateUserKeysToRemove: EventEmitter<any> = new EventEmitter<any>(); 

  showSniper: boolean = false;
  snipedValue: any;
  isManualSelection: boolean = false;
  keysToSelect: string[] = [];

  userHeaderMappings: any; 
  isCustomMapping: boolean = false; 

  sniperMessage: string = 'Analyzing...'
  isLoading: boolean = true;
  isKeyValid: boolean = false;
  previousIsKeyValid: boolean = false;

  fixOptions: any = [
    { name: 'Choose one of my columns', action: 'select-column'},
    { name: 'Skip and leave blank', action: 'skip'}
  ]

  constructor() { }

  ngDoCheck(): void {
    if (this.isKeyValid !== this.previousIsKeyValid) {
      if (this.isKeyValid) {
        this.handleUnitValid.emit('CptOwnership');
      } else {
        this.handleUnitInvalid.emit('CptOwnership');
      }
      this.previousIsKeyValid = this.isKeyValid;
    }
  }

  ngOnInit() {
    this.userHeaderMappings = this.userProfile?.importMappings?.cptOwnership || [];
  }

  snipeValue() {
    this.isKeyValid = false;
    this.isLoading = true;
    this.showSniper = true;

    setTimeout(() => {
      this.csvData.forEach((dataObj) => {
        const name = dataObj[this.playerNameKey].trim().toLowerCase();
        let value = null;

        const knownKeys = KNOWN_CPT_OWN_HEADERS.concat(this.userHeaderMappings); 

        const matchingKey = knownKeys.find(key => dataObj.hasOwnProperty(key));
    
        if (matchingKey) {
          if (this.userHeaderMappings.some((k) => k === matchingKey)) { this.isCustomMapping = true; }
          const rawValue = dataObj[matchingKey];
          if (rawValue !== null && rawValue !== undefined) {
            const parsedValue = parseFloat(rawValue);
            if (!isNaN(parsedValue)) {
              value = Number(parsedValue.toFixed(4));
            } else {
              value = 0;
            }
          }
          this.snipedValue = matchingKey;
        } else {
          this.snipedValue = null;
        }

        this.keysToSelect.push(...Object.keys(dataObj));

        if (value !== null) {
          this.isKeyValid = true;
          this.sniperMessage = `Column: ${this.snipedValue}`;

          const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
          if (match.bestMatch.rating > 0.5) {
            const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
            if (matchingProjectionObj) {
              matchingProjectionObj.CptnOwnership = value;
            }
          }

        } else {
          this.isKeyValid = false;
          this.sniperMessage = "No Ownership Column Detected";
        }
      });

      // Remove duplicates from the keys array
      this.keysToSelect = Array.from(new Set(this.keysToSelect));
      this.isLoading = false;
    }, 200);
  }

  selectKey(key: string): void {

    this.snipedValue = key;
    this.isKeyValid = true;
    this.sniperMessage = `Column: ${this.snipedValue}`;
    this.csvData.forEach((dataObj) => {
      if (dataObj.hasOwnProperty(key)) {

        const name = dataObj[this.playerNameKey].trim().toLowerCase();
        const match = stringSimilarity.findBestMatch(name, this.draftGroup.map(player => player.Name.toLowerCase()));
        if (match.bestMatch.rating > 0.5) {
          const matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
          if (matchingProjectionObj) {
            let value = null;
            const rawValue = dataObj[key];
            if (rawValue !== null && rawValue !== undefined) {
              const parsedValue = parseFloat(rawValue);
              if (!isNaN(parsedValue)) {
                value = Number(parsedValue.toFixed(4));
              } else {
                value = 0;
              }
              matchingProjectionObj.CptnOwnership = value;
            }
          }
        }

      }
    });
    this.handleUpdateUserKeyAdditions.emit({ type: 'cptOwnership', value: key }); 
  }

  handleRemoveCustomMapping() { 
    this.handleUpdateUserKeysToRemove.emit({ type: 'cptOwnership', value: this.snipedValue }); 
  }

  onClickChangeKey() {
    // this.snipedValue = null; 
    // this.snipeValue();
  }

  handleErrorOption(option) {
    this.sniperMessage = "Value Skipped";
    this.isKeyValid = true;
  }
}

