import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  Timestamp,
  query,
  where,
  limit,
  orderBy,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContestsService {

  database = getFirestore();

  constructor() { }

  async getAllContests(site, sport) {
    const ref = collection(this.database, `${site}-contests-${sport}`);

    if (environment.production) {

      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      const twelveHoursAgoTimestamp = currentTimestamp - 12 * 60 * 60 * 1000;
      
      const twelveHoursAgo = new Date(twelveHoursAgoTimestamp);
      const q = query(ref, where('contestStartTime', '>=', twelveHoursAgo.toISOString()));
      const querySnapshot = await getDocs(q);

      const contests = [];
      querySnapshot.forEach((doc) => {
        const contest = {
          id: doc.id,
          ...doc.data()
        };
        contests.push(contest);
      });
      return contests;

    } else {

      const timeAgo = new Date();
      timeAgo.setDate(timeAgo.getDate() - 30);
      // const timeAgo = new Date();
      // timeAgo.setMonth(timeAgo.getMonth() - 8);
      const q = query(ref, where('contestStartTime', '>=', timeAgo.toISOString()));
      const querySnapshot = await getDocs(q);

      const contests = [];
      querySnapshot.forEach((doc) => {
        const contest = {
          id: doc.id,
          ...doc.data()
        };
        contests.push(contest);
      });
      return contests;

    }
  }

  async getDraftGroupById(site, groupId, sport) {
    const docRef = await doc(this.database, `${site}-draftgroups-${sport}/${groupId}`);
    const docSnap = await getDoc(docRef);
    const jobData = docSnap.data();
    return jobData;
  }

  async getSlateGameCount(site, groupId, sport) {
    const docRef = await doc(this.database, `${site}-slates-${sport}/${groupId}`);
    const docSnap = await getDoc(docRef);
    const jobData = docSnap.data();
    return jobData;
  }

  async getContestsByDraftGroupId(site, sport, draftGroupId) {
    const ref = collection(this.database, `${site}-contests-${sport}`);

    const q = query(
      ref,
      where('draftGroupId', '==', draftGroupId),
    );

    const querySnapshot = await getDocs(q);

    const contests = [];
    querySnapshot.forEach((doc) => {
      const contest = {
        id: doc.id,
        ...doc.data()
      };
      contests.push(contest);
    });
    return contests;
  }


  async checkForLateSwapNba() {

    const dkLateSwaps = await this.checkDkLateSwaps();
    const fdLateSwaps = await this.checkFdLateSwaps();

    if (dkLateSwaps.length > 0 || fdLateSwaps > 0) {
      return true;
    } else {
      return false;
    }
  }

async checkDkLateSwaps() {
  const dkRef = collection(this.database, `dk-slates-nba`);
  const dkSlatesQuerySnapshot = await getDocs(dkRef);
  const dkSlates = dkSlatesQuerySnapshot.docs.map((doc) => doc.data());
  
  const combinedDkArray = dkSlates.reduce((result, obj) => {
    for (const key in obj) {
      result.push(obj[key]);
    }
    return result;
  }, []);

  const today = new Date();
  const now = new Date().toISOString();
  const todayFormatted = today.toISOString().split('T')[0]; // Format "YYYY-MM-DD"

  const todaysContests = combinedDkArray.filter((obj) => {
    const contestStartTime = obj.contest_start.split('T')[0]; // Get the date part
    return contestStartTime === todayFormatted;
  });

  const dkLateSwaps = todaysContests.filter((obj) => {
    const contestStartTime = obj.contest_start;
    const lastGameStart = obj.lastGameStartTime;
    return contestStartTime < now && now < lastGameStart;
  });

  if (dkLateSwaps !== null) {
    return dkLateSwaps;
  } else {
    return [];
  }

}

async checkFdLateSwaps() {
  const fdRef = collection(this.database, `fd-slates-nba`);
  const dkSlatesQuerySnapshot = await getDocs(fdRef);
  const dkSlates = dkSlatesQuerySnapshot.docs.map((doc) => doc.data());
  
  const combinedDkArray = dkSlates.reduce((result, obj) => {
    for (const key in obj) {
      result.push(obj[key]);
    }
    return result;
  }, []);

  const today = new Date();
  const now = new Date().toISOString();
  console.log(now);
  const todayFormatted = today.toISOString().split('T')[0]; // Format "YYYY-MM-DD"

  // Filter the objects based on contestStartTime
  const todaysContests = combinedDkArray.filter((obj) => {
    const contestStartTime = obj.contest_start.split('T')[0]; // Get the date part
    return contestStartTime === todayFormatted;
  });

  const fdLateSwaps = todaysContests.filter((obj) => {
    const contestStartTime = obj.contest_start;
    const lastGameStart = obj.lastGameStartTime;
    return contestStartTime < now && now < lastGameStart;
  });

  if (fdLateSwaps !== null) {
    return fdLateSwaps;
  } else {
    return [];
  }
}



}
