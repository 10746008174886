<div class="results-log-wrapper">


    <div class="ss-job-progress-log-wrapper" [ngClass]="{ active : jobInitiated, failed : isManualFailure || isJobFailed , fs : fullScreen }">

        <div class="backdrop-logo">
            <svg-icon [svgStyle]="{ 'height.px':112 }" src="/assets/logos/ace-mind-logo-mono.svg"></svg-icon>
        </div>

        <div class="counter">
            {{ formattedTime }}
        </div>

        <div #logContainer class="progress-line-items" (scroll)="onScroll()">

            <div class="progress-line">
                <div class="text">Waiting for job to begin...</div>
            </div>
    
            <div class="progress-line">
                <div class="text">You can leave this page and return while while it is running.</div>
            </div>
    
            <div class="progress-line" *ngIf="jobInitiated">
                <div class="ss-spinner" *ngIf="!jobSubscription"></div>
                <div class="text">Sending job to  {{jobData.contest ? 'Simulation' : 'Optimizer'}} Engine</div>
            </div>
    
            <section *ngIf="jobSubscription">
    
                <div class="progress-line">
                    <div class="ss-spinner" *ngIf="jobSubscription.jobProgressLog.length === 0 && !isManualFailure"></div>
                    <div class="text">Data routed and listening for updates.</div>
                </div>
    
                <div class="automated-messages">
                    <div class="progress-line" *ngFor="let m of jobSubscription.jobProgressLog; let i = index">
                        <div *ngIf="jobSubscription && jobSubscription?.status !== 'complete' && jobSubscription?.status !== 'job-failed'" class="ss-spinner" [ngClass]="{ 'show-spinner': i === jobSubscription.jobProgressLog.length - 1 }"></div>
                        <div class="text">{{m}}</div>
                    </div>
                </div>
    
                <div class="progress-line" *ngIf="isPreparingSummary">
                    <div class="ss-spinner"></div>
                    <div class="text">Preparing Lineups & Summary...</div>
                </div>
    
                <section *ngIf="jobSubscription.jobExceptionLog">
                    <div class="progress-line" *ngFor="let m of jobSubscription?.jobExceptionLog; let i = index">
                        <div class="text error">{{m}}</div>
                    </div>
                </section>
    
                <section *ngIf="isJobFailed && !isRefreshingJob">
                    <div class="progress-line" *ngIf="isManualFailure">
                        <div class="text error">Job exceeded max run time. Check config settings and any uploaded files.</div>
                    </div>
    
                    <div class="progress-line" *ngIf="isJobFailed">
                        <div class="text error">Job Failed</div>
                    </div>
    
                    <div class="progress-line" *ngIf="isJobFailed">
                        <!-- <div class="retry-btn" *ngIf="!isRefreshingJob" (click)="onClickRetryJob()">Restart Job and Resolve Issues</div> -->
                    </div>
                </section>
    
                <div class="progress-line" *ngIf="isRefreshingJob">
                    <div class="ss-spinner"></div>
                    <div class="text">Rolling back simulation for changes...</div>
                </div>
    
            </section>
        </div>

    </div>

</div>