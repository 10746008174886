async function validateJobDoc(jobData) {
  const exceptions = [];

  // CHECK FOR SIM AND CONTEST
  if (jobData.jobType === 'simulator' && jobData.contest == null) {
    exceptions.push('Sims require a contest. Select a contest in the top bar.');
  }

  // CHECK CONFIG IS GOOD
  if (!jobData.optionsValid) {
    exceptions.push('Check your config settings. One or more input is invalid');
  }

  const {
    selectedPlayers,
    selectedPlayerWithOwnership,
    selectedPlayerWithProjection,
    selectedPlayerWithStdDev,
    selectedPlayersWithNegativeStdDev
   } = evaluateProjectionData(jobData.draftGroup);

  // DRAFT GROUP HAS PLAYERS
  if (jobData.draftGroup == null || jobData.draftGroup.filter((g) => g.selected).length === 0) {
    exceptions.push('No players are selected. Select players to be included in your run.');
  }

  if (selectedPlayers < 3) {
    exceptions.push('Too few players selected to run an effective sim or opto');
  }

  if (selectedPlayerWithOwnership < 6 && jobData.jobType === 'simulator') {
    exceptions.push('Too few players selected have an onwership value');
  }

  if (selectedPlayerWithProjection !== selectedPlayers.length) {
    exceptions.push('One or more of your selected players does not have a projection value');
  }

  if (selectedPlayerWithStdDev !== selectedPlayerWithStdDev) {
    exceptions.push('One or more of your selected players does not have a stdDev value');
  }

  if (selectedPlayersWithNegativeStdDev !== 0) {
    exceptions.push('One or more of your selected players does not have a stdDev value');
  }

  return exceptions;
}

export default validateJobDoc;


//draftGroupFunctions

const evaluateProjectionData = (draftGroup) => {
  const selectedPlayers = draftGroup.filter((p) => p.selected);
  const selectedPlayerWithProjection = selectedPlayers.filter((p) => p.Projection > 0).length;
  console.log('selectedPlayerWithProjection', selectedPlayerWithProjection)

  const selectedPlayerWithOwnership = selectedPlayers.filter((p) => p.Ownership > 0).length;
  console.log('selectedPlayerWithOwnership', selectedPlayerWithOwnership);

  const selectedPlayerWithStdDev = selectedPlayers.filter((p) => p.StdDev > 0).length;
  console.log('selectedPlayerWithStdDev', selectedPlayerWithStdDev);

  const selectedPlayersWithNegativeStdDev = selectedPlayers.filter((p) => p.StdDev < 0).length;
  console.log('selectedPlayersWithNegativeStdDev', selectedPlayersWithNegativeStdDev);

  return {
    selectedPlayers,
    selectedPlayerWithProjection,
    selectedPlayerWithOwnership,
    selectedPlayerWithStdDev,
    selectedPlayersWithNegativeStdDev,
  };
}