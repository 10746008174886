import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'single-sport-selection',
  templateUrl: './single-sport-selection.component.html',
  styleUrls: ['./single-sport-selection.component.scss']
})
export class SingleSportSelectionComponent {
  @Input() userProfile: any;
  @Input() userId: any;
  @Input() currentSport: string;
  @Input() currentSportEndData: string;
  @Input() preSelectedSport: string;

  @Output() handleCloseOverlay: EventEmitter<any> = new EventEmitter<any>();

  selectedSport: string;
  lockedUntilDate: any;
  isLoading: boolean = false;
  isWarningLock: boolean = false;
  confirmationText: string = '';

  countdown: number = 10;
  originalDelay: number = 10;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.selectedSport = this.preSelectedSport;
    if (this.selectedSport !== null) {
      this.animateContent();
    }
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 30);
    this.lockedUntilDate = futureDate;
  }

  animateContent() {
    setTimeout(() => {
      anime({
        targets: '#sport-text',
        scale: [1, 1.15, 1],
        duration: 2000,
        direction: 'normal',
      });
    }, 200);
  }

  onClickJobCard(sport) {
    // this.isLoading = true;
    this.selectedSport = sport;
  }

  onClickConfirmSport() {
    this.isLoading = true;
    this.isWarningLock = true;
    this.updateCountdown();
    setTimeout(() => {
      this.isWarningLock = false;
      this.handleUpdateSingleSport()
    }, this.originalDelay * 1000); 
  }

  handleUpdateSingleSport() {
    const profileUpdate = {
      ...this.userProfile,
      id: this.userId,
      singleSportSub: {
        sport: this.selectedSport,
        lockedUntilDate: this.lockedUntilDate,
      }
    }
    this.userService.updateUserPublicProfile(profileUpdate).then((data) => {
      if (data !== null) {
        this.isLoading = false;
        this.handleCloseOrCancel(true);
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  updateCountdown() {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  handleCloseOrCancel(shouldUpdate) {
    this.isLoading = false;
    this.selectedSport = null;
    this.isWarningLock = false;
    this.handleCloseOverlay.emit(shouldUpdate);
  }
}
