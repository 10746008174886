import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { GoogleAuthProvider, getRedirectResult, updateProfile, sendEmailVerification } from "firebase/auth";
import { UserService } from 'src/app/services/user.service';
import { StateService } from 'src/app/services/state.service';
import { serverTimestamp } from 'firebase/firestore'
import { CreationService } from 'src/app/services/creation.service';
import { logEvent, getAnalytics } from "firebase/analytics";
import { Title, Meta } from '@angular/platform-browser';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  auth: any;
  userForm: UntypedFormGroup;
  successMsg = '';
  errorMsg = '';
  isAuthenticating = false;
  provider = new GoogleAuthProvider();
  isProcessingProvider = false;

  errorMsgs = {
    email: [
      {
        type: 'required',
        message: 'You need an email'
      },
      {
        type: 'pattern',
        message: 'That format doesn\'t  look right'
      }
    ],
    password: [
      {
        type: 'required',
        message: 'Password is required.'
      },
      {
        type: 'minlength',
        message: 'It doesn\'t look long enough.'
      }
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private creationService: CreationService,
    private titleService: Title,
    private metaService: Meta,
    private stateService: StateService,
    private subMonitor: SubMonitorService,
  ) {
    this.titleService.setTitle('AceMind');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Access your account to manage your smart displays.'
      }
    );
  }

  ngOnInit(): void {

    this.auth = getAuth();

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        setTimeout(() => {
          this.subMonitor.getSubscription().subscribe((data) => {
            if (data) {
              this.router.navigate(['dashboard']);
            } else {
              this.router.navigate(['/onboard', user.uid]);
            }
          });
        }, 700);
      }
    });

    this.userForm = this.fb.group({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required
      ])),
    });

    this.animateForm();
  }

  animateForm() {
    setTimeout(() => {
      anime({
        targets: '#auth-card',
        scale: [0.5, 1],
        opacity: [0, 1],
        duration: 1000,
        direction: 'normal',
      });
    }, 200);
  }

  signIn(email, password) {
    this.isAuthenticating = true;

    signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        // Signed in 
        this.stateService.setFullScreenAnimation(true);
        const user = userCredential.user;
        this.router.navigate(['/palermo']);
        this.isAuthenticating = false;
        const analytics = getAnalytics();
        logEvent(analytics, 'login');
      })
      .catch((error) => {
        const errorCode = error.code;
        if (error.message = 'Firebase: Error (auth/wrong-password).') {
          this.errorMsg = 'Email or Password is incorrect';
        } else {
          this.errorMsg = error.message;
        }
        this.isAuthenticating = false;
        console.log('error signing in')
      });
  }

  signInGoogle() {
    signInWithPopup(this.auth, this.provider)
    .then((result) => {
      if (result != null) {
        this.stateService.setFullScreenAnimation(true);
        this.isProcessingProvider = true;
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const user = result.user;
        this.userService.getUserMilestonesById(user.uid).then((data) => {
          if (data != null) {
            this.router.navigate(['palermo']);
            this.isProcessingProvider = false;
          } else {
            updateProfile(this.auth.currentUser, {
              displayName: user.displayName
            }).then(() => {
              this.creationService.createInitialUserContent(user).then((res) => {
                if (res = 'success') {
                  this.userService.createUserMilestones({registered: true}, user.uid);
                  this.router.navigate(['onboard', user.uid]);
                  this.isProcessingProvider = false;
                }
              });
            }).catch((error) => {
              this.errorMsg = error.message;
            });
          }
        })
      } else {
        this.isProcessingProvider = false;
      }
    }).catch((error) => {
      console.log('REDIRECT ERROR ' + error)
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }

  goToSignup() {
    this.router.navigate(['/sign-up']);
  }

  goToResetPassword() {
    this.router.navigate(['reset']);
  }

  onFieldIn() {
    this.errorMsg = '';
  }

}
