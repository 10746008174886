import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { JobsService } from 'src/app/services/jobs.service';
import mixpanel from 'mixpanel-browser';
import { DraftGroupService } from 'src/app/services/draft-group.service';
import { interval } from 'rxjs';

@Component({
  selector: 'job-progress-log',
  templateUrl: './job-progress-log.component.html',
  styleUrls: ['./job-progress-log.component.scss']
})
export class JobProgressLogComponent {
  @Input() jobData: any;
  @Input() jobSubscription: any;
  @Input() fullScreen: any = false;
  @ViewChild('logContainer') logContainer!: ElementRef;

  jobInitiated: boolean;
  isPreparingSummary: boolean;
  isJobFailed: boolean;
  isManualFailure: boolean;
  isRefreshingJob: boolean;
  estimatedJobRunTime: string = 'Calculating...';
  autoScroll: boolean = true;
  autoScrollTimeout: any;

  formattedTime: string = '00:00';
  private timerSubscription: Subscription | undefined;
  private totalSeconds: number = 0;

  constructor(
    private jobsService: JobsService,
    private draftGroupService: DraftGroupService,
  ) { }

  ngOnInit() {
    if (this.jobData?.status !== 'complete') {
      this.jobInitiated = true;
      this.startTimer();
      this.checkForManualFailure();
    }

    // this.startDummyLogs()
  }

  // startDummyLogs() {
  //   this.jobSubscription = {}
  //   this.jobSubscription.jobProgressLog = []
  //   interval(1000).subscribe(() => {
  //     this.jobSubscription?.jobProgressLog.push(`Log entry at ${new Date().toLocaleTimeString()}`);
  //     this.jobSubscription.status = 'running'
  //     if (this.autoScroll) {
  //       this.scrollToBottom();
  //     }
  //   });
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobSubscription && changes.jobSubscription.currentValue?.status === 'complete') {
      this.isPreparingSummary = true;
    }
    if (changes.jobSubscription && changes.jobSubscription.currentValue?.status === 'job-failed') {
      this.isJobFailed = true;
      this.stopTimer();
    }
    if (changes.jobSubscription) {
      const currentJobProgressLog = changes.jobSubscription.currentValue?.jobProgressLog || [];
      const previousJobProgressLog = changes.jobSubscription.previousValue?.jobProgressLog || [];
      if (currentJobProgressLog.length !== previousJobProgressLog.length) {
        // console.log('CHANGES TO THE LOG HAPPENED')
        if (this.autoScroll) {
          this.scrollToBottom();
        }
      }
    }
  }

  onScroll(): void {
    const nativeElement = this.logContainer.nativeElement;
    const isScrolledToBottom = nativeElement.scrollHeight - nativeElement.clientHeight <= nativeElement.scrollTop + 35;

    if (!isScrolledToBottom) {
      // console.log('USER DID SCROLL')
      this.autoScroll = false;
      clearTimeout(this.autoScrollTimeout);
      this.autoScrollTimeout = setTimeout(() => {
        this.autoScroll = true;
        this.scrollToBottom();
      }, 10000);
    } else {
      this.autoScroll = true;
    }
  }

  private scrollToBottom(): void {
    const nativeElement = this.logContainer.nativeElement;
    nativeElement.scrollTop = nativeElement.scrollHeight;
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  private startTimer() {
    let checked = false;
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      const minutes = Math.floor(this.totalSeconds / 60);
      const seconds = this.totalSeconds % 60;
      this.formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      this.totalSeconds++;
      if (minutes >= 30 && !checked) {
        this.checkForManualFailure();
        checked = true;
      }
    });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  };

  checkForManualFailure() {
    const firestoreTimestamp = this.jobData.updatedAt;
    const date = new Date(firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000);
    const currentTime = new Date();

    const twelveMinutesAgo = new Date(currentTime);
    twelveMinutesAgo.setMinutes(currentTime.getMinutes() - 5);

    if (date < twelveMinutesAgo) {
      this.isManualFailure = true;

      mixpanel.track(
        "Job Failed by Client",
        { "Sport": this.jobData.sport, "Site": this.jobData.site, "Type": this.jobData.jobType, "SubType": this.jobData.jobSubType }
      );

      if (this.jobData.status !== 'job-failed') {
        const job = this.jobData;
        const data = {
          ...job,
          status: 'job-failed',
        }
        this.jobsService.updateUniversalJob(this.jobData.userId, this.jobData.jobId, data).then((data) => {
          if (data) {
            this.isJobFailed = true;
            this.stopTimer();
          }
        }).catch((e) => {
          this.isRefreshingJob = false;
          console.log(e);
        })

      }
    }
  }

}
