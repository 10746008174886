import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {
    this.titleService.setTitle('AceMind | Privacy Policy');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'Review the Privacy Policy for AceMind IOT Connected Smart Displays.'
      }
    );
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
