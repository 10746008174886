import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {
  userId: any;
  isUserAffirmation: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private subMonitor: SubMonitorService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.titleService.setTitle('Ace Mind');
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'AceMind'
      }
    );
  }

  ngOnInit(): void {
  }

}
