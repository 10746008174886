const BASE_COLUMNS = [
    { prop: 'Name', name: 'Name', showTeamChip: true},
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Money Line' },
    { prop: 'TeamSpread', name: 'Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
    { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
];

const BASE_COLUMNS_OPTO = [
    { prop: 'Name', name: 'Name', showTeamChip: true},
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Money Line' },
    { prop: 'TeamSpread', name: 'Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'Ownership', editable: true, tooltip: 'Expected Ownership Percentage for the Selected Contest. Used for Lineup Building' },
];

const BASE_SHOWDOWN_DK_COLUMNS = [
    { prop: 'Name', name: 'Name', showTeamChip: true },
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Money Line' },
    { prop: 'TeamSpread', name: 'Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage for the Selected Contest' },
    { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain Ownership Percentage for Selected Contest. Used for Lineup Building' },
    { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
];

const BASE_SHOWDOWN_FD_COLUMNS = [
    { prop: 'Name', name: 'Name', showTeamChip: true},
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Team Money Line' },
    { prop: 'TeamSpread', name: 'Team Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage for the Selected Contest' },
    { prop: 'MvpOwnership', name: 'MVP Ownership', editable: true, tooltip: 'Expected MVP Ownership Percentage for Selected Contest. Used for Lineup Building' },
    { prop: 'FieldPts', name: 'Field Points', editable: true, tooltip: 'Contest Field’s Median Player Projection. Used for Lineup Building' },
];

const BASE_SHOWDOWN_DK_COLUMNS_OPTO = [
    { prop: 'Name', name: 'Name', showTeamChip: true},
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Money Line' },
    { prop: 'TeamSpread', name: 'Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage' },
    { prop: 'CptnOwnership', name: 'CPTN Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' }
];

const BASE_SHOWDOWN_FD_COLUMNS_OPTO = [
    { prop: 'Name', name: 'Name', showTeamChip: true},
    { prop: 'Position', name: 'Position' },
    { prop: 'Salary', name: 'Salary' },
    { prop: 'GameInfo', name: 'Game Info', matchup: true },
    { prop: 'TeamMoneyline', name: 'Money Line' },
    { prop: 'TeamSpread', name: 'Spread' },
    { prop: 'GameTotal', name: 'Game Total' },
    { prop: 'ImpliedTeamTotal', name: 'Implied Team Total' },
    { prop: 'Projection', name: 'Projection', editable: true, tooltip: 'Player’s Median Projection. Used During Contest Simulation' },
    { prop: 'StdDev', name: 'StdDev', editable: true, tooltip: 'Standard Deviation of Player’s Expected Outcomes. Used During Contest Simulation' },
    { prop: 'Ownership', name: 'FLEX Ownership', editable: true, tooltip: 'Expected UTIL/FLEX Ownership Percentage' },
    { prop: 'MvpOwnership', name: 'MVP Ownership', editable: true, tooltip: 'Expected Captain/MVP Ownership Percentage for Selected Contest. Used for Lineup Building' }
];

export const NFL_TABLES_SIM = {
    projections_showdown_dk: BASE_SHOWDOWN_DK_COLUMNS,
    projections_showdown_fd: BASE_SHOWDOWN_FD_COLUMNS,
    projections_classic: BASE_COLUMNS
};

export const NFL_TABLES_OPTO = {
    projections_showdown_dk: BASE_SHOWDOWN_DK_COLUMNS_OPTO,
    projections_showdown_fd: BASE_SHOWDOWN_FD_COLUMNS_OPTO,
    projections_classic: BASE_COLUMNS_OPTO,
};