export const NBA_LINEUP_FILTERS = [
    {
        name: 'Player Exposure',
        key:'playerExposure',
        max: null,
        min: null,
    },
    {
        name: 'Projection',
        key:'fpts',
        max: 10000,
        min: 1,
    }
]