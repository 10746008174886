import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface FilterMessage {
  isReset: boolean;
  playerFilters: any;
}

interface FilterMessageTeam {
  isReset: boolean;
  teamFilters: any;
}

interface HomeJobPayload {
  jobType: string;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private showFullScreenAnimation = new BehaviorSubject<boolean>(false);
  private disableWizard = new BehaviorSubject<boolean>(true);
  private subscriptionChecked = new BehaviorSubject<boolean>(false);
  private allJobCache = new BehaviorSubject<Array<any>>([]);
  private dailyJobCount = new BehaviorSubject<number>(0);
  private dailyJobLimitReached = new BehaviorSubject<boolean>(false);
  private isLateSwapActive = new BehaviorSubject<boolean>(false);
  private mlbImportMatchingMethod = new BehaviorSubject<string>('');
  private canImportById = new BehaviorSubject<boolean>(false);
  private playerExposureFilters = new BehaviorSubject<FilterMessage>({
    isReset: false,
    playerFilters: [],
  });
  private teamExposureFilters = new BehaviorSubject<FilterMessageTeam>({
    isReset: false,
    teamFilters: [],
  });
  private homeJobPayload = new BehaviorSubject<HomeJobPayload>({
    jobType: null,
  });
  private entryBuilderFilters = new BehaviorSubject<Array<any>>([]);
  private projectionDraftGroup = new BehaviorSubject<Array<any>>([]);

  showFullScreenAnimation$ = this.showFullScreenAnimation.asObservable();
  disableWizard$ = this.disableWizard.asObservable();
  subscriptionChecked$ = this.subscriptionChecked.asObservable();
  allJobCache$ = this.allJobCache.asObservable();
  dailyJobCount$ = this.dailyJobCount.asObservable();
  dailyJobLimitReached$ = this.dailyJobLimitReached.asObservable();
  isLateSwapActive$ = this.isLateSwapActive.asObservable();
  mlbImportMatchingMethod$ = this.mlbImportMatchingMethod.asObservable();
  canImportById$ = this.canImportById.asObservable();
  playerExposureFilters$ = this.playerExposureFilters.asObservable();
  teamExposureFilters$ = this.teamExposureFilters.asObservable();
  entryBuilderFilters$ = this.entryBuilderFilters.asObservable();
  projectionDraftGroup$ = this.projectionDraftGroup.asObservable();
  homeJobPayload$ = this.homeJobPayload.asObservable();
  

  setFullScreenAnimation(value: boolean) {
    this.showFullScreenAnimation.next(value);

    setTimeout(() => {
      this.showFullScreenAnimation.next(false);
    }, 5000);
  }

  setWizardDisabled(value: boolean) {
    this.disableWizard.next(value);
  }

  setSubscriptionChecked(value: boolean) {
    this.subscriptionChecked.next(value);
  }

  setAllJobCache(value: any) {
    this.allJobCache.next(value);
  }

  setDailyJobCount(value: number) {
    this.dailyJobCount.next(value);
  }

  setDailyJobCountReached(value: boolean) {
    this.dailyJobLimitReached.next(value);
  }

  setLateSwapActive(value: boolean) {
    this.isLateSwapActive.next(value);
  }

  setMlbImportMatchingMethod(value: string) {
    this.mlbImportMatchingMethod.next(value);
  }

  setCanImportById(value: boolean) {
    this.canImportById.next(value);
  }

  setPlayerExposureFilters(value: any) {
    this.playerExposureFilters.next(value);
  }

  clearPlayerExposureFilters() {
    const filterResetObject: FilterMessage = {
      isReset: true, // Set to `true` to indicate reset
      playerFilters: [],
    };
    this.playerExposureFilters.next(filterResetObject); // Use `.next()` to update the BehaviorSubject's value
  }

  setTeamExposureFilters(value: any) {
    this.teamExposureFilters.next(value);
  }

  setEntryBuilderFilters(value: any) {
    this.entryBuilderFilters.next(value);
  }

  setProjectionDraftGroup(value: any) {
    this.projectionDraftGroup.next(value);
  }

  setHomeJobPayload(value: any) {
    this.homeJobPayload.next(value);
  }

}
