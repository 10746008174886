import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile, sendEmailVerification, deleteUser, signOut } from "firebase/auth";
import { SubMonitorService } from 'src/app/services/sub-monitor.service';
import { SubscriptionTiers, LegacyTierInfo } from 'src/app/constants/subscription-tiers';
import {
  getFirestore,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { DatePipe } from '@angular/common';
import { StripeService } from 'src/app/services/stripe.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export interface ImgFile {
  name: string;
  filepath: string;
  size: number;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user: any;
  userProfile: any;
  userSubscription: any;
  currentSubIsCanceled: boolean = false;

  // NEW STRIPE FLAGS TO PREVENT DELETE WITH ACTIVE SUB
  isStripeSubscribed: boolean = false;
  canceledPlanAvailableUntilDate: any;

  subscriptionDetail: any;
  isLoading = false;
  accountDisplayName: any;
  verifyEmailSent = false;
  errorString: any;
  isPreparingPortal = false;

  showDeleteWarning = false;
  showDeleteConfirmation = false;
  isDeletingUser = false;
  authenticationRequired = false;
  showAlternatePricing: boolean;
  showSubscription: boolean;

  storage: any;
  database: any;
  fileUploadProgress: any;
  isFileUploading: boolean;
  isFileUploaded: boolean;
  imgIXurlPrefix = 'https://********.imgix.net/';  // TO DO SETUP IMG IX
  fileUrl: any;
  processedFileName: any;
  isSaving = false;
  customerPortal: any;

  isSingleSport: boolean = false;
  isProfileSportChecked: boolean = false;
  subscribedSport: string = null;
  subscribedSportEndDate: any = null;
  isEligibleForSportChange: boolean = false;
  seasonData: any;
  isUnlocking: boolean = false;

  constructor(
    private subMonitor: SubMonitorService,
    private router: Router,
    private userService: UserService,
    private datePipe: DatePipe,
    private stripeService: StripeService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    const auth = getAuth();
    this.database = getFirestore()
    this.user = auth.currentUser;
    this.accountDisplayName = this.user.displayName;

    this.storage = getStorage();
    // NEW POC TO IMPROVE STRIPE RELIABILITY;
    this.getStripeSubscriptionData();

    this.subMonitor.getSubscription().subscribe((data) => {
      console.log('THE SUB IS ', data)
      if (data) {
        this.userSubscription = data;
        if (this.userSubscription === 'FREE') { this.subscriptionDetail = SubscriptionTiers[0] };
        if (this.userSubscription === 'GRANDFATHER') { this.subscriptionDetail = SubscriptionTiers[0] };
        if (this.userSubscription === 'ACE_SINGLE') {
          this.subscriptionDetail = LegacyTierInfo[0];
          this.handleSingleSubData();
        };
        if (this.userSubscription === 'ACE_SINGLE_YEARLY') {
          this.subscriptionDetail = LegacyTierInfo[0];
          this.showAlternatePricing = true;
          this.handleSingleSubData();
        };
        if (this.userSubscription === 'ACE_BASIC') { this.subscriptionDetail = LegacyTierInfo[1]; };
        if (this.userSubscription === 'ACE_BASIC_YEARLY') {
          this.subscriptionDetail =  LegacyTierInfo[1];
          this.showAlternatePricing = true;
        };
        if (this.userSubscription === 'ACE_PRO') { this.subscriptionDetail = SubscriptionTiers[1] };
        if (this.userSubscription === 'ACE_PRO_YEARLY') {
          this.subscriptionDetail = SubscriptionTiers[1];
          this.showAlternatePricing = true;
        };
        this.handleShowSubscription();
      }
    });
  }

  getStripeSubscriptionData() {
    this.stripeService.getUserActiveSubscriptions(this.user.uid).then(
      (subscriptionsObservable) => {
        subscriptionsObservable.subscribe(
          (subscriptions) => {
            // Handle subscriptions data
            if (subscriptions != null) {
              const results = subscriptions.data || [];
              console.log(results);
              if (results.length > 1 && environment.production) {
                this.postMessageToDiscord(`🤔 Multiple Active Subs Detected for userId: ${this.user.uid}`)
              }
              const activeSubs = results.filter((r) => r.canceled_at === null && r.plan.active === true);
              if (activeSubs.length > 0) {
                this.isStripeSubscribed = true;
                this.currentSubIsCanceled = false;
              }
              const canceledButActiveSubs = results.filter((r) => r.canceled_at != null && r.plan.active === true);
              if (canceledButActiveSubs.length > 0) {
                this.isStripeSubscribed = true;
                this.currentSubIsCanceled = true;
                const serviceEndDate = canceledButActiveSubs[0].current_period_end;
                const date = new Date(serviceEndDate * 1000);
                const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 100}`;
                this.canceledPlanAvailableUntilDate = formattedDate;
              }
            }
          },
          (error) => {
            // Handle error
            console.error('Error fetching user active subscriptions:', error);
          }
        );
      },
      (error) => {
        // Handle error if the initial promise is rejected
        console.error('Error fetching user active subscriptions:', error);
      }
    );
  };

  postMessageToDiscord(message: string) {
    const webhookUrl = 'https://discord.com/api/webhooks/1239922202145591327/egNdUznrvyG0wfZYFHuTQoukVASJWKHTV5q5iQ8bwDPO0hWMH9VB-mwjp4d7e89ljsqR';
    const payload = { content: message };
    this.http.post(webhookUrl, payload).subscribe(
      () => console.log('Alert Posted to Error Monitoring.'),
      (error) => console.error('Failed to Post Error Monitoring:', error)
    );
  }

  handleShowSubscription() {
    setTimeout(() => {
      this.showSubscription = true;
    }, 600);
  }

  onSelectPhoto() {
    document.getElementById('photo-input').click();
  }

  handleSingleSubData() {
    this.isSingleSport = true;
    this.userService.getUserSingleSportSeasons().then((data) => {
      this.seasonData = data;
    })
    if (!this.isProfileSportChecked && this.user?.uid) {
      this.isProfileSportChecked = true;
      this.userService.getUserPublicProfileById(this.user.uid).then((data) => {  // NEW
        if (data != null) {
          this.userProfile = data;
          this.subscribedSport = data?.singleSportSub?.sport || null;
          this.subscribedSportEndDate = data?.singleSportSub?.lockedUntilDate || null;
          if (this.subscribedSportEndDate != null) {
            const formattedDate = this.datePipe.transform(
              new Date(this.subscribedSportEndDate.seconds * 1000 + this.subscribedSportEndDate.nanoseconds / 1000000),
              'MM/dd/yy @ hh:mm a'
            );
            this.subscribedSportEndDate = formattedDate;
            this.checkEligibilityToChange();
          }
        }
      });
    }
  }

  checkEligibilityToChange() {
    if (this.seasonData[this.subscribedSport] === false) {
      this.isEligibleForSportChange = true;
      console.log('sport eligible for change');
      return;
    }
    const timestampDate = new Date(this.subscribedSportEndDate);
    console.log(timestampDate);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - timestampDate.getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    if (daysDifference >= 30) {
      console.log('The timestamp is 30 days or more from the current date.');
      this.isEligibleForSportChange = true;
    } else {
      console.log('The timestamp is less than 30 days from the current date.' + daysDifference + 'days currently');
    }
  }

  onClickUnlockSport() {
    this.isUnlocking = true;
    const profileUpdate = {
      ...this.userProfile,
      id: this.user.uid,
      singleSportSub: {
        sport: null,
        lockedUntilDate: null,
      }
    }
    console.log('update is', profileUpdate);
    this.userService.updateUserPublicProfile(profileUpdate).then(() => {
      this.isUnlocking = false;
      this.subscribedSport = null;
      this.subscribedSportEndDate = null;
      this.isUnlocking = null;
    }).catch((e) => {
      console.log(e);
    })
  }

  uploadImage(event: FileList) {
    this.isFileUploading = true;

    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      console.log('File type is not supported!');
      return;
    }

    const filePath = `fileStorage/${new Date().getTime()}_${file.name}`;
    const imgIxPath = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(this.storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        this.fileUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log('error with upload' + error)
        this.isFileUploading = false;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateProfile(this.user, {
            photoURL: downloadURL,
          });
          this.userService.updateUserPublicProfile({ photoURL: downloadURL, id: this.user.uid })
            .then(() => {
              this.isFileUploading = false;
            })
            .catch((e) => {
              console.log(e);
            })
        });
      }
    );
  }

  onSaveProfileChanges() {
    updateProfile(this.user, {
      displayName: this.accountDisplayName
    })
      .then(() => {
        this.userService.updateUserPublicProfile({ displayName: this.accountDisplayName, id: this.user.uid })
          .then(() => {
           
          })
          .catch((e) => {
            console.log(e);
          })
      })
      .catch((error) => { console.log(error) });
  }

  async onManageSubscription() {
    this.isPreparingPortal = true;
    const functions = getFunctions();
    const getPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    getPortalLink(this.user.uid)
      .then((portal) => {
        this.customerPortal = portal.data;
        window.location.assign(this.customerPortal.url)
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onClickDeleteAccount() {
    if (this.isStripeSubscribed && this.currentSubIsCanceled === false) {
      alert('You have an active subscription. Please manage your subscription to cancel before deleting your account.')
      return;
    }
    this.showDeleteWarning = true;
  }

  onClickDeleteWarning() {
    this.showDeleteConfirmation = true;
  }

  onCancelDeleteWarning() {
    this.showDeleteConfirmation = false;
    this.showDeleteWarning = false;
  }

  onClickDeleteConfirmation() {
    this.showDeleteConfirmation = false;
    this.showDeleteWarning = false;
    this.isDeletingUser = true;
    deleteUser(this.user).then(() => {
      this.router.navigate(['/login'])
    }).catch((error) => {
      this.isDeletingUser = false;
      console.log(error.message);
      if (error.message == 'Firebase: Error (auth/requires-recent-login).') {
        this.authenticationRequired = true;
      }
    });
  }

  onReAuth() {
    const auth = getAuth();
    signOut(auth);
  }


  resendVerificationEmail() {
    sendEmailVerification(this.user)
      .then(() => {
        this.verifyEmailSent = true;
        setTimeout(() => {
          this.verifyEmailSent = false;
        }, 4000);
      });
  }

}
