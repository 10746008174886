import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Milestones {
  id?: string;
  registered: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  database = getFirestore();

  constructor(private http: HttpClient) { }

  async getUserMilestonesById(id) {
    const docRef = doc(this.database, `user-milestones/${id}`);  
    const docSnap = await getDoc(docRef);
    return docSnap.data() as Observable<Milestones>;
  }

  createUserMilestones(education: Milestones, userId) {
    return setDoc(doc(this.database, 'user-milestones', userId), education);
  }

  deleteUserMilestones(educations: Milestones) {
    const docRef = doc(this.database, `user-milestones/${educations.id}`);
    return deleteDoc(docRef);
  }

  updateUserMilestones(educationData) {
    const docRef = doc(this.database, `user-milestones/${educationData.id}`); 
    return updateDoc(docRef, educationData);
  }

  async getUserPublicProfileById(id) {
    const profileRef = doc(this.database, `user-profiles/${id}`);  
    const profileSnap = await getDoc(profileRef);
    return profileSnap.data();
  }

  createUserPublicProfile(profile, userId) {
    return setDoc(doc(this.database, 'user-profiles', userId), profile);
  }

  updateUserPublicProfile(profileData) {
    const docRef = doc(this.database, `user-profiles/${profileData.id}`); 
    return updateDoc(docRef, profileData);
  }

  resetUserProfileJobCount(profileData) {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(today.getDate()).padStart(2, '0');
    const year = String(today.getFullYear()).slice(-2);
    const monitorDate = `${month}_${day}_${year}`;
    const update = {
      dailyJobCount: {
        date: monitorDate,
        count: 0,
      }
    }
    const docRef = doc(this.database, `user-profiles/${profileData.id}`); 
    return updateDoc(docRef, update);
  }

  async getUserSingleSportSeasons() {
    const docRef = doc(this.database, `admin-data/seasons`);  
    const docDataSnap = await getDoc(docRef);
    return docDataSnap.data();
  }

  async isPhoneNumberUnique(number: string): Promise<boolean> {
    const docRef = doc(this.database, `verified-numbers`, number);  
    const docSnap = await getDoc(docRef);
  
    return !docSnap.exists();
  }

  async savePhoneNumberWithUserId(number: string, userId: string): Promise<void> {
    const docRef = doc(this.database, `verified-numbers`, number);
    await setDoc(docRef, {
      userId: userId
    });
  }

  sendVerificationCode(phoneNumber: string, isPhoneVerified: boolean = false): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = environment.twilio.verifyURL;
    console.log('PHONE NUMBER ', phoneNumber)
    const body = { phoneNumber, isPhoneVerified };
    return this.http.post(url, body, { headers });
  }

  verifyCode(phoneNumber: string, verificationCode: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = environment.twilio.verifyURL;
    const body = { phoneNumber, verificationCode, isPhoneVerified: true };
    return this.http.post(url, body, { headers });
  }

}
