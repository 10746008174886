<import-widget
*ngIf="showSniper"
title="Minutes"
[subTitle]="sniperMessage"
[isLoading]="isLoading"
[showFix]="!isKeyValid"
[fixOptions]="fixOptions"
fixMessage="Select the column containing player's Minutes value"
optionMessage="How would you like to fix this value?"
[availableKeys]="keysToSelect"
[isMappingValid]="isKeyValid"
(handleMappingSelected)="selectKey($event)"
(handleEditMapping)="onClickChangeKey()"
(handleErrorOption)="handleErrorOption($event)"
[isCustomMapping]="isCustomMapping"
(handleRemoveCustomMapping)="handleRemoveCustomMapping()"
>
</import-widget>