import { environment } from "src/environments/environment";

export const LegacyTierInfo = [
    {
        subRole: 'PRO',
        name: "AceMind - Legacy Single Sport",
        price: "$24.99",
        frequency: '/mo',
        description: "You were previously on a lower tier. Now that we have moved to one price, you can keep this monthly sub at your original rate. If you cancel, you won't have this price as an option next time.",
        benefits: [
            "Access to All Sports",
            "Optimizer with Opto to Sim Workflows",
            "Full Size Contest Sim (No Prebuilt Pools)",
            "Projection Data (NFL, MLB, more in the works)",
            "Easy Projection Importer for custom data",
            "Fine grained options and rules",
            "Portfolio Builder to Refine Entries",
            "Import Custom Lineups into Sims",
            "All jobs run on our faster cloud engine",
        ],
        showUpgrade: false,
        showManage: true,
        priceCode: '',
        nonTrialPriceCode: '',
        alternatePricing: {
            yearlyPrice: '$343.91',
            price: "$28.66",
            priceCode: '',
            nonTrialPriceCode: '',
        },
        buttonText: 'Start 3 Day Trial',
        nonTrialButtonText: 'Subscribe Now',
        buttonClass: 'blue',
    },
    {
        subRole: 'PRO',
        name: "AceMind - Legacy Basic",
        price: "$34.99",
        frequency: '/mo',
        description: "You were previously on a lower tier. Now that we have moved to one price, you can keep this monthly sub at your original rate. If you cancel, you won't have this price as an option next time.",
        benefits: [
            "Access to All Sports",
            "Optimizer with Opto to Sim Workflows",
            "Full Size Contest Sim (No Prebuilt Pools)",
            "Projection Data (NFL, MLB, more in the works)",
            "Easy Projection Importer for custom data",
            "Fine grained options and rules",
            "Portfolio Builder to Refine Entries",
            "Import Custom Lineups into Sims",
            "All jobs run on our faster cloud engine",
        ],
        showUpgrade: false,
        showManage: true,
        priceCode: '',
        nonTrialPriceCode: '',
        alternatePricing: {
            yearlyPrice: '$245.90',
            price: "$20.49",
            priceCode: '',
            nonTrialPriceCode: '',
        },
        buttonText: 'Start 3 Day Trial',
        nonTrialButtonText: 'Subscribe Now',
        buttonClass: 'blue',
    },
]

export const SubscriptionTiers = [
    {
        subRole: null,
        name: "Free",
        price: "$0.00",
        type: null,
        description: "Lucky you. You got Free Ace",
        benefits: [
            "Use all the features",
            "Thanks for being a champion",
        ],
        showUpgrade: true,
        showManage: false,
        priceCode: null,
        buttonText: 'Continue Free',
        nonTrialButtonText: 'Subscribe Now',
        buttonClass: 'free',
    },
    // {
    //     subRole: 'SINGLE_SPORT',
    //     name: "Ace Single Sport",
    //     price: "$24.99",
    //     frequency: '/mo',
    //     description: "Sim or Opto with one Sport",
    //     benefits: [
    //         "Pick Your Sport Every 30 days",
    //         "Lineup Uploads up to 2,000 Rows",
    //         "20 Sim or Opto Jobs per Day",
    //         "Sim Contests up to 20K Max Entries",
    //         "Sim Optimized Lineups",
    //         "Import Multiple Opto Lineups into a Sim",
    //         "Jobs Run on our Basic Engine",
    //     ],
    //     showUpgrade: false,
    //     showManage: true,
    //     priceCode: environment.subscription.single,
    //     nonTrialPriceCode: environment.nonTrialSubscription.single,
    //     alternatePricing: {
    //         yearlyPrice: '$245.90',
    //         price: "$20.49",
    //         priceCode: environment.subscription.single_annual,
    //         nonTrialPriceCode: environment.nonTrialSubscription.single_annual,
    //     },
    //     buttonText: 'Start 3 Day Trial',
    //     nonTrialButtonText: 'Subscribe Now',
    //     buttonClass: 'green',
    // },
    // {
    //     subRole: 'BASIC',
    //     name: "Ace Basic",
    //     price: "$34.99",
    //     frequency: '/mo',
    //     description: "All Sports with Limits",
    //     benefits: [
    //         "Access to All Sports",
    //         "Lineup Uploads up to 2,000 Rows",
    //         "20 Sim or Opto Jobs per Day",
    //         "Sim Contests up to 20K Max Entries",
    //         "Sim Optimized Lineups",
    //         "Import Multiple Opto Lineups into a Sim",
    //         "Jobs Run on our Basic Engine",
    //     ],
    //     showUpgrade: false,
    //     showManage: true,
    //     priceCode: environment.subscription.basic,
    //     nonTrialPriceCode: environment.nonTrialSubscription.basic,
    //     alternatePricing: {
    //         yearlyPrice: '$343.91',
    //         price: "$28.66",
    //         priceCode: environment.subscription.basic_annual,
    //         nonTrialPriceCode: environment.nonTrialSubscription.basic_annual,
    //     },
    //     buttonText: 'Start 3 Day Trial',
    //     nonTrialButtonText: 'Subscribe Now',
    //     buttonClass: 'green',
    // },
    {
        subRole: 'PRO',
        name: "AceMind",
        price: "$74.99",
        frequency: '/mo',
        description: "Sim, Opto, and Entry Builder Tools + Projections",
        benefits: [
            "Access to All Sports",
            "Optimizer with Opto to Sim Workflows",
            "Full Size Contest Sim (No Prebuilt Pools)",
            "Projection Data (NFL, MLB, more in the works)",
            "Easy Projection Importer for custom data",
            "Fine grained options and rules",
            "Portfolio Builder to Refine Entries",
            "Import Custom Lineups into Sims",
            "All jobs run on our faster cloud engine",
        ],
        showUpgrade: false,
        showManage: true,
        priceCode: environment.subscription.pro,
        nonTrialPriceCode: environment.nonTrialSubscription.pro,
        alternatePricing: {
            yearlyPrice: '$738.59',
            price: "$61.55",
            priceCode: environment.subscription.pro_annual,
            nonTrialPriceCode: environment.nonTrialSubscription.pro_annual,
        },
        buttonText: 'Start 3 Day Trial',
        nonTrialButtonText: 'Subscribe Now',
        buttonClass: 'blue',
    },
]

// Duplicates exist because of trial and non trial products
export const PriceCodeToTierNameDev = {
    // LEAVE THESE FOR CHECKS OF EXISTING SUBS
    'price_1OZLgYAkcA4MXJW6Bou05oRa': 'ACE_SINGLE',
    'price_1OZLlOAkcA4MXJW6MPkMzmaK': 'ACE_SINGLE_YEARLY',
    'price_1OZLsaAkcA4MXJW6xjMiTXdx': 'ACE_BASIC',
    'price_1OZLvVAkcA4MXJW6mQ2YPDnB': 'ACE_BASIC_YEARLY',
    'price_1OZLysAkcA4MXJW61CmTldVI': 'ACE_PRO',
    'price_1OZM17AkcA4MXJW6zeVkr8uQ': 'ACE_PRO_YEARLY',
    'price_1Oe5bFAkcA4MXJW6J8mTwxsX': 'ACE_SINGLE',
    'price_1Oe5e0AkcA4MXJW6apRmsHUi': 'ACE_SINGLE_YEARLY',
    'price_1Oe5g8AkcA4MXJW6kUe8zHio': 'ACE_BASIC',
    'price_1Oe5jCAkcA4MXJW6lvVUQS5o': 'ACE_BASIC_YEARLY',
    'price_1Oe5l6AkcA4MXJW6ArA474oN': 'ACE_PRO',
    'price_1Oe5n2AkcA4MXJW6uV6IV6LM': 'ACE_PRO_YEARLY',
  };

  // Duplicates exist because of trial and non trial products
  export const PriceCodeToTierNameProd = {
    // LEAVE THESE FOR CHECKS OF EXISTING SUBS
    'price_1OZvImAkcA4MXJW6KzhsI1la': 'ACE_SINGLE',
    'price_1OZvJ1AkcA4MXJW6y6EpQf7z': 'ACE_SINGLE_YEARLY',
    'price_1OZvJDAkcA4MXJW6yyb8IUqO': 'ACE_BASIC',
    'price_1OZvJNAkcA4MXJW6kuNQDC3M': 'ACE_BASIC_YEARLY',
    'price_1OZvJYAkcA4MXJW6r1Pmrz88': 'ACE_PRO',
    'price_1OZvJeAkcA4MXJW6fPco54AA': 'ACE_PRO_YEARLY',
    'price_1Oe5nwAkcA4MXJW6jipwOxJd': 'ACE_SINGLE',
    'price_1Oe5oAAkcA4MXJW61zo1DoSP': 'ACE_SINGLE_YEARLY',
    'price_1Oe5oGAkcA4MXJW6yF7BHYQR': 'ACE_BASIC',
    'price_1Oe5olAkcA4MXJW6aoylRx77': 'ACE_BASIC_YEARLY',
    'price_1Oe5oxAkcA4MXJW6daZj15ei': 'ACE_PRO',
    'price_1Oe5p4AkcA4MXJW6tHRsm0R7': 'ACE_PRO_YEARLY',
  };
