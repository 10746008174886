<div class="ss-form-group" style="border-bottom: none; margin-bottom: 0px">
  <div class="flex space-between tool-tip-row">
    <label>Lineup Scoring Weights</label>
    <tool-tip
      position="right"
      explanation="Adjusts the Weights Used in the Lineup Sorting Process to Determine Top Lineups. Sum of all inputs must equal 100%"
    ></tool-tip>
  </div>

  <div class="total-value" [ngClass]="{ error: totalPercentage !== 100 }">
    Total: {{ totalPercentage }}%
  </div>
</div>

<div class="ss-form-group">
  <div class="weight-inputs-grid">
    <!--ROI-->
    <div class="input-set">
      <div class="weight-label">Roi</div>
      <div class="flex space-between items-center">
        <div class="percentage-input ss-input-append-wrapper">
          <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            [(ngModel)]="jobData.options?.lineupSortWeights.roi"
            (ngModelChange)="handleCalculateSum($event)"
            [ngClass]="{ error: isError }"
          />
          <div class="ss-input-append-container">%</div>
        </div>
        <tool-tip position="right" explanation="Lineup's ROI"></tool-tip>
      </div>
    </div>
    <!-- / ROI-->

    <!--Projection-->
    <div class="input-set">
      <div class="weight-label">Projection</div>
      <div class="flex space-between items-center">
        <div class="percentage-input ss-input-append-wrapper">
          <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            [(ngModel)]="jobData.options?.lineupSortWeights.projection"
            (ngModelChange)="handleCalculateSum($event)"
            [ngClass]="{ error: isError }"
          />
          <div class="ss-input-append-container">%</div>
        </div>
        <tool-tip explanation="Sum of Lineup's Projection"></tool-tip>
      </div>
    </div>
    <!-- / Projection-->

    <!--Ownership-->
    <div class="input-set">
      <div class="weight-label">Ownership</div>
      <div class="flex space-between items-center">
        <div class="percentage-input ss-input-append-wrapper">
          <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            [(ngModel)]="jobData.options?.lineupSortWeights.ownership"
            (ngModelChange)="handleCalculateSum($event)"
            [ngClass]="{ error: isError }"
          />
          <div class="ss-input-append-container">%</div>
        </div>
        <tool-tip explanation="Geometric Mean of Lineup Ownership"></tool-tip>
      </div>
    </div>
    <!-- / Ownership-->

    <!--Top1Percent-->
    <div class="input-set">
      <div class="weight-label">Top 1% Finishes</div>
      <div class="flex space-between items-center">
        <div class="percentage-input ss-input-append-wrapper">
          <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            [(ngModel)]="jobData.options?.lineupSortWeights.top1Percent"
            (ngModelChange)="handleCalculateSum($event)"
            [ngClass]="{ error: isError }"
          />
          <div class="ss-input-append-container">%</div>
        </div>
        <tool-tip
          explanation="% of Times Lineup Finishes in the Top 1% of lineups in the contest field"
        ></tool-tip>
      </div>
    </div>
    <!-- / Top1Percent-->

    <!--Win Percentage-->
    <div class="input-set">
      <div class="weight-label">Win Percentage</div>
      <div class="flex space-between items-center">
        <div class="percentage-input ss-input-append-wrapper">
          <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            [(ngModel)]="jobData.options?.lineupSortWeights.wins"
            (ngModelChange)="handleCalculateSum($event)"
            [ngClass]="{ error: isError }"
          />
          <div class="ss-input-append-container">%</div>
        </div>
        <tool-tip
          explanation="% of Times Lineup wins in the contest field"
        ></tool-tip>
      </div>
    </div>
    <!-- / Win Percentage-->

    <div class="error-line" *ngIf="isError">
      Total of All Inputs Must Equal 100%
    </div>

  </div>
</div>
