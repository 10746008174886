<div class="match-widget">
  <div class="team-info-row" (click)="onClickSortByMatch()">
    <img class="away" [src]="awayImgURL" />
    <div class="middle-data">
      <div class="sort-btn">
        <div class="middle-btn">
          <div class="middle-text">
            VS
          </div>
        </div>
      </div>
    </div>
    <img class="home" [src]="homeImgURL" />
  </div>
</div>
