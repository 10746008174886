import { Component, Input } from '@angular/core';
import { getStorage, ref, getDownloadURL, updateMetadata } from "firebase/storage";

@Component({
  selector: 'job-results-sim-data',
  templateUrl: './job-results-sim-data.component.html',
  styleUrls: ['./job-results-sim-data.component.scss']
})
export class JobResultsSimDataComponent {
  @Input() jobData: any;
  activeSection: string = 'lineups';


  // file props
  storage: any;
  isDownloading: boolean;
  showSettings: boolean = true;

  ngOnInit() {
  }

  onToggleNav(section) {
    this.activeSection = section;
  }

  handleCollapseSidePanel() {
    this.showSettings = !this.showSettings;
  }

}
