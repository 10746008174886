<div class="results-sub-header">
  <div class="left-wrap">
    <div class="data-links">
      <div
        class="link"
        (click)="onToggleNav('lineups')"
        [class.active]="activeSection === 'lineups'"
      >
        Build Lineups
      </div>
      <div
        class="link"
        (click)="onToggleNav('player_stats')"
        [class.active]="activeSection === 'player_stats'"
      >
        Player Stats
      </div>
      <!-- <div
        class="link"
        (click)="onToggleNav('lineup_stats')"
        [class.active]="activeSection === 'lineup_stats'"
      >
        Lineup Stats
      </div> -->
      <!-- <div
        class="link"
        (click)="onToggleNav('log')"
        [class.active]="activeSection === 'log'"
      >
        Review Log
      </div> -->
      <!-- <div
        class="link"
        (click)="onToggleNav('settings')"
        [class.active]="activeSection === 'settings'"
      >
        Settings Used
      </div> -->
    </div>
  </div>

</div>


<div class="job-results-grid" [ngClass]="{ 'hide-settings': !showSettings, 'place-in-background ': activeSection !== 'lineups'}">
  <div class="job-results-lineups-wrapper" *ngIf="jobData">
    <job-build-lineups [jobData]="jobData"></job-build-lineups>
  </div>

  <div class="job-results-config-wrapper">
    <job-results-side-panel
      [jobData]="jobData"
      (handleHideConfig)="handleCollapseSidePanel()"
    ></job-results-side-panel>

    <div *ngIf="!showSettings" class="closed-panel-label">
      Exposure Filters and Settings
    </div>
  </div>
</div>

<div *ngIf="activeSection === 'player_stats'">
  <player-stats [jobData]="jobData"></player-stats>
</div>

<!-- <div *ngIf="activeSection === 'lineup_stats'">
  <lineup-stats [jobData]="jobData"></lineup-stats>
</div> -->


