import { BASE_ENTRY_TABLE_SIM_COLUMNS } from "./shared-config/shared-table-data"

export const MMA_LINEUP_GEN_TABLES_OPTO = {
  DK_CLASSIC: [
    { prop: 'f1', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f2', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f3', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f4', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f5', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f6', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'fpts', name: 'Projection', tooltip: 'Lineup projection', filterValue: 'fpts' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total salary of the Lineup", filterValue: 'salary' },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'winPProd', name: 'Win Prod', tooltip: 'Product of Lineup Win Probabilities', filterValue: 'winPProd' },
    { prop: 'winPSum', name: 'Win Sum', tooltip: 'Sum of Lineup Win Probabilities', filterValue: 'winPSum' },
  ],
  FD_CLASSIC: [
    { prop: 'f1', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f2', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f3', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f4', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f5', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f6', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'fpts', name: 'Projection', tooltip: 'Lineup projection', filterValue: 'fpts' },
    { prop: 'stddev', name: 'Std Dev', tooltip: 'Standard Deviation of Lineup Projections', filterValue: 'stddev' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total salary of the Lineup", filterValue: 'salary' },
    { prop: 'ownProd', name: 'Own Product', tooltip: 'Product of Lineup Ownership', filterValue: 'ownProd' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'winPProd', name: 'Win Prod', tooltip: 'Product of Lineup Win Probabilities', filterValue: 'winPProd' },
    { prop: 'winPSum', name: 'Win Sum', tooltip: 'Sum of Lineup Win Probabilities', filterValue: 'winPSum' },
  ],
}



export const MMA_LINEUP_GEN_TABLES_SIM = {
  DK_CLASSIC: [
    { prop: 'f1', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f2', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f3', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f4', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f5', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'f6', name: 'F', tooltip: null, searchable: true, noId: true },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections ', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },
    ...BASE_ENTRY_TABLE_SIM_COLUMNS
  ],
  FD_CLASSIC: [
    { prop: 'f1', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f2', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f3', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f4', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f5', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'f6', name: 'F', tooltip: null, searchable: true, noFrontId: true },
    { prop: 'fpts', name: 'Projection', tooltip: 'Sum of Lineup Projections ', filterValue: 'fpts' },
    { prop: 'fieldFpts', name: 'Field Projection', tooltip: 'Sum of Lineup Field Projections', filterValue: 'fieldFpts' },
    { prop: 'roi', name: 'ROI', tooltip: 'Lineup Return on Investment in Contest Simulations', filterValue: 'roi' },
    { prop: 'ceiling', name: 'Ceiling', tooltip: 'Highest Lineup Score in Contest Simulations', filterValue: 'ceiling' },
    { prop: 'avgCashWon', name: 'Avg Cash Won', tooltip: 'Average Lineup Equity in Contest Simulations', filterValue: 'avgCashWon' },
    { prop: 'salary', name: 'Salary', prefix: '$', tooltip: "Total Salary of the Lineup", filterValue: 'salary' },
    { prop: 'lineupType', name: 'Type', tooltip: null },
    { prop: 'geoMean', name: 'Geometric Mean', tooltip: 'Geometric Mean of Lineup Ownership', filterValue: 'geoMean' },
    { prop: 'simDupes', name: 'Sim Dupes', tooltip: 'Lineup Duplicates Seen in Contest Simulations', filterValue: 'simDupes' },
    { prop: 'ownSum', name: 'Own Sum', tooltip: 'Sum of Lineup Ownership', filterValue: 'ownSum' },
    { prop: 'cashProb', name: 'Cash Prob', tooltip: 'Cash Frequency of Lineup in Contest Simulations', filterValue: 'cashProb' },
    { prop: 'top1Prob', name: 'Top 1% Prob', tooltip: 'Top 1% Frequency of Lineup in Contest Simulations', filterValue: 'top1Prob' },
    { prop: 'winProb', name: 'Win Prob', tooltip: 'Win Frequency of Lineup in Contest Simulations', filterValue: 'winProb' },
    ...BASE_ENTRY_TABLE_SIM_COLUMNS
  ],
}