<div class="base-edit-overlay">
    <div #content class="content" [style.max-width]="width">
        <div #modalCard class="ss-base-card p24">
            <div class="close-wrapper" (click)="handleCloseOverlay()">
                <svg-icon class="icon" [svgStyle]="{ 'height.px':20 }" src="/assets/icons/close-square.svg"></svg-icon>
              </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
