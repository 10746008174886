import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PriceCodeToTierNameDev, PriceCodeToTierNameProd } from '../constants/subscription-tiers';

import {
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  database = getFirestore();

  constructor(private http: HttpClient) {}

  async getStripeCustomerIdByAceUserId(userId) {
    const docRef = doc(this.database, `customers/${userId}`);  
    const docSnap = await getDoc(docRef);
    const profile = docSnap.data();
    if (profile) {
      return profile.stripeId;
    } else {
      return null;
    }
  }

  async getUserActiveSubscriptions(userId: string): Promise<Observable<any>> {
    try {
      const customerId = await this.getStripeCustomerIdByAceUserId(userId);

      if (customerId) {
        const secretKey = environment.stripe.apiKey;
        const url = `https://api.stripe.com/v1/subscriptions?customer=${customerId}`;
        return this.http.get<any>(url, {
          headers: {
            'Authorization': `Bearer ${secretKey}`
          }
        });
      } else {
        return of(null);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching customer ID:', error);
      throw error; // Propagate error up
    }
  }

  async getUserSubscriptionTier(userId: string) {
    try {
      const customerId = await this.getStripeCustomerIdByAceUserId(userId);

      if (customerId) {
        const secretKey = environment.stripe.apiKey;
        const url = `https://api.stripe.com/v1/subscriptions?customer=${customerId}`;
        const stripeResponse = await this.http.get<any>(url, {
          headers: {
            'Authorization': `Bearer ${secretKey}`
          }
        }).toPromise();
    
        if (stripeResponse && stripeResponse.data && stripeResponse.data.length > 0) {
          const currentActivePriceCode = stripeResponse.data[0].plan.id;
      
          let tierName = null;
          if (environment.production) {
            tierName = PriceCodeToTierNameProd[currentActivePriceCode];
          } else {
            tierName = PriceCodeToTierNameDev[currentActivePriceCode];
          }
    
          if (tierName !== null) {
            return tierName;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user subscription tier:', error);
      throw error;
    }
  }
  

}
