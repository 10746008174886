<base-edit-overlay (closeOverlay)="handleCloseOverlay()" width="36em">
    <div class="ss-info-modal-wrapper" style="padding-bottom: 8px;">
        <div class="modal-header">Import Config</div>

        <div *ngIf="isLoading" class="modal-loading-wrapper">
            <div class="ss-spinner page-spinner passive"></div>
        </div>

        <div class="empty-state-wrapper"
            *ngIf="availableCorrs.length === 0 && importMode === 'customCorrelations' && !isLoading">
            No Recent Correlations for Draft Group: {{draftGroupId}}
        </div>
        <div class="empty-state-wrapper"
            *ngIf="availablePlayerCorrs.length === 0 && importMode === 'playerCorrelations' && !isLoading">
            No Recent Player Correlations for Draft Group: {{draftGroupId}}
        </div>
        <div class="empty-state-wrapper"
            *ngIf="availablePlayerRules.length === 0 && importMode === 'playerRules' && !isLoading">
            No Recent Player Rules for Draft Group: {{draftGroupId}}
        </div>
        <div class="empty-state-wrapper"
            *ngIf="availableTeamRules.length === 0 && importMode === 'teamRules' && !isLoading">
            No Recent Team Rules for Draft Group: {{draftGroupId}}
        </div>
        <div class="empty-state-wrapper"
            *ngIf="availableMatchupRules.length === 0 && importMode === 'matchupRules' && !isLoading">
            No Recent Matchup Rules for Draft Group: {{draftGroupId}}
        </div>
        <div class="empty-state-wrapper"
            *ngIf="(availableStackPairRules.length === 0 && availableStackLimitRules.length === 0) && importMode === 'stackRules' && !isLoading">
            No Recent Rules for Draft Group: {{draftGroupId}}
        </div>

        <ng-container *ngIf="availableCorrs.length > 0 && importMode === 'customCorrelations' && !isLoading ">
            <div class="setting-title">
                Recent Correlations for Draft Group: {{draftGroupId}}
            </div>
            <div class="modal-search">
                <input #searchInput id="searchValue" name="searchValue" class="ss-modal-sort-input"
                    [(ngModel)]="searchValue" (ngModelChange)="handleSortQuery('corr')" placeholder="Sort by name..."
                    autocomplete="off" />
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !c.selected}"
                    *ngFor="let c of availableCorrs; let i = index" (click)="onSelectCorr(c, i)">
                    <div class="info">
                        <div class="title">
                            <span>{{c.name}} ({{c.id}})</span>
                        </div>
                        <div class="players-wrapper">
                            <div class="player" *ngFor="let cr of c.correlations">
                                {{ cr.position }}: {{ cr.value }}
                            </div>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="c.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>
            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportCorrelations()">
                Import {{totalSelections}}
            </button>
        </ng-container>

        <ng-container *ngIf="availablePlayerCorrs.length > 0 && importMode === 'playerCorrelations' && !isLoading ">
            <div class="setting-title">
                Recent Player Correlations for Draft Group: {{draftGroupId}}
            </div>
            <div class="modal-search">
                <input #searchInput id="searchValue" name="searchValue" class="ss-modal-sort-input"
                    [(ngModel)]="searchValue" (ngModelChange)="handleSortQuery('playerCorr')"
                    placeholder="Sort by name..." autocomplete="off" />
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !c.selected}"
                    *ngFor="let c of availablePlayerCorrs; let i = index" (click)="onSelectPlayerCorr(c)">
                    <div class="info">
                        <div class="title">
                            <span>{{c.name}} ({{c.id}})</span>
                        </div>
                        <div class="players-wrapper">
                            <div class="player" *ngFor="let cr of c.correlations">
                                {{ cr.name }}: {{ cr.value }}
                            </div>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="c.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>
            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportPlayerCorrelations()">
                Import {{totalSelections}} </button>
        </ng-container>

        <ng-container *ngIf="availablePlayerRules.length > 0 && importMode === 'playerRules' && !isLoading ">
            <div class="setting-title mb16">
                Recent Player Rules for Draft Group: {{draftGroupId}}
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !rule.selected}"
                    *ngFor="let rule of availablePlayerRules | uniquePlayerRule; let i = index" (click)="onSelectPlayerRule(rule)">
                    <div class="info">
                        <div class="title">
                            <span>{{rule.type === 'at-least' ? 'At Least' : (rule.type === 'at-most' ? 'At Most' :
                                'Exactly')}} -
                                {{rule.value}}</span>
                        </div>
                        <div class="players-wrapper">
                            <div class="player" *ngFor="let player of rule.players">{{player.name}}</div>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="rule.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>

            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportPlayerRules()">
                Import {{totalSelections}} </button>
        </ng-container>

        <ng-container *ngIf="availableTeamRules.length > 0 && importMode === 'teamRules' && !isLoading ">
            <div class="setting-title mb16">
                Recent Team Rules for Draft Group: {{draftGroupId}}
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !rule.selected}"
                    *ngFor="let rule of availableTeamRules | uniqueTeamRule; let i = index" (click)="onSelectTeamRule(rule)">
                    <div class="info">
                        <div class="title" style="margin-bottom: 0;">
                            <span>{{rule.team}} - {{rule.type === 'team-limit' ? 'Limit' : 'At Least' }}:
                                {{rule.value}}</span>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="rule.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>

            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportTeamRules()">
                Import {{totalSelections}} </button>
        </ng-container>

        <ng-container *ngIf="availableMatchupRules.length > 0 && importMode === 'matchupRules' && !isLoading ">
            <div class="setting-title mb16">
                Recent Matchup Rules for Draft Group: {{draftGroupId}}
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !rule.selected}"
                    *ngFor="let rule of availableMatchupRules | uniqueMatchupRule; let i = index" (click)="onSelectMatchupRule(rule)">
                    <div class="info">
                        <div class="title" style="margin-bottom: 0;">
                            <span>{{rule.matchup}} - {{rule.type === 'matchup-limit' ? 'Limit' : 'At Least' }}:
                                {{rule.value}}</span>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="rule.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>

            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportMatchupRules()">
                Import {{totalSelections}} </button>
        </ng-container>

        <ng-container
            *ngIf="(availableStackPairRules.length > 0 || availableStackLimitRules.length > 0) && importMode === 'stackRules' && !isLoading ">
            <div class="setting-title mb16">
                Recent Matchup Rules for Draft Group: {{draftGroupId}}
            </div>
            <div class="import-rules-list">
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !rule.selected}"
                    *ngFor="let rule of availableStackLimitRules; let i = index" (click)="onSelectStackLimitRule(rule)">
                    <div class="info">
                        <div class="title" style="margin-bottom: 0;">
                            <span>Limit: {{rule.value}} - {{rule.scope}}</span>
                        </div>
                        <div class="players-wrapper mt8">
                            <div class="player">Limit Positions:&nbsp;</div>
                            <div class="player" *ngFor="let p of rule.positions">{{p}}</div>
                        </div>
                        <div class="players-wrapper mt8" *ngIf="rule.unlessPositions.length > 0">
                            <div class="player">Unless Positions - </div>
                            <div class="player">{{rule.unlessPositionsType}}:&nbsp;</div>
                            <div class="player" *ngFor="let p of rule.unlessPositions">{{p}}</div>
                        </div>
                        <div class="players-wrapper mt8" *ngIf="rule.excludeTeams.length > 0">
                            <div class="player">Exclude Teams:&nbsp;</div>
                            <div class="player" *ngFor="let e of rule.excludeTeams">{{e}}</div>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="rule.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>
                <div class="rule-wrapper" [ngClass]="{ 'idle' : !rule.selected}"
                    *ngFor="let rule of availableStackPairRules; let i = index" (click)="onSelectStackPairRule(rule)">
                    <div class="info">
                        <div class="title" style="margin-bottom: 0;">
                            <span>Pair: {{rule.value}} - {{rule.key}} - {{rule.scope}}</span>
                        </div>
                        <div class="players-wrapper mt8">
                            <div class="player">With:&nbsp;</div>
                            <div class="player" *ngFor="let p of rule.positions">{{p}}</div>
                        </div>
                        <div class="players-wrapper mt8" *ngIf="rule.excludeTeams.length > 0">
                            <div class="player">Exclude Teams:&nbsp;</div>
                            <div class="player" *ngFor="let e of rule.excludeTeams">{{e}}</div>
                        </div>
                    </div>
                    <div class="selected-chip" *ngIf="rule.selected">
                        <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                    </div>
                </div>
            </div>

            <div *ngIf="importError != null" class="import-error-text">
                {{importError}}
            </div>

            <button class="ss-primary-btn" *ngIf="totalSelections > 0" (click)="onClickImportStackRules()">
                Import {{totalSelections}} </button>
        </ng-container>


    </div>
</base-edit-overlay>