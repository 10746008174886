<div class="ss-page-wrapper auth">
  <div id="auth-card" *ngIf="!isProcessingProvider" class="ss-auth-content ss-modal-card">
    <div class="ss-auth-header mb24">
      <!-- <app-flap-row class="ss-animated-header" message="Sign In"></app-flap-row> -->
      Login
    </div>
    <div class="ss-auth-form">
      <form class="form" [formGroup]="userForm" (ngSubmit)="signIn(userForm.value.email, userForm.value.password)">

        <div class="ss-form-group">
          <input class="ss-input expanded bordered" type="email" name="email" formControlName="email"
            placeholder="you@something.com" (focus)="onFieldIn()" autocomplete="email" />
        </div>

        <div class="ss-form-group">
          <input class="ss-input expanded bordered" type="password" formControlName="password"
            placeholder="Your password..." autocomplete="off" (focus)="onFieldIn()" autocomplete="current-password"
            required />
        </div>

        <ng-container *ngFor="let error of errorMsgs.email">
          <app-message-prompt *ngIf="userForm.get('email').hasError(error.type) && userForm.get('email').touched"
            type="warning" mb="24px" messageTitle="Check your email" messageBody="{{ error.message }}">
          </app-message-prompt>
        </ng-container>


        <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Nope. Try Again."
          messageBody="{{ errorMsg }}">
        </app-message-prompt>

        <button class="ss-primary-btn" [disabled]="!userForm.valid || isAuthenticating">
          {{isAuthenticating ? '' : 'Login'}}
          <span *ngIf="isAuthenticating" class="ss-spinner btn-spinner"></span>
        </button>

        <hr class="mt24 mb24" />

        <div class="ss-secondary-btn o-auth" (click)="signInGoogle()">
          <svg-icon src="/assets/icons/google.svg"></svg-icon>
          Continue with Google
        </div>


        <div class="ss-terms-prompt-wrapper">
          By continuing you agree to these
          <a routerLink="/terms">Terms</a> and
          <a routerLink="/privacy">Privacy Policy</a>
        </div>

        <div class="ss-auth-form-sub-row">
          <div class="auth-link" routerLink="/reset">
            Forgot Password
          </div>
        </div>

      </form>
    </div>
  </div>
</div>