<div [ngClass]="label ? 'ss-form-group' : 'select-wrapper'">
    <label *ngIf="label" for="type-ahead">{{label}}</label>
    <input #typeAheadInput type="text" class="ss-input expanded" (input)="onInput($event.target.value)"
        [value]="selectedItem ? selectedItem.name : ''" (blur)="onBlur()" (focus)="onFocus()"
        (keyPress)="handleKeyboardEvent($event)" placeholder="{{placeholder}}" [ngStyle]="{ background: background}"/>
    <ul *ngIf="showList" [ngStyle]="isAbsolutePositionList ? {'position': 'absolute'} : {}">
        <li *ngFor="let item of filteredItems; let i = index" [ngClass]="{ active: i === activeIndex }"
            (click)="selectItem(item)">
            <div class="flex space-between items-center">
                <div>
                    <div>{{ item.name }}</div>
                    <div class="flex" *ngIf="item?.maximumEntries || item?.entryFee">
                        <div class="option-detail" *ngIf="item?.maximumEntries">Max Entries: {{item.maximumEntries | number}}</div>
                        <div class="option-detail" *ngIf="item?.entryFee">Entry Fee: ${{item.entryFee}}</div>
                    </div>

                </div>
                <div class="badge" *ngIf="showItemBadge && item?.maximumEntries > 20000">
                    Pro
                </div>
            </div>
        </li>
        <li *ngIf="items.length === 0" style="color: #717f9d; cursor: disabled;">
            No Available Selections
        </li>
    </ul>
</div>