import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniquePlayerRule'
})
export class UniquePlayerRulePipe implements PipeTransform {
  transform(rules: any[]): any[] {
    // Your logic to filter out items based on type and unique player names
    return rules.filter((rule, index, self) => {
      const isDuplicate = self.findIndex((otherRule) => this.areRulesEqual(rule, otherRule)) === index;
      return isDuplicate;
    });
  }

  private areRulesEqual(rule1, rule2): boolean {
    // Your logic to compare rules based on type and unique player names
    // For simplicity, let's assume 'type' and 'players' are the properties to compare
    return rule1.type === rule2.type && this.arePlayersEqual(rule1.players, rule2.players);
  }

  private arePlayersEqual(players1, players2): boolean {
    // Your logic to compare player names
    // For simplicity, let's assume the player names are stored in an array and should all be identical
    return players1.every((player, index) => player.name === players2[index].name);
  }
}