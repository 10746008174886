import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lineup-sort-weights',
  templateUrl: './lineup-sort-weights.component.html',
  styleUrls: ['./lineup-sort-weights.component.scss']
})
export class LineupSortWeightsComponent {
  @Input() jobData: any;

  isError: boolean;
  totalPercentage: number = 100;

  ngOnInit() {
    let total = 0;
    Object.entries(this.jobData.options.lineupSortWeights).forEach(([key, value]) => {
      if (key !== 'isValid') {
        total += Number(value) || 0;
      }
    });
    this.totalPercentage = total;
    if (total !== 100) {
      this.isError = true;
      this.jobData.optionsValid = false;
    } else {
      this.jobData.optionsValid = true;
      this.isError = false;
    }
  }

  handleCalculateSum(event) {
    let total = 0;
    Object.entries(this.jobData.options.lineupSortWeights).forEach(([key, value]) => {
      if (key !== 'isValid') {
        total += Number(value) || 0;
      }
    });
    this.totalPercentage = total;
    if (total !== 100) {
      this.isError = true;
      this.jobData.optionsValid = false;
    } else {
      this.isError = false;
      this.jobData.optionsValid = true;
    }
  }

}
