import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-prompt',
  templateUrl: './message-prompt.component.html',
  styleUrls: ['./message-prompt.component.scss']
})
export class MessagePromptComponent implements OnInit {
  @Input() type: string;
  @Input() messageIcon: string;
  @Input() messageTitle: string;
  @Input() messageBody: any;

  @Input() mt: string;
  @Input() mb: string;

  constructor() { }

  ngOnInit() {
    if (!this.messageIcon && this.type === 'danger'){
      // this.messageIcon = '/assets/illustrations/mini-robot-red.svg';
    } else if (!this.messageIcon && this.type === 'warning') {
      // this.messageIcon = '/assets/illustrations/mini-robot-orange.svg';
    }
  }

}
