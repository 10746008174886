import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent {
  @Input() segments: Array<{ label: string; value: any; isDefault: boolean }> = [];
  @Input() barSize = '120px';
  @Input() barHeight = '32px';
  @Input() textSize = 16;

  @Output() toggleChange = new EventEmitter<any>();

  activeSegment: string;

  ngOnInit(): void {
    this.activeSegment = this.segments.find((s) => s.isDefault).value;
  }

  toggleSwitch(value): void {
    this.activeSegment = value;
    this.toggleChange.emit(value);
  }

}
