import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueTeamRule'
})
export class UniqueTeamRulePipe implements PipeTransform {
  transform(rules: any[]): any[] {
    // Your logic to filter out items based on team type and value
    return rules.filter((rule, index, self) => {
      const isDuplicate = self.findIndex((otherRule) => this.areTeamRulesEqual(rule, otherRule)) === index;
      return isDuplicate;
    });
  }

  private areTeamRulesEqual(rule1, rule2): boolean {
    // Your logic to compare team rules based on team type and value
    // For simplicity, let's assume 'team', 'type', and 'value' are the properties to compare
    return (
      rule1.team === rule2.team &&
      rule1.type === rule2.type &&
      rule1.value === rule2.value
    );
  }
}