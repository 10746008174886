<div>
  <div class="config-tab-control">
    <div class="tab-links">
      <div
        class="link"
        (click)="onToggleGroup('settings')"
        [class.active]="activeGroup === 'settings'"
      >
        Settings
      </div>
      <div
        class="link 2"
        (click)="onToggleGroup('stacks')"
        [class.active]="activeGroup === 'stacks'"
      >
        Stack Settings
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'settings'">
    <div class="centered-form">
      <section *ngIf="jobData.jobSubType">
        <presets-manager
          [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="
            onClickSetPresetDefault($event.id, $event.index, $event.data)
          "
          (onClickRemovePresetDefault)="
            onClickRemovePresetDefault($event.id, $event.index, $event.data)
          "
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"
        ></presets-manager>
      </section>

      <hr style="margin-bottom: 12px; margin-top: 24px" />

      <div class="inline-inputs">
        <!-- TOTAL SIMS -->
        <input-group
          label="Simulations Per Field Generation"
          tooltip="# of Simulations to run for each Generated Contest Field"
          [(ngModel)]="jobData.options.totalSimulations"
          [min]="100"
          [max]="100000"
          [errorMessage]="'100-100K'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.totalSimulations < 100 ||
              jobData.options.totalSimulations == null ||
              jobData.options.totalSimulations > 100000
          }"
        >
        </input-group>

        <!-- LINEUP GENERATIONS -->
        <input-group
          label="Number of Generations"
          tooltip="# of Contest Field Generations to Run (1-25)"
          [(ngModel)]="jobData.options.lineupGenerations"
          [min]="1"
          [max]="25"
          [errorMessage]="'1-25'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.lineupGenerations < 1 ||
              jobData.options.lineupGenerations == null ||
              jobData.options.lineupGenerations > 25
          }"
        >
        </input-group>

        <!-- TOP LINEUPS PERCENT -->
        <input-group
          label="Lineup Holdover Percent"
          tooltip="% of Lineups to Keep between Contest Field Generations"
          [(ngModel)]="jobData.options.topLineupsPct"
          [min]="0"
          [max]="100"
          [errorMessage]="'0-100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.topLineupsPct < 0 ||
              jobData.options.topLineupsPct == null ||
              jobData.options.topLineupsPct > 100
          }"
        >
        </input-group>

        <!--KEEP USER LINEUPS-->
        <div class="ss-form-group">
          <div class="toggle-wrapper">
            <div class="flex items-center">
              <div class="label">Keep User Lineups?</div>
              <tool-tip
                explanation="Forces Algorithm to Keep All User Lineups Between Runs"
                position="right"
              ></tool-tip>
            </div>
            <toggle-switch
              [checked]="jobData.options.keepUserLineups"
              (checkedChange)="onToggleKeepUserLineups($event)"
            ></toggle-switch>
          </div>
        </div>

        <!--NEW SORT WEIGHTS -->
        <lineup-sort-weights
          *ngIf="jobData && jobData.options"
          [jobData]="jobData"
        ></lineup-sort-weights>

        <input-group
          label="Max Batters Vs Pitcher"
          tooltip="Maximum Number of Batters Allowed in Lineup vs Pitcher"
          [(ngModel)]="jobData.options.maxBattersVsPitcher"
          [min]="0"
          [max]="4"
          [errorMessage]="'Value between 0 and 4'"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="% Field Using Stacks"
          tooltip="What % of the generated field uses at least a 4 man team stack on DK and 3 man team stack on FD"
          id="usingStacksPercent"
          [(ngModel)]="jobData.options.usingStacksPercent"
          [min]="0"
          [max]="100"
          [errorMessage]="'Value between 0 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="% Field w/ Max Stack L"
          tooltip="What % of generated stacks use the respective site's maximum team stack length (5 on DK, 4 on FD)"
          [(ngModel)]="jobData.options.usingMaxStackLenPercent"
          [min]="0"
          [max]="100"
          [errorMessage]="'Value between 0 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Secondary Stack %"
          tooltip="What % of generated stacks have at least a 2 man secondary stack"
          [(ngModel)]="jobData.options.secondaryStackPercent"
          [min]="0"
          [max]="100"
          [errorMessage]="'Value between 0 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [max]="10000000"
          [errorMessage]="'This field is required'"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Max % off Optimal"
          tooltip="Sets the maximum percentage off of the optimal lineup (by fpts) that the field generator is allowed to create. If the optimal is 100 and this value is 20, the minimum lineup fpts allowed to be created by the generator is 80"
          [(ngModel)]="jobData.options.maxPctOffOptimal"
          [min]="1"
          [max]="100"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Default Pitcher Variance"
          [(ngModel)]="jobData.options.defaultPitcherVar"
          [min]="0"
          [max]="100"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>

        <input-group
          label="Default Hitter Variance"
          [(ngModel)]="jobData.options.defaultHitterVar"
          [min]="0"
          [max]="100"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isPercentage]="true"
          (valueChange)="onValueChange($event)"
        ></input-group>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'stacks'">
    <div class="flex space-between mt16" style="padding: 0px 12px">
      <div class="job-options-section-header" style="margin-bottom: 8px">
        Team Stack Ownership
      </div>
      <tool-tip
        explanation="By default, these are set to 100% and treated equally. Update to use your own percentages."
      />
    </div>

    <div class="file-drop-section">
      <div class="info-row">
        <div class="upload-text" *ngIf="!updatedTeamStackCount">
          Update all with CSV (Team | Value)
        </div>
        <div
          class="upload-text"
          *ngIf="updatedTeamStackCount"
          style="color: var(--ss-colors-green)"
        >
          {{ updatedTeamStackCount }} Teams Updated
        </div>
        <div class="tool-btn" (click)="downloadCSV()">
          <div class="icon">
            <svg-icon
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/csv-icon.svg"
            ></svg-icon>
          </div>
          <div class="text">Template</div>
        </div>
      </div>
      <modal-csv-capture
        (fileAdded)="handleTeamStackFile($event)"
      ></modal-csv-capture>
    </div>

    <hr style="margin-bottom: 0px" />

    <div class="centered-form" *ngIf="teamStackConfig">
      <div class="inline-inputs">
        <input-group
          label="{{ t.name }}"
          *ngFor="let t of jobData.options.teamStackConfig"
          [(ngModel)]="t.value"
          [min]="0"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Valid 0 - 100'"
          (valueChange)="onValueChange({ userChange: true })"
        >
        </input-group>
      </div>
    </div>
  </div>
</div>
