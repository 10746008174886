import { Component, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'square-anime-widget',
  templateUrl: './square-anime-widget.component.html',
  styleUrls: ['./square-anime-widget.component.scss']
})
export class SquareAnimeWidgetComponent {
  @ViewChild('container') container!: ElementRef;

  ngAfterViewInit(): void {
    this.initAnimation();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.initAnimation();
  }

  private initAnimation(): void {
    const containerWidth = this.container.nativeElement.offsetWidth;
    const containerHeight = this.container.nativeElement.offsetHeight;

    anime({
      targets: '.ball',
      keyframes: [
        { translateY: 0, scaleX: 1, scaleY: 1.125, duration: 2800, easing: 'easeInOutSine' }, // Move up and scale
        { translateY: 0, scaleX: 1.21, scaleY: 1.21, duration: 1800, easing: 'easeInOutSine' }, // Move down and reset scale
        { translateY: 0, scaleX: 1, scaleY: 1.125, duration: 2800, easing: 'easeInOutSine' } // Repeat
      ],
      loop: true,
      update: function (anim) {
        const target = anim.animatables[0].target;
        const offset = (anim.currentTime % 1600) / 1600;
        const shadowLength = 50 * offset;
        target.style.boxShadow = `0 0 ${shadowLength}px rgba(255, 245, 190, 0.5)`;
        
        // Shape transformation (circle to square)
        const radius = Math.abs(Math.sin(anim.currentTime / 1000)) * 30; // Varies radius
        target.setAttribute('r', radius.toString());
      }
    });
  }
}
