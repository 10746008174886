import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent {
@Input() filter: any;
@Input() filters: any;
@Output() onRemoveFilter = new EventEmitter<{ filterId: number, event: Event }>();


onClickOperator(value) {
  this.filter.operator = value;
}

onClickDeleteFilter(event: Event) {
  this.onRemoveFilter.emit({ filterId: this.filter.id, event: event });
}

}
