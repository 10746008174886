import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'job-configuration-section',
  templateUrl: './job-configuration-section.component.html',
  styleUrls: ['./job-configuration-section.component.scss']
})
export class JobConfigurationSectionComponent {
  @Input() jobData: any;
  @Input() isContestStarted: any;
  @Input() jobExceptions: any;
  @Output() handleHideConfig: EventEmitter<void> = new EventEmitter<void>();

  showSettingsContent: boolean = true;
  configSection: string = 'settings';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobData && changes.jobData.currentValue && changes.jobData.currentValue?.type !== changes.jobData.previousValue?.type) {
      this.configSection = 'settings';
    }
    if (changes.jobExceptions && changes.jobExceptions.currentValue.length > changes.jobExceptions.previousValue?.length && !this.showSettingsContent) {
      this.handleHideConfig.emit();
      this.showSettingsContent = !this.showSettingsContent;
    }
  }

  onClickMinimizeConfig() {
    this.showSettingsContent = !this.showSettingsContent;
    this.handleHideConfig.emit();
  }

  onClickSectionLink(section) {
    this.configSection = section;
  }
  
}
