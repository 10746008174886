<div class="ss-form-group">
    <label for="type-ahead">{{label}}</label>
    <div class="pill-input-wrapper">
        <div class="pills-wrap">
            <div class="pill" *ngFor="let item of selectedItems; let i = index">
                {{item}}
                <svg-icon (click)="onClickRemovePill(i)" class="icon" [svgStyle]="{ 'height.px':14 }" src="/assets/icons/close-square.svg"></svg-icon>
            </div>
        </div>
        <input #typeAheadInput type="text" (input)="onInput($event.target.value)"
        [value]="selectedItem ? selectedItem.name : ''" (blur)="onBlur()" (focus)="onFocus()"
        (keyPress)="handleKeyboardEvent($event)" placeholder="{{placeholder}}" />
    </div>
    <ul *ngIf="showList">
        <li *ngFor="let item of filteredItems; let i = index" [ngClass]="{ active: i === activeIndex }"
            (click)="selectItem(item)">
            {{ item.name }}
        </li>
    </ul>
</div>