import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobThrottleService {

  private readonly JOB_LIMIT_HOURLY = 30; // Maximum jobs per hour
  private readonly JOB_LIMIT_FIVE_MINUTES = 5; // Maximum jobs per 5 minutes
  private readonly TIME_WINDOW_HOURLY = 60 * 60 * 1000; // 1 hour in milliseconds
  private readonly TIME_WINDOW_FIVE_MINUTES = 5 * 60 * 1000; // 5 minutes in milliseconds

  constructor() {}

  canSubmitJob(): boolean {
    const now = Date.now();
    let jobTimestamps = JSON.parse(localStorage.getItem('jobTimestamps')) || [];

    // Filter out timestamps that are outside the hourly window
    jobTimestamps = jobTimestamps.filter(timestamp => now - timestamp <= this.TIME_WINDOW_HOURLY);

    // Check hourly limit
    if (jobTimestamps.length >= this.JOB_LIMIT_HOURLY) {
      const oldestTimestamp = jobTimestamps[0];
      const timeRemaining = this.TIME_WINDOW_HOURLY - (now - oldestTimestamp);
      const minutesRemaining = Math.floor(timeRemaining / (1000 * 60));
      const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      alert(`You can only run 30 jobs in one hour. Let it simmer for ${minutesRemaining} min and ${secondsRemaining} seconds before your next cloud run.`);
      return false;
    }

    // Check 5-minute limit
    const recentTimestamps = jobTimestamps.filter(timestamp => now - timestamp <= this.TIME_WINDOW_FIVE_MINUTES);
    if (recentTimestamps.length >= this.JOB_LIMIT_FIVE_MINUTES) {
      alert("Simmer down now. You can only run 5 jobs within a 5 minute time frame. Give it a minute and try again. Cloud runs use a lot of resources.");
      return false;
    }

    // Allow job submission, update timestamps, and save to local storage
    jobTimestamps.push(now);
    localStorage.setItem('jobTimestamps', JSON.stringify(jobTimestamps));

    return true;
  }
}
