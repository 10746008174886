import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputGroupComponent),
      multi: true
    }
  ]
})
export class InputGroupComponent {
  @Input() label: string;
  @Input() tooltip: string;
  @Input() ngModel: any;
  @Input() min: number;
  @Input() max: number;
  @Input() errorMessage: string;
  @Input() isInline: boolean = false;
  @Input() isPercentage: boolean = false;
  @Output() ngModelChange = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();

  private innerValue: any;

  onModelChange(event: any) {
    this.ngModelChange.emit(event);
    this.valueChange.emit({ userChange: true });
  }

  get isError() {
    return this.ngModel < this.min || this.ngModel == null || this.ngModel > this.max;
  }

    // ControlValueAccessor implementation
    writeValue(value: any): void {
      this.innerValue = value;
    }
  
    onChange: any = () => {};
    onTouched: any = () => {};
  
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
  
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
}
