<div class="settings-wrapper mt16">
  <div class="ss-form-group" style="margin-bottom: 0px">
    <div class="flex space-between">
      <label>Load Saved Projections</label>
      <tool-tip
        explanation="If you previously loaded data for this draft group, you can load them to bypass the template upload."
      />
    </div>
    <div class="preset-input-row" *ngIf="availableDraftGroupPresets.length > 0">
      <div class="mock-preset-input" (click)="onClickShowPresets()">
        <div class="text">Select saved projections...</div>
        <div class="icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 8 }"
            src="/assets/icons/chev-down.svg"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div
      class="preset-input-row"
      *ngIf="availableDraftGroupPresets.length == 0"
    >
      <div class="mock-preset-input" style="cursor: default">
        <div class="text" style="color: var(--ss-text-muted)">
          Import data to create presets
        </div>
      </div>
    </div>
  </div>
  <ul *ngIf="showPresets" #presetList>
    <li class="preset-definition">
      Your Presets for Draft Group:
      {{
        jobData.contest ? jobData.contest.draftGroupId : jobData.draftGroupId
      }}
    </li>
    <li *ngFor="let set of availableDraftGroupPresets; let i = index">
      <div class="preset-item-row">
        <div class="name" (click)="onClickApplyDraftGroupPreset(set)">
          {{ set?.name | slice : 0 : 42
          }}{{ set?.name.length > 42 ? '...' : '' }}
        </div>
        <div class="actions">
          <div class="delete-wrapper" (click)="onRemovePreset(set.id, i)">
            <div class="icon" *ngIf="deletePresetIndex !== i">
              <svg-icon
                [svgStyle]="{ 'height.px': 20 }"
                src="/assets/icons/trash-square.svg"
              ></svg-icon>
            </div>
            <div
              class="ss-spinner passive"
              *ngIf="isDeletingPreset && deletePresetIndex === i"
            ></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<hr class="dividing-line mb16 mt24" />

<div class="file-drop-section">
  <draft-group-importer
    [draftGroup]="jobData.draftGroup"
    [draftGroupId]="jobData.draftGroupId"
    [jobData]="jobData"
    [importMode]="importMode"
    (importComplete)="handleImportComplete()"
  ></draft-group-importer>
</div>

<div class="template-links-prompt">Don't have a file?</div>
<div class="template-links">
  <div class="template-link" (click)="generateTemplateCSV()">
    Download Our Template
  </div>
  <div
    class="template-link"
    *ngIf="!isCopyingCSV"
    (click)="copyCsvToClipboard()"
  >
    Copy CSV to Clipboard
  </div>
  <div class="template-link" *ngIf="isCopyingCSV" style="text-decoration: none">
    {{ copyCvsMessage }}
  </div>
</div>
