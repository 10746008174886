<div
  class="ss-subscribe-page-wrapper"
  *ngIf="!isCheckingActiveSubscription && !isShowVerifyPhoneNumber
  "
>
  <div class="ss-subscribe-header-wrapper">
    <div class="ss-subscribe-header">Tools and Data at a Fair Price</div>
    <div class="ss-subscribe-sub-header">
      Our aim is to deliver top-tier sim and opto tools that push cloud
      computing to the max, never giving you lineups from a static pool, all
      while keeping our prices lower than the competition.
    </div>
  </div>

  <div class="ss-subscriptions">
    <div class="ss-subscription-widget" *ngFor="let p of products">
      <div class="header">
        <div class="plus-mark" *ngIf="p.subRole === 'PASS_PLUS'">
          <svg-icon
            [svgStyle]="{ 'height.px': 12 }"
            src="/assets/icons/plus-icon.svg"
          ></svg-icon>
        </div>
        <div class="tier">
          {{ p.name }}
        </div>
      </div>

      <div class="description">
        {{ p.description }}
      </div>

      <div class="price-row">
        <div class="price-feature">
          <div
            *ngIf="
              !p.alternatePricing ||
              (p.alternatePricing && !p.showAlternatePricing)
            "
            class="price"
          >
            {{ p.price }}
          </div>
          <div
            *ngIf="p.alternatePricing && p.showAlternatePricing"
            class="price"
          >
            {{ p.alternatePricing.price }}
          </div>
          <div class="frequency">
            {{ p.frequency }}
          </div>
        </div>
        <div class="toggle-wrapper" *ngIf="p.alternatePricing">
          <div class="label">Billed Annually</div>
          <toggle-switch
            [(checked)]="p.showAlternatePricing"
            (checkedChange)="onClickShowAltPricing(p, $event)"
          ></toggle-switch>
        </div>
      </div>

      <div class="promo-wrapper">
        <!-- <div>Get <span class="discount">25%</span> off the first 2 months with code</div>
                <div class="code">EARLYACE</div>
                <div class="instruction">Enter Code During Checkout</div> -->
      </div>

      <button
        class="ss-secondary-btn subscribe-btn"
        [disabled]="isPreparingStore"
        (click)="subscribe(p, { trial: false })"
        [ngClass]="p.buttonClass"
      >
        <span *ngIf="!isPreparingStore">{{ p.nonTrialButtonText }}</span>
        <div
          class="ss-spinner subscribe-spinner"
          *ngIf="isPreparingStore"
        ></div>
      </button>

      <button
        *ngIf="isTrialAvailable && voipAttemptCount < 6"
        class="ss-secondary-btn subscribe-btn trial"
        [disabled]="isPreparingStoreTrial"
        (click)="onClickUnlockTrial(p)"
        [ngClass]="p.buttonClass"
      >
        <span *ngIf="!isPreparingStoreTrial">{{ p.buttonText }}</span>
        <div
          class="ss-spinner subscribe-spinner"
          *ngIf="isPreparingStoreTrial"
        ></div>
      </button>

      <div class="benefits">
        <div class="feature" *ngFor="let b of p.benefits">
          <div class="icon">
            <svg-icon
              *ngIf="p.subRole"
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/check-square.svg"
            ></svg-icon>
            <svg-icon
              style="opacity: 0.7"
              *ngIf="p.subRole == null"
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/close-square.svg"
            ></svg-icon>
          </div>
          <div class="name">
            {{ b }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="ss-subscribe-page-wrapper"
  *ngIf="isShowVerifyPhoneNumber && voipAttemptCount < 6"
>
  <div class="ss-subscribe-header-wrapper">
    <div class="ss-subscribe-header">Unlock 3 Day Trial</div>
    <div class="ss-subscribe-sub-header">
      Verify your phone number to unlock the trial. We will never send you
      marketing texts.
    </div>
  </div>

  <div class="ss-subscriptions">
    <div class="ss-verify-widget">
      <div
        class="sms-loading-wrapper"
        *ngIf="
          isSendingCode ||
          isUpdatingUserAccount ||
          isVerifyingCode ||
          isPreparingStoreTrial
        "
      >
        <div class="ss-spinner page-spinner"></div>
      </div>

      <section
        *ngIf="!isSendingCode && !isUpdatingUserAccount && !isVerifyingCode"
      >
        <section *ngIf="!isPhoneVerified && !isCodeSent">
          <div class="phone-header-text">Enter Mobile Number</div>
          <div class="phone-input-wrapper">
            <div class="prefix">+1</div>
            <input
              type="text"
              appPhoneFormat
              (input)="onPhoneNumberEntry($event.target.value)"
              class="ss-input phone-input expanded mb16"
              placeholder="XXX XXX XXXX"
              [(ngModel)]="phoneNumber"
            />
          </div>
          <app-message-prompt
            *ngIf="!isPhoneNumberUnique || isSuspectedVoip"
            type="warning auth"
            mt="24px"
            messageTitle="Trial Not Available for This Phone Number"
            messageBody="Number has already been verified by another account or is not a real 'Mobile' number."
          >
          </app-message-prompt>

          <app-message-prompt
            *ngIf="verificationError"
            type="danger auth"
            mt="24px"
            messageTitle="Check Your Number"
            messageBody="The number is not a valid number or there is an issue with your connection. Refresh and try again."
          >
          </app-message-prompt>
          <button
            class="ss-primary-btn mt24"
            [disabled]="isSendingCode || !isPhoneFormatValid"
            (click)="onClickConfirmPhoneNumber()"
          >
            Get Code
          </button>
          <div class="sms-info">
            Attempts to use voIP or Burner Services will remove trial option. US
            Only.
          </div>
        </section>

        <section *ngIf="isCodeSent && !isPhoneVerified">
          <div class="phone-header-text">Verification Code</div>
          <ng-otp-input
            (onInputChange)="onCodeChange($event)"
            [config]="{
              length: 6,
              allowNumbersOnly: true,
              inputClass: 'code-input',
              containerClass: 'sms-code-input-row'
            }"
          ></ng-otp-input>
          <app-message-prompt
            *ngIf="verificationError"
            type="danger auth"
            mt="24px"
            messageTitle="Invalid Code"
            messageBody="Try entering your code again."
          >
          </app-message-prompt>
          <div class="sms-info cursor-p" (click)="onRetryNumber()">
            Code sent to: {{ this.phoneNumber }}. Click to Retry
          </div>
        </section>
      </section>
    </div>
    <div class="cancel-wrapper">
      <div class="cancel-text-btn" (click)="onClickCancelVerification()">Cancel Verification</div>
    </div>
  </div>
</div>
