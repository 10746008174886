const BASE_SIM_OPTIONS = {
    totalSimulations: 50000,
    lineupGenerations: 5,
    topLineupsPct: 5,
    keepUserLineups: true,
    lineupSortWeights: {
        roi: 100,
        projection: 0,
        ownership: 0,
        top1Percent: 0,
        wins: 0,
    },
    projectionMinimum: 25,
    randomness: 100,
    minLineupSalary: 49200,
    maxPctOffOptimal: 15,
    defaultVar: 30,
};

const BASE_OPTO_OPTIONS = {
    projectionMinimum: 50,
    randomness: 100,
    minLineupSalary: 49200,
    numLineups: 150,
    numUniques: 1,
}

export const PGA_SIM_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_SIM_OPTIONS,
    },
    CLASSIC_FD: {
        ...BASE_SIM_OPTIONS,
    },
    SHOWDOWN_DK: {
        ...BASE_SIM_OPTIONS,
    },
    SHOWDOWN_FD: {
        ...BASE_SIM_OPTIONS,
    },
};

export const PGA_OPTO_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_OPTO_OPTIONS,
    },
    CLASSIC_FD: {
        ...BASE_OPTO_OPTIONS,
    },
    SHOWDOWN_DK: {
        ...BASE_OPTO_OPTIONS,
    },
    SHOWDOWN_FD: {
        ...BASE_OPTO_OPTIONS,
    },
};