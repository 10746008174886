import { Component, Input } from '@angular/core';
import { getStorage, ref, getDownloadURL, updateMetadata, uploadBytesResumable } from "firebase/storage";

@Component({
  selector: 'job-results-late-swap-data',
  templateUrl: './job-results-late-swap-data.component.html',
  styleUrls: ['./job-results-late-swap-data.component.scss']
})
export class JobResultsLateSwapDataComponent {
  @Input() jobData: any;
  activeSection: string = 'p1';
  columns: any;
  rows: any;

  // From old vet.
  storage: any;
  lateSwapPortfolios: any;
  selectedPortfolio: any;

  isCopyingCSV: boolean = false;
  showDownloadOptions: boolean = false;
  isDownloading: boolean = false;

  lineupRows: any = [];
  lineupColumns = [];

  ngOnInit() {
    this.setTableData('p1');
    this.storage = getStorage(); 
    this.columns = [1,2,3,4,5,6,7,8,9] // UPDATE WITH NFL COLUMNS
  }

  onToggleNav(section) {
    this.activeSection = section;
  }

  setTableData(portfolio) {

  }

  onClickShowDownloadOptions() {
    this.showDownloadOptions = true;
  }

  handleCloseDownloadOptionsModal() {
    this.showDownloadOptions = false;
  }

  generatePortfolioLineupsCSV(download, isEntriesMode) {
    this.showDownloadOptions = false;
    console.log(this.jobData.contest);
    let fileData = [];
    if (this.jobData.site === 'dk') {
      if (isEntriesMode) {
        fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
          ['Entry ID']: data['Entry ID'],
          ['Contest Name']: this.jobData.contest.name || 'undefined',
          ['Contest ID']: this.jobData.contest.id || 'undefined',
          ['Entry Fee']: this.jobData.contest.entryFee || 'undefined',
          PG: data.PG,
          SG: data.SG,
          SF: data.SF,
          PF: data.PF,
          C: data.C,
          G: data.G,
          F: data.F,
          UTIL: data.UTIL,
        }));
      } else {
        fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
          EntryId: data['Entry ID'],
          PG: data.PG,
          SG: data.SG,
          SF: data.SF,
          PF: data.PF,
          C: data.C,
          G: data.G,
          F: data.F,
          UTIL: data.UTIL,
          salary: data.Salary,
          originalProjection: data['Original Projection'],
          updatedProjection: data['Updated Projection'],
          avgFpts: data['Average Fpts'],
          avgROI: data['Average ROI'],
          avgRank: data['Average Rank'],
          stdFpts: data['Std Fpts'],
          stdRank: data['Std Rank'],
          winProb: data['Win Prob'],
        }));
      }
    } else {
      fileData = this.selectedPortfolio.portfolioLineups.map((data) => ({
        EntryId: data['Entry ID'],
        PG: data.PG,
        PG2: data.PG2,
        SG: data.SG,
        SG2: data.SG2,
        SF: data.SF,
        SF2: data.SF2,
        PF: data.PF,
        PF2: data.PF2,
        C: data.C,
        UTIL: data.UTIL,
        salary: data.Salary,
        originalProjection: data['Original Projection'],
        updatedProjection: data['Updated Projection'],
        avgFpts: data['Average Fpts'],
        avgROI: data['Average ROI'],
        avgRank: data['Average Rank'],
        stdFpts: data['Std Fpts'],
        stdRank: data['Std Rank'],
        winProb: data['Win Prob'],
      }))
    }

    const csv = this.convertArrayToCSV(fileData);

    if (download) {
      this.downloadCSV(csv, `Portfolio_Lineups_${this.jobData.contest.name}.csv`);
    } else {
      this.isCopyingCSV = true;
      setTimeout(() => {
        const textarea = document.createElement('textarea');
        textarea.value = csv;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setTimeout(() => {
          this.isCopyingCSV = false;
        }, 700);
      }, 1000);
    }
  }

  private convertArrayToCSV(array: any[]): string {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
    return header + rows.join('');
  }

  private downloadCSV(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }


  async downloadSummary() {
    let extension = 'csv';
    if (this.jobData?.isSummaryZip === true) {
      extension = 'zip';
    }
    this.isDownloading = true;
    const filePath = `sim_results/${this.jobData.userId}_${this.jobData.jobId}.${extension}`;
    const fileName = `Simulation_Results_${this.jobData.contest.name}.${extension}`;

    const storageRef = ref(this.storage, filePath);

    const metadata = {
      contentDisposition: `inline; filename="${fileName}"`,
    };

    try {
      await updateMetadata(storageRef, metadata);

      // Get the download URL and initiate the download
      const url = await getDownloadURL(storageRef);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
      URL.revokeObjectURL(link.href);
      this.isDownloading = false;
    } catch (error) {
      // Handle any errors
      console.error('Error downloading file:', error);
      this.isDownloading = false;
    }
  }

}
