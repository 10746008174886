<div class="ss-form-group" [ngClass]="{'inline-input': isInline}">
    <div class="flex space-between tool-tip-row">
      <label>{{ label }}</label>
      <tool-tip *ngIf="tooltip" position="right" [explanation]="tooltip"></tool-tip>
    </div>
    <div class="" [ngClass]="{'percentage-input ss-input-append-wrapper': isPercentage}">
      <input appSelectAllText type="number" class="ss-input expanded"
        [(ngModel)]="ngModel" (ngModelChange)="onModelChange($event)"
        [ngClass]="{ 'error' : isError, 'has-percentage' : isPercentage }">
      <div *ngIf="isPercentage" class="ss-input-append-container">
        %
      </div>
    </div>
    <div class="field-error" *ngIf="isError">
      {{ errorMessage }}
    </div>
</div>