import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  userSubmittedEmail: string;
  emailSent = false;

  resetForm: UntypedFormGroup;
  errorMsg = '';
  isProcessing = false;
  auth: any;


  errorMsgs = {
    email: [
      {
        type: 'required',
        message: 'Provide email.'
      },
      {
        type: 'pattern',
        message: 'Email is not valid.'
      }
    ]
  };

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.resetForm = this.fb.group({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    });
  }

  onResetPassword() {
    this.isProcessing = true;
    this.auth = getAuth();
    sendPasswordResetEmail(this.auth, this.userSubmittedEmail)
  .then(() => {
    this.isProcessing = false;
    this.emailSent = true;
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    this.isProcessing = false;
    this.emailSent = true;
  });
  }

  onBackToLogin() {
    this.router.navigate(['/login']);
  }

}
