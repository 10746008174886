<div class="nav">
    <div class="ss-nav">
        <div class="ss-nav-items-wrapper">
            <div class="ss-nav-left">
                <div class="ss-logo-wrapper" (click)="onClickLogo()">
                    <svg-icon [svgStyle]="{ 'height.px':44 }" src="/assets/icons/ace_skull.svg"></svg-icon>
                    <div class="brand">
                        <Span class="logo-bold">Ace</Span>
                        <Span class="logo-bold">Mind</Span>
                        <!-- <div class="slogan">Calibrate. Dominate. Win.</div> -->
                    </div>
                </div>
                <div *ngIf="isAuthenticated && user && showAvatarSection" class="link-wrapper">
                    <a class="link" routerLink="/dashboard" [class.active]="activeLinkSpace === 'dashboard'">
                        Home
                    </a>
                    <a class="link" routerLink="/run"
                        [class.active]="activeLinkSpace === 'run'">
                        Sim / Opto
                    </a>
                    <a href="#" class="link" routerLink="/results"
                        [class.active]="activeLinkSpace === 'results'">
                        Results
                    </a>
                    <!-- <a class="link" routerLink="/entries" (click)="onClickMainMenu('Entries')"
                        [class.active]="activeLinkSpace === 'entries'">
                        My Entries
                    </a> -->
                </div>
            </div>
            <div *ngIf="!isAuthenticated && !isLoading" class="ss-nav-links">
                <a class="nav-link" routerLink="/login">
                    Login
                </a>
                <a class="nav-link-btn" routerLink="/sign-up">
                    Sign Up
                </a>
            </div>
            <div *ngIf="isAuthenticated && !showAvatarSection" class="ss-nav-links">
                <a class="nav-link" (click)="onClickSignOut()">
                    Logout
                </a>
            </div>
            <div *ngIf="isAuthenticated && !isLoading && user && showAvatarSection" class="ss-avatar-wrapper"
                (click)="onShowUserMenu()">
                <section class="desktop">
                    <div class="ss-nav-user-info">
                        <div class="name">{{user?.displayName}}</div>
                    </div>
                    <div class="ss-nav-avatar">
                        <img class="avatar-img" *ngIf="user.photoURL" src="{{user.photoURL}}">
                        <img *ngIf="!user.photoURL" src="/assets/illustrations/avatar.svg" height="100%" width="100%" />
                    </div>
                </section>
                <section class="mobile">
                    <div class="burger">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </section>
            </div>
            <div [hidden]="!showUserMenu" class="ss-nav-user-menu" #menu>
                <section class="mobile-set">
                    <div class="menu-item" (click)="onClickMenuLink('/dashboard')">
                        <svg-icon src="/assets/icons/home-outline.svg"></svg-icon>
                        Dashboard
                    </div>
                    <div class="menu-item" (click)="onClickMenuLink('/run')">
                        <svg-icon src="/assets/icons/cube-outline.svg"></svg-icon>
                        Simulator / Optimizer
                    </div>
                    <div class="menu-item" (click)="onClickMenuLink('/results')">
                        <svg-icon src="/assets/icons/go-to-board.svg"></svg-icon>
                        Results
                    </div>
                </section>
                <div class="menu-item" (click)="onClickMenuLink('discord')">
                    <svg-icon src="/assets/logos/discord-square-chip.svg"></svg-icon>
                    Connect on Discord
                </div>
                <div class="menu-item" (click)="onClickMenuLink('/account')">
                    <svg-icon src="/assets/icons/settings-cog.svg"></svg-icon>
                    Account & Settings
                </div>
                <div class="menu-item" (click)="onClickSignOut()">
                    <svg-icon src="/assets/icons/logout.svg"></svg-icon>
                    Logout
                </div>
            </div>
        </div>
    </div>

</div>