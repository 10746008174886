<div class="filter-wrapper">
    <div class="delete-filter-wrap" (click)="onClickDeleteFilter($event)">
        <svg-icon
        [svgStyle]="{ 'height.px': 9 }"
        src="/assets/icons/close-solo.svg"
      ></svg-icon>
    </div>
    <div class="inputs-row">

        <div class="operator-row">
            <div class="field-label">Operator</div>
            <div class="segments">
                <div class="segment" (click)="onClickOperator('less-than-or-equal-to')" [ngClass]="{ 'active' : filter.operator === 'less-than-or-equal-to'}">
                    <=
                </div>
                <div class="segment" (click)="onClickOperator('equal-to')" [ngClass]="{ 'active' : filter.operator === 'equal-to'}">
                    ==
                </div>
                <div class="segment" (click)="onClickOperator('greater-than-or-equal-to')" [ngClass]="{ 'active' : filter.operator === 'greater-than-or-equal-to'}">
                    >=
                </div>
            </div>

            <div class="label">
                {{filter.operator}}
            </div>

        </div>

        <div class="input">
            <div class="field-label">Filter Value</div>
            <input
            appSelectAllText
            type="number"
            class="ss-input expanded"
            id="filterValue"
            [(ngModel)]="filter.value"
            [ngClass]="{
              error:
              filter.value < filter.min ||
              filter.value == null ||
              filter.value > filter.max
            }"
          />
        </div>

    </div>

    <div
    class="field-error"
    *ngIf="
    filter.value < filter.min ||
    filter.value == null ||
    filter.value > filter.max
    "
  >
    Enter Value Between {{ filter.min }} and
    {{ filter.max }}
  </div>

</div>
