<div class="ss-base-card p16">

    <div class="lineups-info-wrapper">
        <div class="lineups-info-header">
            Add Custom Lineups to Sim
        </div>
        <div class="lineups-info-desc">
            The sim will consider these lineups along with the generated lineups to fill the contest.
        </div>
    </div>

    <div class="opto-job-utility-wrapper" *ngIf="jobData.contest && !fileURL && !isFileUploading">
        <div class="optimizer-available-prompt-wrapper" *ngIf="availableOptoJobs && availableOptoJobs.length > 0"
            (click)="onClickSelectOptoJobs()">
            <div class="icon">
                <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/power-chip.svg"></svg-icon>
            </div>
            <div class="message">
                {{availableOptoJobs.length}} Optimizer Jobs Available to Import
            </div>
        </div>
    </div>

    <div class="ss-add-file-row" *ngIf="!fileURL && !isFileUploading">
        <div class="file-drop-section">
            <modal-csv-capture (fileAdded)="readFileContent($event)"></modal-csv-capture>
        </div>

        <div class="download-template-btn" (click)="generateLineupsTemplateCSV()">
            Download Lineups Template
        </div>
    </div>

    <div class="flex space-between items-center mb16" *ngIf="fileURL && validationComplete">
        <div class="file-title-row">
            <div class="label">
                Lineups Addded
            </div>
            <div class="action" (click)="clearCustomData()">
                Clear
            </div>
        </div>

        <div class="download-btn" (click)="downloadFile()">
            <div class="icon">
                <svg-icon [svgStyle]="{ 'height.px':16 }" src="/assets/icons/csv-icon.svg"></svg-icon>
            </div>
            <div class="text">
                Download
            </div>
        </div>
    </div>

    <div class="ss-job-progress-log-wrapper" [ngClass]="{ active : isFileAdded && !validationComplete}">

        <div class="progress-line" *ngIf="!jobData.optimizerSeed">
            <div class="text">Waiting for lineups template...</div>
        </div>

        <div class="progress-line" *ngIf="jobData.optimizerSeed">
            <div class="text">Optimizer Job Detected</div>
        </div>

        <div class="progress-line" *ngIf="jobData.optimizerSeed">
            <div class="text">Fetching your optimized lineups...</div>
        </div>

        <div class="progress-line" *ngIf="isFileAdded">
            <div class="ss-spinner" *ngIf="!recordsAdded"></div>
            <div class="text">Preparing Your Lineups File</div>
        </div>

        <div class="progress-line" *ngIf="recordsAdded">
            <div class="text">Rows detected: {{recordsAdded}}</div>
        </div>

        <div class="progress-line" *ngIf="isFileUploading || fileURL">
            <div class="ss-spinner" *ngIf="isFileUploading"></div>
            <div class="text">Uploading file for validation: {{fileUploadProgress || '100'}}%</div>
        </div>

        <div class="progress-line" *ngIf="isFileValidating || validationComplete">
            <div class="ss-spinner" *ngIf="isFileValidating"></div>
            <div class="text">Validating Your File</div>
        </div>

        <div class="progress-line" *ngIf="validationComplete && validationResponse?.message">
            <div class="text">{{validationResponse?.message}}</div>
        </div>

        <div class="progress-line" *ngIf="validationComplete && fileURL">
            <div class="text">Lineups Added to Job</div>
        </div>

    </div>

</div>


<base-edit-overlay *ngIf="isSelectingOptoJobs" (closeOverlay)="handleCloseOverlay()">

    <div class="ss-modal-header">
        <div class="header-name" style="color: var(--ss-text-primary)">Import Optimized Lineups</div>
        <div class="header-desc" style="color: var(--ss-text-secondary)">Select one or more jobs to consolidate lineups and add to this job</div>
    </div>

    <div class="job-list">
        <div class="template-row" *ngFor="let j of availableOptoJobs">
            <div class="template-item" [ngClass]="{ 'selected' : j.selected}" (click)="onSelectOptoJob(j)">
                <div class="selected-chip" *ngIf="j.selected">
                    <svg-icon [svgStyle]="{ 'height.px':26 }" src="/assets/icons/selected-chip.svg"></svg-icon>
                </div>
                <div class="info">
                    <div>
                        <svg-icon *ngIf="j.site === 'dk'" [svgStyle]="{ 'height.px':32 }"
                            src="/assets/logos/dk/dk-logo-icon.svg"></svg-icon>
                        <svg-icon *ngIf="j.site === 'fd'" [svgStyle]="{ 'height.px':32 }"
                            src="/assets/logos/fd/fd-logo-icon.svg"></svg-icon>
                    </div>
                    <div>
                        <div class="job-name">Optimizer: {{j.slate}}
                        </div>
                        <div class="flex">
                            <div class="job-detail">Completed: {{ j.updatedAt.toDate() | date : 'MM/dd/yyyy hh:mm a' }}</div>
                            <div class="job-detail">Lineups: {{j.optoResult?.length}}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="import-modal-actions" *ngIf="totalSelectedLinups > 0">
        <button class="ss-primary-btn mt24" (click)="onClickImportSelectedOptoLineups({download:false})">Import
            {{totalSelectedLinups}} Lineups</button>
        <a (click)="onClickImportSelectedOptoLineups({download:true})">Download and Edit Combined Lineups</a>
    </section>

</base-edit-overlay>

<base-edit-overlay *ngIf="isSubscriptionLimit" (closeOverlay)="handleCloseOverlay()">
    <div class="ss-upgrade-modal-wrapper">
        <div class="logo">
            <svg-icon [svgStyle]="{ 'height.px':88 }" src="/assets/logos/ace-mind-logo-mono.svg"></svg-icon>
        </div>
        <div class="title">Ace Pro</div>
        <div class="sub-title">Upgrade to add more than 2,000 Lineups and run more jobs on our faster engine.</div>
        <button class="ss-secondary-btn mt24" routerLink="/account">Manage Account</button>
    </div>
</base-edit-overlay>