<div *ngIf="showTimer" class="slate-lock-wrapper">
    <div class="lock-icon"><svg-icon class="icon" [svgStyle]="{ 'height.px':16 }" src="/assets/icons/lock.svg"></svg-icon></div>
    <div class="lock-countdown non-selectable">
        {{ countdown.minutes }}:{{ countdown.seconds }}
    </div>
</div>

<div *ngIf="isStartTimeInPast" class="slate-lock-wrapper">
    <div class="lock-icon"><svg-icon class="icon" [svgStyle]="{ 'height.px':18 }" src="/assets/icons/lock.svg"></svg-icon></div>
    <div class="lock-label non-selectable">
        Locked
    </div>
</div>