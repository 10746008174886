import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DraftGroupService } from 'src/app/services/draft-group.service';
import { JobsService } from 'src/app/services/jobs.service';
import { StateService } from 'src/app/services/state.service';
import { mapPresetToSport } from '../../utilities/preset-mapping-fuctions';

@Component({
  selector: 'draft-group-data',
  templateUrl: './draft-group-data.component.html',
  styleUrls: ['./draft-group-data.component.scss'],
})
export class DraftGroupDataComponent {
  @Input() jobData: any;
  @Output() importComplete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('presetList') presetList!: ElementRef;

  availableDraftGroupPresets = [];
  isApplyingGroupPreset: boolean;
  showPresets: boolean;
  isDeletingPreset: boolean;
  deletePresetIndex: any;

  isCopyingCSV: boolean;
  copyCvsMessage: string = '';

  importMode: any;

  constructor(
    private draftGroupService: DraftGroupService,
    private stateService: StateService,
    private renderer: Renderer2,
    private jobsService: JobsService,
  ) { }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (this.showPresets) {
      if (this.availableDraftGroupPresets.length > 0) {
        const elementInDOM = this.renderer.selectRootElement(this.presetList.nativeElement, true);
        if (elementInDOM) {
          if (this.showPresets && !this.isDeletingPreset && !this.presetList.nativeElement.contains(event.target)) {
            this.showPresets = false;
          }
        }
      }
    }
  }

  ngOnInit() {
    if (this.jobData.sport === 'PGA' && this.jobData?.isTournament && this.jobData.jobType === 'simulator') {
      this.importMode = `sim-pga-${this.jobData.site}-${this.jobData.jobSubType}-tournament`;
    } else {
      this.importMode = `${this.jobData.jobType === 'simulator' ? 'sim' : 'opto'}-${this.jobData.sport.toLowerCase()}-${this.jobData.site}-${this.jobData.jobSubType}`;
    }
    this.loadDraftGroupPresets();
  }

  loadDraftGroupPresets() {
    const id = this.jobData.draftGroupId;
    this.draftGroupService.getUserDraftGroupPresets(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        this.availableDraftGroupPresets = data;
      }
    })
  };

  generateTemplateCSV() {
    const dataWithoutSelected = this.jobData.draftGroup.map(({ selected, ...rest }) => rest);
    const csv = this.convertArrayToCSV(dataWithoutSelected);
    this.downloadCSV(csv, `Projection_Template_${this.jobData.sport}.csv`);
  }

  private convertArrayToCSV(array: any[]): string {
    // Get the keys (header)
    const keys = Object.keys(array[0]);
    const isFlexOwnership = this.jobData.sport === 'NFL' && this.jobData.jobSubType === 'showdown';
  
    // Conditionally replace 'Ownership' with 'FLEX Ownership' if sport is 'nba'
    const header = keys
      .map((key) => (isFlexOwnership && key === 'Ownership' ? 'FlexOwnership' : key))
      .join(',') + '\n';
  
    // Create the CSV rows
    const rows = array.map((obj) => Object.values(obj).join(',') + '\n');
  
    // Return the complete CSV string
    return header + rows.join('');
  }
  

  private downloadCSV(csv: string, filename: string) {
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  copyCsvToClipboard() {
    this.isCopyingCSV = true;
    setTimeout(() => {
      const dataWithoutSelected = this.jobData.draftGroup.map(({ selected, BattingOrder, ...rest }) => rest);
      const csv = this.convertArrayToCSV(dataWithoutSelected);

      const textarea = document.createElement('textarea');
      textarea.value = csv;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';

      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.copyCvsMessage = 'Data Copied to Clipboard'

      setTimeout(() => {
        this.isCopyingCSV = false;
        this.copyCvsMessage = 'Fetching Data...';
      }, 1500);
    }, 1000);
  };

  onClickShowPresets() {
    if (this.showPresets) {
      return;
    }
    setTimeout(() => {
      this.showPresets = true;
    }, 200);
  }

  onRemovePreset(id, index) {
    if (this.isDeletingPreset) {
      return;
    }
    this.deletePresetIndex = index;
    this.isDeletingPreset = true;
    this.draftGroupService.deleteUserDraftGroupPreset(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletePresetIndex = null;
          this.isDeletingPreset = false;
          this.loadDraftGroupPresets();
        }, 700);
      }
    })
  }

  onClickApplyDraftGroupPreset(set) {
    this.showPresets = false;
    this.isApplyingGroupPreset = true;
    this.handleApplyPresetData(set);
    // setTimeout(() => {
    //   this.handleApplyPresetData(set);
    // }, 400);
  };

  async handleApplyPresetData(set) {
    const mappedGroup =  await mapPresetToSport(this.jobData.sport, this.jobData, set);
    mappedGroup.sort((a,b) => b.selected - a.selected);
    this.jobData.draftGroup = mappedGroup;
    this.stateService.setProjectionDraftGroup(mappedGroup);
    this.jobData.draftGroupPresetId = set.id;
    this.isApplyingGroupPreset = false;
    this.jobsService.saveJobDataSnapshot(this.jobData);
    this.importComplete.emit();
  };

  handleImportComplete() {
    this.importComplete.emit();
  }

}
