<div class="ss-page-wrapper auth">
  <div *ngIf="!isProcessingProvider" class="ss-auth-content ss-modal-card">
    <!-- <div class="ss-auth-header">
            <span>Almost Time...</span>
         </div>
         <div class="auth-sub-header">We are still working on a few things.  Stay tuned.</div> -->

    <div class="ss-auth-header mb24">
      Create Account
    </div>
    <div class="ss-auth-form">
      <form class="form" [formGroup]="userForm"
        (ngSubmit)="signUp(userForm.value.name, userForm.value.email, userForm.value.password)">

        <div class="ss-form-group">
          <input class="ss-input expanded bordered" type="text" autocomplete="name" formControlName="name"
            placeholder="Your Name" (focus)="onFieldIn()" />
        </div>

        <div class="ss-form-group">
          <input class="ss-input expanded bordered" type="email" name="email" autocomplete="email" formControlName="email"
            placeholder="you@something.com" (focus)="onFieldIn()" />
        </div>

        <div class="ss-form-group">
          <input class="ss-input expanded bordered" type="password" formControlName="password"
            placeholder="Set Password" autocomplete="off" (focus)="onFieldIn()" required autocomplete="new-password"/>
          <div class="validator-bar">
            <div>
              <span class="value"
                [ngClass]="{'valid' : !userForm.get('password').hasError('specialChar') && userForm.get('password').value.length > 0}">Special
                Char</span>
              &nbsp;/&nbsp;
              <span class="value"
                [ngClass]="{'valid' : !userForm.get('password').hasError('hasNumber') && userForm.get('password').value.length > 0}">Number</span>
            </div>
            <div class="value" [ngClass]="{'valid' : userForm.get('password').value.length >= 8}">
              8+ Characters
            </div>
          </div>
        </div>

        <ng-container *ngFor="let error of errorMsgs.email">
          <app-message-prompt *ngIf="userForm.get('email').hasError(error.type) && userForm.get('email').touched"
            type="warning" mb="24px" messageTitle="Check your email" messageBody="{{ error.message }}">
          </app-message-prompt>
        </ng-container>


        <app-message-prompt *ngIf="errorMsg" type="danger auth" mb="24px" messageTitle="Nope. Try Again."
          messageBody="{{ errorMsg }}">
        </app-message-prompt>

        <button class="ss-primary-btn mt32" [disabled]="!userForm.valid || isProcessing">
          {{isProcessing ? '' : 'Sign Up'}}
          <span *ngIf="isProcessing" class="ss-spinner btn-spinner"></span>
        </button>

        <hr class="mt24 mb24" />

        <div class="ss-secondary-btn o-auth" (click)="signUpGoogle()">
          <svg-icon src="/assets/icons/google.svg"></svg-icon>
          Continue with Google
        </div>

      </form>
    </div>

    <div class="ss-terms-prompt-wrapper">
      By continuing you agree to these
      <a routerLink="/terms">Terms</a> and 
      <a routerLink="/privacy">Privacy Policy</a>
    </div>
    
  </div>
</div>