import { Component, HostListener, Input } from '@angular/core';
import { getAuth } from 'firebase/auth';

import setJobOptions from 'src/app/pages/portal/job-creator/job-utilities/set-job-options';

import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'pga-opto-options',
  templateUrl: './pga-opto-options.component.html',
  styleUrls: ['./pga-opto-options.component.scss']
})
export class PgaOptoOptionsComponent {
  @Input() jobData: any;

  user: any;

  activeGroup: string = 'settings';

  availablePresets: any;
  isDefaultApplied: boolean;
  presetName: string;
  selectedPreset: any = null;
  showPresets: boolean;
  showSavePreset: boolean;
  deletePresetIndex: any;
  isMakingPresetDefault: boolean;
  defaultPresetExists: boolean;
  makePresetDefaultIndex: any;
  isSavingPreset: boolean;
  isDeletingPreset: boolean;
  valuesDirty: boolean;

  totalSelectedPlayers: any;
  searchablePlayers: any;
  siteSalaryMax: any;
  isSalaryMaxEntryLimit: boolean;

  // rules: any = [];
  ruleError: string;
  showRuleForm: boolean;
  ruleType: string = 'at-least';
  ruleValue: number = 1;
  rulePlayers: any = [];

  showImportModal: boolean = false;
  importMode: string = null;

  constructor(
    private jobsService: JobsService,
  ) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
      // Prevent the wheel event for number inputs
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.mapDraftGroupData();
    this.getUserOptionPresets({ skipMatch: false, setDefaults: false });
  }

  ngAfterContentInit() {
    if (this.jobData?.contest?.maximumEntries > 100000) {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 49500;
      } else {
        this.siteSalaryMax = 59500;
      }
      this.isSalaryMaxEntryLimit = true;
    } else {
      if (this.jobData.site === 'dk') {
        this.siteSalaryMax = 50000;
      } else {
        this.siteSalaryMax = 60000;
      }
    }
  }

  handleRulesUpdate() {
    this.jobsService.saveJobDataSnapshot(this.jobData);
  }


  // PLAYER RULE
  onClickAddRule() {
    this.ruleType = 'at-least';
    this.ruleValue = 1;
    this.rulePlayers = [];
    this.showRuleForm = true;
  }

  onClickSaveRule() {
    const newRule = {
      type: this.ruleType,
      value: this.ruleValue,
      players: this.rulePlayers,
    }
    const rulesToUpdate = this.jobData.rules || [];
    rulesToUpdate.push(newRule);
    this.jobData.rules = rulesToUpdate;
    this.handleRulesUpdate();
    this.showRuleForm = false;
  }

  onClickRemoveRule(index) {
    this.jobData.rules.splice(index, 1);
    this.handleRulesUpdate();
  }

  onClickCancelRule() {
    this.showRuleForm = false;
  }

  onClickRemoveStagedPlayer(index) {
    this.rulePlayers.splice(index, 1);
  }

  onSelectPlayer(data) {
    this.ruleError = null;
    const playerExists = this.rulePlayers.some(player => player === data);
    if (playerExists) {
      this.ruleError = 'Player Already Exists';
      return;
    }
    this.rulePlayers.push(data);
  }

  onValueChange(options) {
    if (options.userChange) {
      this.valuesDirty = true;
    }

    const isValid = this.validateOptionValues();
    if (isValid) {
      this.jobData.optionsValid = true;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    } else {
      this.jobData.optionsValid = false;
      this.jobsService.saveJobDataSnapshot(this.jobData);
    }
  }

  validateOptionValues() {
    const conditions = [
      { condition: this.jobData.options.projectionMinimum < 0 || this.jobData.options.projectionMinimum == null },
      { condition: this.jobData.options.randomness < 0 || this.jobData.options.randomness > 1000 || this.jobData.options.randomness == null },
      { condition: this.jobData.options.minLineupSalary < 0 || this.jobData.options.minLineupSalary > this.siteSalaryMax || this.jobData.options.minLineupSalary == null },
      { condition: this.jobData.options.numLineups < 0 || this.jobData.options.numLineups > 5000 || this.jobData.options.numLineups == null },
      { condition: this.jobData.options.numUniques < 1 || this.jobData.options.numUniques > 5 || this.jobData.options.numUniques == null }
    ];

    const isInvalid = conditions.some(({ condition }) => condition);
    return !isInvalid;
  }

  mapDraftGroupData() {
    const searchData = this.jobData.draftGroup.map(player => ({
      name: player.Name,
      id: player.PlayerId,
      ...(player.Position ? { position: player.Position } : {}),
    }));
    this.searchablePlayers = searchData;
  };

  getUserOptionPresets(options) {
    this.jobsService.getJobOptionPresetsByUserId(this.jobData.userId).then((data) => {
      if (data) {
        const presets = data;
        const applicablePresets = presets.filter((set) =>
          set.data.sport === this.jobData.sport
          && set.data.jobType === this.jobData.jobType &&
          set.data.jobSubType === this.jobData.jobSubType)
        this.availablePresets = applicablePresets.sort((a, b) => {
          return b.data.updatedAt - a.data.updatedAt;
        });
        this.defaultPresetExists = applicablePresets.some((set) => set.data.isDefault);
        if (this.jobData.optionPresetId && !options.skipMatch) {
          const jobPreset = this.availablePresets.find((set) => set.id === this.jobData.optionPresetId);
          if (jobPreset !== undefined) {
            this.presetName = jobPreset.data.name;
            this.onSelectOptionPreset(jobPreset.data, jobPreset.id);
          } else {
            this.presetName = 'Preset Deleted - Data Still Applied'
          }
        } else if (this.defaultPresetExists && options.skipMatch === false) {
          const defaultPreset = applicablePresets.find((set) => set.data.isDefault);
          this.onSelectOptionPreset(defaultPreset.data, defaultPreset.id);
          this.isDefaultApplied = true;
          setTimeout(() => {
            this.isDefaultApplied = false;
          }, 4200);
        } else if (!options.skipMatch) {
          this.presetName = 'Default Options'
          if (options.setDefaults !== false) {
            this.onClickRestoreDefaults();
          }
        }
      }
    })
  }

  onSelectOptionPreset(preset, id) {
    this.presetName = preset.name;
    this.selectedPreset = {
      id: id,
      preset: preset,
    };
    const newValues = preset.presetValues;

    this.jobData.optionPresetId = id;
    this.jobData.options = newValues;

    this.onValueChange({ userChange: false });
    this.showPresets = false;
  }

  onClickShowSave() {
    this.showSavePreset = true;
  }

  onCancelSavePreset() {
    this.showSavePreset = false;
  }

  onRemovePreset(id, index) {
    if (this.isDeletingPreset) {
      return;
    }
    this.deletePresetIndex = index;
    this.isDeletingPreset = true;
    this.jobsService.deleteOptionPresetById(this.jobData.userId, id).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.deletePresetIndex = null;
          this.isDeletingPreset = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onClickSetPresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: true,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.isMakingPresetDefault = false;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
      }
    })
  }

  onSaveJobPreset(name) {
    this.isSavingPreset = true;
  
    const presetValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});
  
    const preset = {
      isDefault: false,
      presetValues: presetValues,
      name: name,
    };
  
    this.jobsService.createJobOptionPreset(
      this.jobData.userId,
      this.jobData.sport,
      this.jobData.jobType,
      this.jobData.jobSubType,
      preset,
    ).then((res) => {
      if (res) {
        this.presetName = name;
        this.valuesDirty = false;
        this.selectedPreset = {
          id: res,
          preset: preset,
        };
  
        this.jobData.optionPresetId = res;
        this.jobsService.saveJobDataSnapshot(this.jobData);
        setTimeout(() => {
          this.getUserOptionPresets({ skipMatch: true });
          this.showSavePreset = false;
          this.isSavingPreset = false;
        }, 1000);
      }
    });
  }

  onClickUpdateExistingPreset() {
    this.isSavingPreset = true;

    const newValues = Object.keys(this.jobData.options).reduce((acc, key) => {
      acc[key] = this.jobData.options[key] || 0;
      return acc;
    }, {});

    this.jobsService.updateJobOptionPresetValues(this.jobData.userId, this.selectedPreset.id, newValues).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.isSavingPreset = false;
          this.valuesDirty = false;
          this.getUserOptionPresets({ skipMatch: true, setDefaults: false });
        }, 700);
      }
    })
  }

  onClickRemovePresetDefault(id, index, preset) {
    if (this.isMakingPresetDefault) {
      return;
    }
    const payload = {
      ...preset,
      isDefault: false,
    }
    this.makePresetDefaultIndex = index;
    this.isMakingPresetDefault = true;
    this.jobsService.updateJobOptionPreset(this.jobData.userId, id, payload).then((data) => {
      if (data !== null) {
        setTimeout(() => {
          this.makePresetDefaultIndex = null;
          this.getUserOptionPresets({ skipMatch: true });
        }, 700);
        setTimeout(() => {
          this.isMakingPresetDefault = false;
        }, 800)
      }
    })
  }

  onClickShowPresets() {
    if (this.showPresets) {
      return;
    }
    setTimeout(() => {
      this.showPresets = true;
    }, 200);
  }

  async onClickRestoreDefaults() {
    this.presetName = 'Default Options';
    this.selectedPreset = null;
    this.showPresets = false;

    const options = await setJobOptions(this.jobData.sport, this.jobData.site, this.jobData.jobType, this.jobData.jobSubType);
    this.jobData.options = options;
    this.jobData.optionPresetId = null;

    this.onValueChange({ userChange: false });
  }

  preventScroll(event: WheelEvent) {
    event.preventDefault();
  }

  // RULE IMPORT 

  onClickImportPlayerRules() {
    this.showImportModal = true;
    this.importMode = 'playerRules';
  }

  handleImportPlayerRules(importRules) {
    importRules.forEach((c) => {
      if (this.jobData.rules.some((rule) => rule === c)) {
        console.log('Rule already exists');
      } else {
        this.jobData.rules.push(c);
      }
    })
    this.handleRulesUpdate();
    this.handleCloseImportModal();
  }

  handleCloseImportModal() {
    this.showImportModal = false;
    this.importMode = null;
  }

  onToggleGroup(group) {
    this.activeGroup = group;
  }

}
