// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lineupBuilderVersion: 0.0,
  stripe: {
    apiKey: 'rk_test_51NejB4AkcA4MXJW6ZuBaTXH7nzYdEzTV939BC1SQAKpi42m3XpxyIdUqSB3kgbikRV8vziwwW97ocHKJFYokbN5k00AeoE88yb',
  },
  twilio: {
    verifyURL: 'https://us-central1-acemind---dev.cloudfunctions.net/ace_sms_verification_handler'
  },
  firebase: {
    apiKey: "AIzaSyD_tbTNYVn9Ye867l_klafS37AnGV-Nuf8",
    authDomain: "acemind---dev.firebaseapp.com",
    projectId: "acemind---dev",
    storageBucket: "acemind---dev.appspot.com",
    messagingSenderId: "951776501459",
    appId: "1:951776501459:web:11bcf192ebdf6a87ee7e26",
    measurementId: "G-0JPZWTWTWB"
  },
  mixpanelToken: '15683b72c2f7c8bbcffd2497c63e6e47',
  apiURL: 'https://us-central1-acemind---dev.cloudfunctions.net',
  subscription: {
    pro: 'price_1OZLysAkcA4MXJW61CmTldVI',
    pro_annual: 'price_1OZM17AkcA4MXJW6zeVkr8uQ',
  },
  nonTrialSubscription: {
    pro: 'price_1Oe5l6AkcA4MXJW6ArA474oN',
    pro_annual: 'price_1Oe5n2AkcA4MXJW6uV6IV6LM',
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
