import { Component, DoCheck, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KNOWN_CEILING_HEADERS, KNOWN_PROJECTION_HEADERS, KNOWN_STDDEV_HEADERS } from 'src/app/constants/mappings/projection-mappings';
import { StateService } from 'src/app/services/state.service';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'stddev-sniper',
  templateUrl: './stddev-sniper.component.html',
  styleUrls: ['./stddev-sniper.component.scss']
})
export class StddevSniperComponent implements DoCheck, OnChanges, OnInit {
  @Input() draftGroup: any;
  @Input() jobData: any;
  @Input() csvData: any;
  @Input() playerNameKey: any;
  @Input() userDefinedProjectionValue: any;
  @Input() userProfile: any;
  @Input() requirements: any;

  @Output() handleUnitValid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUnitInvalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeyAdditions: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleUpdateUserKeysToRemove: EventEmitter<any> = new EventEmitter<any>();

  showSniper: boolean = false;
  valueToMap: string = 'StdDev';
  valueToValidate: string = 'stdDev';
  snipedValue: any;
  isManualSelection: boolean = false;
  keysToSelect: string[] = [];

  userHeaderMappings: any;
  isCustomMapping: boolean = false;
  userProjectionMappings: any = [];
  mlbImportMatchingMethod: any;
  canImportById: any;

  sniperMessage: string = 'Analyzing...'
  isLoading: boolean = true;
  isKeyValid: boolean = false;
  isExactStdDev: boolean = false;
  previousIsKeyValid: boolean = false;

  isErrorCalculating: boolean = false;

  fixOptions: any = [
    { name: 'Choose one of my columns', action: 'select-column' },
    { name: 'Skip and leave blank', action: 'skip' }
  ]

  private importMethodSub: Subscription;
  private importByIdSub: Subscription;

  constructor(private stateService: StateService) {
    this.importMethodSub = this.stateService.mlbImportMatchingMethod$.subscribe((value) => {
      this.mlbImportMatchingMethod = value;
    });
    this.importByIdSub = this.stateService.canImportById$.subscribe((value) => {
      this.canImportById = value;
    });
  }

  ngDoCheck(): void {
    if (this.isKeyValid !== this.previousIsKeyValid) {
      if (this.isKeyValid) {
        this.handleUnitValid.emit(this.valueToMap);
      } else {
        this.handleUnitInvalid.emit(this.valueToMap);
      }
      this.previousIsKeyValid = this.isKeyValid;
    }
  }

  ngOnInit() {
    this.userHeaderMappings = this.userProfile?.importMappings?.[this.valueToValidate] || [];
    this.userProjectionMappings = this.userProfile?.importMappings?.projection || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userDefinedProjectionValue && this.userDefinedProjectionValue !== undefined && !this.isExactStdDev) {
      if (this.requirements.includes(this.valueToMap)) {
        this.sniperMessage = "Attempting to Calculate...";
        this.snipeValue();
      }
    }
  };

  selectKey(key: string): void {
    this.snipedValue = key;
    this.handleUpdateUserKeyAdditions.emit({ type: this.valueToValidate, value: key });
    this.isKeyValid = true;
    this.sniperMessage = `Column: ${this.snipedValue}`;
    this.isManualSelection = true;

    this.snipeValue();
  }

  snipeValue() {
    this.isKeyValid = false;
    this.isLoading = true;
    this.showSniper = true;

    setTimeout(() => {
      const knownKeys = KNOWN_STDDEV_HEADERS.concat(this.userHeaderMappings);
      let needsCalculation = false;

      let matchingKey;
      if (this.isManualSelection) {
        // USER SELECTED KEY USED
        matchingKey = this.snipedValue;
      } else {
        this.isManualSelection = false;
        matchingKey = knownKeys.find(key => this.csvData[0].hasOwnProperty(key));
      }

      // Use the selected key to process data objects
      this.csvData.forEach((dataObj) => {
        if (matchingKey) {
          if (this.userHeaderMappings.some((k) => k === matchingKey)) {
            this.isCustomMapping = true;
            this.snipedValue = matchingKey;
          }
          this.isKeyValid = true;
          this.sniperMessage = `Column: ${matchingKey}`;
          this.processDataObject(dataObj, matchingKey);
        } else {
          this.snipedValue = null;
          // this.isKeyValid = false;
          // this.sniperMessage = `No ${this.valueToMap} Column Detected`;
          this.isKeyValid = false;
          needsCalculation = true;
        }

        this.keysToSelect.push(...Object.keys(dataObj));
      });

      if (needsCalculation) {
        this.sniperMessage = "Attempting to Calculate...";
        this.calculateStdDev();
      }

      this.keysToSelect = Array.from(new Set(this.keysToSelect));
      this.isLoading = false;
    }, 200);
  }

  // HANDLE MLB PRECISE MAPPING
  handleMLBMatching(dataObj) {
    let matchingProjectionObj = null;

    if (this.canImportById) {
      // MATCH BY ID VAL
      const matchingId = dataObj[this.mlbImportMatchingMethod];
      matchingProjectionObj = this.draftGroup.find(obj => obj.PlayerId.toString() === matchingId.toString());
    } else {
      // MATCH BY NAME + TEAM VAL
      const namePlusTeam = (dataObj[this.playerNameKey] + dataObj[this.mlbImportMatchingMethod]).trim().toLowerCase();
      const draftGroupNamesPlusTeam = this.draftGroup.map(player => (player.Name + player.Team).trim().toLowerCase());
      const match = stringSimilarity.findBestMatch(namePlusTeam, draftGroupNamesPlusTeam);
      if (match.bestMatch.rating > 0.5) {
        matchingProjectionObj = this.draftGroup.find(obj => (obj.Name + obj.Team).trim().toLowerCase() === match.bestMatch.target);
      }
    }

    return matchingProjectionObj;
  }

  // SIMPLE FUZZY MATCH
  handleNameOnlyMatch(dataObj) {
    let matchingProjectionObj = null;
    const name = dataObj[this.playerNameKey].trim().toLowerCase();
    const draftGroupNames = this.draftGroup.map(player => player.Name.trim().toLowerCase());
    const match = stringSimilarity.findBestMatch(name, draftGroupNames);
    if (match.bestMatch.rating > 0.5) {
      matchingProjectionObj = this.draftGroup.find(obj => obj.Name.trim().toLowerCase() === match.bestMatch.target);
    }

    return matchingProjectionObj;
  }

  processDataObject(dataObj, matchingKey) {
    let value = null;
    let matchingProjectionObj = null;
    const rawValue = dataObj[matchingKey];
    if (rawValue !== null && rawValue !== undefined) {
      const parsedValue = parseFloat(rawValue);
      if (!isNaN(parsedValue)) {
        value = Number(parsedValue.toFixed(4));
      } else {
        value = 0;
      }
    }

    if (this.jobData.sport === 'MLB') {
      matchingProjectionObj = this.handleMLBMatching(dataObj);
    } else {
      matchingProjectionObj = this.handleNameOnlyMatch(dataObj);
    }

    if (matchingProjectionObj) {
      matchingProjectionObj[this.valueToMap] = value;
    }
  }

  handleRemoveCustomMapping() {
    this.handleUpdateUserKeysToRemove.emit({ type: this.valueToValidate, value: this.snipedValue });
  }

  onClickChangeKey() {
    // LEAVING FOR FURTHER REFACTOR WHEN TIME ALLOWS
  }

  calculateStdDev() {
    const knownCeilingKeys = KNOWN_CEILING_HEADERS;
    const knownProjectionKeys = KNOWN_PROJECTION_HEADERS.concat(this.userProjectionMappings);
    let errorCount = 0;

    const matchingCeilingKey = knownCeilingKeys.find(key => this.csvData[0].hasOwnProperty(key));
    const matchingProjectionKey = knownProjectionKeys.find(key => this.csvData[0].hasOwnProperty(key));

    this.csvData.forEach((dataObj) => {
      let ceiling = null;
      let projection = null;
      let matchingObject = null;

      if (matchingCeilingKey) {
        ceiling = parseInt(dataObj[matchingCeilingKey], 10);
      }

      if (matchingProjectionKey) {
        projection = parseInt(dataObj[matchingProjectionKey], 10);
      }

      if (this.userDefinedProjectionValue !== undefined) {
        projection = parseInt(dataObj[this.userDefinedProjectionValue], 10);
      }

      if (ceiling !== null && projection !== null) {
        if (this.jobData.sport === 'MLB') {
          matchingObject = this.handleMLBMatching(dataObj);
        } else {
          matchingObject = this.handleNameOnlyMatch(dataObj);
        }

        if (matchingObject) {
          const stdDevValue = (ceiling - projection) * 0.68;
          matchingObject.StdDev = parseFloat(stdDevValue.toFixed(2));
        } else {
          errorCount++;
        }
      } else {
        errorCount++;
      }

    });

    if (errorCount > 0) {
      this.calculateStdDevWithProjection();
    } else {
      this.sniperMessage = "Calculated from Multiple Values";
      this.isKeyValid = true;
    }

    this.isLoading = false;
  }


  calculateStdDevWithProjection() {
    const knownProjectionKeys = KNOWN_PROJECTION_HEADERS.concat(this.userProjectionMappings);
    let errorCount = 0;

    this.csvData.forEach((dataObj) => {
      let projection = null;
      let matchingObject = null;

      if (this.jobData.sport === 'MLB') {
        matchingObject = this.handleMLBMatching(dataObj);
      } else {
        matchingObject = this.handleNameOnlyMatch(dataObj);
      }

      if (matchingObject) {
        const matchingProjectionKey = knownProjectionKeys.find(key => matchingObject.hasOwnProperty(key));
        if (matchingProjectionKey) {
          projection = parseInt(matchingObject[matchingProjectionKey], 10);
        }
        if (this.userDefinedProjectionValue !== undefined) {
          projection = parseInt(matchingObject[this.userDefinedProjectionValue], 10);
        }
      }

      if (projection !== null) {
        const stdDevValue = projection * 0.3;
        matchingObject.StdDev = stdDevValue;
      }
    });

    if (errorCount > 0) {
      this.isErrorCalculating = true;
      this.sniperMessage = "Unable to Auto Calculate";
    } else {
      this.sniperMessage = "Calculated from Projection Value";
      this.isKeyValid = true;
    }
    this.isLoading = false;
  }


  handleErrorOption(option) {
    this.sniperMessage = "Value Skipped";
    this.isKeyValid = true;
  }
}
