<import-widget
*ngIf="showSniper"
title="Batting Order"
[subTitle]="sniperMessage"
[isLoading]="isLoading"
[showFix]="!isKeyValid"
fixMessage="Select the column containing player's BattingOrder value"
[availableKeys]="keysToSelect"
[isMappingValid]="isKeyValid"
[isCustomMapping]="isCustomMapping"
(handleRemoveCustomMapping)="handleRemoveCustomMapping()"
(handleMappingSelected)="selectKey($event)"
(handleEditMapping)="onClickChangeKey()"
(handleErrorOption)="handleErrorOption($event)"
isOptional="true"
>
</import-widget>