<div class="job-lineups-builder-rapper">
  <div class="lineups-builder-header">
    <div class="builder-title-row">
      <div class="builder-title">Build Your Entries</div>
      <div style="margin-left: 2px">
        <tool-tip
          isFeatured="true"
          explanation="We don't use a static pool for your lineups.  Your run created a unique pool based on your settings. Use this tool to filter and refine a set of lineups you want to enter."
        />
      </div>

      <div class="lineups-count-row" *ngIf="!isCustomNumLineups">
        <div
          class="actual-lineups-prompt non-selectable"
          *ngIf="
            !isFetchingTableData &&
            jobData?.currentLineups &&
            jobData?.currentLineups.length < selectedNumberInt
          "
        >
          {{ jobData?.currentLineups.length }} Exist
        </div>
        <select
          class="ss-input"
          id="filterType"
          [disabled]="isFetchingTableData"
          [(ngModel)]="selectedNumber"
          (change)="onSelectNumLineups($event)"
        >
          <option value="1">1 Lineups</option>
          <option value="3">3 Lineups</option>
          <option value="20">20 Lineups</option>
          <option value="88">88 Lineups</option>
          <option value="150">150 Lineups</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      <div class="lineups-count-row" *ngIf="isCustomNumLineups">
        <div
          class="actual-lineups-prompt non-selectable"
          *ngIf="
            !isFetchingTableData &&
            jobData?.currentLineups &&
            jobData?.currentLineups.length < selectedNumberInt
          "
        >
          {{ jobData?.currentLineups.length }} Exist
        </div>
        <div class="custom-count-cancel" (click)="onClickCancelCustomNumber()">
          <svg-icon
            class="cursor-p"
            [svgStyle]="{ 'height.px': 8 }"
            src="/assets/icons/close-solo.svg"
          ></svg-icon>
        </div>
        <input
          #customCountInput
          appSelectAllText
          class="ss-input manual"
          id="filterType"
          type="number"
          placeholder="500 (Max)"
          [disabled]="isFetchingTableData"
          [(ngModel)]="customNumLineups"
          (keypress)="onEnterGetCustomLineupNumber($event)"
        />
        <button
          [disabled]="
            customNumLineups < 1 ||
            customNumLineups > 500 ||
            isFetchingTableData
          "
          class="custom-count-btn"
          (click)="onClickGetCustomLineupNumber()"
        >
          <svg-icon
            [svgStyle]="{ 'height.px': 12 }"
            src="/assets/icons/return-key.svg"
          ></svg-icon>
        </button>
      </div>

      <div class="uniques-count-row">
        <select
          class="ss-input"
          id="filterType"
          [disabled]="isFetchingTableData"
          [(ngModel)]="selectedUniques"
          (change)="onSelectNumUniques($event)"
        >
          <option value="1">Min Unique: 1</option>
          <option value="2">Min Unique: 2</option>
          <option value="3">Min Unique: 3</option>
          <option value="4">Min Unique: 4</option>
          <option value="5">Min Unique: 5</option>
        </select>
      </div>
    </div>

    <div class="tools-wrapper">
      <div
        class="tool-btn"
        *ngIf="exposureMap"
        (click)="onToggleTableExposure()"
      >
        <div class="text">EXP</div>
        <toggle-switch [checked]="showTableExposure"></toggle-switch>
      </div>

      <div
        class="tool-btn"
        (click)="(onClickSimOptoEntries)"
        *ngIf="jobData.jobType === 'optimizer'"
        (click)="onClickSimOptoEntries()"
      >
        <div class="icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/power-chip.svg"
          ></svg-icon>
        </div>
        <div class="text">Sim These Entries</div>
      </div>

      <div class="tool-btn" (click)="onClickExportCurrentLineups()">
        <div class="icon">
          <svg-icon
            *ngIf="jobData.site === 'dk'"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/logos/dk/dk-logo-icon.svg"
          ></svg-icon>
          <svg-icon
            *ngIf="jobData.site === 'fd'"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/logos/fd/fd-logo-icon.svg"
          ></svg-icon>
          <svg-icon
            *ngIf="jobData.site === 'ikb'"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/logos/ikb/ikb-logo.svg"
          ></svg-icon>
        </div>
        <div class="text">Export {{ selectedNumber }} Entries</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isLoadingTableData && !isFetchingTableData" style="opacity: 1">

  <div *ngIf="errorQueryingLineups" class="bq-error">
    <empty-state-skull-animation [showText]="false" [isLoading]="false" [animateOnRender]="false"></empty-state-skull-animation>
    <div class="error-info-wrapper">
      <div class="bq-error-header">
        You've made the entry builder angry. 
      <div class="friendly-text">
        While we monitor errors, feel free to hit us up on Discord while we are working out issues in the new tools.
      </div>
      </div>
      <!-- <code class="bq-error-text">
        {{ errorQueryingLineups }}
      </code> -->
    </div>
  </div>

  <section *ngIf="columns && jobData?.currentLineups && !errorQueryingLineups">
    <entry-builder-table
      [rows]="jobData.currentLineups"
      [columns]="columns"
      [exposureMap]="exposureMap"
      [showExposure]="showTableExposure"
      [iLoadingData]="isFetchingTableData"
      hideToolsRow="true"
      [playerHighlight]="jobData.playerHighlight"
      showAdvancedHeader="true"
      [jobData]="jobData"
      [filters]="filters"
      (handleQueryTable)="handleQueryTable()"
      (handleUpdateFilters)="handleUpdateFilters($event)"
    ></entry-builder-table>
  </section>
</div>

<div
  *ngIf="isLoadingTableData || isFetchingTableData"
  class="ss-base-card"
  style="min-height: 100vh"
>
  <div class="mock-info-row">
    <div class="ss-spinner passive"></div>
    <div class="loading-info">{{ loadingMessage }}</div>
  </div>

  <div class="ghost-header"></div>
  <div
    class="ghost-row"
    *ngFor="
      let i of [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
      ]
    "
  >
    <div
      class="ghost-cell"
      *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
    ></div>
  </div>
</div>
