<div class="ss-form-group">
    <label for="type-ahead">{{label}}</label>
    <input #typeAheadInput type="text" class="ss-input expanded" (input)="onInput($event.target.value)"
        [value]="selectedItem ? selectedItem.name : ''" (blur)="onBlur()" (focus)="onFocus()"
        (keyPress)="handleKeyboardEvent($event)" placeholder="{{placeholder}}" />
    <ul *ngIf="showList">
        <li *ngFor="let item of filteredItems; let i = index" [ngClass]="{ active: i === activeIndex }"
            (click)="selectItem(item)">
            {{ item.name }}
        </li>
    </ul>
</div>