export const NFL_TEAM_LOGO_IDS = {
    'ARI': 3800,
    'ATL': 200,
    'BAL': 325,
    'BUF': 610,
    'CAR': 750,
    'CHI': 810,
    'CIN': 920,
    'CLE': 1050,
    'DAL': 1200,
    'DEN': 1400,
    'DET': 1540,
    'GB': 1800,
    'HOU': 2120,
    'IND': 2200,
    'JAX': 2250,
    'KC': 2310,
    'LA': 2510,
    'LAC': 4400,
    'LAR': 2510,
    'LV': 2520,
    'MIA': 2700,
    'MIN': 3000,
    'NE': 3200,
    'NO': 3300,
    'NYG': 3410,
    'NYJ': 3430,
    'OAK': 2520,
    'PHI': 3700
}

