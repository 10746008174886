import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { SubMonitorService } from './services/sub-monitor.service';
import { UserService } from './services/user.service';
import { logEvent, getAnalytics } from 'firebase/analytics';
import { StateService } from 'src/app/services/state.service';
import { Subscription } from 'rxjs';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { ContestsService } from './services/contests.service';
import { StripeService } from './services/stripe.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AceMind';
  showFooter = true;
  showAppNav = false;
  showAnimation: boolean;
  isSubscriberStateCheced: boolean;
  private subscription: Subscription;
  // New way of checking tier without claims;
  stripeSubscriptionTierName: any;
  currentPath: any;
  user: any;

  constructor(
    private router: Router,
    private subMonitor: SubMonitorService,
    private userService: UserService,
    private stateService: StateService,
    private contestsService: ContestsService,
    private stripeService: StripeService,
  ) {
    mixpanel.init(environment.mixpanelToken, { debug: true, track_pageview: true, persistence: 'localStorage' });
    // const analytics = getAnalytics();
    router.events.subscribe((val) => {
      this.currentPath = location.pathname;
      if (
        location.pathname.includes('/landing') ||
        location.pathname.includes('/login') ||
        location.pathname.includes('/sign-up') ||
        location.pathname.includes('/reset')
      ) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    });
    this.subscription = this.stateService.showFullScreenAnimation$.subscribe(
      value => {
        this.showAnimation = value;
      }
    );
  }

  ngOnInit() {
    this.checkSubscription();
  }

  delayAndRetry() {
    setTimeout(() => {
      this.checkSubscription();
    }, 2000)
  }

  async checkSubscription() {
    const auth = getAuth();
    
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.user = user;

        await this.stripeService.getUserSubscriptionTier(this.user.uid).then((data) => {
          this.stripeSubscriptionTierName = data;
        });

        try {
          const registered = await this.userService.getUserMilestonesById(user.uid);

          if (registered == null) {
            this.delayAndRetry();
            return;
          }
          const profile = await this.userService.getUserPublicProfileById(user.uid);
          mixpanel.identify(user.uid);
          mixpanel.people.set({ $email: user.email, $name: user.displayName });
         

          if (profile?.dailyJobCount !== null) {
            const today = new Date();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const day = String(today.getDate()).padStart(2, '0');
            const year = String(today.getFullYear()).slice(-2);
            const monitorDate = `${month}_${day}_${year}`;

            if (monitorDate !== profile.dailyJobCount.date) {
              this.userService.resetUserProfileJobCount({id: user.uid}).catch((e) => {
                console.log(e);
              });
            } else {
              this.stateService.setDailyJobCount(profile.dailyJobCount.count);
            }

          } else {
            this.userService.resetUserProfileJobCount({id: user.uid}).then((res) => {
              if (res !== null) {
                console.log(res);
              }
            }).catch((e) => {
              console.log(e);
            });
          }
          
          if (profile?.grandfathered === true) {
            this.setSubscriptionAndFlag('FREE', profile);
            this.loadSubscriberState();
            localStorage.setItem('aceFather', 'XJ458OO8');
            mixpanel.people.set({ "Plan": "Grandfathered" });
          } else {
            // const data = await user.getIdTokenResult(true);
            // console.log('DATA OLD WAY IS', data.claims.stripeRole);
            // const subscription = data.claims.stripeRole;
            const subscription = this.stripeSubscriptionTierName;

            mixpanel.people.set({ "Plan": subscription });
            
            if (subscription != null) {
              this.setSubscriptionAndFlag(subscription, profile);
              this.loadSubscriberState();
            } else {
              this.setSubscriptionAndFlag(null, profile);
              console.log('no subscription');
              this.router.navigate(['onboard', this.user.uid]);
            }
          }
        } catch (error) {
          console.error('Error fetching user profile or token result:', error);
        }
      } else {
        console.log('Unauthenticated');
        if (this.currentPath === '/') {
          this.router.navigate(['/login']);
        }
      }
    });
  }
  
  setSubscriptionAndFlag(subscription, profile) {
    this.subMonitor.setSubscription(subscription);
    this.stateService.setSubscriptionChecked(true);
    if (profile?.firstSubscribedAt == null && subscription !== null) {
      const profileData = {
        ...profile,
        id: this.user.uid,
        firstSubscribedAt: new Date,
      }
      this.userService.updateUserPublicProfile(profileData)
    }
  }

  loadSubscriberState() {
    if (!this.isSubscriberStateCheced) {
      this.isSubscriberStateCheced = true;
      // this.checkForLateSwaps();
    }
  }

  // checkForLateSwaps() {
  //   this.contestsService.checkForLateSwapNba().then((hasLateSwaps) => {
  //     if (hasLateSwaps) {
  //       this.stateService.setLateSwapActive(true);
  //       console.log('AVAILABLE LATE SWAPS');
  //     } else {
  //       this.stateService.setLateSwapActive(false);
  //       console.log('No late swaps available');
  //     }
  //   }).catch((e) => {
  //     console.log('Error checking late swaps', e);
  //   });
  // }
  

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
