import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'base-edit-overlay',
  templateUrl: './base-edit-overlay.component.html',
  styleUrls: ['./base-edit-overlay.component.scss']
})
export class BaseEditOverlayComponent {
  @ViewChild('modalCard', { static: false }) modalCard!: ElementRef;
  @Output() closeOverlay: EventEmitter<any> = new EventEmitter<any>();
  @Input() width: string = '32em'

  modalRendered: boolean;

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.modalCard && this.modalRendered && !this.modalCard.nativeElement.contains(event.target)) {
      this.closeOverlay.emit();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.modalRendered = true;
    }, 300);
  }

  handleCloseOverlay() {
    this.closeOverlay.emit();
  }

}
