<div>
  <div class="config-tab-control">
    <div class="tab-links">
      <div
        class="link"
        (click)="onToggleGroup('settings')"
        [class.active]="activeGroup === 'settings'"
      >
        Settings
      </div>
      <div
        *ngIf="jobData.jobSubType === 'classic' "
        class="link"
        (click)="onToggleGroup('stacks')"
        [class.active]="activeGroup === 'stacks'"
      >
        Team Stacks
      </div>
      <div
        class="link"
        (click)="onToggleGroup('position_corrs')"
        [class.active]="activeGroup === 'position_corrs'"
      >
        Pos Corrs
      </div>
      <div
        class="link"
        (click)="onToggleGroup('player_corrs')"
        [class.active]="activeGroup === 'player_corrs'"
      >
        Player Corrs
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'settings'">
    <div class="centered-form">
      <section *ngIf="jobData.jobSubType">
        <presets-manager
          [showSavePreset]="showSavePreset"
          [isDefaultApplied]="isDefaultApplied"
          [presetName]="presetName"
          [isSavingPreset]="isSavingPreset"
          [selectedPreset]="selectedPreset"
          [valuesDirty]="valuesDirty"
          [showPresets]="showPresets"
          [availablePresets]="availablePresets"
          [jobData]="jobData"
          [makePresetDefaultIndex]="makePresetDefaultIndex"
          [deletePresetIndex]="deletePresetIndex"
          [isMakingPresetDefault]="isMakingPresetDefault"
          [isDeletingPreset]="isDeletingPreset"
          [defaultPresetExists]="defaultPresetExists"
          (onClickShowPresets)="onClickShowPresets()"
          (onClickUpdateExistingPreset)="onClickUpdateExistingPreset()"
          (onClickShowSave)="onClickShowSave()"
          (onSaveJobPreset)="onSaveJobPreset($event.name)"
          (onCancelSavePreset)="onCancelSavePreset()"
          (onClickRestoreDefaults)="onClickRestoreDefaults()"
          (onSelectOptionPreset)="onSelectOptionPreset($event.data, $event.id)"
          (onClickSetPresetDefault)="
            onClickSetPresetDefault($event.id, $event.index, $event.data)
          "
          (onClickRemovePresetDefault)="
            onClickRemovePresetDefault($event.id, $event.index, $event.data)
          "
          (onRemovePreset)="onRemovePreset($event.id, $event.index)"
        ></presets-manager>
      </section>

      <hr style="margin-bottom: 12px; margin-top: 24px" />

      <div class="inline-inputs">
        <!-- TOTAL SIMS -->
        <input-group
          label="Simulations Per Field Generation"
          tooltip="# of Simulations to run for each Generated Contest Field"
          [(ngModel)]="jobData.options.totalSimulations"
          [min]="100"
          [max]="100000"
          [errorMessage]="'100-100K'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.totalSimulations < 100 ||
              jobData.options.totalSimulations == null ||
              jobData.options.totalSimulations > 100000
          }"
        >
        </input-group>

        <!-- LINEUP GENERATIONS -->
        <input-group
          label="Number of Generations"
          tooltip="# of Contest Field Generations to Run (1-25)"
          [(ngModel)]="jobData.options.lineupGenerations"
          [min]="1"
          [max]="25"
          [errorMessage]="'1-25'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.lineupGenerations < 1 ||
              jobData.options.lineupGenerations == null ||
              jobData.options.lineupGenerations > 25
          }"
        >
        </input-group>

        <!-- TOP LINEUPS PERCENT -->
        <input-group
          label="Lineup Holdover Percent"
          tooltip="% of Lineups to Keep between Contest Field Generations"
          [(ngModel)]="jobData.options.topLineupsPct"
          [min]="0"
          [max]="100"
          [errorMessage]="'0-100'"
          [isInline]="true"
          [isPercentage]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.topLineupsPct < 0 ||
              jobData.options.topLineupsPct == null ||
              jobData.options.topLineupsPct > 100
          }"
        >
        </input-group>

        <!--KEEP USER LINEUPS-->
        <div class="ss-form-group">
          <div class="toggle-wrapper">
            <div class="flex items-center">
              <div class="label">Keep User Lineups?</div>
              <tool-tip explanation="Forces Algorithm to Keep All User Lineups Between Runs" position="right"></tool-tip>
            </div>
            <toggle-switch
              [checked]="jobData.options.keepUserLineups"
              (checkedChange)="onToggleKeepUserLineups($event)"
            ></toggle-switch>
          </div>
        </div>

        <!--NEW SORT WEIGHTS -->
        <lineup-sort-weights *ngIf="jobData && jobData.options" [jobData]="jobData"></lineup-sort-weights>

        <input-group
          label="Projection Minimum"
          tooltip="Projection minimum to be used in generating lineups"
          [(ngModel)]="jobData.options.projectionMinimum"
          [min]="0"
          [errorMessage]="'This field is required'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.projectionMinimum < 0 ||
              jobData.options.projectionMinimum == null
          }"
        >
        </input-group>

        <input-group
          label="Randomness"
          tooltip="Blanket randomness applied to all players' stddev in game simulations -- 100 means leave player stddevs as is"
          [(ngModel)]="jobData.options.randomness"
          [min]="0"
          [max]="1000"
          [errorMessage]="'Enter a value between 0 and 1000'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.randomness < 0 ||
              jobData.options.randomness > 1000 ||
              jobData.options.randomness == null
          }"
        >
        </input-group>

        <input-group
          label="Minimum Lineup Salary"
          tooltip="Minimum salary to be used by the field generator. The closer this value is to the max salary the longer it takes for the generator to find lineups"
          [(ngModel)]="jobData.options.minLineupSalary"
          [min]="0"
          [max]="siteSalaryMax"
          [errorMessage]="'Enter a value between 0 and ' + siteSalaryMax"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.minLineupSalary < 0 ||
              jobData.options.minLineupSalary > siteSalaryMax ||
              jobData.options.minLineupSalary == null
          }"
        >
        </input-group>

        <input-group
          label="Max % off Optimal"
          tooltip="Sets the maximum percentage off of the optimal lineup (by fpts) that the field generator is allowed to create. If the optimal is 100 and this value is 20, the minimum lineup fpts allowed to be created by the generator is 80"
          [(ngModel)]="jobData.options.maxPctOffOptimal"
          [min]="1"
          [max]="100"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isInline]="true"
          appendText="%"
          [isPercentage]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.maxPctOffOptimal < 1 ||
              jobData.options.maxPctOffOptimal > 100 ||
              jobData.options.maxPctOffOptimal == null
          }"
        >
        </input-group>

        <!-- <input-group
          label="Default Variance"
          tooltip="If no standard deviation is provided in your projections, this value (divided by 100) is multiplied by the player projection to estimate standard deviation"
          [(ngModel)]="jobData.options.defaultVar"
          [min]="0"
          [max]="100"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isInline]="true"
          appendText="%"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.defaultVar < 0 ||
              jobData.options.defaultVar > 100 ||
              jobData.options.defaultVar == null
          }"
        >
        </input-group> -->

        <input-group
          *ngIf="jobData.jobSubType === 'classic'"
          label="Percent of Field Using Stacks"
          tooltip="What percent of the field created by the field generator is guaranteed to have a qb/pass catcher stack."
          [(ngModel)]="jobData.options.pctFieldUsingStacks"
          [min]="0"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.pctFieldUsingStacks < 0 ||
              jobData.options.pctFieldUsingStacks > 100 ||
              jobData.options.pctFieldUsingStacks == null
          }"
        >
        </input-group>

        <input-group
          *ngIf="jobData.jobSubType === 'classic'"
          label="Percent of Field Using Double Stacks"
          tooltip="What percent of stacks are guaranteed to be a qb/2 pass catcher stack"
          [(ngModel)]="jobData.options.pctFieldDoubleStacks"
          [min]="0"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Enter a percentage between 0 and 100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.pctFieldDoubleStacks < 0 ||
              jobData.options.pctFieldDoubleStacks > 100 ||
              jobData.options.pctFieldDoubleStacks == null
          }"
        >
        </input-group>

        <input-group
          label="Default QB Variance"
          tooltip="If no stddev is provided for a qb, this value is multiplied by the player's fpts projection to generate a stddev"
          [(ngModel)]="jobData.options.defaultQbVar"
          [min]="1"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.defaultQbVar < 1 ||
              jobData.options.defaultQbVar > 100 ||
              jobData.options.defaultQbVar == null
          }"
        >
        </input-group>

        <input-group
          label="Default Skill Position Variance"
          tooltip="If no stddev is provided for a rb/wr/te, this value is multiplied by the player's fpts projection to generate a stddev"
          [(ngModel)]="jobData.options.defaultSkillposVr"
          [min]="1"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.defaultSkillposVr < 1 ||
              jobData.options.defaultSkillposVr > 100 ||
              jobData.options.defaultSkillposVr == null
          }"
        >
        </input-group>

        <input-group
          label="Default Defense Variance"
          tooltip="If no stddev is provided for a dst, this value is multiplied by the player's fpts projection to generate a stddev"
          [(ngModel)]="jobData.options.defaultDefVar"
          [min]="1"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Enter a percentage between 1 and 100'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.defaultDefVar < 1 ||
              jobData.options.defaultDefVar > 100 ||
              jobData.options.defaultDefVar == null
          }"
        >
        </input-group>

        <input-group
          label="Number of Players Allowed vs Defense"
          [(ngModel)]="jobData.options.numPlayersVsDef"
          [min]="0"
          [max]="8"
          [errorMessage]="'Enter a value between 0 and 8'"
          [isInline]="true"
          (valueChange)="onValueChange({ userChange: true })"
          [ngClass]="{
            error:
              jobData.options.numPlayersVsDef < 0 ||
              jobData.options.numPlayersVsDef > 8 ||
              jobData.options.numPlayersVsDef == null
          }"
        >
        </input-group>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'stacks'">
    <div class="flex space-between mt16" style="padding: 0px 12px">
      <div class="job-options-section-header" style="margin-bottom: 8px">
        Team Stack Ownership
      </div>
      <tool-tip
        explanation="By default, these are set to 100% and treated equally. Update to use your own percentages."
      />
    </div>

    <div class="file-drop-section">
      <div class="info-row">
        <div class="upload-text" *ngIf="!updatedTeamStackCount">
          Update all with CSV (Team | Value)
        </div>
        <div
          class="upload-text"
          *ngIf="updatedTeamStackCount"
          style="color: var(--ss-colors-green)"
        >
          {{ updatedTeamStackCount }} Teams Updated
        </div>
        <div class="tool-btn" (click)="downloadCSV()">
          <div class="icon">
            <svg-icon
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/csv-icon.svg"
            ></svg-icon>
          </div>
          <div class="text">Template</div>
        </div>
      </div>
      <modal-csv-capture
        (fileAdded)="handleTeamStackFile($event)"
      ></modal-csv-capture>
    </div>

    <hr style="margin-bottom: 0px" />

    <div class="centered-form" *ngIf="teamStackConfig">
      <div class="inline-inputs">
        <input-group
          label="{{ t.name }}"
          *ngFor="let t of jobData.options.teamStackConfig"
          [(ngModel)]="t.value"
          [min]="0"
          [max]="100"
          [isPercentage]="true"
          [errorMessage]="'Valid 0 - 100'"
          (valueChange)="onValueChange({ userChange: true })"
        >
        </input-group>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'position_corrs'">
    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px">
          Custom Position Correlations
        </div>
        <div class="import-setting-link" (click)="onClickImportCorrlations()">
          Import Recent
        </div>
      </div>

      <hr style="margin-bottom: 20px" />

      <div class="rule-wrapper" *ngFor="let c of correlations; let i = index">
        <div class="info">
          <div class="title">
            <span>{{ c.name }} ({{ c.id }})</span>
          </div>
          <div class="players-wrapper">
            <div class="player" *ngFor="let cr of c.correlations">
              {{ cr.position }}: {{ cr.value }}
            </div>
          </div>
        </div>
        <div class="icon" (click)="onClickRemoveCorrelation(i)">
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/close-square.svg"
          ></svg-icon>
        </div>
      </div>

      <hr
        *ngIf="correlations && correlations.length > 0"
        style="margin-bottom: 20px"
      />

      <button
        class="ss-utility-btn secondary fluid"
        (click)="onClickAddCorrelation()"
        *ngIf="!showAddCorrelation"
      >
        Add Custom Correlation
      </button>

      <div *ngIf="showAddCorrelation">
        <player-type-ahead
          *ngIf="showAddCorrelation && !selectedCorrelationPlayer"
          [items]="searchablePlayers"
          (itemSelected)="onSelectCorrelationPlayer($event)"
          label="Select Player:"
          placeholder="Search Players..."
        ></player-type-ahead>

        <div
          *ngIf="
            showAddCorrelation && !selectedCorrelationPlayer && correlationError
          "
          class="base-error-text"
        >
          {{ correlationError }}
        </div>

        <div *ngIf="selectedCorrelationPlayer">
          <div class="selected-player-wrapper">
            <div>
              {{ selectedCorrelationPlayer.name }} ({{
                selectedCorrelationPlayer.id
              }})
            </div>
            <div class="position">{{ selectedCorrelationPlayer.position }}</div>
          </div>

          <hr style="margin-bottom: 20px" />

          <section>
            <div class="ss-form-group" style="margin-bottom: 0px">
              <label
                >{{
                  showSaveCorrelationPreset
                    ? 'New Preset Name'
                    : 'Correlation Value Presets'
                }}
                <span
                  class="preset-applied"
                  *ngIf="isDefaultCorrelationPresetApplied"
                  >✓ Preset Applied</span
                >
              </label>
              <div class="preset-input-row" *ngIf="!showSaveCorrelationPreset">
                <div
                  class="mock-preset-input"
                  (click)="onClickShowCorrelationPresets()"
                >
                  <div class="text">
                    {{ selectedCorrelationPreset?.name | slice : 0 : 80
                    }}{{ selectedCorrelationPreset?.name > 80 ? '...' : '' }}
                  </div>
                  <div class="icon">
                    <svg-icon
                      [svgStyle]="{ 'height.px': 8 }"
                      src="/assets/icons/chev-down.svg"
                    ></svg-icon>
                  </div>
                </div>
              </div>
              <div
                *ngIf="!showCorrelationPresets && !showSaveCorrelationPreset"
                class="save-row"
              >
                <div class="save-text" (click)="onClickShowSaveCorrelation()">
                  Save Values as Preset
                </div>
              </div>
              <div
                *ngIf="showSaveCorrelationPreset"
                class="save-preset-wrapper"
              >
                <input
                  [(ngModel)]="newCorrelationPresetName"
                  class="ss-input expanded"
                  placeholder="Name your preset..."
                  [disabled]="isSavingCorrelationPreset"
                  maxlength="65"
                />
                <div
                  class="save-preset-btn"
                  (click)="onSaveCorrelationPreset()"
                  *ngIf="!isSavingCorrelationPreset"
                >
                  Save
                </div>
                <div
                  class="cancel-preset-btn"
                  (click)="onCancelCorrelationPreset()"
                  *ngIf="!isSavingCorrelationPreset"
                >
                  Cancel
                </div>
                <div
                  class="ss-spinner passive"
                  style="min-width: 30px"
                  *ngIf="isSavingCorrelationPreset"
                ></div>
              </div>
            </div>
            <ul *ngIf="showCorrelationPresets" #corrPresetList>
              <li>
                <div class="preset-item-row">
                  <div
                    class="name"
                    (click)="onClickRestoreCorrelationDefaults()"
                  >
                    App Defaults
                  </div>
                </div>
              </li>
              <li
                class="preset-definition"
                *ngIf="correlationPresets?.length > 0"
              >
                Your Correlation Value Presets
              </li>
              <li
                class="preset-definition empty"
                *ngIf="correlationPresets?.length === 0"
              >
                No Correlation Value Presets Created
              </li>
              <li *ngFor="let set of correlationPresets; let i = index">
                <div class="preset-item-row">
                  <div
                    class="name"
                    (click)="onSelectCorrelationPreset(set.data, set.id)"
                  >
                    {{ set.data.name | slice : 0 : 42
                    }}{{ set.data.name.length > 42 ? '...' : '' }}
                  </div>
                  <div class="actions">
                    <div
                      class="default-wrapper"
                      *ngIf="
                        !set.data.isDefault && !defaultCorrelationPresetExists
                      "
                    >
                      <div
                        class="default-text"
                        (click)="
                          onClickSetCorrelationPresetDefault(
                            set.id,
                            i,
                            set.data
                          )
                        "
                        *ngIf="
                          makingCorrelationIndex !== i &&
                          deletingCorrelationIndex !== i
                        "
                      >
                        Make Default
                      </div>
                      <div
                        class="ss-spinner passive"
                        *ngIf="
                          isMakingCorrelationPresetDefault &&
                          makingCorrelationIndex === i
                        "
                      ></div>
                    </div>
                    <div
                      class="default-wrapper"
                      *ngIf="
                        set.data.isDefault && deletingCorrelationIndex !== i
                      "
                    >
                      <div
                        class="default-text active"
                        *ngIf="
                          !isMakingCorrelationPresetDefault &&
                          makingCorrelationIndex !== i
                        "
                      >
                        Current Default
                      </div>
                      <div
                        class="delete-chip"
                        (click)="
                          onClickRemoveCorrelationPresetDefault(
                            set.id,
                            i,
                            set.data
                          )
                        "
                        *ngIf="!isMakingCorrelationPresetDefault"
                      >
                        <svg-icon
                          [svgStyle]="{ 'height.px': 20 }"
                          src="/assets/icons/square-close.svg"
                        ></svg-icon>
                      </div>
                      <div
                        class="ss-spinner passive"
                        *ngIf="
                          isMakingCorrelationPresetDefault &&
                          makingCorrelationIndex === i
                        "
                      ></div>
                    </div>
                    <div
                      class="delete-wrapper"
                      (click)="onRemoveCorrelationPreset(set.id, i)"
                    >
                      <div class="icon" *ngIf="deletingCorrelationIndex !== i">
                        <svg-icon
                          [svgStyle]="{ 'height.px': 20 }"
                          src="/assets/icons/trash-square.svg"
                        ></svg-icon>
                      </div>
                      <div
                        class="ss-spinner passive"
                        *ngIf="
                          isDeletingCorrelationPreset &&
                          deletingCorrelationIndex === i
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          <hr style="margin-bottom: 20px; margin-top: 20px" />

          <div class="correlation-inputs-wrapper">
            <div
              class="ss-form-group"
              *ngFor="let cv of selectedCorrelationValues"
            >
              <label for="contestType">{{ cv.position }}:</label>
              <input
                appSelectAllText
                class="ss-input"
                placeholder="0.00"
                type="number"
                maxlength="12"
                [(ngModel)]="cv.value"
                (change)="validateCorrValue(cv.value)"
                [ngClass]="{
                  error: cv.value == null || cv.value > 1 || cv.value < -1
                }"
              />
            </div>
          </div>
        </div>

        <hr class="mt24 mb16" />

        <div
          *ngIf="
            showAddCorrelation && correlationError && selectedCorrelationPlayer
          "
          class="base-error-text"
        >
          {{ correlationError }}
        </div>

        <div class="save-row" *ngIf="selectedCorrelationPlayer">
          <div class="actions">
            <button
              class="ss-utility-btn secondary"
              (click)="onClickCancelCorrelation()"
            >
              Cancel
            </button>
            <button
              class="ss-utility-btn"
              (click)="onClickSaveCorrelation()"
              [disabled]="correlationError"
            >
              Add Correlation
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="activeGroup === 'player_corrs'">
    <div class="rules-list p16">
      <div class="flex space-between">
        <div class="job-options-header" style="margin-bottom: 8px">
          Player to Player Correlations
        </div>

        <div
          class="import-setting-link"
          (click)="onClickImportPlayerCorrlations()"
        >
          Import Recent
        </div>
      </div>

      <hr style="margin-bottom: 20px" />

      <div
        class="rule-wrapper"
        *ngFor="let c of jobData.playerCorrelations; let i = index"
      >
        <div class="info">
          <div class="title">
            <span>{{ c.name }} ({{ c.id }})</span>
          </div>
          <div class="players-wrapper">
            <div class="player" *ngFor="let cr of c.correlations">
              {{ cr.name }}: {{ cr.value }}
            </div>
          </div>
        </div>
        <div class="icon" (click)="onClickRemovePlayerCorrelation(i)">
          <svg-icon
            class="icon"
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/close-square.svg"
          ></svg-icon>
        </div>
      </div>

      <hr
        *ngIf="playerCorrelations && playerCorrelations.length > 0"
        style="margin-bottom: 20px"
      />

      <button
        class="ss-utility-btn secondary fluid"
        (click)="onClickAddPlayerCorrelation()"
        *ngIf="!showAddPlayerCorrelation"
      >
        Add Player Correlations
      </button>

      <div *ngIf="showAddPlayerCorrelation">
        <player-type-ahead
          *ngIf="showAddPlayerCorrelation && !selectedPlayerCorrelationPlayer"
          [items]="searchablePlayers"
          (itemSelected)="onSelectPlayerCorrelationPlayer($event)"
          label="Select Player:"
          placeholder="Search Players..."
        ></player-type-ahead>

        <div
          *ngIf="
            showAddPlayerCorrelation &&
            !selectedPlayerCorrelationPlayer &&
            playerCorrelationError
          "
          class="base-error-text"
        >
          {{ playerCorrelationError }}
        </div>

        <div *ngIf="selectedPlayerCorrelationPlayer">
          <div class="selected-player-wrapper">
            <div>
              {{ selectedPlayerCorrelationPlayer.name }} ({{
                selectedPlayerCorrelationPlayer.id
              }})
            </div>
            <div class="position">
              {{ selectedPlayerCorrelationPlayer.position }}
            </div>
          </div>

          <hr style="margin-bottom: 20px" />

          <div
            class="player-correlation-item"
            *ngFor="let p of selectedPlayerCorrelationPlayerSet; let i = index"
          >
            <div class="ss-form-group" style="flex: 1">
              <label>Player</label>
              <div class="player-box">{{ p.name }}</div>
            </div>
            <div class="ss-form-group" style="width: auto">
              <label>Correlation Value</label>
              <input
                appSelectAllText
                class="ss-input"
                placeholder="0.00"
                type="number"
                maxlength="12"
                [(ngModel)]="p.value"
                (change)="validatePlayerCorrValue(p.value)"
                [ngClass]="{
                  error: p.value == null || p.value > 1 || p.value < -1
                }"
              />
            </div>
            <div class="remove" (click)="onDeletePlayerFromCorrelationSet(i)">
              <svg-icon
                class="icon"
                [svgStyle]="{ 'height.px': 20 }"
                src="/assets/icons/close-square.svg"
              ></svg-icon>
            </div>
          </div>

          <player-type-ahead
            [items]="searchablePlayers"
            (itemSelected)="onClickAddPlayerToCorrelationSet($event)"
            label="Add Correlation Player:"
            placeholder="Search Players..."
          ></player-type-ahead>
        </div>

        <hr class="mt24 mb16" />

        <div
          *ngIf="
            showAddPlayerCorrelation &&
            playerCorrelationError &&
            selectedPlayerCorrelationPlayer
          "
          class="base-error-text"
        >
          {{ playerCorrelationError }}
        </div>

        <div class="save-row" *ngIf="selectedPlayerCorrelationPlayer">
          <div class="actions">
            <button
              class="ss-utility-btn secondary"
              (click)="onClickCancelPlayerCorrelation()"
            >
              Cancel
            </button>
            <button
              class="ss-utility-btn"
              (click)="onClickSavePlayerCorrelation()"
              [disabled]="playerCorrelationError"
            >
              Add Correlations
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<rules-importer
  *ngIf="showImportModal"
  [userId]="jobData.userId"
  [jobType]="jobData.jobType"
  [sport]="jobData.sport"
  [draftGroupId]="
    jobData.contest ? jobData.contest.draftGroupId : jobData.draftGroupId
  "
  (handleImportCorrelations)="handleImportCorrelations($event)"
  (handleImportPlayerCorrelations)="handleImportPlayerCorrelations($event)"
  (handleCloseImportModal)="handleCloseImportModal()"
></rules-importer>
