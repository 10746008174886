<div class="ss-base-card p16">

    <div class="lineups-info-wrapper">
        <div class="lineups-info-header">
            Add Files to Run a Late Swap Sim
        </div>
        <div class="lineups-info-desc">
            You MUST add both your entries file and contest file from DK
        </div>
    </div>

    <app-message-prompt *ngIf="fileError" type="warning" mb="24px" [messageBody]="fileError">
    </app-message-prompt>

    <div *ngIf="isFileUploading" class="ss-spinner passive"></div>

    <section *ngIf="!isFileUploading">
    
        <div class="late-swap-doc-group" *ngIf="jobData.lateSwapData?.contestFile == null">
            <div class="file-section-label">Contest File</div>
            <div class="file-drop-section">
                <modal-csv-capture (fileAdded)="readFileContent($event, 'ContestFile')"></modal-csv-capture>
            </div>
        </div>
    
        <div class="file-added-wrapper" *ngIf="jobData.lateSwapData?.contestFile?.fileURL !== null">
            <div class="clear-wrapper" (click)="clearFile('ContestFile')">
                <svg-icon [svgStyle]="{ 'height.px':10 }" src="/assets/icons/close-solo.svg"></svg-icon>
            </div>
            <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
            <div class="message">Contest File Added</div>
        </div>
    
        <div class="late-swap-doc-group" *ngIf="jobData.lateSwapData?.entriesFile == null">
            <div class="file-section-label">Entries File</div>
            <div class="file-drop-section">
                <modal-csv-capture (fileAdded)="readFileContent($event, 'EntriesFile')"></modal-csv-capture>
            </div>
        </div>
    
        <div class="file-added-wrapper" *ngIf="jobData.lateSwapData?.entriesFile?.fileURL !== null">
            <div class="clear-wrapper" (click)="clearFile('EntriesFile')">
                <svg-icon [svgStyle]="{ 'height.px':10 }" src="/assets/icons/close-solo.svg"></svg-icon>
            </div>
            <svg-icon [svgStyle]="{ 'height.px':24 }" src="/assets/icons/selected-chip.svg"></svg-icon>
            <div class="message">Entries File Added</div>
        </div>
    
    </section>

</div>