import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyFilter'
})
export class FriendlyFilterPipe implements PipeTransform {
  isPercentage: boolean;

  transform(filter: any): string {
    if (!filter) {
      return '';
    }

    let friendlyType: string;
    let friendlyOperator: string;

    switch (filter.filterType) {
      case 'playerExposure':
        friendlyType = 'Exp:';
        this.isPercentage = true;
        break;
      case 'fpts':
        friendlyType = 'Fpts';
        break;
      default:
        friendlyType = filter.filterType;
        break;
    }

    // Convert operator to a short string
    switch (filter.operator) {
      case 'greater-than-or-equal-to':
        friendlyOperator = '>=';
        break;
      case 'less-than-or-equal-to':
        friendlyOperator = '<=';
        break;
      case 'equal-to':
        friendlyOperator = '==';
        break;
      default:
        friendlyOperator = filter.operator;
        break;
    }

    if (this.isPercentage) {
      filter.value = filter.value + '%'
    }

    // Build the friendly string
    let friendlyFilter = '';
    if (filter.filterValue) {
      friendlyFilter = `${friendlyType} ${filter.filterValue} ${friendlyOperator} ${filter.value}`;
    } else {
      friendlyFilter = `${friendlyType} ${friendlyOperator} ${filter.value}`;
    }

    return friendlyFilter;
  }
}