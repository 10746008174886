<div
  class="player-exposure-tools-header"
  *ngIf="jobData && jobData?.currentPlayers && !isLoadingLatestValues"
>
  <div class="top-row">
    <div class="player-count">Players: {{jobData?.currentPlayers.length}}</div>
    <div class="sort-controls">
      <div
        class="sort-btn"
        [ngClass]="{ active: isSortDesc && !isSearchActive }"
        (click)="onClickHighestToLowest()"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 6 }"
          src="/assets/icons/chev-down.svg"
          style="transform: translateY(-2px)"
        ></svg-icon>
      </div>
      <div
        class="sort-btn"
        [ngClass]="{ active: !isSortDesc && !isSearchActive }"
        (click)="onClickLowestToHighest()"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 6 }"
          src="/assets/icons/chev-down.svg"
          style="transform: rotate(180deg) translateY(-3px)"
        ></svg-icon>
      </div>
    </div>
  </div>
  <div class="search-bar">
    <input
      #searchInput
      class="ss-input expanded"
      type="text"
      placeholder="Search players..."
      (input)="onSearchPlayer($event)"
    />
  </div>

  <div class="apply-filters-row">
    <div class="right">
        <button class="ss-secondary-btn reset-filters-btn" *ngIf="totalExposureFilters > 0" (click)="onClickResetExposureFilters()">Reset</button>
        <button class="ss-secondary-btn apply-filter-btn" [disabled]="!showApplyFilters" [ngClass]="{'disabled' : !showApplyFilters}" (click)="onClickApplyFilters()">
          <svg-icon
          [svgStyle]="{ 'height.px': 14 }"
          src="/assets/icons/filter-solo.svg"
        ></svg-icon>
          Apply Exposure Filters
        </button>
    </div>
  </div>

</div>

<div *ngIf="isLoadingPlayerData" style="height: 32px; display: flex; justify-content: center;margin-top: 18px;">
  <div class="ss-spinner input-row-loader"></div>
</div>

<div class="player-exposure-wrapper" *ngIf="jobData && jobData?.currentPlayers && !isLoadingLatestValues && !isLoadingPlayerData">

  
  <section *ngIf="jobData?.selectedExposurePlayer">

    <div class="focused-label-row">
      <div class="focused-label">Focused Player</div>
      <div class="focused-close">
        <svg-icon
          (click)="onClickClearFocus()"
          class="cursor-p"
          [svgStyle]="{ 'height.px': 8 }"
          src="/assets/icons/close-solo.svg"
        ></svg-icon>
      </div>
    </div>

    <div class="player-item featured" *ngFor="let p of filteredPlayers">
      <div class="info-row">
        <div class="left">
          <div class="player-icon featured">
            <svg-icon
              [svgStyle]="{ 'height.px': 24 }"
              src="/assets/icons/user-hex.svg"
            ></svg-icon>
          </div>
          <div class="player-detail">
            {{ p.name }} <span *ngIf="p?.showdownPosition">{{p.showdownPosition}}</span>
          </div>
        </div>
        <div class="right">
  
          <div class="action-btn" (click)="onClickLockInPlayer(p)" [ngClass]="{ 'active-green' : p.minExposure == 100}">
            <svg-icon
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/lock.svg"
          ></svg-icon>
          </div>
  
          <div class="action-btn" (click)="onClickRemovePlayer(p)" [ngClass]="{ 'active-red' : p.maxExposure == 0}">
            <svg-icon
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/trash-solid.svg"
          ></svg-icon>
          </div>
  
          <div class="action-btn" (click)="onClickSortLineupsByPlayer(p.name)" [ngClass]="{ 'active-sort' : p.name === jobData.playerHighlight }">
            <svg-icon
              class="utility-search-icon"
              [svgStyle]="{ 'height.px': 13 }"
              src="/assets/icons/featured-sort.svg"
            ></svg-icon>
  
          </div>
        </div>
      </div>
  
      <div class="tools-row">
        <div class="min-label">
          Min Exp
        </div>
        <input class="exp-input" type="number" placeholder="Filter Val" (input)="handlePlayerChange()" [(ngModel)]="p.minExposure" [ngClass]="{ 'active-green' : p.minExposure == 100 }" />
        <div class="current-exp">{{ p.exposure }}%</div>
        <input class="exp-input" type="number" placeholder="Filter Val" (input)="handlePlayerChange()" [(ngModel)]="p.maxExposure" [ngClass]="{ 'active-red' : p.maxExposure == 0 }" />
        <div class="max-label">
          Max Exp
        </div>
      </div>
    </div>

    <!-- <div class="player-item" *ngFor="let p of filteredPlayers">
      <div class="info-row">
        <div class="left">
          <div class="player-icon featured">
            <svg-icon
              [svgStyle]="{ 'height.px': 24 }"
              src="/assets/icons/user-hex.svg"
            ></svg-icon>
          </div>
          <div class="player-detail">
            {{ p.name }}
          </div>
        </div>
        <div class="right">
          <div class="search-btn" (click)="onClickSortLineupsByPlayer(p.name)">
            <svg-icon
              class="utility-search-icon"
              [svgStyle]="{ 'height.px': 13 }"
              src="/assets/icons/featured-sort.svg"
            ></svg-icon>
          </div>
        </div>
      </div>

      <div class="tools-row">
        <div class="min-icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 18 }"
            src="/assets/icons/trash-square.svg"
          ></svg-icon>
        </div>
        <input class="exp-input" placeholder="Filter Min" />
        <div class="current-exp">{{ p.exposure }}%</div>
        <input class="exp-input" placeholder="Filter Max" />
        <div class="max-icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 18 }"
            src="/assets/icons/check-square.svg"
          ></svg-icon>
        </div>
      </div>
    </div> -->
  </section>

  <div class="existing-filters-widget" *ngIf="totalExposureFilters > 0">
    <div class="existing-header-row" (click)="showExistingFilters = !showExistingFilters">
      <div class="existing-header-text">
        Player Filters Applied: {{totalExposureFilters}}
      </div>
      <div class="toggle-icon">
        <svg-icon
        [svgStyle]="{ 'height.px': 7 }"
        src="/assets/icons/chev-down.svg"
      ></svg-icon>
      </div>
    </div>
    <div class="existing-filters-wrapper" *ngIf="showExistingFilters">
      <div class="existing-filter" *ngFor="let f of existingPlayerFilters">
        <div class="filter-info">
          <div>{{f.filterValue}}</div>
          <div>{{f.operator}} {{f.value}}</div>
        </div>
        <div class="existing-filter-action" (click)="onRemoveExistingFilter(f)">
          <svg-icon
            [svgStyle]="{ 'height.px': 10 }"
            src="/assets/icons/close-solo.svg"
          ></svg-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="player-item" *ngFor="let p of jobData.currentPlayers.slice(0, 150)">
    <div class="info-row">
      <div class="left">
        <div class="player-icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 24 }"
            src="/assets/icons/user-hex.svg"
          ></svg-icon>
        </div>
        <div class="player-detail">
          {{ p.name }} <span *ngIf="p?.showdownPosition">{{p.showdownPosition}}</span>
        </div>
      </div>
      <div class="right">

        <div class="action-btn" (click)="onClickLockInPlayer(p)" [ngClass]="{ 'active-green' : p.minExposure == 100}">
          <svg-icon
          [svgStyle]="{ 'height.px': 13 }"
          src="/assets/icons/lock.svg"
        ></svg-icon>
        </div>

        <div class="action-btn" (click)="onClickRemovePlayer(p)" [ngClass]="{ 'active-red' : p.maxExposure == 0}">
          <svg-icon
          [svgStyle]="{ 'height.px': 13 }"
          src="/assets/icons/trash-solid.svg"
        ></svg-icon>
        </div>

        <div class="action-btn" (click)="onClickSortLineupsByPlayer(p.name)" [ngClass]="{ 'active-sort' : p.name === jobData.playerHighlight }">
          <svg-icon
            class="utility-search-icon"
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/featured-sort.svg"
          ></svg-icon>

        </div>
      </div>
    </div>

    <div class="tools-row">
      <div class="min-label">
        Min Exp
      </div>
      <input class="exp-input" type="number" placeholder="Filter Val" (input)="handlePlayerChange()" [(ngModel)]="p.minExposure" [ngClass]="{ 'active-green' : p.minExposure == 100 }" />
      <div class="current-exp">{{ p.exposure }}%</div>
      <input class="exp-input" type="number" placeholder="Filter Val" (input)="handlePlayerChange()" [(ngModel)]="p.maxExposure" [ngClass]="{ 'active-red' : p.maxExposure == 0 }" />
      <div class="max-label">
        Max Exp
      </div>
    </div>
  </div>
</div>
