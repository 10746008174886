<div class="results-sub-header">
  <div class="left-wrap">
    <div class="data-links">
      <div
        class="link"
        (click)="onToggleNav('p1')"
        [class.active]="activeSection === 'p1'"
      >
        Portfolio 1 (Optimal)
      </div>
      <div
        class="link"
        (click)="onToggleNav('p2')"
        [class.active]="activeSection === 'p2'"
      >
        Portfolio 2
      </div>
      <div
        class="link"
        (click)="onToggleNav('p3')"
        [class.active]="activeSection === 'p3'"
      >
        Portfolio 3
      </div>
      <div
        class="link"
        (click)="onToggleNav('p4')"
        [class.active]="activeSection === 'p4'"
      >
        Portfolio 4
      </div>
      <div
        class="link"
        (click)="onToggleNav('p5')"
        [class.active]="activeSection === 'p5'"
      >
        Portfolio 5
      </div>
      <div
        class="link"
        (click)="onToggleNav('p6')"
        [class.active]="activeSection === 'p6'"
      >
        Portfolio 6
      </div>
    </div>
  </div>
</div>

<section class="insight-table"
style="transform: translateY(-24px);">
<base-table [rows]="selectedPortfolio?.portfolioLineups || []" [columns]="lineupColumns"
    (downloadTable)="onClickShowDownloadOptions()" showCopy="true" [isCopyingData]="isCopyingCSV"
    (handleCopyTable)="generatePortfolioLineupsCSV(false, false)" showDownload="true"
    showAdvancedHeader="true"></base-table>
</section>


<base-edit-overlay *ngIf="showDownloadOptions" (closeOverlay)="handleCloseDownloadOptionsModal()">
<div class="ss-upgrade-modal-wrapper">
    <div class="title">Just want your entries?</div>
    <div class="sub-title">The Entries file is formatted to be uploaded to {{jobData.site === 'dk' ? 'DraftKings' : 'FanDuel'}} without modification.
    </div>
    <button class="ss-secondary-btn mt24" (click)="generatePortfolioLineupsCSV(true, true)">Download as Entries</button>
    <button class="ss-secondary-btn mt16" (click)="generatePortfolioLineupsCSV(true, false)">Download All Data</button>
</div>
</base-edit-overlay>
