<div *ngIf="isLoadingJob" class="ss-base-page-loading-wrapper">
  <div class="ss-spinner page-spinner"></div>
</div>

<div class="ss-job-results-header-wrapper" *ngIf="jobData?.site">
  <div class="left-wrap">
    <div class="icon">
      <svg-icon
        *ngIf="jobData.site === 'dk'"
        [svgStyle]="{ 'height.px': 24 }"
        src="/assets/logos/dk/dk-logo-icon.svg"
      ></svg-icon>
      <svg-icon
        *ngIf="jobData.site === 'fd'"
        [svgStyle]="{ 'height.px': 24 }"
        src="/assets/logos/fd/fd-logo-icon.svg"
      ></svg-icon>
      <svg-icon
        *ngIf="jobData.site === 'ikb'"
        [svgStyle]="{ 'height.px': 24 }"
        src="/assets/logos/ikb/ikb-logo.svg"
      ></svg-icon>
    </div>
    <div class="job-string">
      <span class="exo-flair">
        {{
          jobData.jobType === 'simulator' ? 'Simulator' : 'Optimizer'
        }}
        Results:</span
      >
       &nbsp;{{ jobData.sport }} - {{ jobData.slate }}
      <span *ngIf="jobData?.contest && jobData.contest.name"
        > - Entries: {{ jobData.contest.maximumEntries | number }}</span
      >
    </div>
  </div>

  <div class="right-wrap">
    <ttl-component [jobData]="jobData"></ttl-component>

    <div class="tool-btn" (click)="onClickDownloadAll()" *ngIf="showResults">
      <div class="icon">
        <svg-icon
          [svgStyle]="{ 'height.px': 20 }"
          src="/assets/icons/csv-icon.svg"
        ></svg-icon>
      </div>
      <div class="text">
        {{ isDownloading ? 'Downloading Lineups...' : 'Download All Lineups' }}
      </div>
    </div>

    <div class="icon-utility-btn" (click)="onCloseJob()">
      <svg-icon
        [svgStyle]="{ 'height.px': 18 }"
        src="/assets/icons/close-square.svg"
      ></svg-icon>
    </div>
  </div>
</div>

<div class="results-sub-header" *ngIf="jobData && showProgress">
  <div class="left-wrap">
    <div class="job-status">
      {{ jobData.jobType === 'simulator' ? 'Sim' : 'Opto' }} Running on Cloud
      Engine.
    </div>
  </div>
  <div class="right-wrap">
    <div class="id-string">Engine Id: {{ engineId }}</div>
    <div class="id-string">Job Id: {{ jobData.jobId }}</div>
  </div>
</div>

<div class="progress-log-wrapper" *ngIf="jobData && showProgress">
  <job-progress-log
    *ngIf="!isLoadingJob"
    [jobData]="jobData"
    [jobSubscription]="jobSubscription"
    fullScreen="true"
  ></job-progress-log>
</div>

<job-results-sim-data
  [jobData]="jobData"
  *ngIf="showResults && jobData.jobType === 'simulator'"
></job-results-sim-data>

<job-results-opto-data
  [jobData]="jobData"
  *ngIf="showResults && jobData.jobType === 'optimizer'"
></job-results-opto-data>
