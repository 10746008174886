import { Component, Input } from '@angular/core';

@Component({
  selector: 'ttl-component',
  templateUrl: './ttl-component.component.html',
  styleUrls: ['./ttl-component.component.scss']
})
export class TtlComponentComponent {
  @Input() jobData: any;

  contestStartTime: Date;
  isStartTimeInPast: boolean = false;
  isWithinTimeThreshold: boolean = false;
  showTimer: boolean = false;
  countdownInterval: any;
  countdown: any = {
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  ngOnInit() {
    if (this.jobData.jobType === 'optimizer') {
      this.handleEventType2(this.jobData.slate);
    } else {
      this.handleEventType1(this.jobData.contest.contestStartTime);
    }
  }

  handleEventType1(timeString: string): void {
    this.contestStartTime = new Date(timeString);
    const timeDifference = this.contestStartTime.getTime() - Date.now();
    this.isWithinTimeThreshold = timeDifference > 0 && timeDifference <= 1 * 60 * 60 * 1000;

    if (this.isWithinTimeThreshold) {
      this.startCountdown();
    } else if (timeDifference < 0) {
      this.isStartTimeInPast = true;
    }
  }

  handleEventType2(timeString: string): void {
    this.contestStartTime = this.parseEventType2Time(timeString);
    const timeDifference = this.contestStartTime.getTime() - Date.now();
    this.isWithinTimeThreshold = timeDifference > 0 && timeDifference <= 1 * 60 * 60 * 1000;

    if (this.isWithinTimeThreshold) {
      this.startCountdown();
    } else if (timeDifference < 0) {
      this.isStartTimeInPast = true;
    }
  }

  parseEventType2Time(timeString: string): Date {
    const [dateTimePart, extraPart] = timeString.split(/(?<=\d\s[A|P]M)\s/);
    const [month, day, time, timeOfDay] = dateTimePart.split(' ');
    const [hour, minute] = time.split(':');
    const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
    const year = new Date().getFullYear();

    let hour24 = parseInt(hour, 10);
    if (timeOfDay === 'PM' && hour24 < 12) {
      hour24 += 12;
    } else if (hour24 === 12) {
      hour24 = 0;
    }

    return new Date(year, monthIndex, parseInt(day, 10), hour24, parseInt(minute, 10));
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      const timeDifference = this.contestStartTime.getTime() - Date.now();
      if (timeDifference <= 0) {
        clearInterval(this.countdownInterval);
        this.isStartTimeInPast = true;
        this.showTimer = false;
      } else {
        this.countdown = this.convertMillisecondsToCountdown(timeDifference);
      }
    }, 1000);
    this.showTimer = true;
  }

  // Function to convert milliseconds to countdown format
  convertMillisecondsToCountdown(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return {
      hours: this.padZero(hours),
      minutes: this.padZero(minutes),
      seconds: this.padZero(seconds)
    };
  }

  padZero(num: number) {
    return num.toString().padStart(2, '0');
  }
}
