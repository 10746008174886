import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'rules-importer',
  templateUrl: './rules-importer.component.html',
  styleUrls: ['./rules-importer.component.scss']
})
export class RulesImporterComponent implements OnInit {
  @Input() userId: string;
  @Input() jobType: string;
  @Input() sport: string;
  @Input() draftGroupId: string;
  @Input() importMode: string;

  @Output() handleImportCorrelations: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportPlayerCorrelations: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportPlayerRules: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportTeamRules: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportMatchupRules: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleImportStackRules: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCloseImportModal: EventEmitter<any> = new EventEmitter<any>();

  availableCorrs: any = [];
  availablePlayerCorrs: any = [];
  availablePlayerRules: any = [];
  availableTeamRules: any = [];
  availableMatchupRules: any = [];
  availableStackLimitRules: any = [];
  availableStackPairRules: any = [];

  selectedCorrs: any = [];
  selectedPlayerCorrs: any = [];
  totalSelections = 0;

  importError: string = null;
  searchValue: string = null;

  isLoading: boolean = true;

  constructor(private jobsService: JobsService) { }

  ngOnInit(): void {

    if (this.jobType === 'simulator') {
      this.jobsService.getRecentUniversalJobsForConfigImport(this.userId).then((data) => {
        if (data !== null) {
          const jobsWithMatchingDraftGroup = data.filter((j) => j.draftGroupId === this.draftGroupId);
          if (jobsWithMatchingDraftGroup.length > 0) {
            this.generateCorrelationOptions(jobsWithMatchingDraftGroup);
          } else {
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });
    } else {
      this.jobsService.getRecentUniversalJobsForConfigImport(this.userId).then((data) => {
        if (data !== null) {
          const jobsWithMatchingDraftGroup = data.filter((j) => j.draftGroupId === this.draftGroupId);

          if (jobsWithMatchingDraftGroup.length > 0) {
            this.generateRuleOptions(jobsWithMatchingDraftGroup);
          } else {
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });
    }

  }

  generateCorrelationOptions(jobs) {
    const availableCorrs = [];
    const availablePlayerCorrs = [];

    jobs.forEach((job) => {
      if (job.customCorrelations != null) {
        job.customCorrelations.forEach((corr) => {
          availableCorrs.push({
            ...corr,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
      if (job.playerCorrelations != null) {
        job.playerCorrelations.forEach((corr) => {
          availablePlayerCorrs.push({
            ...corr,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
    });

    this.availableCorrs = availableCorrs.sort((a, b) => b.updatedAt - a.updatedAt);
    this.availablePlayerCorrs = availablePlayerCorrs.sort((a, b) => b.updatedAt - a.updatedAt);
    this.isLoading = false;
  }

  generateRuleOptions(jobs) {
    const availablePlayerRules = [];
    const availableTeamRules = [];
    const availableMatchupRules = [];
    const availableStackLimitRules = [];
    const availableStackPairRules = [];

    jobs.forEach((job) => {
      if (job.rules != null) {
        job.rules.forEach((rule) => {
          availablePlayerRules.push({
            ...rule,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
      if (job.teamRules != null) {
        job.teamRules.forEach((rule) => {
          availableTeamRules.push({
            ...rule,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
      if (job.matchupRules != null) {
        job.matchupRules.forEach((rule) => {
          availableMatchupRules.push({
            ...rule,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
      if (job.stackRules != null && job.stackRules.limit.length > 0) {
        job.stackRules.limit.forEach((rule) => {
          availableStackLimitRules.push({
            ...rule,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
      if (job.stackRules != null && job.stackRules.pair.length > 0) {
        job.stackRules.pair.forEach((rule) => {
          availableStackPairRules.push({
            ...rule,
            updatedAt: job.updatedAt,
            selected: false,
          });
        });
      }
     
    });

    this.availablePlayerRules = availablePlayerRules.sort((a, b) => b.updatedAt - a.updatedAt);
    this.availableTeamRules = availableTeamRules.sort((a, b) => b.updatedAt - a.updatedAt);
    this.availableMatchupRules = availableMatchupRules.sort((a, b) => b.updatedAt - a.updatedAt);
    this.availableStackLimitRules = availableStackLimitRules.sort((a, b) => b.updatedAt - a.updatedAt);
    this.availableStackPairRules = availableStackPairRules.sort((a, b) => b.updatedAt - a.updatedAt);
    this.isLoading = false;
  }

  onSelectCorr(corr, index) {
    this.importError = null;

    if (corr.selected) {
      corr.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    }

    if (this.availableCorrs.some((c) => c.name === corr.name && c.selected)) {
      this.importError = 'This player is already selected';
      return;
    } else {
      corr.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectPlayerCorr(corr) {
    if (corr.selected) {
      corr.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    }

    if (this.availablePlayerCorrs.some((c) => c.name === corr.name && c.selected)) {
      this.importError = 'This player is already selected';
      return;
    } else {
      corr.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectPlayerRule(rule) {
    if (rule.selected) {
      rule.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    } else {
      rule.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectTeamRule(rule) {
    if (rule.selected) {
      rule.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    } else {
      rule.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectMatchupRule(rule) {
    if (rule.selected) {
      rule.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    } else {
      rule.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectStackPairRule(rule) {
    if (rule.selected) {
      rule.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    } else {
      rule.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onSelectStackLimitRule(rule) {
    if (rule.selected) {
      rule.selected = false;
      this.totalSelections = this.totalSelections - 1;
      return;
    } else {
      rule.selected = true;
      this.totalSelections = this.totalSelections + 1;
    }
  }

  onClickImportCorrelations() {
    const selections = this.availableCorrs.filter((c) => c.selected === true);

    const cleanedSelections = selections.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    this.handleImportCorrelations.emit(cleanedSelections);
  }

  onClickImportPlayerCorrelations() {
    const selections = this.availablePlayerCorrs.filter((c) => c.selected === true);

    const cleanedSelections = selections.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    this.handleImportPlayerCorrelations.emit(cleanedSelections);
  }

  onClickImportPlayerRules() {
    const selections = this.availablePlayerRules.filter((c) => c.selected === true);

    const cleanedSelections = selections.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    this.handleImportPlayerRules.emit(cleanedSelections);
  }

  onClickImportTeamRules() {
    const selections = this.availableTeamRules.filter((c) => c.selected === true);

    const cleanedSelections = selections.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    this.handleImportTeamRules.emit(cleanedSelections);
  }

  onClickImportMatchupRules() {
    const selections = this.availableMatchupRules.filter((c) => c.selected === true);

    const cleanedSelections = selections.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    this.handleImportMatchupRules.emit(cleanedSelections);
  }

  onClickImportStackRules() {
    const selectedPairs = this.availableStackPairRules.filter((c) => c.selected === true);
    const selectedLimits = this.availableStackLimitRules.filter((c) => c.selected === true);

    const cleanedPairs = selectedPairs.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    const cleanedLimits = selectedLimits.map((s) => {
      const { updatedAt, selected, ...newCorr } = s;
      return newCorr;
    });

    const update = {
      pairs: cleanedPairs,
      limits: cleanedLimits
    }

    this.handleImportStackRules.emit(update);
  }

  handleCloseOverlay() {
    this.handleCloseImportModal.emit();
  }

  handleSortQuery(type) {
    switch (type) {
      case 'corr':
        this.availableCorrs.sort((a, b) => {
          const similarityA = this.calculateFuzzyMatchScore(a.name, this.searchValue);
          const similarityB = this.calculateFuzzyMatchScore(b.name, this.searchValue);
          return similarityB - similarityA;
        });
        break;
      case 'playerCorr':
        this.availablePlayerCorrs.sort((a, b) => {
          const similarityA = this.calculateFuzzyMatchScore(a.name, this.searchValue);
          const similarityB = this.calculateFuzzyMatchScore(b.name, this.searchValue);
          return similarityB - similarityA;
        });
        break;
      case 'playerRules':
        this.availablePlayerRules.sort((a, b) => {
          const similarityA = this.calculateFuzzyMatchScore(a.name, this.searchValue);
          const similarityB = this.calculateFuzzyMatchScore(b.name, this.searchValue);
          return similarityB - similarityA;
        });
        break;
    }
  }

  calculateFuzzyMatchScore(str1, str2) {
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    if (str1.includes(str2)) {
      return str2.length / str1.length;
    }
    return 0;
  }

}
