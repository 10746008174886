const BASE_SIM_OPTIONS = {
    totalSimulations: 50000,
    lineupGenerations: 5,
    topLineupsPct: 5,
    keepUserLineups: true,
    lineupSortWeights: {
        roi: 100,
        projection: 0,
        ownership: 0,
        top1Percent: 0,
        wins: 0,
    },
    projectionMinimum: 3,
    randomness: 100,
    maxPctOffOptimal: 30,
    pctFieldUsingStacks: 60,
    pctFieldDoubleStacks: 25,
    defaultQbVar: 30,
    defaultSkillposVr: 40,
    defaultDefVar: 50,
    numPlayersVsDef: 0,
};

const SHOWDOWN_SIM_OPTIONS = {
    totalSimulations: 50000,
    lineupGenerations: 5,
    topLineupsPct: 5,
    keepUserLineups: true,
    lineupSortWeights: {
        roi: 100,
        projection: 0,
        ownership: 0,
        top1Percent: 0,
        wins: 0,
    },
    projectionMinimum: 0.1,
    randomness: 100,
    maxPctOffOptimal: 30,
    defaultQbVar: 30,
    defaultSkillposVr: 40,
    defaultDefVar: 50,
    numPlayersVsDef: 4,
    allowDefVsQbCpt: true,
};

const BASE_OPTO_OPTIONS = {
    globalTeamLimit: 3,
    projectionMinimum: 4,
    randomness: 100,
    maxPctOffOptimal: 30,
    numLineups: 150,
    numUniques: 1,
};

export const NFL_SIM_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 49000,
    },
    CLASSIC_FD: {
        ...BASE_SIM_OPTIONS,
        minLineupSalary: 59000,
    },
    SHOWDOWN_DK: {
        ...SHOWDOWN_SIM_OPTIONS,
        minLineupSalary: 47000,
    },
    SHOWDOWN_FD: {
        ...SHOWDOWN_SIM_OPTIONS,
        minLineupSalary: 57000,
    },
};

export const NFL_OPTO_OPTIONS = {
    CLASSIC_DK: {
        ...BASE_OPTO_OPTIONS,
        minLineupSalary: 49000,
        useDoubleTe: false,
    },
    CLASSIC_FD: {
        ...BASE_OPTO_OPTIONS,
        minLineupSalary: 59000,
        useDoubleTe: false,
    },
    SHOWDOWN_DK: {
        ...BASE_OPTO_OPTIONS,
        projectionMinimum: 0.1,
        minLineupSalary: 47000,
    },
    SHOWDOWN_FD: {
        ...BASE_OPTO_OPTIONS,
        projectionMinimum: 0.1,
        minLineupSalary: 57000,
    },
};

export const NFL_CORRELATION_DEFAULTS = {
    QB: [
        { position: 'QB', value: 1.00 },
        { position: 'RB', value: 0.10 },
        { position: 'WR', value: 0.36 },
        { position: 'TE', value: 0.35 },
        { position: 'K', value: -0.02 },
        { position: 'DST', value: 0.04 },
        { position: 'Opp QB', value: 0.23 },
        { position: 'Opp RB', value: 0.07 },
        { position: 'Opp WR', value: 0.12 },
        { position: 'Opp TE', value: 0.10 },
        { position: 'Opp K', value: -0.03 },
        { position: 'Opp DST', value: -0.30 }
    ],
    RB: [
        { position: 'QB', value: 0.10 },
        { position: 'RB', value: 1.00 },
        { position: 'WR', value: 0.06 },
        { position: 'TE', value: 0.03 },
        { position: 'K', value: 0.16 },
        { position: 'DST', value: 0.10 },
        { position: 'Opp QB', value: 0.07 },
        { position: 'Opp RB', value: -0.02 },
        { position: 'Opp WR', value: 0.05 },
        { position: 'Opp TE', value: 0.07 },
        { position: 'Opp K', value: -0.13 },
        { position: 'Opp DST', value: -0.21 }
    ],
    WR: [
        { position: 'QB', value: 0.36 },
        { position: 'RB', value: 0.06 },
        { position: 'WR', value: 1.00 },
        { position: 'TE', value: 0.03 },
        { position: 'K', value: 0.00 },
        { position: 'DST', value: 0.06 },
        { position: 'Opp QB', value: 0.12 },
        { position: 'Opp RB', value: 0.05 },
        { position: 'Opp WR', value: 0.05 },
        { position: 'Opp TE', value: 0.06 },
        { position: 'Opp K', value: 0.06 },
        { position: 'Opp DST', value: -0.12 }
    ],
    TE: [
        { position: 'QB', value: 0.35 },
        { position: 'RB', value: 0.03 },
        { position: 'WR', value: 0.03 },
        { position: 'TE', value: 1.00 },
        { position: 'K', value: 0.02 },
        { position: 'DST', value: 0.00 },
        { position: 'Opp QB', value: 0.10 },
        { position: 'Opp RB', value: 0.04 },
        { position: 'Opp WR', value: 0.06 },
        { position: 'Opp TE', value: 0.09 },
        { position: 'Opp K', value: 0.00 },
        { position: 'Opp DST', value: -0.03 }
    ],
    K: [
        { position: 'QB', value: -0.02 },
        { position: 'RB', value: 0.16 },
        { position: 'WR', value: 0.00 },
        { position: 'TE', value: 0.02 },
        { position: 'K', value: 1.00 },
        { position: 'DST', value: 0.23 },
        { position: 'Opp QB', value: -0.03 },
        { position: 'Opp RB', value: -0.13 },
        { position: 'Opp WR', value: 0.06 },
        { position: 'Opp TE', value: 0.09 },
        { position: 'Opp K', value: -0.04 },
        { position: 'Opp DST', value: -0.32 }
    ],
    DST: [
        { position: 'QB', value: 0.04 },
        { position: 'RB', value: 0.10 },
        { position: 'WR', value: 0.06 },
        { position: 'TE', value: 0.00 },
        { position: 'K', value: 0.23 },
        { position: 'DST', value: 1.00 },
        { position: 'Opp QB', value: -0.30 },
        { position: 'Opp RB', value: -0.21 },
        { position: 'Opp WR', value: -0.12 },
        { position: 'Opp TE', value: -0.03 },
        { position: 'Opp K', value: -0.32 },
        { position: 'Opp DST', value: -0.13 }
    ]
};
