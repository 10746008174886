import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { SubMonitorService } from './sub-monitor.service';
import { StateService } from './state.service';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';
import { StripeService } from './stripe.service';


@Injectable({
    providedIn: 'root',
})
export class SubGuard implements CanActivate {
  private subscription: Subscription;
  subscriptionChecked: boolean;

    constructor(private router: Router,
       private stateService: StateService,
       private subMonitor: SubMonitorService,
       private stripeService: StripeService,
      ) {
      this.subscription = this.stateService.subscriptionChecked$.subscribe(
        value => {
          this.subscriptionChecked = value;
        }
      );
    }
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
      return new Promise<boolean>(async (resolve, reject) => {
        if (this.subscriptionChecked) {
          resolve(true);
        } else {
          const fathered = localStorage.getItem('aceFather');
          if (fathered !== null) {
            resolve(true);
          } else {
            const auth = getAuth();
            onAuthStateChanged(auth, async (user: any | null) => {
              if (user) {
                // const data = await user.getIdTokenResult(true);
                // const subscription = data.claims.stripeRole;
                await this.stripeService.getUserSubscriptionTier(user.uid).then((data) => {
                  if (data) {
                    resolve(true);
                  } else {
                    this.router.navigate(['/palermo']);
                    resolve(false);
                  }
                });
                // if (subscription) {
                //   resolve(true);
                // } else {
                //   this.router.navigate(['/palermo']);
                //   resolve(false);
                // }
              }
            });
            // this.router.navigate(['/palermo']);
          }
        }
      });
    }
}