import { Component, ElementRef, OnInit, Renderer2, ViewChild, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// import { StateService } from 'src/app/services/state.service';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;

  user: any;
  userProfile: any;
  isAuthenticated = false;
  showUserMenu = false;

  showAvatarSection = true;


  isLoading = true;

  activeLinkSpace: string;

  // isLateSwapActive: boolean;
  // private subscription: Subscription;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    // private stateService: StateService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkUrlNameSpace();
        window.scrollTo(0, 0);
      }
    });
    // this.subscription = this.stateService.isLateSwapActive$.subscribe(
    //   value => {
    //     this.isLateSwapActive = value;
    //   }
    // );
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(clickedElement) && !clickedElement.classList.contains('ss-nav-user-menu')) {
      this.showUserMenu = false;
    }
  }

  checkUrlNameSpace() {
    const currentUrl = this.router.url.toLowerCase();
    const parts = currentUrl.split('/');
    this.activeLinkSpace = parts.length > 1 ? parts[1] : '';
  }

  ngOnInit(): void {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isAuthenticated = true;
        this.isLoading = false;
        // this.getUserAccount();
      } else {
        console.log('no authenticated user')
        this.isLoading = false;
        this.isAuthenticated = false;
      }
    });

    this.router.events.subscribe(val => {
      if (location.pathname.includes('/onboard')) {
        this.showAvatarSection = false;
      } else {
        this.showAvatarSection = true;
      }
    });

  }

  onShowUserMenu() {
    if (this.showUserMenu === false) {
      this.showUserMenu = true;
    } else {
      this.showUserMenu = false;
    }
  }

  onClickMenuLink(route) {
    if (route === 'discord') {
      window.open('https://discord.gg/cnWsfFuuxU', '_blank');
      return;
    }
    this.showUserMenu = false;
    this.router.navigate([route])
  }

  onClickLogo() {
    if (this.isAuthenticated && this.showAvatarSection === false) {
      return
    } else if (this.isAuthenticated) {
      this.router.navigate(['/dashboard'])
    }
  }

  onClickSignOut() {
    this.showUserMenu = false;
    const auth = getAuth();
    signOut(auth);
    this.router.navigate(['/login'])
  }


  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
