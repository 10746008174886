import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface Item {
  name: string;
}

@Component({
  selector: 'typeahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss']
})
export class TypeAheadComponent {
  @Input() items: Item[] = [];
  @Input() label: String;
  @Input() placeholder: String;
  @Input() showItemBadge: Boolean;
  @Input() isClearAfterSelect: Boolean = false;
  @Input() isAbsolutePositionList: Boolean = false;
  @Input() background: String;
  @Output() itemSelected: EventEmitter<Item> = new EventEmitter<Item>();
  @ViewChild('typeAheadInput', { static: false }) typeAheadInput: ElementRef<HTMLInputElement>;
  filteredItems: Item[] = [];
  selectedItem: Item | null = null;
  showList: boolean = false;
  activeIndex: number = -1;
  private keyDownSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();

  constructor(private elementRef: ElementRef) {
    // Subscribe to the debounced keydown event
    this.keyDownSubject.pipe(debounceTime(50)).subscribe((event) => {
      this.handleKeyboardEvent(event);
    });
  }

  ngOnInit() {
    if (this.items === undefined) {
      this.items = [];
    }
    if (this.background == null) {
      this.background = 'var(--ss-input-bg)'
    }
  }

  filterItems(value: string) {

    if (this.items.length === 0) {
      return;
    }

    if (!value) {
      this.filteredItems = [];
      return;
    }

    const filterValue = value.toLowerCase();
    this.filteredItems = this.items.filter((item) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }

  onInput(value: string) {
    this.filterItems(value);
    this.showList = true;
    this.activeIndex = -1; // Reset active index when the input value changes
  }

  onFocus() {
    if (!this.showList) {
      this.showList = true;
      this.typeAheadInput.nativeElement.value = '';
      this.filteredItems = this.items;
    }
  }

  clearTypeAhead() {
    this.typeAheadInput.nativeElement.value = '';
    this.showList = false;
    if (this.isClearAfterSelect) {
      this.selectedItem = null;
    }
  }

  setTypeAheadValue(string) {
    this.typeAheadInput.nativeElement.value = string;
    this.showList = false;
  }

  onBlur() {
    if (this.showList) {
      setTimeout(() => {
        this.showList = false;
      }, 300);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Debounce the keydown event
    this.keyDownSubject.next(event);
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.items.length === 0) {
      return;
    }
    if (this.activeIndex === - 1 && event.key === 'ArrowUp') {
      return;
    }
    event.preventDefault();
    if (this.showList && this.filteredItems.length > 0) {
      if (event.key === 'ArrowUp') {
        this.activeIndex = this.activeIndex - 1;
      } else if (event.key === 'ArrowDown') {
        this.activeIndex = this.activeIndex + 1;
      } else if (event.key === 'Enter') {
        this.selectItem(this.filteredItems[this.activeIndex]);
      }
    }
  }

  selectItem(item: Item) {
    this.selectedItem = item;
    this.showList = false;
    this.itemSelected.emit(item);
    if (this.isClearAfterSelect) {
      this.clearTypeAhead();
    }
  }

}
