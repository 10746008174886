import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPlayers'
})
export class FilterPlayersPipe implements PipeTransform {

  transform(players: any[], query: string): string[] {
    if (!query) {
      return players; // No filtering if the query is empty
    }

    query = query.toLowerCase(); // Make the query case-insensitive

    // Filter based on the name or ID within the string
    return players.filter(player => player.name.toLowerCase().includes(query));
  }
}