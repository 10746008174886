<div class="match-bar-wrapper">
  <div class="match-bar">
    <div class="left">
      <img class="logo" [src]="awayImgURL" />
      <div class="team-info">
        <div class="name">{{ awayTeam }}</div>
        <div class="count">{{ awaySelectedCount }} Selected</div>
      </div>
    </div>

    <div class="right">
      <div class="bar-action-btn" (click)="onClickSortByTeam(awayTeam)">
        <div class="icon">
            <svg-icon
            [svgStyle]="{ 'height.px': 16 }"
            src="/assets/icons/featured-sort.svg"
          ></svg-icon>
        </div>
        <div class="label">Players</div>
      </div>
      <div class="bar-action-btn" (click)="onClickCheckTeam(awayTeam)">
        <div class="icon green">
            <svg-icon
            [svgStyle]="{ 'height.px': 16 }"
            src="/assets/icons/circle-check.svg"
          ></svg-icon>
        </div>
        <div class="label">Players</div>
      </div>
      <div class="bar-action-btn" (click)="onClickUnCheckTeam(awayTeam)">
        <div class="icon red">
            <svg-icon
            [svgStyle]="{ 'height.px': 16 }"
            src="/assets/icons/circle-x.svg"
          ></svg-icon>
        </div>
        <div class="label">Players</div>
      </div>
    </div>
  </div>

  <div class="match-bar">
    <div class="left">
      <img class="logo" [src]="homeImgURL" />
      <div class="team-info">
        <div class="name">{{ homeTeam }}</div>
        <div class="count">{{ homeSelectedCount }} Selected</div>
      </div>
    </div>

    <div class="right">
        <div class="bar-action-btn" (click)="onClickSortByTeam(homeTeam)">
          <div class="icon">
              <svg-icon
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/featured-sort.svg"
            ></svg-icon>
          </div>
          <div class="label">Players</div>
        </div>
        <div class="bar-action-btn" (click)="onClickCheckTeam(homeTeam)">
          <div class="icon green">
              <svg-icon
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/circle-check.svg"
            ></svg-icon>
          </div>
          <div class="label">Players</div>
        </div>
        <div class="bar-action-btn" (click)="onClickUnCheckTeam(homeTeam)">
          <div class="icon red">
              <svg-icon
              [svgStyle]="{ 'height.px': 16 }"
              src="/assets/icons/circle-x.svg"
            ></svg-icon>
          </div>
          <div class="label">Players</div>
        </div>
      </div>
    </div>

    <div class="global-bar">
      <div class="bar-action-btn" (click)="onClickCheckMatch()">
        <div class="icon green">
            <svg-icon
            [svgStyle]="{ 'height.px': 16 }"
            src="/assets/icons/circle-check.svg"
          ></svg-icon>
        </div>
        <div class="label">Game</div>
      </div>
      <div class="bar-action-btn" (click)="onClickUnCheckMatch()">
        <div class="icon red">
            <svg-icon
            [svgStyle]="{ 'height.px': 16 }"
            src="/assets/icons/circle-x.svg"
          ></svg-icon>
        </div>
        <div class="label">Game</div>
      </div>
    </div>

    <div class="close-btn" (click)="onClickCloseBar()">
        <div class="icon">
            <svg-icon
            [svgStyle]="{ 'height.px': 13 }"
            src="/assets/icons/close-solo.svg"
          ></svg-icon>
        </div>
    </div>

  </div>
