import { Component, Input } from '@angular/core';

@Component({
  selector: 'new-filter-item',
  templateUrl: './new-filter-item.component.html',
  styleUrls: ['./new-filter-item.component.scss']
})
export class NewFilterItemComponent {
  @Input() newFilter: any;


  onClickOperator(value) {
    this.newFilter.operator = value;
  }
}
