import { Component, Input } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'lineup-stats',
  templateUrl: './lineup-stats.component.html',
  styleUrls: ['./lineup-stats.component.scss']
})
export class LineupStatsComponent {
  @Input() jobData: any;

  lineupsList: string = 'projected';

  handleLineupToggle(event) {
    this.lineupsList = event;
    setTimeout(() => {
      anime({
        targets: '.summary-block',
        translateY: ['16px', 0],
        opacity: [0, 1],
        duration: 700,
        direction: 'normal',
        delay: anime.stagger(100),
      });
    }, 400);
  }
}
